import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
  Col,
  Container,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Button,
} from "reactstrap";
import ClickHere from "../assets/img/clickhere.png";
import { useSelector, useDispatch } from "react-redux";
import { getAtheletes } from "../store/actions/athleteActions";
import { toast } from "react-toastify";
import { fetchCollegeStates } from "../store/actions/athleteActions";
import { fetchSchoolStates } from "../store/actions/athleteActions";
import InstantChatModal from "../components/InstantChatModal";
import ArrangeChatAppointmentModal from "../components/ArrangeChatAppointmentModal";
import { Link } from "react-router-dom/cjs/react-router-dom";

function SearchForPlayer() {
  let history = useHistory();
  let athlete = useSelector((state) => state.athlete);
  const { schoolStates, collegeStates } = useSelector((state) => state.athlete);

  let dispatch = useDispatch();
  const [athletes, setAthletes] = useState([]);
  const [id, setId] = useState("");
  const [searchByName, setSearchByName] = useState("");
  const [state, setState] = useState("");
  useEffect(() => {
    if (collegeStates.length == 0) {
      dispatch(fetchCollegeStates());
    }
    if (schoolStates.length == 0) {
      dispatch(fetchSchoolStates());
    }
    localStorage.removeItem("teamId");
    localStorage.removeItem("uniqData");
    localStorage.removeItem("ByteamID");
    dispatch(getAtheletes());
  }, []);
  if (athletes.length > 0) {
    const uniq = [...new Set(athletes)];
    if (uniq.length > 0) {
      history.push({
        pathname: "/search-player/filter-player",
        state: {
          uniq,
          id,
          state,
        },
      });
    }
  }
  const [isShow, setIsShow] = useState(false);
  const toggleInstantChat = () => {
    setIsShow(!isShow);
  };
  const [isShowAppointment, setIsShowAppointment] = useState(false);
  const toggleInstantChatAppointment = () => {
    setIsShowAppointment(!isShowAppointment);
  };
  return (
    <>
      <Container fluid className="mb-5">
        <Row className=" justify-content-center ">
          <Col lg="12" sm="12">
            <div className="complaint-card mt-4">
              <div className="d-flex justify-content-between flex-row-reverse align-items-center pl-4 pt-4 pr-5">
                <p className="font-weight-bold">
                  {" "}
                  For booking a CHAT lesson, we enter the Registration Number of
                  teacher-athlete whom fan wishes to hire (not entering the
                  name). Name appears as double-check. To help fan, we give
                  reminder notice where to find Registration number
                </p>
              </div>
              <div className="px-4">
                <Row>
                  <Col md="3">
                    <Button color="primary" onClick={() => toggleInstantChat()}>
                      ARRANGE <br /> INSTANT CHAT
                    </Button>
                  </Col>
                  <Col md="6" className="p-0 px-3 px-md-0 mt-2 mt-md-0">
                    <p className="font-weight-bold text-small">
                      NOTE: After booking chat and ascrowing funds, Users have
                      up to 1 week to complete chat.Users can email each other
                      to change chat schedule or to choose the platform they
                      mutually prefer for their chat, such as Skype, Zoom,
                      FaceTime, What'sApp, Google Duo, Facebook Messenger, etc.
                    </p>
                  </Col>
                  <Col md="3">
                    <Button
                      color="primary"
                      onClick={() => toggleInstantChatAppointment()}
                    >
                      Arrange chat by <br /> Appointment
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="px-4">
                <p className="font-weight-bold">
                  NOTE: Fan and Athlete can communicate with each other by email
                  about time zones or if adjustment to the chat schedule is
                  needed.TIMELY connecting a video chat is best done with
                  Fan/Athlete cooperation using email messages.
                </p>
              </div>
              <div className="complaint-form pt-0">
                <p className="auth-desc-title mt-0 ml-0 text-center d-flex     justify-content-between flex-column flex-md-row">
                  "SEARCH for athlete for Chat"
                  <Link to="/home" className="text-white text-decoration-none">
                    <Button color="primary" className="px-3 py-0 mt-3 mt-md-0">
                      Back to
                      <br /> INFO <br /> page
                    </Button>
                  </Link>
                </p>
                <Row className="mt-5 align-items-center ">
                  <Col md="6" align="center">
                    <InputGroup className="custom-input-group">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Input
                        className=" ml-2"
                        value={id}
                        onChange={(e) => {
                          setId(e.target.value);
                          setState("");
                          setSearchByName("");
                        }}
                      />
                    </InputGroup>
                    <Label className="auth-desc-subtitle ml-0 mt-2">
                      Find By Registration #
                    </Label>

                    <br />
                  </Col>
                  <Col md={6}>
                    <h5>
                      Not sure of registration number of your favorite athlete?
                      Find it at page called{" "}
                      <a
                        href="https://fhp-fans.web.app/fan/search-player"
                        target="_blank"
                        className="text-dark"
                      >
                        <span className="font-weight-bold">
                          {" "}
                          Search for Player.
                        </span>
                      </a>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" align="center" className="mt-md-0 ">
                    {id != "" &&
                      (athlete.athletes.length > 0 &&
                      athlete?.athletes?.filter(
                        (ath) => ath.athleteID == id && !ath.blocked
                      ).length > 0 ? (
                        <h4 className="text-success">
                          {
                            athlete?.athletes?.filter(
                              (ath) => ath.athleteID == id
                            )[0].first_name
                          }{" "}
                          {
                            athlete?.athletes?.filter(
                              (ath) => ath.athleteID == id
                            )[0].last_name
                          }
                        </h4>
                      ) : (
                        <p className="text-danger">No Player Found!</p>
                      ))}
                    <Button
                      className="bg-transparent b-none pt-3"
                      onClick={(e) => {
                        e.preventDefault();
                        if (state != "") {
                          let resArr =
                            athlete?.athletes.length > 0 &&
                            athlete?.athletes?.filter((ath) => {
                              if (
                                ath.state
                                  .toLowerCase()
                                  .includes(state.toLowerCase())
                              ) {
                                return ath;
                              }
                            });
                          if (resArr.length > 0 && !resArr[0].blocked) {
                            setAthletes(resArr);
                          } else {
                            toast.warning("No Player Found!");
                          }
                        } else if (id != "") {
                          let resArr =
                            athlete.athletes.length > 0 &&
                            athlete?.athletes?.filter(
                              (ath) => ath.athleteID == id
                            );
                          if (resArr.length > 0 && !resArr[0].blocked) {
                            setAthletes(resArr);
                          } else {
                            toast.warning("No Player Found!");
                          }
                        } else if (searchByName != "") {
                          let resArr =
                            athlete.athletes.length > 0 &&
                            athlete.athletes.filter((ath) => {
                              let name = ath.first_name + " " + ath.last_name;
                              return (
                                name.toLowerCase() ==
                                  searchByName.toLowerCase() ||
                                ath.first_name.toLowerCase() ==
                                  searchByName.toLowerCase() ||
                                ath.last_name.toLowerCase() ==
                                  searchByName.toLowerCase()
                              );
                            });
                          if (resArr.length > 0 && !resArr[0].blocked) {
                            setAthletes(resArr);
                          } else {
                            toast.warning("No Player Found!");
                          }
                        } else {
                        }
                      }}
                    >
                      <img src={ClickHere} alt="Click Here" height="60px" />
                      <br />
                      <Label className="auth-desc-subtitle ml-0 mt-2 ">
                        Click to Search
                      </Label>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <InstantChatModal isShow={isShow} toggleInstantChat={toggleInstantChat} />
      <ArrangeChatAppointmentModal
        isShow={isShowAppointment}
        toggleInstantChat={toggleInstantChatAppointment}
      />
    </>
  );
}

export default SearchForPlayer;
