const initialState = {
  offPlatformSiteData: [],
  getFanReviews: {},
  getAthleteReviews: {},
  hasMoreDocument: {},
  isUserExist: false,
  isAthleteUserExist: false,
  loader: false,
  totalChat: null,
  totalAthleteChat: null,
  name: "",
  lastDocument: "",
};

const offPlatformSiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET-OFF-PLATFORM-DATA":
      return {
        ...state,
        offPlatformSiteData: [
          ...state.offPlatformSiteData,
          ...action.payload.data,
        ],
        hasMoreDocument: action.payload.hasMoreDocument,
        lastDocument: action.payload.lastDocument,
      };
    case "GET-OFF-PLATFORM-DATA-EMPTY":
      return {
        ...state,
        offPlatformSiteData: action.payload.data,
        hasMoreDocument: action.payload.hasMoreDocument,
        lastDocument: action.payload.lastDocument,
      };
    case "UPDATE_OFF_PLATFORM_DATA":
      let index = action.payload;
      let upDateData = [...state.offPlatformSiteData];
      upDateData[index].showRating = true;
      return {
        ...state,
        offPlatformSiteData: upDateData,
      };
    case "GET_FAN_ALL_REVIEWS":
      return {
        ...state,
        getFanReviews: action.payload.counts,
        isUserExist: action.payload.isUser,
        totalChat: action.payload.totalChat,
        name: action.payload.name,
      };
    case "GET_ATHLETE_ALL_REVIEWS":
      return {
        ...state,
        getAthleteReviews: action.payload.counts,
        isAthleteUserExist: action.payload.isUser,
        totalAthleteChat: action.payload.totalChat,
        name: action.payload.name,
      };
    case "LOADER":
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default offPlatformSiteReducer;
