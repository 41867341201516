import React from "react";
import { Button, Container } from "reactstrap";
import { Link } from "react-router-dom";
const TermsAndConditionForAthlete = () => {
  return (
    <>
      <div className="p-4">
        <Link to="/home-for-athlete">
          <Button color="primary">Back</Button>
        </Link>{" "}
      </div>
      <Container className="term-and-condition-main">
        <div>
          <p className="termsPoint">
            <h2>Option for Making Quick Change to Your Chat Rate:</h2>
            If you receive a message from a Fan who is registered at FHP and
            wants to VIDEO CHAT with you 'NOW',i.e.,immediately, you can Use
            'Off platform to site' to make a change to your chat rate for that
            Instant Chat if you like,provided you do so before you and the Fan
            agree on chat terms.
          </p>
        </div>
      </Container>
    </>
  );
};

export default TermsAndConditionForAthlete;
