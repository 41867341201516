import React from 'react'

const PublicView = (props) => {
  return (
    <div>
      {props.children}  
    </div>
  )
}

export default PublicView
