import React from "react";
import { useSelector } from "react-redux";
import AthleteLibrary from "../components/library/AthleteLibrary";
import FanLibrary from "../components/library/FanLibrary";

const Library = () => {
  const { uid, user } = useSelector((state) => state.authUser);
  return <>{user?.role == "athlete" ? <FanLibrary /> : <AthleteLibrary />}</>;
};

export default Library;
