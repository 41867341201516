import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Container, Input, Row } from "reactstrap";
import { athleteAnnualEarning } from "../store/actions/athleteEarningAction";
import moment from "moment/moment";
import { Menu, MenuItem } from "@mui/material";
import { CSVLink } from "react-csv";

const AthleteAnnualEarning = () => {
  const [selectedOption, setSelectedOption] = useState("2023");
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    dispatch(
      athleteAnnualEarning(uid, selectedOption, () => {
        setLoader(false);
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      athleteAnnualEarning(uid, selectedOption, () => {
        setLoader(false);
      })
    );
  }, [selectedOption]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (event) => {
    setSelectedOption(event.target.innerText);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { uid, user } = useSelector((state) => state.authUser);
  const [name, setName] = useState(user?.first_name + " " + user?.last_name);
  const date = moment.unix(user?.created_at.seconds).format("DD MMM YYYY");
  const { athleteAnnualEarnings, totalEarning } = useSelector(
    (state) => state.athleteEarning
  );
  const csvLink = useRef(null);
  const headers = [
    { label: "Name", key: "name" },
    { label: "AthleteID", key: "athleteId" },
    { label: "DateRange", key: "dateRange" },
    { label: "Chats Amount Earned", key: "chatsAmountEarned" },
    { label: "Group Chats Amount Earned", key: "groupChatsAmountEarned" },
    { label: "New Badge Subscription", key: "badgesAmountEarned" },
    { label: "Monthly Total ", key: "monthlyTotal" },
  ];
  return (
    <>
      <div>
        <Row className="w-100 px-2 px-md-4 mt-3">
          <Col
            md="6"
            lg="4"
            className="mb-3 mb-md-0 d-flex flex-column justify-content-center"
          >
            <span className="mr-2 text-nowrap mb-2">Athlete Id (reg#)</span>
            <Input value={user.athleteID} placeholder="Athlete id" readOnly />
          </Col>
          <Col
            md="6"
            lg="3"
            className="mb-3 mb-md-0 d-flex flex-column justify-content-center"
          >
            <span className="mr-2 mb-2">Athlete first name , last name </span>
            <Input value={name} placeholder="Enter Name" readOnly />
          </Col>
          <Col
            md="6"
            lg="3"
            className="mb-3 mb-md-0 d-flex flex-column justify-content-center"
          >
            <span className="mr-2 mb-2 text-nowrap">Date Register</span>
            <Input value={date} placeholder="Enter date" readOnly />
          </Col>
          <Col
            md="6"
            lg="2"
            className="d-flex justify-content-center align-items-center"
          >
            <Button color="primary" onClick={handleClick}>
              Select Year
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleMenuItemClick}>2023</MenuItem>
              <MenuItem onClick={handleMenuItemClick}>2024</MenuItem>
              <MenuItem onClick={handleMenuItemClick}>2025</MenuItem>
            </Menu>
          </Col>
        </Row>
        <Container className="my-3">
          <Card className="p-3 overflow-auto">
            {loader ? (
              <div className="spinner-border text-primary loader mx-auto"></div>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">{selectedOption} Earning</th>
                    <th scope="col">$ Chat (distributed.to athlete)</th>
                    <th scope="col">$ Group chat (distributed.to athlete)</th>
                    <th scope="col">$ badges (to athlete)</th>
                    <th scope="col">Athlete Monthly Total</th>
                  </tr>
                </thead>
                <tbody>
                  {athleteAnnualEarnings?.stats?.length > 0 ? (
                    athleteAnnualEarnings.stats?.map((items, index) => {
                      console.log(items);
                      const badgesAmountEarned = parseFloat(
                        items.badgesAmountEarned
                      );
                      const subscriptionsAmountEarned = parseFloat(
                        items.subscriptionsAmountEarned
                      );
                      const result = (
                        badgesAmountEarned + subscriptionsAmountEarned
                      ).toFixed(2);

                      return (
                        <tr key={index}>
                          <th scope="row">{items.dateRange}</th>
                          <td>
                            Chat total ${items.chatsAmountEarned} to {name} for{" "}
                            {items.dateRange}
                          </td>
                          <td>
                            Chat total ${items.groupChatsAmountEarned} to {name}{" "}
                            for {items.dateRange}
                          </td>
                          <td>
                            Badges licenses total ${result} to {name} for{" "}
                            {items.dateRange}
                          </td>
                          <td>${items.monthlyTotal}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </Card>
          <div
            className="mt-3 d-flex flex-column justify-content-center
align-items-end"
          >
            <div className="text-center mt-3">
              <p>
                Total for year <br /> ${athleteAnnualEarnings.annualTotal}
              </p>
              <CSVLink
                ref={csvLink}
                headers={headers}
                filename={`Annual earnings ${moment(new Date()).format(
                  "DD-MM-YYYY"
                )}.csv`}
                data={
                  athleteAnnualEarnings.success
                    ? athleteAnnualEarnings.stats.map((item) => {
                        let chatAmount =
                          " $ " +
                          " " +
                          parseFloat(item.chatsAmountEarned).toFixed(2);
                        let groupChatAmount =
                          " $ " +
                          " " +
                          parseFloat(item.groupChatsAmountEarned).toFixed(2);
                        let badges =
                          "$" +
                          " " +
                          (
                            parseFloat(item.badgesAmountEarned) +
                            parseFloat(item.subscriptionsAmountEarned)
                          ).toFixed(2);
                        let monthly =
                          "$" + " " + parseFloat(item.monthlyTotal).toFixed(2);
                        return {
                          name: name,
                          athleteId: user.athleteID,
                          dateRange: item.dateRange,
                          chatsAmountEarned: chatAmount,
                          groupChatsAmountEarned: groupChatAmount,
                          badgesAmountEarned: badges,
                          monthlyTotal: monthly,
                        };
                      })
                    : []
                }
                className="ml-auto btn btn-primary mr-2"
              >
                DOWNLOAD
              </CSVLink>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AthleteAnnualEarning;
