import firebase from "../../config/firebase";
export const fetchCollegeStates = () => async (dispatch) => {
  firebase
    .firestore()
    .collection("states")
    .orderBy("name", "asc")
    .onSnapshot((query) => {
      var tempStates = [];
      query.forEach((doc) => {
        tempStates.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: "GET_COLLEGE_STATES",
        payload: tempStates,
      });
    });
};

export const fetchSchoolStates = () => async (dispatch) => {
  firebase
    .firestore()
    .collection("school_states")
    .orderBy("name", "asc")
    .onSnapshot((query) => {
      var tempStates = [];
      query.forEach((doc) => {
        tempStates.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: "GET_SCHOOL_STATES",
        payload: tempStates,
      });
    });
};
export const getCollegeCity = (state) => async (dispatch) => {
  firebase
    .firestore()
    .collection("test-colleges-universities")
    .where("state", "==", state)
    .orderBy("city", "asc")
    .onSnapshot((query) => {
      let temp = [];
      query.forEach((doc) => {
        temp.push({
          ...doc.data(),
        });
      });
      dispatch({ type: "GET_CITY_BY_STATE", payload: temp });
    });
};

export const getSchoolCounty = (state) => async (dispatch) => {
  firebase
    .firestore()
    .collection("test_schools")
    .where("state", "==", state)
    .orderBy("county", "asc")
    .onSnapshot((query) => {
      let temp = [];
      query.forEach((doc) => {
        temp.push({
          ...doc.data(),
        });
      });
      dispatch({ type: "GET_COUNTY_BY_STATE", payload: temp });
    });
};
export const getSports = () => async (dispatch) => {
  firebase
    .firestore()
    .collection("sports")
    .orderBy("sport", "asc")
    .onSnapshot((query) => {
      let temp = [];
      query.forEach((doc) => {
        temp.push({
          ...doc.data(),
        });
      });
      dispatch({ type: "GET_SPORTS", payload: temp });
    });
};
export const getAtheletes = () => {
  return async (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .where("role", "==", "athlete")
      .onSnapshot((query) => {
        var athletes = [];
        query.forEach((doc) => {
          athletes.push({ id: doc.id, ...doc.data() });
        });
        dispatch({
          type: "GET_ATHLETES",
          payload: athletes,
        });
      });
  };
};

export const getTimeSlots = () => {
  return async (dispatch) => {
    const timeSlotsRef = firebase
      .firestore()
      .collection("a4b_athelete_timeslots");

    const querySnapshot = await timeSlotsRef.get();

    const filteredTimeSlots = querySnapshot.docs
      .filter((doc) => {
        const { date, start } = doc.data();
        const newDate = new Date(`${date}T${start}:00`);
        const timestamp = newDate.getTime();
        const currentTimestamp = Date.now();

        if (timestamp > currentTimestamp) {
          const differenceInMs = timestamp - currentTimestamp;
          const differenceInHours = Math.floor(
            differenceInMs / (1000 * 60 * 60)
          );

          return differenceInHours <= 48;
        }

        return false;
      })
      .map((doc) => doc.data());

    dispatch({
      type: "TIME_SLOTS",
      payload: filteredTimeSlots,
    });
  };
};

export const fetchInstitutes = () => async (dispatch) => {
  firebase
    .firestore()
    .collection("institution")
    .orderBy("state", "asc")
    .onSnapshot((query) => {
      var tempInstitutes = [];
      query.forEach((doc) => {
        tempInstitutes.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: "GET_INSTITUTES",
        payload: tempInstitutes,
      });
    });
};
export const fetchHighSchoolInstitutes = () => async (dispatch) => {
  firebase
    .firestore()
    .collection("high_school_institution")
    .orderBy("state", "asc")
    .onSnapshot((query) => {
      var tempInstitutes = [];
      query.forEach((doc) => {
        tempInstitutes.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: "GET_HIGH_SCHOOL_INSTITUTES",
        payload: tempInstitutes,
      });
    });
};
export const singleAthlete = (id) => {
  return async (dispatch) => {
    dispatch(addSchoolLoader(true));
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .onSnapshot((query) => {
        dispatch(addSchoolLoader(false));
        dispatch({
          type: "GET_SINGLE_ATHLETE",
          payload: query.data(),
        });
      });
  };
};
export const addSchoolLoader = (val) => async (dispatch) => {
  dispatch({ type: "ADD_SCHOOLS_LOADER", payload: val });
};
export const getRandomAthletes = () => {
  return async (dispatch) => {
    const querySnapshot = await firebase
      .firestore()
      .collection("users")
      .where("role", "==", "athlete")
      .limit(50)
      .get();

    const athletes = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const randomAthletes = [];

    while (randomAthletes.length < 4 && athletes.length > 0) {
      const randomIndex = Math.floor(Math.random() * athletes.length);
      randomAthletes.push(athletes[randomIndex]);
      athletes.splice(randomIndex, 1);
    }

    dispatch({
      type: "GET_RANDOM_ATHLETES",
      payload: randomAthletes,
    });
  };
};

export const fetchTopics =
  (onComplete = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("a4b_topics")
      .onSnapshot((query) => {
        var temp = [];
        query.forEach((doc) => {
          temp.push({ id: doc.id, ...doc.data() });
        });
        dispatch({
          type: "GET_TOPICS",
          payload: temp,
        });
        onComplete();
      });
  };
