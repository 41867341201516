import React from "react";
import { useSelector } from "react-redux";
import FanManager from "../components/manager/FanManager";
import AthleteManager from "../components/manager/AthleteManager";
const Managers = () => {
  const { uid, user } = useSelector((state) => state.authUser);
  return <>{user?.role == "fan" ? <FanManager /> : <AthleteManager />}</>;
};

export default Managers;
