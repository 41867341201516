import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import { logout } from "../store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import headerImg from "../assets/img/CHATJPG2.jpg";
import FiveWaysToStartChatModal from "./FiveWaysToStartChatModal";
function Header() {
  const { user, uid } = useSelector((state) => state.authUser);
  let dispatch = useDispatch();

  const textToCopy = `(Look into the camera, smile, and speak clearly.)\n
  Hi Sports Fans,\n
  My name is _________________ and my sport is___________________. I am a Brand Ambassador for Fans Help Players brand. 
  At Fans Help Players dot com, I’ll be available to video chat with you and 
  answer your questions. 
  You can arrange a chat by following the steps on your Chat Home Page. 
  Thanks, and I hope to see you soon at Fans Help Players dot com 
  `;
  const [isCopy, setIsCopy] = useState(true);
  const handleCopyText = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied successfully!");
        setIsCopy(false);
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle2 = () => {
    setModal2(!modal2);
    setIsCopy(true);
  };
  const [isShow, setIsShow] = useState(false);
  const toggleInstantChat = () => {
    setIsShow(!isShow);
  };

  const handleDownload = () => {
    const fileURL =
      "https://firebasestorage.googleapis.com/v0/b/fans-help-players.appspot.com/o/SCRIPT_FHP%20.docx?alt=media&token=3a8d752f-19c1-4b86-9430-1505a8c7cb9c&_gl=1*wfs8jn*_ga*MTU4MTIzMTk5NS4xNjgzNzkwODU2*_ga_CW55HF8NVT*MTY5NjIyOTk1NS40NzguMS4xNjk2MjMwNTU2LjQwLjAuMA..";

    const a = document.createElement("a");
    a.href = fileURL;
    a.download = "SCRIPT_FHP.docx";
    a.click();
  };
  return (
    <>
      <div className="text-center mt-3 header-heading">
        <Row
          noGutters
          className={` ${
            user?.role == "fan" && "d-flex justify-content-between"
          }`}
        >
          <Col lg="3">
            <img src={headerImg} className="header_img" alt="" />
          </Col>
          <Col
            lg={user?.role == "fan" ? "5" : "6"}
            className="d-flex flex-column mt-lg-4
    align-items-center"
          >
            <h1>
              HOME PAGE FOR CHATS-{user?.role == "athlete" ? "ATHLETE" : "FAN"}
            </h1>
            <a href="https://youtu.be/uIWftF7cjug" target="_blank">
              <h5>https://youtu.be/uIWftF7cjug</h5>
            </a>
            <a
              href={
                user?.role == "athlete"
                  ? "https://Youtu.be/zdb8fNnRsuc"
                  : "https://youtu.be/czhgYJumWOw"
              }
              target="_blank"
            >
              <h6>
                {user?.role == "athlete"
                  ? "https://Youtu.be/zdb8fNnRsuc"
                  : "https://youtu.be/czhgYJumWOw"}
              </h6>
            </a>
            {user?.role == "athlete" ? (
              <>
                <Button color="primary" onClick={toggleInstantChat}>
                  FIVE WAYS A TEACHER-ATHLETE CAN START A CHAT
                </Button>
                <h6 className="px-3">
                  <b>
                    HERE's A GREAT IDEA:Make a video telling Fans why you are a
                    great Chat Partner.Post online.Post a link in your profile
                    where it says:'Why i make a great chat Partner'{" "}
                    <span
                      onClick={toggle2}
                      className="text-primary cursor-pointer"
                    >
                      Link
                    </span>{" "}
                    for script{" "}
                  </b>
                </h6>
              </>
            ) : (
              <Button color="primary" onClick={toggle}>
                How Fan Improves Chances to Chat
              </Button>
            )}
          </Col>
          {user?.role == "fan" && (
            <Col lg="4" className="mt-lg-4 p-4 p-lg-0">
              <h3>
                <b>Note:</b> To input your review of a chat, click “Chats”, then
                choose chat type
              </h3>
            </Col>
          )}
        </Row>
      </div>
      <div className="navbarCompMain ">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-width ml-auto navbar-nav mt-2 mt-lg-0 align-items-lg-center px-4 px-lg-0">
              <li className="nav-item">
                <Link className="nav-link " to="/manager">
                  <i className="fa fa-sm iconClr">Manager</i>
                </Link>
              </li>
              {user?.role == "fan" ? (
                <>
                  <li className="nav-item">
                    <Link className="nav-link " to="/favorite">
                      <i className="fa fa-sm iconClr">Favorite</i>
                    </Link>
                  </li>
                </>
              ) : null}
              <li className="nav-item">
                <Link className="nav-link" to="/off-platform-to-site">
                  <i className="fa fa-sm iconClr">Off-Platform to Site</i>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/library">
                  <i className="fa fa-book fa-sm iconClr">Library</i>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard">
                  <i className="fas fa-home fa-sm iconClr"> Dashboard</i>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/q-a">
                  <i className="fas fa-home fa-sm iconClr">Fan Questions</i>
                </Link>
              </li>
              {user?.role == "athlete" ? (
                <li className="nav-item">
                  <Link className="nav-link" to="/time-slot">
                    <i className="fas fa-clock fa-sm iconClr">
                      {" "}
                      Availability Manager
                    </i>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {user?.role == "athlete" ? (
                <>
                  <li class="dropdown nav-item cursor-pointer mt-1">
                    <i
                      class="dropdown-toggle nav-link fas fa-envelope fa-sm iconClr"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Chats
                    </i>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li className="nav-item">
                        <Link className="nav-link " to="/chat">
                          <i className="fas fa-envelope fa-sm iconClr">
                            {" "}
                            My Appt Chats
                          </i>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link " to="/group-chat">
                          <i className="d-flex fas fa-envelope fa-sm iconClr">
                            My Group Appt Chats
                          </i>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link " to="/complete-chat">
                          <i className="fa fa-home fa-sm iconClr">
                            My Instant Chats
                          </i>
                        </Link>
                      </li>
                    </div>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link " to="/chat">
                      <i className="fas fa-envelope fa-sm iconClr">
                        {" "}
                        My Appt Chats
                      </i>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/complete-chat">
                      <i className="fa fa-sm iconClr">My Instant Chats</i>
                    </Link>
                  </li> */}
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/ask-questions">
                      <i className="fas fa-home fa-sm iconClr">
                        Ask Questions{" "}
                      </i>
                    </Link>
                  </li>
                  <li class="dropdown nav-item cursor-pointer mt-1">
                    <i
                      class="dropdown-toggle nav-link fas fa-envelope fa-sm iconClr"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Chats
                    </i>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li className="nav-item">
                        <Link className="nav-link " to="/chat">
                          <i className="fas fa-envelope fa-sm iconClr">
                            {" "}
                            My 1 on 1 Appt Chats
                          </i>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link " to="/group-chat">
                          <i className="d-flex fas fa-envelope fa-sm iconClr">
                            My Group Appt Chats
                          </i>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/teacher-athletes">
                          <i className="d-flex fas fa-home fa-sm iconClr">
                            {" "}
                            Athletes Ready to Chat
                          </i>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link " to="/complete-chat">
                          <i className="fa fa-home fa-sm iconClr">
                            My Instant Chats
                          </i>
                        </Link>
                      </li>
                    </div>
                  </li>
                </>
              )}

              {user?.role == "athlete" ? (
                <>
                  <li className="nav-item">
                    <Link className="nav-link " to="/setting">
                      <i className="fas fa-cog fa-sm iconClr"> Setting</i>
                    </Link>
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <Link className="nav-link " to="/suggestion-box">
                    <i className="fas fa-box fa-sm iconClr"> Suggestion Box</i>
                  </Link>
                </li>
              )}

              <li className="nav-item">
                <div className="dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    to="#"
                  >
                    <i className="fas fa-user-circle fa-2x iconClr"></i>
                  </Link>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      className="dropdown-item"
                      to="/login"
                      onClick={() => dispatch(logout(uid))}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <Modal isOpen={modal} size="md">
        <ModalBody className="pl-1">
          <ol>
            <li>Post on social media the ad from FHP 'Fan Post this Ad'</li>
            <li>Send video links from FHP to contacts.</li>
            <li>Tell friends and athletes about Chat opportunities at FHP.</li>
            <li>
              Remind sports fan friends that athletic scholarships seldom cover
              all the incidental expenses at school.
            </li>
            <li>
              Look in Library at FHP to see those athletes who frequently answer
              questions at FHP, contact them.
            </li>
            <li>
              Search athletes registered at FHP,and follow steps of Instant Chat
              or if athlete has calendar here,make appt.
            </li>
          </ol>
          <div className="text-right">
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modal2} size="md">
        <ModalBody className="pl-2">
          <div className="d-flex justify-content-between align-items-center">
            <p>
              <b>(Look into the camera, smile, and speak clearly.)</b>
            </p>
            <Button color="primary" onClick={handleCopyText}>
              {isCopy ? "Copy" : <i class="fa fa-solid fa-check"></i>}
            </Button>
          </div>
          <div>
            <p>
              <b>Hi Sports Fans,</b>
            </p>
            <p>
              <b>
                My name is _________________ and my sport is___________________.
                I am a Brand Ambassador for Fans Help Players brand.
                <br /> At Fans Help Players dot com, I’ll be available to video
                chat with you and answer your questions.
                <br /> You can arrange a chat by following the steps on your
                Chat Home Page. Thanks, and I hope to see you soon at Fans Help
                Players dot com
              </b>
            </p>
          </div>
          <div className="text-right">
            <Button color="primary" className="mr-2" onClick={toggle2}>
              Close
            </Button>
            <Button color="primary" onClick={handleDownload}>
              Download
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <FiveWaysToStartChatModal
        isShow={isShow}
        toggleInstantChat={toggleInstantChat}
      />
    </>
  );
}

export default Header;
