import firebase from "../../config/firebase";
import { RepositoryFactory } from "../../repositroy/RepositoryFactory";
export const athleteAnnualEarning =
  (id, year, onComplete = () => {}) =>
  (dispatch) => {
    const timeZoneRepository = RepositoryFactory.get("athleteStats");
    timeZoneRepository
      .getAthletePaymentStates(`${id}/${year}`)
      .then((response) => {
        const currentTime = response.data;
        dispatch({
          type: "ATHLETE_ANNUAL-EARNING",
          payload: currentTime,
        });
        onComplete();
      })
      .catch((error) => {
        console.error(error);
      });
  };

export const chatEarning =
  (id, limits, onComplete = () => {}) =>
  async (dispatch) => {
    await firebase
      .firestore()
      .collection("new_a4b_chat")
      .where("athleteID", "==", id)
      .where("athlete_payment", "==", true)
      .limit(limits)
      .onSnapshot(async (query) => {
        let temp_data = [];
        for await (let doc of query.docs) {
          if (doc.data().athlete_payout) {
            let user = await firebase
              .firestore()
              .collection("users")
              .where("email", "==", doc.data().fan_email)
              .get();
            let rating = await firebase
              .firestore()
              .collection("a4b_chat_rating")
              .where("chatId", "==", doc.id)
              .get();
            temp_data.push({
              name: user.docs[0].data()?.name,
              athlete_payout: doc.data().athlete_payout,
              rating: rating?.docs[0]?.data(),
            });
          }
        }
        let hasMoreDocument = query.size >= limits;
        dispatch({
          type: "CHAT-EARNING",
          payload: { data: temp_data, chatMore: hasMoreDocument },
        });
        onComplete();
      });
  };

export const badgesEarning =
  (id, lastDocument, onComplete = () => {}) =>
  async (dispatch) => {
    let querya = await firebase
      .firestore()
      .collection("badges_subscriptions_logs")
      .where("athleteId", "==", id)
      .orderBy("createdAt", "desc");
    if (lastDocument != "") {
      querya = querya.startAfter(lastDocument);
    }
    querya.limit(5).onSnapshot(async (query) => {
      let temp_data = [];
      for await (let doc of query.docs) {
        let fan = await firebase
          .firestore()
          .collection("users")
          .doc(doc.data().fanId)
          .get();
        temp_data.push({
          ...doc.data(),
          name: fan.data().name,
        });
      }
      let hasMoreDocument = temp_data.length >= 5;
      if (lastDocument != "") {
        dispatch({
          type: "BADGES-EARNING",
          payload: {
            data: temp_data,
            badgesMore: hasMoreDocument,
            lastDocument: query.docs[temp_data.length - 1],
          },
        });
      } else {
        dispatch({
          type: "BADGES-EARNING-EMPTY",
          payload: {
            data: temp_data,
            badgesMore: hasMoreDocument,
            lastDocument: query.docs[temp_data.length - 1],
          },
        });
      }
      onComplete();
    });
  };

export const subscriptionEarning =
  (id, limits, onComplete = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("license_subscriptions")
      .where("athlete_id", "==", id)
      .limit(limits)
      .onSnapshot(async (query) => {
        let temp_data = [];
        for await (let doc of query.docs) {
          let user = await firebase
            .firestore()
            .collection("users")
            .doc(doc.data().fan_id)
            .get();
          temp_data.push({
            name: user.data().name,
            ...doc.data(),
          });
        }
        let hasMoreDocument = query.size >= limits;
        dispatch({
          type: "SUBSCRIPTION-EARNING",
          payload: { data: temp_data, subscriptionMore: hasMoreDocument },
        });
        onComplete();
      });
  };
