import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/actions/authAction";
function Login(args) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modal, setModal] = useState(true);
  let dispatch = useDispatch();
  let authUser = useSelector((state) => state.authUser);
  let auth = useSelector((state) => state.auth);
  if (authUser.uid) {
    return <Redirect to="/dashboard" />;
  }

  const toggle = () => setModal(!modal);

  return (
    <Container fluid className="m-0 login-container">
      <Row className="inline-block">
        <Col md="6" sm="12" className="mt-5 pt-5">
          <h1 className="text-center mt-5 pt-4">
            SIGN IN WITH YOUR<br></br> FHP CREDENTIALS
          </h1>
        </Col>
        <Col sm="12" md="4" className=" ">
          <div className="card-main card shadow-lg login__rounded p-5 h-75">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <p className="h1 fw-normal mb-0 me-3">Sign in</p>
            </div>

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                let creds = {
                  email: email,
                  password: password,
                };
                dispatch(login(creds));
              }}
            >
              <div>
                <Input
                  required
                  className="mb-4 mt-4 mb-2"
                  placeholder="email@address.com"
                  type="email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="paswordOuter">
                  <Input
                    required
                    wrapperClass="mb-4"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="lg"
                  />
                </div>
                <div className="text-center text-md-start mt-4 pt-2">
                  <Button
                    disabled={!email || !password}
                    className="mb-0 px-5"
                    color="primary"
                  >
                    {auth.loading ? <Spinner size="sm" /> : "Log In"}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>

      {/* <Modal isOpen={modal} toggle={toggle} size="lg" {...args} scrollable="true">
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody style={{ overflowY: "auto" }}>
          <div className="text-center">
            <p>
              This Brand Ambassador Agreement (“Agreement”) is made and entered
              as of _______________ (“Effective Date”), by and between
              FansHelpPlayers, LLC (“FHP”) and _____________________________
              (“Athlete”), each being referred to as individually as a “Party,
              and collectively as the “Parties” throughout this Agreement.
              WHEREAS, FHP operates an online platform (the “Platform”) that
              allows FHP to sell athlete-licensed Products and Services to fans
              including, but not limited to, Mementos, Badges, Videos, and
              Endorsements, and arranges Online Chats between athletes and fans;
              WHEREAS, FHP wishes to engage Athlete for the purpose of Athlete
              preparing and presenting Products and Services at FHP, which
              Products and Services bear Athlete’s NIL rights; and WHEREAS, this
              Agreement sets forth certain terms and conditions pursuant to
              which Athlete may register a profile on the Platform (“Profile”)
              in order to engage in and/or provide the License of the NIL rights
              and the preparing and presenting of said Products and Services (as
              each are defined herein) for the Platform; and WHEREAS, this
              Agreement further sets forth certain terms and conditions pursuant
              to which FHP and Athlete will collaborate for the purpose of
              assisting FHP in said developing, preparing and/or presenting of
              Products and Services, including, but not limited to, hosted
              Online Chats, Videos, Badges, and Endorsements, each utilizing
              Athlete’s NIL Rights (as later defined), to be sold to third-party
              purchasers via the Platform in accordance with the terms set forth
              in this Agreement. NOW, THEREFORE, in consideration of the mutual
              promises and conditions contained in this Agreement, and for other
              good and valuable consideration, the receipt of which are hereby
              acknowledged, the Parties agree as follows:
            </p>
            <h3>TERMS AND CONDITIONS</h3>
            <p>
              Term. The term of this Agreement shall commence on the Effective
              Date and shall remain in full force and effect until the earlier
              of the following: (i) six (6) years following the Effective Date,
              or (ii) the date Athlete is no longer considered an amateur
              athlete and/or permanently elects to no longer participate in
              athletic competition at Athlete’s school (the “Term”). For the
              purpose of this Agreement, “school” shall mean Athlete’s high
              school/college/university and/or any other venue of amateur
              athletic competition pursuant to which Athlete is participant as
              an amateur athlete. Termination. Either Party may elect to
              terminate this Agreement immediately upon written notice to the
              other Party as follows: Material Breach: A Party materially
              breaches any representation, warranty provision, duty, obligation,
              or covenant in this Agreement or refuses to fulfill any material
              obligation under this Agreement and such Party fails to cure such
              breach within thirty (30) days of receiving notice of such breach.
              Additionally, FHP may terminate this Agreement, effective
              immediately upon written notice to Athlete and without penalty or
              further obligation, for any of the following: TOS Violation: FHP
              may terminate this Agreement in the event Athlete violates any
              provision contained within the Platform’s Terms and Conditions.
              High School/NCAA Rules or Laws Violation: FHP may terminate this
              Agreement in the event that: (i) FHP, in its sole and absolute
              discretion, determines or becomes aware that (a) the existence of
              this Agreement, entering into this Agreement, and/or performance
              of this Agreement in any way violates, contradicts or is otherwise
              affected by high school district rules and regulations, NCAA rules
              and/or other applicable university policy or rules governing use
              of Athlete’s NIL Rights (collectively, “NIL Policy”), and/or
              applicable laws (whether federal, state, or otherwise) (the
              “Laws”), and/or (b) any requirements, limitations or provisions
              contained in any NIL Policy and/or Laws, or the actual or
              potential enforcement thereof, renders performance of this
              Agreement impossible or impracticable and/or presents a legal or
              reputational risk to FHP. Upon the expiration or termination of
              this Agreement, all rights granted hereunder shall automatically
              terminate; provided, following the expiration or termination of
              this Agreement in accordance with Section 2(a) or Section 2(b),
              FHP shall have the continued right, in perpetuity, to sell the
              products produced at FHP to the date of said expiration or
              termination and bearing Athlete’s NIL Rights in accordance with
              the terms set forth in this Agreement. License; Exclusivity.
              During the Term, Athlete grants FHP the non-exclusive, worldwide,
              royalty-free (except as otherwise set forth herein), sublicensable
              right and license to use Athlete’s name, image, likeness,
              biographical details, logos, videos, and all other indicia of
              identity specified and/or associated with Athlete (“Athlete’s NIL
              Rights”), solely in connection with: (i) promoting FHP and/or the
              Platform, and (ii) in furtherance of preparing, presenting,
              distributing, marketing, advertising, and selling Products and
              Services on the Platform (the “License”). Athlete agrees that FHP
              will have the right to grant sublicenses and take further acts
              with respect to the License consistent with the enjoyment of its
              grant of the License herein. Except as may arise from a breach of
              this Agreement, Athlete hereby irrevocably waives and agrees not
              to assert any claims based on moral rights or any other theory
              based on FHP’s exercise of the License. All materials submitted by
              Athlete bearing Athlete’s NIL Rights (“Athlete Materials”) shall
              be deemed approved for use in accordance with the terms set forth
              in this Agreement. Notwithstanding, Athlete reserves all rights in
              and to Athlete’s NIL Rights to the extent not granted herein.
              Services. During the Term, Athlete agrees to provide the services
              set forth in Schedule A, attached hereto. Compensation. In
              exchange for the License and Athlete’s performance of Services
              under this Agreement, FHP agrees to compensate Athlete in
              accordance with the terms set forth in Schedule B, attached hereto
              (“Compensation”). Athlete Profile Requirements. In order to
              provide the Services, Athlete must create a Profile on the
              Platform. By creating a Profile on the Platform, Athlete agrees to
              provide true, accurate, current, and complete information, and
              comply with the Terms and Conditions provided via the Platform.
              Athlete agrees not to create a Profile using a false identity or
              providing false information or if Athlete has previously been
              removed or banned from the Platform. Athlete is responsible for
              maintaining the confidentiality of Athlete’s Profile information,
              including Athlete’s username and password. Athlete is responsible
              for all activities that occur on or in connection with Athlete’s
              Profile. Athlete agrees to immediately notify FHP of any
              unauthorized access or use of Athlete’s Profile. Athlete
              acknowledges and agrees that FHP is not responsible or liable for
              any damages, losses, costs, expenses, or liabilities related to
              any unauthorized access to or use of Athlete’s Profile. Profile
              Verification. FHP reserves the right to verify Athlete’s Profile
              information (including, but not limited to, contacting Athlete
              and/or Athlete’s school) in order to ensure Athlete’s eligibility
              to participate on the Platform. In the event FHP determines that a
              Profile is not eligible for participation on the Platform, FHP
              reserves the right to immediately remove the Profile without prior
              warning or written notice. Disclosure. Athlete understands that it
              is Athlete’s sole responsibility to notify and/or report this
              Agreement (including, but not limited to, deal terms and
              compensation) as needed to Athlete’s school, conference, and/or
              any other governing entity responsible for determining athlete’s
              eligibility. FHP acknowledges and agrees that this Agreement is
              subject to the approval of Athlete’s university (or other academic
              institution or regulatory body) as is, or may be, required by
              applicable law. Accordingly, Athlete acknowledges and agrees that
              this Agreement will be available for disclosure to Athlete’s
              school/university/regulatory body prior to and after the execution
              hereof
            </p>
            <p>
              Producing Videos, Badges, Endorsements, Online Chats, Mementos
              and/or other Products and Services. By completing registration and
              creating a Profile on the Platform, Athlete agrees and
              acknowledges each of the following: Athlete authorizes and grants
              FHP an irrevocable license to use Athlete’s NIL Rights to create,
              store, distribute, and sell electronic and physical Products and
              Services with Athlete’s name, image, and/or likeness, the amount
              thereof to be mutually determined by FHP and Athlete; and Athlete
              and FHP shall mutually cooperate to provide hosted chats online,
              the frequency, schedule, and content to be mutually determined by
              FHP and Athlete; and Athlete and FHP shall mutually cooperate to
              determine the purchase price of Products and Services created
              utilizing Athlete’s NIL Rights. FTC Guidelines. Athlete shall
              comply with all applicable laws, rules, and regulations, including
              the Federal Trade Commission’s “Guides Concerning the Use of
              Endorsements and Testimonials in Advertising”, as amended, with
              regard to Athlete’s promotion and/or endorsement of FHP and/or the
              Platform. Platform Ownership. Except as it relates to Athlete
              Materials and/or other content provided by Athlete in accordance
              with Athlete’s participation on the Platform, FHP shall own all
              right, title, and interest in and to: (i) the Platform, including
              all software, ideas, processes, data, text, media, and other
              content available on the Platform (individually, and collectively,
              “Platform Content”); and (ii) FHP’s trademarks, logos, and brand
              (“Marks”). Athlete shall not duplicate, copy, or reuse any portion
              of Platform Content or use the Marks without FHP’s prior express
              written consent. Work for Hire. Athlete agrees and acknowledges
              that all Products shall be created as original files owned by FHP,
              and Athlete acknowledges that all Products and Services and/or
              materials (including, but not limited to, Athlete Materials)
              provided by Athlete’s preparing and presenting for and on behalf
              of FHP under this Agreement shall constitute “works made for hire”
              as defined by United States Copyright Law (17 U.S.C. § 101, et
              seq.) and that all rights, title, and interest in and to all
              materials, ideas, feedback, suggestions, improvements, themes,
              creations, documents, designs, concepts, deliverables, and/or
              copyrights (but expressly excluding Athlete’s NIL Rights)
              (collectively, the “Works”) shall solely and exclusively be owned
              by FHP in perpetuity and throughout the world. In furtherance
              thereof Athlete shall not have, nor lay claim to any rights,
              title, or interest in and to any Works provided hereunder. All
              ownership rights, title, and interest in and to the Works, and any
              and all derivative works made therefrom, will be, become, and
              remain the sole property of FHP during and after the Term. If, for
              any reason, the Works are determined not to be "works made for
              hire" or such doctrine is not effective, then Athlete hereby
              irrevocably assigns, conveys, and otherwise transfers to FHP, and
              its respective successors, licensees, and assigns, in perpetuity,
              all right, title and interest worldwide in and to the Works, and
              all proprietary rights therein, in any and all media now known or
              hereafter developed, including all copyrights, trademarks, design
              patents, trade secret rights, moral rights, and all contract and
              licensing rights, and all ancillary, subsidiary and derivative
              rights contained therein and all elements thereof, and the
              exclusive right to advertise, publicize, market, distribute and/
              or sell product developed in connection with the Works and
              anything contained therein, on the Platform and/or any other
              medium. Platform Feedback. To the extent Athlete submits any
              ideas, suggestions, proposals, plans, or other materials related
              to the Platform (individually, and collectively, “Feedback”),
              Athlete acknowledges and agrees that Athlete is submitting
              Feedback at Athlete’s own risk and that FHP has no obligation
              (including of confidentiality or privacy) with respect to
              Athlete’s Feedback. Athlete grants FHP a non-exclusive,
              royalty-free, fully paid, unlimited, universal, sublicensable
              (through multiple tiers of sublicenses), perpetual, and
              irrevocable license, in any and all manner and media, whether now
              known or hereinafter invented or devised, to reproduce, license,
              distribute, modify, adapt, publicly perform, publicly display,
              create derivative works of (for example, translations,
              adaptations, or other changes), and otherwise use and exploit in
              any manner (including commercially), any and all Feedback.
              Non-Disparagement. Athlete agrees that at no time during and after
              the Term will Athlete disparage FHP or the Platform.
              Confidentiality. The Parties acknowledge and agree that the terms
              of this Agreement, and any and all confidential information
              regarding a Party or its operations that is disclosed by a Party
              to the other in furtherance of this Agreement, including, without
              limitation, information concerning the Parties’ operating and
              marketing methods, pricing practices, sales figures, and/or any
              other information deemed proprietary by the disclosing Party
              (“Confidential Information”) will be treated as confidential and
              will not be disclosed to any third party (except as set forth
              herein) at any time during the Term of this Agreement and
              thereafter or used for any purposes other than for purposes
              contemplated by this Agreement. Each Party agrees to: (i) protect
              the other Party’s Confidential Information using the same standard
              of care it uses to protect its own Confidential Information,
              provided that in no event will a Party use less than a reasonable
              standard of care; and (ii) not disclose the other Party’s
              Confidential Information, except to its affiliates, contractors,
              employees, independent contractors, and agents who express a “need
              to know” and have agreed in writing or are otherwise under a legal
              duty to keep such information confidential at a level no less
              protective than required herein. Each Party (and any affiliates,
              contractors, employees and agents to whom it has disclosed
              Confidential Information) may use the other Party’s Confidential
              Information only to the extent necessary to exercise the rights
              granted and fulfill its duties and obligations contained within
              this Agreement, and each Party acknowledges that it shall be
              responsible for the actions of any of its affiliates, partners,
              contractors, employees, and agents that are in violation hereof.
              All Confidential Information shall be the exclusive property of
              the disclosing Party and shall be returned to the disclosing
              Party, or destroyed, immediately upon the termination or
              expiration of this Agreement, or at any other time at the
              disclosing Party’s request. Each Party may disclose the other
              Party’s Confidential Information when required by law but only to
              the extent necessary to comply with such law and only after such
              Party, if legally permissible: (i) uses commercially reasonable
              efforts to notify the other Party; and (ii) gives the other Party
              the chance to challenge or limit the disclosure. Representations
              and Warranties. Each Party represents, warrants, and covenants to
              the other Party that it has the full right and power to (i) enter
              into this Agreement, (ii) perform all obligations to be performed
              by it hereunder, and (iii) grant all rights hereunder granted, in
              each case without violating the legal or equitable rights of any
              other person or entity. Athlete further represents and warrants
              that, as of the Effective Date and continuing throughout the Term
              of this Agreement, the Athlete Materials, and FHP’s and/or its
              affiliates' exercise of any License granted under this Agreement,
              does not and will not: (i) infringe the rights of any third party;
              or (ii) require the payment by FHP of any royalties, remuneration,
              residuals or any other fees (except as otherwise set forth
              herein); or (iii) result in any tort, injury, damage or harm to
              any third party. Athlete further represents and warrants that, as
              of the Effective Date and continuing throughout the Term of this
              Agreement, no use by Athlete of any function of the FHP platform
              will occur without Athlete first verifying with his or her advisor
              that the function or functions which Athlete contemplates to use
              at FHP is/are a use without risk to his or her amateur status
              under applicable NIL laws, guidelines, and/or policies of
              Athlete’s school or relevant regulatory body. Athlete is solely
              responsible for said pre-verification. Indemnification. Athlete
              shall defend, indemnify and hold harmless FHP, and FHP’s officers,
              directors, employees, representatives, and members, (collectively,
              “FHP Indemnitees”), from and against any and all claims, suits,
              losses, damages and expenses (including reasonable attorney’s fees
              and expenses), resulting from third party claims made or suits
              brought against any FHP Indemnitee to the extent based on any
              action or omissions by or on behalf of Athlete, including, without
              limitation, those based upon: (i) Athlete’s grant of the License;
              (ii) FHP’s use of the Athlete Materials and/or Athlete’s NIL
              Rights in accordance with the terms set forth in this Agreement;
              (iii) any negligent act, error or omission, or willful misconduct
              of Athlete, or any third party acting for or on behalf of Athlete;
              and/or (iv) Athlete’s breach of any term, condition,
              representation, or warranty contained in this Agreement (each a
              “Claim”). If any Claim is brought against an FHP Indemnitee,
              Athlete will defend such Claim at Athlete’s sole expense using
              counsel selected by the FHP Indemnitee. If Athlete fails to take
              timely action to defend such Claim, the FHP Indemnitee may defend
              such Claim at Athlete’s expense, including, without limitation,
              deduction of any Compensation payable under this Agreement.
              Athlete shall cooperate in all reasonable respects with the
              investigation, disclosure and defense of any Claim. Athlete will
              not settle, compromise or otherwise enter into any agreement
              regarding the disposition of any Claim against an FHP Indemnitee
              without the prior written consent and approval of the FHP
              Indemnitee. Limitation of Liability. Notwithstanding Athlete’s
              duty to indemnify FHP in accordance with Section 17 of this
              Agreement, in no event shall either Party be liable to the other
              Party for any special, exemplary, indirect, incidental,
              consequential, or punitive damages of any kind or nature
              whatsoever (including, without limitation, lost revenue, profits,
              savings, or business), whether in an action based on contract,
              warranty, strict liability, tort (including, without limitation,
              negligence) or otherwise, even if such Party has been informed in
              advance of the possibility of such damages, or if such damages
              could have reasonably been foreseen by such Party. In no event
              will FHP’s liability under this Agreement exceed the total amount
              of Compensation paid by FHP to Athlete in the six (6) months
              preceding the act that gave rise to liability. Waiver. The failure
              of either Party to exercise the rights granted to such Party under
              this Agreement upon the occurrence of any of the contingencies set
              forth in this Agreement shall not in any event constitute a waiver
              of any such rights upon the occurrence of any additional such
              contingencies. Notices. Any notice required to be given hereunder
              shall be given by sending the same by email, overnight express
              mail or registered or certified mail, postage prepaid, return
              receipt requested to the address stated herein, or to any
              subsequent address designated by either Party for the purpose of
              receiving notices pursuant to this Agreement. All notices shall be
              deemed validly given: (i) after one (1) business day if sent via
              email or overnight express mail, or (ii) after five (5) business
              days if sent via registered or certified mail. Notices shall be
              sent to the following: If to FHP: 128 DORCHESTER F WEST PALM
              BEACH, FL 33417 Email: fanshelpplayers@gmail.com If to Athlete:
              ______________________ ______________________ Email:
              ________________ Press Release; Promotion. The Parties may, upon
              mutual agreement, issue a press release upon execution of this
              Agreement or issue other public marketing and communications
              materials during the Term detailing and/or promoting the Platform,
              and/or Products or Services bearing Athlete’s NIL Rights. FHP and
              Athlete agree to work together in good faith to undertake mutually
              agreed upon advertising and promotional activities for the
              promotion of the Platform, including, but not limited to, Athlete
              promoting the Platform, FHP, FHP’s release of Badges, Videos,
              Mementos, Endorsements, Online Chats, and the relationship
              provided for herein on Athlete’s official social media and/or
              streaming platforms (including, but not limited to, Twitter,
              Instagram, Twitch, YouTube, and/or Facebook). Severability. In the
              event any provision of this Agreement is determined to be invalid
              by a court of competent jurisdiction, such determination shall in
              no way affect the validity or enforceability of any other
              provision contained herein. Equitable Relief. Athlete acknowledges
              that the License and Athlete’s preparation and presenting of
              Products and/or Services provided pursuant to this Agreement are
              personal and unique, and a breach of this Agreement would cause
              FHP irreparable injury for which there is no adequate remedy at
              law. In the event of Athlete’s breach (or threatened breach) of
              any terms, representations, or warranties contained within this
              Agreement, FHP will have the right to enforce this Agreement and
              any of its provisions by injunction, specific performance, or
              other equitable relief without prejudice to any other legal or
              equitable relief to which it may have for Athlete’s breach (or
              threatened breach) of this Agreement, and without any obligation
              to post a bond or other security or to prove damages. Governing
              Law; Dispute Resolution. This Agreement shall be governed by and
              construed in accordance with the laws of the State of Florida,
              without regard to conflict of law principles thereof. Each Party
              agrees that binding arbitration under the Commercial Rules of
              Arbitration of the American Arbitration Association before a
              single arbitrator shall resolve any dispute or controversy arising
              in connection with this Agreement. Judgment upon any award may be
              entered in any court of competent jurisdiction. The decision of
              the arbitrator shall be binding and conclusive upon the Parties.
              In the event of any legal action or arbitration proceeding brought
              for the enforcement of this Agreement, the prevailing party shall
              be entitled to recover reasonably incurred attorney’s fees and
              costs. Notwithstanding, this agreement to binding arbitration
              shall not prevent either Party from seeking injunctive and/or
              other equitable relief from a court of competent jurisdiction.
              Relationship of the Parties. The Parties agree that nothing herein
              shall be deemed to constitute either Party as a partner, employee,
              joint venture, or agent of the other Party. Neither Party shall
              have the power or authority to bind the other with respect to
              third parties or to represent to third parties that they have such
              authority. Athlete expressly acknowledges and agrees that FHP will
              not withhold any federal, state or local income or unemployment
              insurance or social security taxes from any payments made under
              this Agreement (except where tax withholding is otherwise required
              by law), and that it is the responsibility of Athlete to report
              and pay all income, unemployment insurance, social security and
              other taxes on Athlete’s own behalf. No Third-Party Beneficiaries.
              Nothing contained herein, expressed or implied, is intended or
              shall be construed to confer upon or give to any person or entity,
              other than the Parties to this Agreement and their respective
              successors and permitted assigns, any rights or remedies under or
              by reason of this Agreement. This Agreement, and all provisions
              and conditions hereof, are intended to be, and shall be, for the
              sole and exclusive benefit of the Parties and their respective
              successors and permitted assigns, and not for the benefit of any
              other person or entity. Force Majeure. In the event that either
              Party’s performance of any of its respective duties and
              obligations under this Agreement is prevented or hindered due to
              any act of God, fires, strikes, labor disputes, accidents,
              embargoes, riots, floods, earthquakes, wars, pandemic, epidemic,
              governmental actions, or other circumstances beyond the control of
              such Party (each, a “Force Majeure Event”), then such performance
              shall wholly or partially be suspended during the period in which
              the Force Majeure Event is in existence, and no Party shall be
              liable to the other Party during such period owing to such
              circumstances. Assignment. This Agreement and any rights or
              obligations of Athlete hereunder shall not be assigned or
              delegated, whether by transfer, merger, operation of law or
              otherwise, without FHP’s prior written consent. FHP may assign
              and/or sublicense its rights hereunder to any affiliate or
              successor entity in the event of a merger, reorganization or sale
              of FHP, or substantially all of its assets. All provisions of this
              Agreement are binding upon, shall inure to the benefit of, and are
              enforceable by or against the Parties and their respective heirs,
              executors, administrators or other legal representatives and
              permitted successors and assigns. Entire Agreement. This Agreement
              and schedules attached hereto constitute the entire understanding
              between the Parties with respect to the subject matter of this
              Agreement and supersedes all prior agreements whether written or
              oral. No waiver, modification or addition to this Agreement shall
              be valid unless made in writing and signed by the Parties hereto.
              This Agreement may be executed concurrently in one or more
              counterparts, each of which shall be an original, but all of which
              together shall constitute one and the same instrument. This
              Agreement shall not be binding upon either Party until executed by
              an authorized officer thereof. Platform Terms and Conditions.
              Athlete agrees that Athlete’s participation on the Platform shall
              remain subject to the provisions set forth in Platform’s Terms and
              Conditions; provided, in the event of any conflict between this
              Agreement and the Platform’s Terms and Conditions, the terms set
              forth in this Agreement shall prevail. IN WITNESS WHEREOF, the
              Parties have duly executed this Agreement as of the Effective Date
              written above, wherein execution for Athlete is made effective
              through Athlete’s registration of Athlete’s Profile on the FHP
              website via Athlete’s “Click to Accept” of these terms.
            </p>
            <p>
              ATHLETE FANSHELPPLAYERS, LLC By: ______________________________
              By: ________________________________ Name:
              ________________________ ___ Name: ________________________ ______
              I have authority to bind FHP List Non-Party ADVISOR (optional)
              Name: ____________________________ SCHEDULE A Services Athlete
              Duties and Obligations. During the Term, Athlete shall perform
              each of the following services, as required and/or directed by FHP
              (the “Services”): Athlete shall serve as brand ambassador for the
              Platform (where allowed by relevant NIL law. NIL regulation, and
              school policy and only in circumstances not posing risk to
              Athlete’s amateur status as per advice of Athlete’s advisor),
              including, but not limited to, preparing and presenting FHP
              Products to include Videos, Badges, Endorsements, and Mementos,
              and participating in FHP Services to include Online Chats with
              fans via Zoom or similar platform for the purpose of, among other
              things, teaching fans about general sports topics and/or the
              Platform. Athlete may promote FHP and/or the Platform on Athlete’s
              social media, as mutually agreed by Athlete and FHP (the ’Social
              Media’ Posts). FHP may inform Athlete of suggestions as to when to
              publish such Social Media Posts. Social Media Posts may tag and/or
              mention FHP and/or the Platform through authorized hash tags as
              requested by FHP. Athlete agrees to follow FHP’s direction on
              suggested topics, promotion, and hash tags when reasonable to do
              so. SCHEDULE B Compensation Royalties. Regarding FHP’s sale of
              Products and Services bearing Athlete’s NIL rights, FHP agrees to
              pay Athlete a percentage of Gross Revenue (as defined below)
              earned therefrom as follows: Sales of Mementos bearing Athlete’s
              NIL Rights 88% - Athlete (if no School IP or Manager used) Or 78%
              - Athlete (if School IP used but no Manager used) Or 78% - Athlete
              (if Manager used but no School IP used) Or 68% - Athlete (if
              School IP used and Manager used) Sales of Endorsements, Seasonal
              Badges/Videos, Talking Badges, as bearing Athlete’s NIL Rights 88%
              - Athlete (if no Manager) Or 79.2% - Athlete (if Manager used)
              “Gross Revenue” is defined as all revenue received from the sale
              of a specific Product and/or Service bearing Athlete’s NIL Rights.
              Regarding Athlete’s participation in FHP Products bearing
              Athlete’s NIL rights, FHP agrees that Athlete shall set Athlete’s
              own price within the limits provided at FHP’s Platform, except
              that Athlete and FHP agree that a purchasing Fan shall set the
              price for Endorsement Badge which bears Athlete’s NIL rights, such
              price calculated at rate of one ($1) dollar per week of allowed
              Fan posting of said Endorsement Badge on Fan’s social media, such
              length at least four (4) weeks as recognized by minimum price of
              four ($4) dollars for said Endorsement Badge, and up to one
              thousand ($1000) dollars for an Endorsement Badge price selectable
              by Fan wherein Endorsement Badge bearing Athlete’s NIL rights and
              license allowing Fan up to one thousand weeks of social media
              display of said Endorsement Badge on Fan’s social media. Regarding
              Athlete’s participation in fans’ pay-per-minute chats with
              Athlete, FHP agrees that Athlete shall set Athlete’s own
              “per-5-minute” rate (“PP5M Chat Rate”) within limits set on FHP
              Platform, and FHP agrees to pay Athlete a percentage of Gross
              Earnings (as defined below) earned therefrom, as per fan rating of
              specific chat, as follows: Pay-Per-Minute Chats Satisfactory / No
              Rating: 88% - Athlete if no Manager used 79.2% - Athlete if
              Manager used Unsatisfactory Rating (Rude/Short Time): 53% -
              Athlete if no Manager 47.7%- Athlete if Manager use Unsatisfactory
              Rating (No Show): 23% - Athlete if no Manager used 20.7%-Athlete
              if Manager used “Gross Earnings” is defined as all revenues of any
              type received from hosting of a specific pay-per-minute chat with
              Athlete wherein athlete’s image is shown on screen as per NIL
              rights grant. Royalties shall be calculated on a per item sale
              basis and shall be due and payable as accumulated earnings from
              Athlete wallet on demand, provided the accumulated amount exceeds
              a minimum of twenty-five ($25) dollars, or if not, the account
              continues to accumulate until said level is reached, then
              disbursable.
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal> */}
    </Container>
  );
}

export default Login;
