import React from "react";
import GetOffPlatformData from "../components/GetOffPlatformData";
import AddOffPlatformData from "../components/AddOffPlatformData";
import { useSelector } from "react-redux";

const OffPlatformToSite = () => {
  const { user } = useSelector((state) => state.authUser);
  return (
    <>
      {user?.role == "athlete" ? (
       <AddOffPlatformData/>
      ) : (
        <GetOffPlatformData />
      )}
    </>
  );
};

export default OffPlatformToSite;
