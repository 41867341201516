import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Spinner, ModalBody } from "reactstrap";
import Rating from "@mui/material/Rating";
import { useDispatch, useSelector } from "react-redux";
import {
  athleteChatReviewAction,
  chatReviewAction,
} from "../store/actions/chatAction";

const ReviewModal = (props) => {
  let dispatch = useDispatch();
  const { loading } = useSelector((state) => state.chat);
  const { uid, user } = useSelector((state) => state.authUser);
  const [personable, setPersonable] = useState(3);
  const [reviewComment, setReviewComment] = useState("");
  const [chatResponse, setChatResponse] = useState("satisfied");
  useEffect(() => {}, [chatResponse]);

  const handleGender = (e) => {
    setChatResponse(e.target.value);
  };
  const handleReviewSubmit = () => {
    let obj = {};
    let obj1 = {};
    obj = {
      chatId: props.chatID,
      to_id: props.athleteId,
      from_id: uid,
      Personality: personable,
      reviewComment,
      chatResponse,
      chatType: props.chatType ? props.chatType : "single",
      slotId: props.slotId,
    };
    if (user?.role == "fan") {
      if (props.chatType) {
        obj1 = { is_reviewed_fan: true };
      } else {
        obj1 = { is_reviewed_fan: true, fan_response: chatResponse };
      }
      dispatch(
        chatReviewAction(obj, obj1, () => {
          setPersonable(3);
          setReviewComment("");
          setChatResponse("satisfied");
          props.handleShowReview();
        })
      );
    } else {
      obj1 = { is_reviewed_athlete: true, athlete_response: chatResponse };
      obj.type = "manager";
      dispatch(
        athleteChatReviewAction(obj, obj1, () => {
          setPersonable(3);
          setReviewComment("");
          setChatResponse("satisfied");
          props.handleShowReview();
        })
      );
    }
  };
  return (
    <Modal
      isOpen={props.showReviewModal}
      toggle={props.handleShowReview}
      size="l"
      centered
    >
      <ModalBody>
        <div className="d-flex justify-content-end">
          <div>
            <i onClick={props.handleShowReview} className="fas fa-times"></i>
          </div>
        </div>
        <div>
          <h2 className="text-dark review-desc-subtitle mt-0 text-center">
            Rating for {props.chat_id}
          </h2>
          <form className="my-3">
            <label>Were you satisfied with teaching skills?</label>
            <div className="radio">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="satisfied"
                  onChange={handleGender}
                  checked={chatResponse == "satisfied" ? true : false}
                />
                <label className="form-check-label">Satisfied</label>
              </div>
              <br></br>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  value="RST"
                  onChange={handleGender}
                  checked={chatResponse == "RST" ? true : false}
                />
                <label className="form-check-label">
                  Unsatisfied Rude / Short time
                </label>
              </div>
              <br></br>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio4"
                  value="NS"
                  onChange={handleGender}
                  checked={chatResponse == "NS" ? true : false}
                />
                <label className="form-check-label">Unsatisfied No Show</label>
              </div>
            </div>
          </form>
          <div className="d-flex">
            <p className="text-dark review-desc-points">Remarks:</p>
            <Input
              className=""
              type="text"
              value={reviewComment}
              onChange={(e) => {
                setReviewComment(e.target.value);
              }}
            />
          </div>
          <Button
            className="float-right mt-3"
            color="primary"
            onClick={handleReviewSubmit}
          >
            {loading ? <Spinner size="sm" /> : "Submit"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReviewModal;
