import firebase from "../../config/firebase";
import { toast } from "react-toastify";
export const getSlotManagers =
  (email, onComplete = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .where("managerEmail", "==", email)
      .onSnapshot(async (query) => {
        let temp_data = [];
        for await (let doc of query.docs) {
          let athlete = await firebase
            .firestore()
            .collection("users")
            .doc(doc.data().athleteId)
            .get();
          temp_data.push({
            ...doc.data(),
            id: doc.id,
            athlete: athlete.data(),
          });
        }
        dispatch({
          type: "GET-MANAGER",
          payload: temp_data,
        });
        onComplete();
      });
  };
export const getFanQuestions =
  (email, onComplete = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("a4b_questions")
      .where("email", "==", email)
      .get()
      .then(async (query) => {
        let temp_data = [];
        for await (let doc of query.docs) {
          temp_data.push({
            ...doc.data(),
          });
        }
        dispatch({
          type: "GET-FAN-QUESTIONS",
          payload: temp_data,
        });
        onComplete();
      });
  };
export const addPermanentManager = (obj) => async () => {
  let isManager = await firebase
    .firestore()
    .collection("managers")
    .where("athleteId", "==", obj.athleteId)
    .where("fanId", "==", obj.fanId)
    .get();
  if (isManager.docs.length > 0) {
    toast.warning("Already Permanent Manager");
  } else {
    await firebase.firestore().collection("managers").add(obj);
    toast.success("Add Permanent Manager successfully");
  }
};
export const getAthleteManagers =
  (id, onComplete = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("managers")
      .where("athleteId", "==", id)
      .onSnapshot(async (query) => {
        let temp_data = [];
        for await (let doc of query.docs) {
          let fan = await firebase
            .firestore()
            .collection("users")
            .doc(doc.data().fanId)
            .get();
          temp_data.push({
            ...fan.data(),
            id: doc.id,
            btnLoader: false,
          });
        }
        dispatch({
          type: "GET-ATHLETE-MANAGER",
          payload: temp_data,
        });
        onComplete();
      });
  };
export const getFanManagers =
  (id, onComplete = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("managers")
      .where("fanId", "==", id)
      .onSnapshot(async (query) => {
        let temp_data = [];
        for await (let doc of query.docs) {
          let athlete = await firebase
            .firestore()
            .collection("users")
            .doc(doc.data().athleteId)
            .get();
          temp_data.push({
            athleteId: doc.data().athleteId,
            ...athlete.data(),
            id: doc.id,
          });
        }
        dispatch({
          type: "GET-FAN-MANAGER",
          payload: temp_data,
        });
        onComplete();
      });
  };

export const removeManager = (id) => async (dispatch) => {
  dispatch({
    type: "BTN-LOADER",
    payload: id,
  });
  firebase.firestore().collection("managers").doc(id).delete();
  toast.success("Remove Manager Successfully");
};
