import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const Summary = () => {
  return (
    <>
      <div className="p-4"><Link to="/home"><Button color="primary">Back</Button></Link> </div>
      <Container className="term-and-condition-main">
        <div>
          <p className="text-center terms-title">
            Here is info our Brand Ambassadors often share with Fans
          </p>
          <p className="terms-title p-0">
            <a
              className="text-dark"
              target="_blank"
              href="https://fans-help-players-athlete.web.app"
            >
              FANSHELPPLAYERS.com
            </a>
          </p>
          <p className="termsPoint">
            The FHP site registers{" "}
            <b>student athletes (SA) from colleges across the USA</b>, and from
            high schools in several states. Fans visiting the FHP site can link
            to SA social media posts. Fans visiting the FHP site can{" "}
            <b>
              purchase an NIL Memento, such as Team Spirit badge with endorsing
              SA name and SA photo. Fans can also link to
              other sites of FHP brand (see below).
            </b>
          </p>
        </div>
        <div>
          <p className="terms-title p-0">
            <a
              className="text-dark"
              target="_blank"
              href="https://match4future-platform.web.app"
            >
              MATCH4FUTURE.com
            </a>
          </p>
          <p className="termsPoint">
            The <b>M4F</b> site is a <b>job search and match</b> designed to let{" "}
            <b>student athletes (SA) find an internship</b> in a non-sports
            field.
          </p>
        </div>
        <div>
          <p className="terms-title p-0">
            <a
              className="text-dark"
              target="_blank"
              href="https://athlete-for-brand.web.app"
            >
              AMBASSADOR4BRAND.com
            </a>
          </p>
          <p className="termsPoint">
            The <b>A4B</b> site is an employment opportunity (self-employment
            basis) for{" "}
            <b>
              student athletes (SA) to chat with FANS and inform them about the
              FHP brand sites and programs. Student athletes (SA) are paid
            </b>{" "}
             per minute chat charge to <b>FANS</b>.
          </p>
        </div>
        <div>
          <p className="terms-title p-0">
            <a
              className="text-dark"
              target="_blank"
              href="https://collective-alums-main.web.app"
            >
              COLLECTIVEALUMS.com
            </a>
          </p>
          <p className="termsPoint">
            The <b>CA</b> site is an information and communication site.{" "}
            <b>Videos on site explain the FHP programs</b> and give alumni of
            each school an app to assist alums with sending{" "}
            <b>emails to their Alma Mater contacts</b>, such as to Admin and
            Coaches. The emails are designed to
            <b>
              encourage the schools to permit their student athletes to learn
              about and use the FHP sites and programs.
            </b>
          </p>
        </div>
        <div>
          <p className="terms-title p-0">
            <a
              className="text-dark"
              target="_blank"
              href="https://unkle501.web.app"
            >
              UNKLE501.com
            </a>
          </p>
          <p className="termsPoint">
            Scientific research has shown that of the{" "}
            <b>98+% of college athletes who do not go pro in sports</b>, many
            will experience{" "}
            <b>depression and difficulty in finding a post-college career</b>.
            The ‘<b>U</b>nderserved <b>N</b>eedy <b>K</b>ids
            <b>L</b>earning <b>E</b>ntrepreneurship, Inc. site (UNKLE501)
            collects donations to address this major amateur sports problem.
            Monies <b>provide college student athletes</b>, during their years
            of college sports, with an{" "}
            <b>opportunity to participate in non-sports internships</b>. Such
            internship experiences{" "}
            <b>
              give the student athletes valuable insight into non-sports careers
            </b>
            , and helps them make <b>key contacts for later job hunting.</b>
          </p>
          <p className="terms-title p-0">
            <a
              className="text-dark"
              target="_blank"
              href="https://fhp-nft.web.app"
            >
              FHPNFT.com
            </a>
          </p>
          <p className="termsPoint">
            The FHPNFT site prepares{" "}
            <span className="font-weight-bold">
              Non-Fungible Tokens honoring selected Student Athletes’ careers
            </span>
            . These art assets can be offered to buyers with Athletes receiving
            majority of money from the sale.
          </p>
        </div>
      </Container>
    </>
  );
};

export default Summary;
