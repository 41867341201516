import React, { useEffect, useState } from "react";
import { Button, Card, Modal, ModalBody, Table } from "reactstrap";
import { getGroupSlotById } from "../store/actions/favoriteAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const ViewGroupChatParticipantModal = (props) => {
  let [loader, setLoader] = useState(true);

  let dispatch = useDispatch();
  let { groupSlotById } = useSelector((state) => state.favorite);

  useEffect(() => {
    if (props.slotId !== "") {
      setLoader(true);
      dispatch(
        getGroupSlotById(props.slotId, () => {
          setLoader(false);
        })
      );
    }
  }, [props.slotId]);

  const handelClose = () => {
    props.handelViewBookingGroupModal();
  };
  return (
    <Modal
      isOpen={props.ShowViewBookingGroupModal}
      toggle={props.handelViewBookingGroupModal}
      size="xl"
      top="true"
      backdrop="static"
      keyboard={false}
    >
      <ModalBody>
        <Card className="p-2">
          {loader ? (
            <div className="spinner-border text-primary loader mx-auto my-4"></div>
          ) : (
            <Table className="table" responsive>
              <thead>
                <tr>
                  <th>Fan Name</th>
                  <th>Fan Email</th>
                  <th>Payment Date</th>
                  <th>Paid Amount</th>
                </tr>
              </thead>
              <tbody>
                {groupSlotById.length > 0 ? (
                  groupSlotById.map((items, index) => {
                    return (
                      <tr key={index}>
                        <td>{items.name}</td>
                        <td>{items.email}</td>
                        <td>
                          {moment
                            .unix(items.payments.payment_date.seconds)
                            .format("MMM-DD-YYYY")}
                        </td>
                        <td>{items.paidAmount}$</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Card>
        <div className="mt-3 d-flex justify-content-end">
          <Button color="primary" onClick={handelClose}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ViewGroupChatParticipantModal;
