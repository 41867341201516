import firebase from "../../config/firebase";
import { toast } from "react-toastify";
import axios from "axios";

export const addOffPlatformSite =
  (data, data2, onComplete = () => {}) =>
  async (dispatch) => {
    dispatch(offPlateFormLoader(true));
    const chatStart = firebase.firestore.Timestamp.now();
    const chatMinutesInMillis = data2.chat_minutes * 60 * 1000;
    const chatEnd = new firebase.firestore.Timestamp(
      chatStart.seconds + Math.floor(chatMinutesInMillis / 1000),
      (chatStart.nanoseconds + (chatMinutesInMillis % 1000) * 1000000) %
        1000000000
    );
    let timeslotsId = firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .doc().id;
    let question = await firebase
      .firestore()
      .collection("a4b_questions")
      .doc(data.highPriorityQuestion)
      .get();
    firebase
      .firestore()
      .collection("users")
      .where("email", "==", data.email)
      .get()
      .then(async (items) => {
        const foundUser = items.docs.find(
          (item) => item.data()?.email === data.email
        );
        if (foundUser) {
          let timesloteData = {
            created_at: firebase.firestore.Timestamp.now(),
            fan_id: foundUser.id,
            ...data,
          };
          let chatData = {
            created_at: firebase.firestore.Timestamp.now(),
            participants: [foundUser.id, data2.athleteID],
            chat_end: chatEnd,
            chat_start: chatStart,
            time_slot_id: timeslotsId,
            ...data2,
          };
          if (question.data() == undefined) {
            toast.warning("Please enter right question id");
            dispatch(offPlateFormLoader(false));
          } else if (
            question.data().priority == "paid" &&
            question.data().status == "open"
          ) {
            firebase
              .firestore()
              .collection("a4b_athelete_timeslots")
              .doc(timeslotsId)
              .set(timesloteData)
              .then((docRef) => {
                toast.success("Slot added Successfully");
                dispatch(offPlateFormLoader(false));
                onComplete();
              })
              .catch((error) => {
                toast.warning(error);
              });
            firebase.firestore().collection("new_a4b_chat").add(chatData);
            firebase
              .firestore()
              .collection("a4b_questions")
              .doc(data.highPriorityQuestion)
              .update({
                status: "close",
              });
          } else if (question.data().priority == "unpaid") {
            toast.warning(
              "Please enter high Priority question id and this qustion status is close"
            );
            dispatch(offPlateFormLoader(false));
          }
        } else {
          toast.warning("user not found");
          dispatch(offPlateFormLoader(false));
        }
      });
  };

export const getOffPlatformData =
  (id, lastDocument, onComplete = () => {}) =>
  (dispatch) => {
    dispatch(offPlateFormLoader(true));
    let querya = firebase
      .firestore()
      .collection("new_a4b_chat")
      .where("participants", "array-contains", id)
      .where("type", "==", "Instant")
      .where("status", "==", "payment pending")
      .orderBy("created_at", "desc");
    if (lastDocument != "") {
      querya = querya.startAfter(lastDocument);
    }
    querya.limit(5).onSnapshot(async (query) => {
      let data = [];
      for await (let doc of query.docs) {
        let timeSlot = "";
        if (doc.data().time_slot_id) {
          timeSlot = await firebase
            .firestore()
            .collection("a4b_athelete_timeslots")
            .doc(doc.data().time_slot_id)
            .get();
        }
        let user = await firebase
          .firestore()
          .collection("users")
          .doc(doc.data().athleteID)
          .get();

        const countQuery = await firebase
          .firestore()
          .collection("a4b_chat_rating")
          .where("to_id", "==", doc.data().athleteID)
          .get();
        let tempState = countQuery.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        let counts = tempState.reduce((acc, { chatResponse }) => {
          acc[chatResponse] = (acc[chatResponse] || 0) + 1;
          return acc;
        }, {});
        counts.total = tempState.length;
        let count = counts;

        const chatQuery = await firebase
          .firestore()
          .collection("new_a4b_chat")
          .where("athleteID", "==", doc.data().athleteID)
          .where("status", "==", "complete")
          .get();
        const tempTotalChat = chatQuery.docs.map((doc) => ({
          status: doc.status,
        }));
        let totalChat = tempTotalChat.length;
        data.push({
          id: doc.id,
          count: count,
          showRating: false,
          totalChat: totalChat,
          timeSlot: timeSlot.data(),
          chat_end: doc.data().chat_end,
          athleteID: doc.data().athleteID,
          participants: doc.data().participants,
          chat_minutes: doc.data().chat_minutes,
          is_reviewed_fan: doc.data().is_reviewed_fan,
          athlete_chat_rate: doc.data().athlete_chat_rate,
          fanEmail: doc.data().fan_email,
          athleteEmail: doc.data().athlete_email,
          chatId: doc.data()?.chat_id,
          status: doc.data().status,
          chatDay: doc.data().chat_start,
          chatUser: user.data(),
          athleteChatRate: doc.data().athlete_chat_rate,
          chatMinutes: doc.data().chat_minutes,
        });
      }
      let hasMoreDocument = data.length >= 5;
      if (lastDocument != "") {
        dispatch({
          type: "GET-OFF-PLATFORM-DATA",
          payload: {
            data,
            hasMoreDocument,
            lastDocument: query.docs[data.length - 1],
          },
        });
      } else {
        dispatch({
          type: "GET-OFF-PLATFORM-DATA-EMPTY",
          payload: {
            data,
            hasMoreDocument,
            lastDocument: query.docs[data.length - 1],
          },
        });
      }
      onComplete();
      dispatch(offPlateFormLoader(false));
    });
  };
export const upDateOffPlatformData = (index) => (dispatch) => {
  dispatch({
    type: "UPDATE_OFF_PLATFORM_DATA",
    payload: index,
  });
};

export const checkFanReviews =
  (email, onComplete = () => {}) =>
  async (dispatch) => {
    let user = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .where("role", "==", "fan")
      .get();
    if (user.docs.length !== 0) {
      let rating = await firebase
        .firestore()
        .collection("a4b_chat_rating")
        .where("from_id", "==", user.docs[0].id)
        .get();
      let tempStats = rating.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const counts = tempStats.reduce((acc, { chatResponse }) => {
        acc[chatResponse] = (acc[chatResponse] || 0) + 1;
        return acc;
      }, {});
      counts.total = tempStats.length;
      const chatQuery = await firebase
        .firestore()
        .collection("new_a4b_chat")
        .where("fan_email", "==", email)
        .where("status", "==", "complete")
        .get();
      const tempTotalChat = chatQuery.docs.map((doc) => ({
        status: doc.status,
      }));
      let totalChat = tempTotalChat.length;
      dispatch({
        type: "GET_FAN_ALL_REVIEWS",
        payload: {
          counts: counts,
          totalChat: totalChat,
          isUser: true,
          name: user.docs[0].data().name,
        },
      });
      onComplete();
    } else {
      toast.warning("Email not Exist");
      dispatch({
        type: "GET_FAN_ALL_REVIEWS",
        payload: { counts: null, isUser: false },
      });
      onComplete();
    }
  };
export const resetFanReviews = () => (dispatch) => {
  dispatch({
    type: "GET_FAN_ALL_REVIEWS",
    payload: { counts: null, isUser: false },
  });
};
export const checkAthleteReviews =
  (id, onComplete = () => {}) =>
  async (dispatch) => {
    let user = await firebase.firestore().collection("users").doc(id).get();
    let name = user.data().first_name + " " + user.data().last_name;
    let rating = await firebase
      .firestore()
      .collection("a4b_chat_rating")
      .where("to_id", "==", id)
      .get();
    let tempStats = rating.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    const counts = tempStats.reduce((acc, { chatResponse }) => {
      acc[chatResponse] = (acc[chatResponse] || 0) + 1;
      return acc;
    }, {});
    counts.total = tempStats.length;
    const chatQuery = await firebase
      .firestore()
      .collection("new_a4b_chat")
      .where("athleteID", "==", id)
      .where("status", "==", "complete")
      .get();
    const tempTotalChat = chatQuery.docs.map((doc) => ({ status: doc.status }));
    let totalChat = tempTotalChat.length;
    dispatch({
      type: "GET_ATHLETE_ALL_REVIEWS",
      payload: {
        counts: counts,
        totalChat: totalChat,
        isUser: true,
        name: name,
      },
    });
    onComplete();
  };
export const resetAthleteReviews = () => (dispatch) => {
  dispatch({
    type: "GET_ATHLETE_ALL_REVIEWS",
    payload: { counts: null, totalChat: null, isUser: false },
  });
};
export const offPlateFormLoader = (val) => (dispatch) => {
  dispatch({
    type: "LOADER",
    payload: val,
  });
};

export const sendEmailFanPaymentOffPlatFormChat =
  (payload, onSuccess = () => {}) =>
  (dispatch) => {
    const athleteResponseEmailNew = `: Hi  Athlete ${payload.athleteName}. Did you know Fan ${payload.fanName} at FHP just funded escrow to chat with you? Go to your chat home page to follow up.
    `;
    const options = {
      to: payload?.athleteEmail,
      subject: "Amount Escrowed A4B Chat",
      text: athleteResponseEmailNew,
    };
    axios
      .post(
        "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
        options
      )
      .then((response) => {
        toast.success("Email Sent Successfully");
        onSuccess();
      })
      .catch((error) => {
        toast.error("Error occured try Again !");
      });
  };

export const sendEmailAthleteAccept =
  (payload, onSuccess = () => {}) =>
  (dispatch) => {
    const athleteResponseEmailNew = `Hi Sports Fan, Did you know Athlete ${payload.athleteName}  at FHP, ID# ${payload.athleteID} just signaled agreement to chat with you. Go to your chat home page to follow up.
    `;
    const options = {
      to: payload?.fanEmail,
      subject: "A4B Chat Request Accepted",
      text: athleteResponseEmailNew,
    };
    axios
      .post(
        "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
        options
      )
      .then((response) => {
        toast.success("Email Sent Successfully");
        onSuccess();
      })
      .catch((error) => {
        toast.error("Error occured try Again !");
      });
  };
