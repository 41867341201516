// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import authReducer from "./authReducer";
import athleteReducer from "./atheleteReducer";
import chatReducer from "./chatReducer";
import questionReducer from "./questionAnswerReducer";
import libraryReducer from "./libraryReducer";
import offPlatformSiteReducer from "./offPlatformSiteReducer";
import myChatInActionReducer from "./myChatInActionReducer";
import athleteEarningReducer from "./athleteEarningReducer";
import favoriteReducer from "./favoriteReducer";
import managerReducer from "./managerReducer";

export let rootReducer = combineReducers({
  athlete: athleteReducer,
  authUser: authUserReducer,
  auth: authReducer,
  chat: chatReducer,
  question: questionReducer,
  library: libraryReducer,
  offPlatform: offPlatformSiteReducer,
  athleteCompleteChat: myChatInActionReducer,
  athleteEarning: athleteEarningReducer,
  favorite: favoriteReducer,
  manager: managerReducer,
});

export default rootReducer;
