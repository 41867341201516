import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { setIsShowAppointment } from "../store/actions/chatAction";

const InstantChatModal = ({ isShow, toggleInstantChat }) => {
  let dispatch = useDispatch();
  const textToCopy =
    "Hi, I'm a sports fan registered at FansHelpPlayers. I put a question in the chat question database (Question ID#:.........). If you think you could answer this question for me, please EMAIL back to me to let me Know. Then we both visit Home Page For Chat, section 'Off Platform to site'.";
  const handleCopyText = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopy(false);
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };
  const isOpen = useSelector((state) => state.chat.modal);
  const [isCopy, setIsCopy] = useState(true);
  const [isShowTemplate, setIsShowTemplate] = useState(false);
  const toggleTemplate = () => {
    setIsShowTemplate(!isShowTemplate);
    setIsCopy(true);
  };
  return (
    <>
      <Modal
        isOpen={isShow || isOpen}
        toggle={toggleInstantChat}
        size="lg"
        centered
      >
        <ModalBody>
          <Row>
            <Col md="8">
              <ol>
                <li>
                  <a
                    className="text-dark"
                    href="https://athlete-for-brand.firebaseapp.com/ask-questions"
                    target="_blank"
                  >
                    Fan Puts PRIORITY question into database
                  </a>
                </li>
                <li>
                  <a
                    className="text-dark"
                    href="https://fhp-fans.web.app/fan/search-player"
                    target="_blank"
                  >
                    Fan searches for athlete on FHP search or at 'library'
                  </a>
                </li>
                <li>
                  Fan emails chosen athlete using{" "}
                  <h6
                    className="d-inline hover_pointer"
                    onClick={toggleTemplate}
                  >
                    <u>TEMPLATE</u>
                  </h6>
                </li>
                <li>Athlete replies by email to fan</li>
                <li>
                  Athlete reply also sends offer to Fan's 'Off Platform to
                  Site'.
                </li>
                <li>
                  Fan visits 'Off platform to site' and if offer is OK, Fan
                  funds escrow.
                </li>
                <li>
                  Fan and athlete proceed to chat using 'My Instant Chats'.
                </li>
                <li>
                  Fan and Teacher-Athlete can email each other to adjust
                  time/date of chat.
                </li>
              </ol>
            </Col>
            <Col
              md="4"
              className="d-flex justify-content-center align-items-center"
            >
              Note:If you hover over any of these steps, you may find navigation
              to correct page.
            </Col>
          </Row>
          <div className="text-right">
            <Button
              color="primary"
              onClick={() => {
                isOpen
                  ? dispatch(setIsShowAppointment(false))
                  : toggleInstantChat();
              }}
            >
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isShowTemplate}
        toggle={toggleTemplate}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <ModalBody>
          <div className="text-right">
            <Button color="primary" onClick={handleCopyText}>
              {isCopy ? (
                <i class="far fa-copy"></i>
              ) : (
                <i class="fa fa-solid fa-check"></i>
              )}
            </Button>
          </div>
          <p>{textToCopy}</p>
          <div className="text-right">
            <Button
              color="primary"
              className="p-1"
              onClick={() => toggleTemplate()}
            >
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InstantChatModal;
