const initialState = {
  chats: [],
  lastDocument: "",
  moreData: true,
  chatTopic: [],
  slotChats: [],
  slots: [],
  groupSlots: [],
  availableSlots: [],
  timeSlots: [],
  fanBookedSlots: [],
  messagesById: [],
  unReadMessages: [],
  loading: false,
  canAdd: false,
  unSub: null,
  chatsCount: 0,
  topics: [],
  chatsEarning: 0,
  chatsEarningByID: 0,
  chatsStatus: "",
  chatRatings: [],
  reviewStats: {},
  totalChat: 0,
  modal: false,
  checkQuestionPriority: {},
  isQuestionIdExists: false,
  groupChats: [],
  athleteGroupChats: [],
  getManagerReviews: {},
  isUserExist: false,
  totalChat: null,
  name: "",
  FanQuestionRequests: [],
  slotsFansQuestionRequests: [],
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET-ATHLETE-GROUP-CHAT":
      return {
        ...state,
        athleteGroupChats: action.payload,
      };
    case "GET-GROUP-CHAT":
      return {
        ...state,
        groupChats: action.payload,
      };
    case "IS-QUESTION-EXISTS":
      return {
        ...state,
        isQuestionIdExists: action.payload,
      };
    case "UPDATE_REVIEW_STATUS":
      let groupChat = [...state.groupChats];
      let updateGroupChats = groupChat.map((chat) => {
        if (chat.id === action.payload.chatId) {
          return {
            ...chat,
            slot: {
              ...chat.slot,
              payments: chat.slot.payments.map((payment) => ({
                ...payment,
                is_reviewied_fan:
                  payment.fanId === action.payload.fanId
                    ? true
                    : payment.is_reviewied_fan,
              })),
            },
          };
        }
        return chat;
      });
      return {
        ...state,
        groupChats: updateGroupChats,
      };
    case "ATHLETE-REVIEW":
      let groupChatData = [...state.athleteGroupChats];
      let updateGroupChat = groupChatData.map((chat) => {
        if (chat.id === action.payload) {
          return {
            ...chat,
            is_reviewed_athlete: true,
          };
        } else {
          return chat;
        }
      });
      return {
        ...state,
        athleteGroupChats: updateGroupChat,
      };
    case "FAN_REVIEW":
      let cha = [...state.chats];
      let reviewChat = cha.map((chat) => {
        if (chat.id === action.payload) {
          return {
            ...chat,
            is_reviewed_fan: true,
          };
        }
        return chat;
      });
      return {
        ...state,
        chats: reviewChat,
      };
    case "REMOVE_CHAT":
      let chates = [...state.chats];
      let removeChat = chates.filter((chat) => chat.id !== action.payload);
      return {
        ...state,
        chats: removeChat,
      };
    case "UPDATE_CHAT_STATUS":
      let { chatId, status } = action.payload;
      let chat = [...state.chats];
      let updateChatStatus = chat.map((chat) => {
        if (chatId == chat.id) {
          return {
            ...chat,
            status: status,
          };
        }
        return chat;
      });
      return {
        ...state,
        chats: updateChatStatus,
      };
    case "GET_ALL_CHAT_BY_ID":
      return {
        ...state,
        chats: [...state.chats, ...action.payload.tempChat],
        moreData: action.payload.hasMoreDocument,
        lastDocument: action.payload.lastDocument,
      };
    case "GET_ALL_CHAT_BY_ID_EMPTY":
      return {
        ...state,
        chats: action.payload.tempChat,
        moreData: action.payload.hasMoreDocument,
        lastDocument: action.payload.lastDocument,
      };
    case "UPDATE_CHAT":
      let index = action.payload;
      let updateChat = [...state.chats];
      updateChat[index].showRating = true;
      return {
        ...state,
        chats: updateChat,
      };
    case "UPDATE_CHAT_LOADER":
      let { id, type } = action.payload;
      let chats = [...state.chats];
      const updatedChats = chats.map((chat) => {
        if (chat.id === id) {
          if (type == "reject") {
            return {
              ...chat,
              rejectLoader: !chat.rejectLoader,
            };
          } else if (type == "accept") {
            return {
              ...chat,
              acceptLoader: !chat.acceptLoader,
            };
          }
        }
        return chat;
      });
      return {
        ...state,
        chats: updatedChats,
      };
    case "GET_TOPICS":
      return {
        ...state,
        topics: action.payload,
      };
    case "GET_ALL_CHAT_BY_SLOT_ID":
      return {
        ...state,
        slotChats: action.payload,
      };
    case "GET_CHAT_COUNT_BY_ID":
      return {
        ...state,
        chatsCount: action.payload,
      };
    case "GET_ALL_RATING_BY_ID":
      return {
        ...state,
        chatRatings: action.payload,
      };
    case "GET_CHAT_EARNING_BY_ID":
      return {
        ...state,
        chatsEarning: action.payload,
      };
    case "GET_CHAT_EARNING_BY_ATHLETE_ID":
      return {
        ...state,
        chatsEarningByID: action.payload,
      };
    case "GET_ALL_SLOTS_BY_ID":
      return {
        ...state,
        slots: action.payload,
      };
    case "GET_ALL_GROUP_SLOTS_BY_ID":
      return {
        ...state,
        groupSlots: action.payload,
      };
    case "GET_AVAILABLE_SLOTS":
      return {
        ...state,
        availableSlots: action.payload,
      };
    case "GET_ALL_SLOTS_BY_DATE":
      return {
        ...state,
        timeSlots: action.payload,
      };
    case "GET_ALL_REVIEWS":
      return {
        ...state,
        reviewStats: action.payload.counts,
        totalChat: action.payload.totalChat,
      };
    case "CHAT_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "OPEN-APPOINTMENT-MODAL":
      return {
        ...state,
        modal: action.payload,
      };
    case "CHECK_SLOT_PRIORITY":
      return {
        ...state,
        checkQuestionPriority: action.payload,
      };
    case "GET_MANAGER_REVIEWS":
      return {
        ...state,
        getManagerReviews: action.payload.counts,
        isUserExist: action.payload.isUser,
        totalChat: action.payload.totalChat,
        name: action.payload.name,
      };
    case "IS-FAN-REQUEST":
      return {
        ...state,
        FanQuestionRequests: action.payload,
      };
    case "GET-FAN-REQUESTS-BY-SLOT-ID":
      return {
        ...state,
        slotsFansQuestionRequests: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default chatReducer;
