import React, { useEffect } from "react";
import { Button, Col, Container, Row, Table } from "reactstrap";
import mapImg from "../../assets/img/time map img.jpeg";
import instructionRatingImg from "../../assets/img/ratingImg.png";
import { useDispatch, useSelector } from "react-redux";
import { getGroupChats } from "../../store/actions/chatAction";
import defaultImg from "../../assets/img/bgImg.jpg";
import moment from "moment";
import ReviewModal from "../../components/ReviewModal";
import { useState } from "react";
const FanGroupChat = () => {
  const currentDate = moment();

  const [loader, setLoader] = useState(true);
  // const [showReview, setShowReview] = useState(false);
  // const [chatID, setChatID] = useState();
  // const [chat_id, setChat_ID] = useState();
  // const [receiverId, setReceiverId] = useState("");
  // const [slotId, setSlotId] = useState("");

  const [reviewData, setReviewData] = useState({
    showReview: false,
    chatID: "",
    chat_id: "",
    receiverId: "",
    slotId: "",
  });

  let dispatch = useDispatch();
  const { groupChats } = useSelector((state) => state.chat);
  const { uid, user } = useSelector((state) => state.authUser);

  useEffect(() => {
    dispatch(
      getGroupChats(uid, () => {
        setLoader(false);
      })
    );
  }, []);

  const handleShowReview = (participant, chatID, chat_id, slotId) => {
    setReviewData((prev) => ({
      showReview: !prev.showReview,
      chatID: chatID,
      chat_id: chat_id,
      receiverId: participant,
      slotId: slotId,
    }));
    // setReceiverId(participant);
    // setChatID(chatID);
    // setChat_ID(chat_id);
    // setSlotId(slotId);
    // setShowReview(!showReview);
  };
  function isButtonDisabled(chatStartTimestamp) {
    const chatStart = moment.unix(chatStartTimestamp);
    // chatStart.add(7, "days");
    chatStart.add(2, "hours");
    return chatStart.isAfter(currentDate);
  }
  return (
    <Container fluid>
      <Row noGutters>
        <Col align="center" className="mt-4 mb-2">
          <div className="ChatLeft">
            <Row noGutters>
              <div className="text-center w-100">
                <img
                  src={instructionRatingImg}
                  // style={{ width: "30%" }}
                  alt="Time Zone Img USA"
                  className="instructionReviewImg"
                />
              </div>
              <span className="text-danger w-100 text-center p-3">
                S= Satisfied, U-NS= Unsatisfied No Show & U-RST= Unsatisfied
                Rude / Short Time
                <br></br>
                To ensure smooth communication, kindly keep your chat partner
                informed about any time zone differences. This will help both of
                you stay synchronized and maintain effective interaction.
              </span>
              <div className="text-center w-100">
                <img
                  src={mapImg}
                  style={{ width: "20%" }}
                  alt="Time Zone Img USA"
                />
              </div>
              <Col sm="12">
                <div className="chat">
                  <Table className="table" responsive>
                    <thead>
                      <tr>
                        <th> </th>
                        <th>Chat id</th>
                        <th>Day for chat</th>
                        <th>Chat rate</th>
                        <th>Fan email</th>
                        <th>Athlete email</th>
                        <th>Manager type</th>
                        <th>Manager Of chat</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    {!loader && (
                      <tbody>
                        {groupChats.map((items, index) => {
                          let filterFanReview = items.slot?.payments.filter(
                            (items) => items.fanId == uid
                          );
                          let disableButton = isButtonDisabled(
                            items.chat_start.seconds
                          );
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  className="chat-profile"
                                  src={
                                    items.athleteData?.player_image
                                      ? items.athleteData?.player_image.url
                                      : defaultImg
                                  }
                                />
                              </td>
                              <td>{items.chat_id}</td>
                              <td>
                                {" "}
                                {moment
                                  .unix(items.chat_start.seconds)
                                  .format("MMM Do YY")}
                              </td>
                              <td>
                                {(
                                  items.slot?.perParticipantAmount + 0.5
                                ).toFixed(2)}
                              </td>
                              <td>{user.email}</td>
                              <td>{items.athlete_email}</td>
                              <td>{items.slot?.managerType}</td>
                              <td>
                                {items.manager
                                  ? items.manager.name
                                  : items.athleteData.first_name +
                                    " " +
                                    items.athleteData.last_name}
                              </td>
                              <td>
                                <Button
                                  color="primary"
                                  disabled={
                                    (filterFanReview &&
                                      filterFanReview[0].is_reviewied_fan) ||
                                    !disableButton
                                  }
                                  onClick={() =>
                                    handleShowReview(
                                      items.athleteID,
                                      items.id,
                                      items.chat_id,
                                      items.time_slot_id
                                    )
                                  }
                                >
                                  Chat review
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </Table>
                  {loader && (
                    <div>
                      <i className="spinner-border text-primary mb-3"></i>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ReviewModal
        showReviewModal={reviewData.showReview}
        handleShowReview={() => {
          handleShowReview("", "");
        }}
        chatID={reviewData.chatID}
        slotId={reviewData.slotId}
        athleteId={reviewData.receiverId}
        chat_id={reviewData.chat_id}
        chatType="group"
      ></ReviewModal>
    </Container>
  );
};

export default FanGroupChat;
