import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import {
  addQuestion,
  completeQuestionPayment,
} from "../store/actions/questionAnswerActions";
import QuestionPaypal from "../components/QuestionPaypal";
import {
  fetchCollegeStates,
  fetchSchoolStates,
  getSports,
} from "../store/actions/athleteActions";
import QuestionTopDetails from "../components/QuestionTopDetails";
import { getAvailableslotsByDate } from "../store/actions/chatAction";

const AskQuestion = () => {
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.authUser);
  const { addQuestionLoader } = useSelector((state) => state.question);
  const lessons = useSelector((state) => state.chat?.topics);
  const { sports, collegeStates } = useSelector((state) => state.athlete);

  const [state, setState] = useState("");
  const [sport, setSport] = useState("");
  const [college, setCollege] = useState("");
  const [gender, setGender] = useState("");

  const [priority, setPriority] = useState("");

  const handleState = (e) => {
    setState(e.target.value);
  };
  const handleGender = (e) => {
    setGender(e.target.value);
  };
  const handleSport = (e) => {
    setSport(e.target.value);
  };
  const handleCollege = (e) => {
    setCollege(e.target.value);
  };
  const [data, setData] = useState({
    question: "",
    description: "",
    lesson: "",
    topic: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
  };
  useEffect(() => {
    dispatch(fetchCollegeStates());
    dispatch(fetchSchoolStates());
    dispatch(getSports());
    dispatch(getAvailableslotsByDate(uid, new Date()));
  }, []);

  const completeQuestionPay = (order) => {
    dispatch(
      completeQuestionPayment(
        {
          paymentId: order?.id,
          price: order?.purchase_units[0]?.amount?.value,
          question: data.question,
          description: data.description,
          lesson: data.lesson,
          topic: data.topic,
          email: user.email,
          fan_id: user.id,
          priority: priority,
          priority: priority,
          college: college,
          state: state,
          gender: gender,
          sport: sport,
          status: "open",
        },
        () => {
          setData({
            question: "",
            lesson: "",
            topic: "",
            description: "",
          });
          setState("");
          setGender("");
          setCollege("");
          setSport("");
          setPriority("");
        }
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      question: data.question,
      description: data.description,
      lesson: data.lesson,
      topic: data.topic,
      priority: priority,
      college: college,
      state: state,
      gender: gender,
      sport: sport,
      email: user.email,
      fan_id: user.id,
      status: "open",
    };

    dispatch(
      addQuestion(obj, () => {
        setData({
          question: "",
          lesson: "",
          topic: "",
          description: "",
        });
        setState("");
        setGender("");
        setCollege("");
        setSport("");
        setPriority("");
      })
    );
  };

  return (
    <>
      <QuestionTopDetails />
      <Container>
        <Row>
          <Col md={10} className="m-auto py-4">
            <p>
              NOTE: You can really help a Teacher-Athlete to fully understand
              your question by linking your question to one or more of the
              following items. This alerts the Teacher-Athlete during their
              database search about the context of your question: <br />
              <b>YOUR CHOICE OF LINKS FOR YOUR QUESTION:</b> sport name, a
              specific sport level (high school/college), a specific gender, a
              specific State, a specific Topic.You also have the choice to boost
              your question to PRIORITY STATUS by paying $3.
            </p>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="exampleEmail">
                  Tell athlete FOCUS of your question
                </Label>
                <Input
                  required
                  id="exampleEmail"
                  name="question"
                  value={data?.question}
                  onChange={handleChange}
                  placeholder="Add new question"
                  type="text"
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Give specifics and details</Label>
                <Input
                  required
                  id="exampleEmail"
                  name="description"
                  value={data?.description}
                  onChange={handleChange}
                  placeholder="Add description"
                  type="textarea"
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Select Topic</Label>
                <span className="text-danger">
                  {" "}
                  (Fan, you may leave Topic blank if you selected a Lesson)
                </span>
                <Input
                  id="exampleEmail"
                  name="topic"
                  placeholder="Add new question"
                  type="select"
                  value={data?.topic}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    drop down menu , ? navigation page- Topics
                  </option>
                  <option value="smart-goals">Smart Goals</option>
                  <option value="technique">Technique</option>
                  <option value="favorite-moment">Favorite-Moment</option>
                  <option value="dream-assignment">Dream Assignment</option>
                  <option value="hobbies-pets-music">Hobbies/Pets/Music</option>
                  <option value="role-model">Role Model</option>
                  <option value="diet-conditioning">Diet/Conditioning</option>
                  <option value="inspiration">Inspiration</option>
                  <option value="mental-tips">Mental Tips</option>
                  <option value="staying-positive">Staying Positive</option>
                  <option value="self-talk-mantras">Self-Talk Mantras</option>
                  <option value="teamwork-skills">Teamwork Skills</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Select Lesson</Label>
                <span className="text-danger">
                  {" "}
                  (Fan, you may leave Lesson blank if you selected a Topic)
                </span>
                <Input
                  id="exampleEmail"
                  name="lesson"
                  placeholder="Add new question"
                  type="select"
                  value={data?.lesson}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    drop down menu , ? navigation page- 23 lessons{" "}
                  </option>
                  {lessons?.map((items, index) => {
                    return <option value={items.id}>{items.name}</option>;
                  })}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Select College FOCUS or HS FOCUS or leave blank</Label>
                <div>
                  <Input type="select" value={college} onChange={handleCollege}>
                    <option value="" disabled>
                      Does college or High School describe focus of your
                      question? Pick on or leave blank
                    </option>

                    <option value="college-athlete">College</option>
                    <option value="hs-athlete">HS</option>
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Select FOCUS on a State or leave blank</Label>
                <div>
                  <Input type="select" value={state} onChange={handleState}>
                    <option value="" disabled>
                      IS there a state closely related to your question? Pick
                      one or leave blank.
                    </option>
                    {collegeStates?.map((insti) => {
                      return <option value={insti.name}>{insti.name}</option>;
                    })}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Select FOCUS on a Gender or leave blank</Label>
                <div>
                  <Input type="select" value={gender} onChange={handleGender}>
                    {" "}
                    <option value="" disabled>
                      Does gender apply to your question?Pick one or leave
                      blank.
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Select FOCUS on a Sport or leave blank</Label>
                <div>
                  <Input type="select" value={sport} onChange={handleSport}>
                    <option value="" disabled>
                      Does a particular sport apply to your question?Pick one of
                      leave blank.
                    </option>
                    {sports?.map((sport) => {
                      return <option value={sport.sport}>{sport.sport}</option>;
                    })}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Boost your Question to Priority Status</Label>
                <div>
                  <Input
                    type="select"
                    value={priority}
                    onChange={handlePriorityChange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value="unpaid">Normal</option>
                    <option value="paid">High Priority</option>
                  </Input>
                </div>
              </FormGroup>

              {priority == "paid" ? (
                <QuestionPaypal
                  price={3}
                  completeQuestionPayment={completeQuestionPay}
                />
              ) : (
                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!data?.topic && !data?.lesson}
                  >
                    {addQuestionLoader ? <Spinner size="sm" /> : "Add Question"}
                  </Button>
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AskQuestion;
