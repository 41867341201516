const initialState = {
  managerOfSlots: [],
  fanQuestions: [],
  athleteManager: [],
  fanManager: [],
};

const managerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BTN-LOADER":
      let manager = [...state.athleteManager];
      let update = manager.map((items) =>
        items.id == action.payload ? { ...items, btnLoader: true } : items
      );
      return {
        ...state,
        athleteManager: update,
      };
    case "GET-ATHLETE-MANAGER":
      return {
        ...state,
        athleteManager: action.payload,
      };
    case "GET-FAN-MANAGER":
      return {
        ...state,
        fanManager: action.payload,
      };
    case "GET-MANAGER":
      return {
        ...state,
        managerOfSlots: action.payload,
      };
    case "GET-FAN-QUESTIONS":
      return {
        ...state,
        fanQuestions: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default managerReducer;
