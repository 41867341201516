import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  Container,
  Row,
  Col,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import ProfileCard from "../components/ProfileCard";
import ActiveChats from "../components/ActiveChatsCard";
import SearchForPlayer from "./SearchforPlayer";
import { useDispatch, useSelector } from "react-redux";
import SuggestionBox from "../components/SuggestionBox";
import { updateAgreeAndLogin } from "../store/actions/authAction";
import { Box } from "@mui/material";

import { athleteRequestResetRating } from "../store/actions/athleteResetSummaryRequest";
function Dashboard() {
  let auth = useSelector((state) => state.authUser);

  const dispatch = useDispatch();

  const sendRequest = (e) => {
    e.preventDefault();
    dispatch(athleteRequestResetRating(auth.uid));
  };

  return (
    <Container fluid className="m-0 Dashboard-container">
      <Row className="dashboard">
        {auth?.user?.role == "athlete" ? (
          <Col sm="12" className="text-center">
            <p className="text-center font-weight-bold">
              NOTE: After booking chat and escrowing funds,users have up to 1
              week to complete chat. Users can email each other to change chat
              schedule or to choose the platform they mutually prefer for their
              chat, such as Skype, Zoom, FaceTime, What'sApp, Google Duo,
              Facebook Messenger, etc.
            </p>
            <p className="text-center font-weight-bold">
              NOTE:Athlete, after a Fan buys a badge from you, the amount due
              you will usually be sent to your PayPal account within 24
              hours.When a Fan chats with you, the portion of the chat escrow
              due you will usually be sent to your PayPal account within 24
              hours if the Fan enters a Chat review of S,U-RST,or U-NS.If Fan
              does not give a rating for the chat,you should receive the amount
              due you by 1-week after the chat.
            </p>
            <p className="text-center font-weight-bold">
              You can login and change your youtube video link on{" "}
              <a
                href="https://fans-help-players-athlete.web.app/admin/profile"
                target="_blank"
              >
                profile section
              </a>{" "}
              of your FHP athlete account
            </p>
            <Button color="primary" onClick={sendRequest}>
              Request to admin reset my ratings summary to zero
            </Button>
            <p className="text-center font-weight-bold mt-2">
              To report your NIL activities at FHP to your school, you can
              download reports below.
            </p>
          </Col>
        ) : (
          ""
        )}
        <Col lg="4" sm="12">
          <ProfileCard />
          {auth?.user?.role == "fan" ? <ActiveChats /> : ""}
        </Col>
        <Col lg="8" sm="12">
          {auth?.user?.role == "fan" ? <SearchForPlayer /> : <ActiveChats />}
        </Col>
      </Row>
      {auth?.user?.role == "athlete" ? (
        <Row className="dashboard py-5">
          <SuggestionBox />
        </Row>
      ) : (
        ""
      )}
    </Container>
  );
}

export default Dashboard;
