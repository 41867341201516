import firebase from 'firebase/app';
// import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyAhFvijjf-QgePs3vb9pfxD0n2DAwlLGSc',
	authDomain: 'fans-help-players.firebaseapp.com',
	projectId: 'fans-help-players',
	storageBucket: 'fans-help-players.appspot.com',
	messagingSenderId: '669033615466',
	appId: '1:669033615466:web:ee64d766bc2d6540fc7a13',
	measurementId: 'G-46NJDJKGN8',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// export const db = firebase.firestore()
export default firebase;
