import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Collapse,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { updatePassword } from "../store/actions/authAction";

const PasswordChange = () => {
  const dispatch = useDispatch();
  let { passwordLoading } = useSelector((state) => state.auth);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowCurrentPassword = (e) => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleShowNewPassword = (e) => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowConfirmPassword = (e) => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const editCollapse = () => {
    setEditOpen(!editOpen);
  };
  return (
    <Container fluid className="changePswrdMain mt-4 mt-md-0">
      <Row className="no-gutters justify-content-center">
        <Col lg="8">
          <div className="complaint-card mt-4">
            <div className="complaint-form">
              <h5 className="card-title">Change Password </h5>
              <Row>
                <Col sm="6">
                  <p className="edit-password-info">Password:</p>
                </Col>
                <Col align="end">
                  <div className="d-flex">
                    <Input
                      className="form-control mr-2"
                      type="password"
                      value="......."
                      readOnly
                    />

                    <p
                      className="edit-password-info ml-auto px-4 bg-gradient"
                      style={{ cursor: "pointer" }}
                      onClick={editCollapse}
                    >
                      Edit
                    </p>
                  </div>
                </Col>
              </Row>
              <Collapse isOpen={editOpen}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (newPassword !== confirmPassword) {
                      toast.warning("New Passwords didn't match");
                    } else {
                      let obj = {
                        currentPassword: currentPassword,
                        newPassword: newPassword,
                      };
                      dispatch(
                        updatePassword({
                          obj,
                          onSuccess: () => {
                            setCurrentPassword("");
                            setNewPassword("");
                            setConfirmPassword("");
                            setShowCurrentPassword(false);
                            setShowNewPassword(false);
                            setShowConfirmPassword(false);
                          },
                        })
                      );
                    }
                  }}
                >
                  <Label>Current Password</Label>
                  <div className="paswordChangeOuter">
                    <Input
                      required
                      className="edit-password-info"
                      type={showCurrentPassword ? "text" : "password"}
                      placeholder="Your Current Password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    {currentPassword.length > 0 ? (
                      <i
                        className="fas fa-eye pointer eyeIcon"
                        onClick={handleShowCurrentPassword}
                      ></i>
                    ) : null}
                  </div>

                  <Label className="mt-2">New Password</Label>

                  <div className="paswordChangeOuter">
                    <Input
                      required
                      className="edit-password-info"
                      type={showNewPassword ? "text" : "password"}
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    {newPassword.length > 0 ? (
                      <i
                        className="fas fa-eye pointer eyeIcon"
                        onClick={handleShowNewPassword}
                      ></i>
                    ) : null}
                  </div>
                  <Label className="mt-2">Confirm New Password</Label>
                  <div className="paswordChangeOuter">
                    <Input
                      required
                      className="edit-password-info"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm New Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {confirmPassword.length > 0 ? (
                      <i
                        className="fas fa-eye pointer eyeIcon"
                        onClick={handleShowConfirmPassword}
                      ></i>
                    ) : null}
                  </div>

                  <div className="text-right mt-3">
                    <Button className="mb-0 px-5" color="primary" type="submit">
                      {passwordLoading ? <Spinner size="sm" /> : "Update"}
                    </Button>
                  </div>
                </Form>
              </Collapse>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordChange;
