import { toast } from "react-toastify";
import firebase from "../../config/firebase";
export const getMyChatInAction =
  (id, lastDocument, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      let querya = firebase
        .firestore()
        .collection("new_a4b_chat")
        .where("participants", "array-contains", id)
        .where("via", "==", "paypal")
        .where("type", "==", "Instant")
        .orderBy("created_at", "desc");
      if (lastDocument != "") {
        querya = querya.startAfter(lastDocument);
      }
      querya.limit(5).onSnapshot(async (query) => {
        let athleteData = [];
        for await (let doc of query.docs) {
          let questionId = "";
          let note = "";
          if (doc.data().time_slot_id) {
            let timeSlote = await firebase
              .firestore()
              .collection("a4b_athelete_timeslots")
              .doc(doc.data().time_slot_id)
              .get();
            questionId = timeSlote.data()?.highPriorityQuestion;
            note = timeSlote.data()?.note;
          }
          let user = await firebase
            .firestore()
            .collection("users")
            .doc(doc.data().athleteID)
            .get();
          athleteData.push({
            id: doc.id,
            athleteImg: user.data()?.player_image.url,
            questionId: questionId,
            note: note,
            chatUser: user.data(),
            ...doc.data(),
          });
        }
        let hasMoreDocuments = athleteData.length >= 5;
        if (lastDocument != "") {
          dispatch({
            type: "GET_CHAT_ACTION_COMPLETE",
            payload: {
              athleteData,
              hasMoreDocuments,
              lastDocument: query.docs[athleteData.length - 1],
            },
          });
        } else {
          dispatch({
            type: "GET_CHAT_ACTION_COMPLETE_EMPTY",
            payload: {
              athleteData,
              hasMoreDocuments,
              lastDocument: query.docs[athleteData.length - 1],
            },
          });
        }
        onComplete();
      });
    } catch (error) {
      console.log("Error");
    }
  };
export const completeTopUpPayment = (id, details, minutes) => () => {
  firebase
    .firestore()
    .collection("new_a4b_chat")
    .doc(id)
    .update({
      topUpPayment: true,
      topUpPayments: [{ minutes: minutes, payment_id: details.id }],
    })
    .then(() => {
      toast.success("Thank you for supporting the athlete");
    })
    .catch((error) => {
      toast.error("An error occurred while processing your payment.");
    });
};
