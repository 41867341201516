import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row, Table } from "reactstrap";
import {
  changeNotification,
  changeValunteerNotification,
  getFavoriteAthletes,
  notificationLoader,
  notificationValunteerLoader,
  removeFromFavorite,
} from "../store/actions/favoriteAction";
import defaultImg from "../assets/img/bgImg.jpg";
import { useState } from "react";

const Favorites = () => {
  let dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [BtnLoader, setBtnLoader] = useState(false);
  const { uid, user } = useSelector((state) => state.authUser);
  const [volunteerLoader, setVolunteerLoader] = useState(false);
  let { favoriteAthletes, lastDocument, moreData } = useSelector(
    (state) => state.favorite
  );

  console.log(volunteerLoader, "volunteerLoader");
  useEffect(() => {
    dispatch(
      getFavoriteAthletes(uid, "", () => {
        setLoader(true);
      })
    );
  }, []);
  const loadMore = () => {
    setBtnLoader(true);
    dispatch(
      getFavoriteAthletes(uid, lastDocument, () => {
        setBtnLoader(false);
      })
    );
  };
  const handelRemove = (items) => {
    let obj = {
      athleteId: items.athleteId,
      fanId: items.fanId,
    };
    dispatch(removeFromFavorite(obj));
  };
  const handleSwitchNotification = (items, val) => {
    if (val == "notification") {
      dispatch(notificationLoader(items.id));
      let obj = {
        id: items.id,
        notification: !items.notification,
      };
      dispatch(changeNotification(obj));
    } else {
      dispatch(notificationValunteerLoader(items.id));
      let obj = {
        id: items.id,
        volunteerNotification: !items.volunteerNotification,
      };
     dispatch(changeValunteerNotification(obj));
    }
  };

  return (
    <div className="text-center pt-3">
      <h1>My Favorite Athletes</h1>
      <h5>
        NOTE: Add new Fav by searching for athlete, then click ‘heart’ on
        Profile.
      </h5>
      <Container className=" justify-content-center mt-2">
        <Row className="justify-content-center">
          <Col xs={12} lg={12} className="text-center">
            <Card className="p-3 mb-3">
              {loader ? (
                <>
                  <Table className="table table_library" responsive>
                    <thead>
                      <tr>
                        <th>Athlete</th>
                        <th>Athlete Id</th>
                        <th>Notification</th>
                        <th>volunteer </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {favoriteAthletes.length > 0 ? (
                        favoriteAthletes.map((items, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  className="chat-profile"
                                  src={
                                    items.player_image
                                      ? items.player_image.url
                                      : defaultImg
                                  }
                                />
                                <p>
                                  {items.first_name + " " + items.last_name}
                                </p>
                              </td>
                              <td>{items.athleteID}</td>
                              <td>
                                <label key={items.id} className="switch">
                                  <input
                                    type="checkbox"
                                    checked={items.notification}
                                    onChange={() =>
                                      handleSwitchNotification(
                                        items,
                                        "notification"
                                      )
                                    }
                                  />
                                  {items.notificationBtnLoader ? (
                                    <div className="spinner-border spinner-border-sm text-primary loader mx-auto"></div>
                                  ) : items.notification ? (
                                    <span className="slider round text-align-start">
                                      on
                                    </span>
                                  ) : (
                                    <span className="slider round text-align-end">
                                      off
                                    </span>
                                  )}
                                </label>
                              </td>

                              <td>
                                <label key={items.id} className="switch">
                                  <input
                                    type="checkbox"
                                    checked={items.volunteerNotification}
                                    onChange={() =>
                                      handleSwitchNotification(
                                        items,
                                        "volunteer"
                                      )
                                    }
                                  />

                                  {items?.notificationBtnLoaders ? (
                                    <div className="spinner-border spinner-border-sm text-primary loader mx-auto"></div>
                                  ) : items?.volunteerNotification ? (
                                    <span className="slider round text-align-start">
                                      on
                                    </span>
                                  ) : (
                                    <span className="slider round text-align-end">
                                      off
                                    </span>
                                  )}
                                </label>
                              </td>

                              <td>
                                <Button
                                  color="primary"
                                  disabled={items.btnLoader}
                                  onClick={() => handelRemove(items)}
                                >
                                  {items.btnLoader ? (
                                    <div className="spinner-border loader mx-auto spinner-border-sm"></div>
                                  ) : (
                                    "Remove"
                                  )}
                                </Button>
                                <Link
                                  to={`/search-group-player/${items.athleteId}/buy-rights`}
                                >
                                  <Button className="ml-2 custom-btn">
                                    View Available Slots
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>N/A</td>
                          <td>N/A</td>
                          <td>N/A</td>
                          <td>N/A</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div className="mt-3">
                    {BtnLoader ? (
                      <i className="spinner-border text-primary mb-3"></i>
                    ) : (
                      <Button
                        color="primary"
                        onClick={loadMore}
                        disabled={!moreData}
                      >
                        Load more
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                <div className="spinner-border text-primary loader mx-auto"></div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Favorites;
