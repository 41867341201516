import React, { useState } from "react";
import defaultImg from "../assets/img/bgImg.jpg";
import {
  Button,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { useSelector } from "react-redux";
import classnames from "classnames";
import moment from "moment";
import ReviewModal from "./ReviewModal";

const ChatBookingModal = (props) => {
  const { slotChats } = useSelector((state) => state.chat);
  const { uid, user } = useSelector((state) => state.authUser);
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  const [receiverId, setReceiverId] = useState("");
  const [chatID, setChatID] = useState();
  const [showReview, setShowReview] = useState(false);

  const handleShowReview = (participant, chatID) => {
    setReceiverId(participant);
    setChatID(chatID);
    setShowReview(!showReview);
  };
  return (
    <>
      <Modal
        isOpen={props.showChatBookingModal}
        toggle={props.handleChatBookingModal}
        size="xl"
        top="true"
      >
        <ModalBody>
          <div className="d-flex justify-content-end">
            <div>
              <i
                onClick={props.handleChatBookingModal}
                className="fas fa-times"
              ></i>
            </div>
          </div>
          <div>
            <div className="ChatLeft">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames("pointer", {
                      active: currentActiveTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Accepted
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("pointer", {
                      active: currentActiveTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Rejected
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("pointer", {
                      active: currentActiveTab === "3",
                    })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Completed
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={currentActiveTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="chat">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>
                                {" "}
                                {user?.role == "athlete"
                                  ? "Booked By"
                                  : "Booked with"}
                              </th>
                              {user?.role == "athlete" ? <th>Email</th> : ""}
                              <th>Date</th>
                              <th>Window</th>
                              <th>Amount Paid $</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          {slotChats?.length > 0 &&
                            slotChats
                              .filter((chat) => {
                                if (chat.status == "accepted") {
                                  return chat;
                                }
                              })
                              .map((ele, index) => {
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td className="text-center">
                                        <img
                                          className="chat-profile"
                                          src={
                                            ele.chatUser.player_image
                                              ? ele.chatUser.player_image.url
                                              : defaultImg
                                          }
                                        />
                                      </td>
                                      <td className="table-chat-request">
                                        {ele.chatUser?.role == "athlete"
                                          ? ele.chatUser?.first_name +
                                            " " +
                                            ele.chatUser?.last_name
                                          : ele.chatUser?.name}
                                      </td>
                                      {user?.role == "athlete" ? (
                                        <td className="table-chat-request">
                                          {ele?.fan_email}
                                        </td>
                                      ) : (
                                        ""
                                      )}

                                      <td className="table-chat-request">
                                        {moment
                                          .unix(ele.chat_start.seconds)
                                          .format("MMM Do YY")}
                                      </td>
                                      <td className="table-chat-request">
                                        {moment
                                          .unix(ele.chat_start.seconds)
                                          .format("h:mm a")}{" "}
                                        -{" "}
                                        {moment
                                          .unix(ele.chat_end.seconds)
                                          .format("h:mm a")}
                                      </td>
                                      <td className="table-chat-request">
                                        {ele.paid_amount.value}
                                      </td>
                                      <td className="table-chat-request">
                                        {ele.status}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                        </table>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <div className="chat">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>
                                {" "}
                                {user?.role == "athlete"
                                  ? "Booked By"
                                  : "Booked with"}
                              </th>
                              {user?.role == "athlete" ? <th>Email</th> : ""}
                              <th>Date</th>
                              <th>Window</th>
                              <th>Amount Paid $</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          {slotChats?.length > 0 &&
                            slotChats
                              .filter((chat) => {
                                if (chat.status == "rejected") {
                                  return chat;
                                }
                              })
                              .map((ele, index) => {
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td className="text-center">
                                        <img
                                          className="chat-profile"
                                          src={
                                            ele.chatUser.player_image
                                              ? ele.chatUser.player_image.url
                                              : defaultImg
                                          }
                                        />
                                      </td>
                                      <td className="table-chat-request">
                                        {ele.chatUser?.role == "athlete"
                                          ? ele.chatUser?.first_name +
                                            " " +
                                            ele.chatUser?.last_name
                                          : ele.chatUser?.name}
                                      </td>
                                      {user?.role == "athlete" ? (
                                        <td className="table-chat-request">
                                          {ele?.fan_email}
                                        </td>
                                      ) : (
                                        ""
                                      )}

                                      <td className="table-chat-request">
                                        {moment
                                          .unix(ele.chat_start.seconds)
                                          .format("MMM Do YY")}
                                      </td>
                                      <td className="table-chat-request">
                                        {moment
                                          .unix(ele.chat_start.seconds)
                                          .format("h:mm a")}{" "}
                                        -{" "}
                                        {moment
                                          .unix(ele.chat_end.seconds)
                                          .format("h:mm a")}
                                      </td>
                                      <td className="table-chat-request">
                                        {ele.paid_amount.value}
                                      </td>
                                      <td className="table-chat-request">
                                        {ele.status}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                        </table>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <div className="chat">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>
                                {" "}
                                {user?.role == "athlete"
                                  ? "Booked By"
                                  : "Booked with"}
                              </th>
                              {user?.role == "athlete" ? <th>Email</th> : ""}
                              <th>Date</th>
                              <th>Window</th>
                              <th>Amount Paid $</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {slotChats?.length > 0 &&
                            slotChats
                              .filter((chat) => {
                                if (chat.status == "complete") {
                                  return chat;
                                }
                              })
                              .map((ele, index) => {
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td className="text-center">
                                        <img
                                          className="chat-profile"
                                          src={
                                            ele.chatUser.player_image
                                              ? ele.chatUser.player_image.url
                                              : defaultImg
                                          }
                                        />
                                      </td>
                                      <td className="table-chat-request">
                                        {ele.chatUser?.role == "athlete"
                                          ? ele.chatUser?.first_name +
                                            " " +
                                            ele.chatUser?.last_name
                                          : ele.chatUser?.name}
                                      </td>
                                      {user?.role == "athlete" ? (
                                        <td className="table-chat-request">
                                          {ele?.fan_email}
                                        </td>
                                      ) : (
                                        ""
                                      )}

                                      <td className="table-chat-request">
                                        {moment
                                          .unix(ele.chat_start.seconds)
                                          .format("MMM Do YY")}
                                      </td>
                                      <td className="table-chat-request">
                                        {moment
                                          .unix(ele.chat_start.seconds)
                                          .format("h:mm a")}{" "}
                                        -{" "}
                                        {moment
                                          .unix(ele.chat_end.seconds)
                                          .format("h:mm a")}
                                      </td>
                                      <td className="table-chat-request">
                                        {ele.paid_amount.value}
                                      </td>
                                      <td className="table-chat-request">
                                        {ele.status}
                                      </td>
                                      <td className="table-chat-request text-center">
                                        <Button
                                          disabled={ele.is_reviewed_athlete}
                                          className="btn-sm mx-auto"
                                          color="primary"
                                          onClick={() => {
                                            handleShowReview(
                                              ele.participants.filter(
                                                (ele) => ele != uid
                                              ),
                                              ele.id
                                            );
                                          }}
                                        >
                                          {" "}
                                          Review Chat
                                        </Button>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                        </table>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ReviewModal
        showReviewModal={showReview}
        handleShowReview={() => {
          handleShowReview("", "");
        }}
        chatID={chatID}
        athleteId={receiverId[0]}
      ></ReviewModal>
    </>
  );
};

export default ChatBookingModal;
