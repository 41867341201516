const initialState = {
  athleteAnnualEarnings: [],
  totalEarning: 0,
  chatEarnings: [],
  badgesEarnings: [],
  subscriptionEarnings: [],
  chatMore: true,
  badgesMore: true,
  subscriptionMore: true,
  lastDocument: "",
};

const athleteEarningReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ATHLETE_ANNUAL-EARNING":
      return {
        ...state,
        athleteAnnualEarnings: action.payload,
      };
    case "CHAT-EARNING":
      return {
        ...state,
        chatEarnings: action.payload.data,
        chatMore: action.payload.chatMore,
      };
    case "BADGES-EARNING":
      return {
        ...state,
        badgesEarnings: [...state.badgesEarnings, ...action.payload.data],
        badgesMore: action.payload.badgesMore,
        lastDocument: action.payload.lastDocument,
      };
    case "BADGES-EARNING-EMPTY":
      return {
        ...state,
        badgesEarnings: action.payload.data,
        badgesMore: action.payload.badgesMore,
        lastDocument: action.payload.lastDocument,
      };
    case "SUBSCRIPTION-EARNING":
      return {
        ...state,
        subscriptionEarnings: action.payload.data,
        subscriptionMore: action.payload.subscriptionMore,
      };

    default:
      return {
        ...state,
      };
  }
};

export default athleteEarningReducer;
