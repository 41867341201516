import React from "react";
import { Button, Card, Col, Container, Row, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchHighRespondedAthletes } from "../../store/actions/libraryAction";
import { Link } from "react-router-dom";
import { useState } from "react";
import moment from "moment";

const AthleteLibrary = () => {
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();
  let { lastDocument, highRespondedAthletes } = useSelector(
    (state) => state.library
  );
  let isMoreData = useSelector((state) => state.library?.hasMoreDocument);

  useEffect(() => {
    dispatch(
      fetchHighRespondedAthletes("", () => {
        setLoader(false);
      })
    );
  }, []);

  const loadMore = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(
      fetchHighRespondedAthletes(lastDocument, () => {
        setLoader(false);
      })
    );
  };

  return (
    <div>
      <Container className=" justify-content-center mt-2">
        <Row className="justify-content-center">
          <Col xs={12} lg={12} className="text-center">
            <p>Note: The library data may take a few seconds to load.</p>
            <Card className="p-3 mb-3">
              <Table className="table table_library" responsive>
                <thead>
                  <tr>
                    <th>Athlete</th>
                    <th>Athlete Last Login</th>
                    <th>Gender</th>
                    <th>ID Number</th>
                    <th>Profile click to see</th>
                    <th>Answered</th>
                    <th>Question Topic</th>
                    <th>Question Lesson</th>
                  </tr>
                </thead>
                <tbody>
                  {highRespondedAthletes?.map((items, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="d-md-flex d-block align-items-center justify-content-center">
                            <img
                              src={items?.player_image?.url}
                              alt=""
                              width={63}
                              height={72}
                            />
                            <span className="d-flex">
                              {items.first_name} {items.last_name}
                            </span>
                          </span>
                        </td>
                        <td>
                          {moment
                            .unix(items.lastLoggedIn.seconds)
                            .format("MM-DD-YYYY")}
                        </td>
                        <td>{items.gender}</td>
                        <td>{items.athleteId}</td>
                        <td>
                          {" "}
                          <Link
                            to={`/search-player/${items.athleteID}/buy-rights`}
                          >
                            <Button className="custom-btn">View Profile</Button>
                          </Link>
                        </td>
                        <td>{items?.answered}</td>
                        <td className="text-left height-50px">
                          {items.topics.map((item, index) =>
                            items.topics ? (
                              <p key={index + 100}>{item}</p>
                            ) : (
                              <p key={index + 100}>N/A</p>
                            )
                          )}
                        </td>
                        <td className="text-left height-50px">
                          {items.lessons.map((item, index) =>
                            items.lessons ? (
                              <p key={index + 200}>{item}</p>
                            ) : (
                              <p key={index + 200}>N/A</p>
                            )
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="mt-3">
                {loader ? (
                  <i className="spinner-border text-primary mb-3"></i>
                ) : (
                  <Button
                    color="primary"
                    onClick={loadMore}
                    disabled={!isMoreData}
                  >
                    Load more
                  </Button>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AthleteLibrary;
