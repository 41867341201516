import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import {
  getFanManagers,
  getSlotManagers,
} from "../../store/actions/managerAction";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ViewBookingGroupModal from "../ViewBookingGroupModal";
import { useState } from "react";
import CreateSlotsModal from "../CreateSlotsModal";
const FanManager = () => {
  const [activeTab, setActiveTab] = useState("myAthletes");
  const [loader, setLoader] = useState(true);
  // const [openModal, setOpenModal] = useState(false);
  // const [slotData, setSlotData] = useState("");
  const [viewBooking, setViewBooking] = useState({
    openModal: false,
    slotData: "",
  });
  // const [modalSetTimeSlot, setModalSetTimeSlot] = useState(false);
  // const [athleteId, setAthleteId] = useState("");
  // const [athleteEmail, setAthleteEmail] = useState("");
  // const [editData, setEditData] = useState("");
  // const [edit, setEdit] = useState(false);

  const [createSlot, setCreateSlot] = useState({
    modalSetTimeSlot: false,
    edit: false,
    editData: "",
    athleteEmail: "",
    athleteId: "",
  });

  let dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.authUser);
  let { managerOfSlots, fanManager } = useSelector((state) => state.manager);

  useEffect(() => {
    dispatch(
      getSlotManagers(user.email, () => {
        setLoader(false);
      })
    );
    dispatch(
      getFanManagers(uid, () => {
        setLoader(false);
      })
    );
  }, []);

  const toggled = () => {
    // setOpenModal(!openModal);
    setViewBooking((prev) => ({
      ...prev,
      openModal: !prev.openModal,
    }));
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleModalTimeSlots = (data) => {
    setCreateSlot((prev) => ({
      modalSetTimeSlot: !prev.modalSetTimeSlot,
      edit: false,
      editData: "",
      athleteEmail: data?.email,
      athleteId: data?.athleteId,
    }));
    // setEdit(false);
    // setEditData("");
    // setAthleteEmail(data?.email);
    // setAthleteId(data?.athleteId);
    // setModalSetTimeSlot(!modalSetTimeSlot);
  };
  const handelEdit = (ele) => {
    setCreateSlot((prev) => ({
      ...prev,
      modalSetTimeSlot: !prev.modalSetTimeSlot,
      edit: true,
      editData: ele,
    }));
    // setEditData(ele);
    // setEdit(true);
    // setModalSetTimeSlot(!modalSetTimeSlot);
  };
  return (
    <Container className="mt-3">
      <Card className="p-2">
        <Nav className="mt-2" tabs>
          <NavItem className="cursor-pointer">
            <NavLink
              className={
                activeTab === "myAthletes"
                  ? " bg-primary text-white py-1 px-3 "
                  : " py-1"
              }
              onClick={() => {
                toggleTab("myAthletes");
              }}
            >
              <h5 className="m-0">My Athletes</h5>
            </NavLink>
          </NavItem>
          <NavItem className="cursor-pointer">
            <NavLink
              className={
                activeTab === "slotManager"
                  ? " bg-primary text-white py-1 px-3"
                  : " py-1"
              }
              onClick={() => {
                toggleTab("slotManager");
              }}
            >
              <h5 className="m-0">Slots manager</h5>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="px-3 pt-3">
          <TabPane tabId="myAthletes">
            {loader ? (
              <div className="text-center">
                <i className="spinner-border text-primary mb-3"></i>{" "}
              </div>
            ) : (
              <Table className="table" responsive>
                <thead>
                  <tr>
                    <th>Athlete Name</th>
                    <th>Athlete Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {fanManager.length > 0 ? (
                    fanManager.map((items, index) => {
                      return (
                        <tr key={index}>
                          <td>{items.first_name + " " + items.last_name}</td>
                          <td>{items.email}</td>
                          <td>
                            <Button
                              color="primary"
                              onClick={() => handleModalTimeSlots(items)}
                            >
                              Create Slot
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </TabPane>
          <TabPane tabId="slotManager">
            {loader ? (
              <div className="text-center">
                <i className="spinner-border text-primary mb-3"></i>{" "}
              </div>
            ) : (
              <Table className="table" responsive>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Tentative start</th>
                    <th>Tentative end</th>
                    <th>Time zone</th>
                    <th>Email</th>
                    <th>Manager name</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                {managerOfSlots.length > 0 ? (
                  managerOfSlots.map((ele, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>{ele.date}</td>
                        <td>
                          {ele.start.split(":")[0] > "12"
                            ? `${ele.start.split(":")[0] - 12}:${
                                ele.start.split(":")[1]
                              } PM`
                            : `${ele.start.split(":")[0]}:${
                                ele.start.split(":")[1]
                              } AM`}
                        </td>
                        <td>
                          {ele.end.split(":")[0] > "12"
                            ? `${ele.end.split(":")[0] - 12}:${
                                ele.end.split(":")[1]
                              } PM`
                            : `${ele.end.split(":")[0]}:${
                                ele.end.split(":")[1]
                              } AM`}
                        </td>
                        <td>{ele?.time_zone || "Fan and Athlete discuss"}</td>
                        <td>{ele?.athlete_email}</td>
                        <td>{user.name}</td>
                        <td className="d-flex flex-column justify-content-end align-items-center button-width">
                          <div className="d-flex">
                            {ele?.managerType == "Volunteer" ? (
                              <Link to={`/volunteer-requests/${ele.id}/accept`}>
                                <Button
                                  color="primary"
                                  className="text-nowrap mr-2"
                                >
                                  See Volunteer
                                </Button>
                              </Link>
                            ) : null}
                            {ele?.status == "notBooked" ? (
                              <Button
                                color="primary"
                                className="text-nowrap mr-2"
                                onClick={() => handelEdit(ele)}
                              >
                                Edit Slot
                              </Button>
                            ) : null}
                            <Button
                              className="custom-btn text-nowrap"
                              onClick={() => {
                                toggled();
                                // setSlotData(ele);
                                setViewBooking((prev) => ({
                                  ...prev,
                                  slotData: ele,
                                }));
                              }}
                            >
                              View Bookings
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                )}
              </Table>
            )}
          </TabPane>
        </TabContent>
      </Card>
      {createSlot.modalSetTimeSlot && (
        <CreateSlotsModal
          modalSetTimeSlot={createSlot.modalSetTimeSlot}
          handleModalTimeSlots={handleModalTimeSlots}
          edit={createSlot.edit}
          editData={createSlot.editData}
          athleteId={createSlot.athleteId}
          athleteEmail={createSlot.athleteEmail}
          slotType="fan"
          fanEmail={user.email}
        />
      )}
      <ViewBookingGroupModal
        ShowViewBookingGroupModal={viewBooking.openModal}
        handelViewBookingGroupModal={() => {
          toggled();
        }}
        slotData={viewBooking.slotData}
        type="manager"
      />
    </Container>
  );
};

export default FanManager;
