import React, { useEffect, useRef } from 'react'

function Paypal(props) {
    const paypal = useRef()
    useEffect(() => {
        window.paypal
            .Buttons({
                createOrder: (data, actions, err) => {
                    return actions.order.create({
                        intent: "CAPTURE",
                        purchase_units: [
                            {
                                description: "License Fee",
                                amount: {
                                    currency_code: "USD",
                                    value: props.price,
                                },
                            },
                        ],
                    });
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    props.completePaypalPayment(props.chatId,order,props.athelete)
                },
                onError: (err) => {
                    console.log(err);
                },
            })
            .render(paypal.current);
    }, [])
    return (
        <>
            <div ref={paypal}></div>
        </>
    )
}

export default Paypal