import { AUTH_NOTIFICATION, AUTH_SET_LOADING } from "../types";
const initState = {
  loading: false,
  passwordLoading: false,
  agreeToTerms: false,
};
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_REQUEST_END":
      return {
        ...state,
        loading: false,
      };
    case "PASSWORD_REQUEST":
      return {
        ...state,
        passwordLoading: true,
      };
    case "PASSWORD_REQUEST_END":
      return {
        ...state,
        passwordLoading: false,
      };
    case "AGREE_TO_TERMS":
      return {
        ...state,
        agreeToTerms: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default authReducer;
