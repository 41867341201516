const initialState = {
  allQuestions: [],
  addQuestionLoader: false,
  replyLoader: false,
  highPriorityQuestions: [],
  checkRequested: [],
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_QUESTIONS":
      return {
        ...state,
        allQuestions: action.payload,
      };
    case "ADD_QUESTION_LOADER":
      return {
        ...state,
        addQuestionLoader: action.payload,
      };
    case "REPLY_LOADER":
      return {
        ...state,
        replyLoader: action.payload,
      };
    case "HIGH_PRIORITY_QUESTIONS":
      return {
        highPriorityQuestions: action.payload,
      };
    case "CHECK_REQUESTED":
      return {
        checkRequested: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default questionReducer;
