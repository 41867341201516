import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Input,
} from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  badgesEarning,
  chatEarning,
  subscriptionEarning,
} from "../store/actions/athleteEarningAction";
import moment from "moment/moment";
import { useRef } from "react";
import { CSVLink } from "react-csv";

const AthleteAccountMonthly = (props) => {
  let dispatch = useDispatch();
  const csvLink = useRef(null);
  const [activeTab, setActiveTab] = useState("chat");
  const [loader, setLoader] = useState(false);
  const [chatEarningMore, setChatEarningMore] = useState(10);
  const [badgesEarningMore, setBadgesEarningMore] = useState(10);
  const [subscriptionEarningMore, setSubscriptionEarningMore] = useState(10);
  const { uid, user } = useSelector((state) => state.authUser);
  const [userName, setUserName] = useState(
    user.first_name + " " + user.last_name
  );
  const {
    chatEarnings,
    badgesEarnings,
    subscriptionEarnings,
    subscriptionMore,
    badgesMore,
    chatMore,
    lastDocument,
  } = useSelector((state) => state.athleteEarning);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    setLoader(true);
    dispatch(
      chatEarning(uid, chatEarningMore, () => {
        setLoader(false);
      })
    );
    dispatch(badgesEarning(uid, "", () => {}));
  }, []);
  const handelChatEarning = () => {
    setLoader(true);
    dispatch(
      chatEarning(uid, chatEarningMore, () => {
        setLoader(false);
      })
    );
  };
  const handelBadgesEarning = () => {
    setLoader(true);
    dispatch(
      badgesEarning(uid, "", () => {
        setLoader(false);
      })
    );
  };
  const handelChatEarningLoadMore = () => {
    setChatEarningMore(chatEarningMore + 10);
    dispatch(chatEarning(uid, chatEarningMore + 10));
  };
  const handelBadgesEarningLoadMore = () => {
    setBadgesEarningMore(badgesEarningMore + 10);
    dispatch(badgesEarning(uid, badgesEarningMore + 10));
  };
  const handelSubscriptionEarningLoadMore = () => {
    setSubscriptionEarningMore(subscriptionEarningMore + 10);
    dispatch(subscriptionEarning(uid, subscriptionEarningMore + 10));
  };
  const headers = [
    { label: "Athlete name", key: "athleteName" },
    { label: "AthleteID", key: "athleteId" },
    { label: "Fan name", key: "name" },
    { label: "Chats Amount Earned", key: "payout_amount" },
    { label: "Rating", key: "rating" },
    { label: "Date", key: "date" },
  ];
  const headers2 = [
    { label: "Athlete name", key: "athleteName" },
    { label: "AthleteID", key: "athleteId" },
    { label: "Fan name", key: "name" },
    { label: "Badges Amount Earned", key: "payout_amount" },
    { label: "Date", key: "date" },
  ];

  return (
    <Container className="py-3">
      <Row>
        <Col sm="6">
          <p className="d-flex justify-content-center align-items-center">
            Athlete first name & last name{" "}
            <Input className="w-50 ml-2" value={userName} readOnly />
          </p>
        </Col>
        <Col sm="6">
          <p className="d-flex justify-content-center align-items-center">
            FHP ID#{" "}
            <Input value={user.athleteID} className="w-50 ml-2" readOnly />
          </p>
        </Col>
      </Row>
      <Card className="pb-3">
        <Nav tabs>
          <NavItem className="cursor-pointer">
            <NavLink
              className={
                activeTab === "chat"
                  ? " bg-primary text-white py-1 px-3 "
                  : " py-1"
              }
              onClick={() => {
                toggle("chat");
                handelChatEarning();
              }}
            >
              <h3 className="m-0">Chat</h3>
            </NavLink>
          </NavItem>
          <NavItem className="cursor-pointer">
            <NavLink
              className={
                activeTab === "Badges"
                  ? " bg-primary text-white py-1 px-3"
                  : " py-1"
              }
              onClick={() => {
                toggle("Badges");
                handelBadgesEarning();
              }}
            >
              <h3 className="m-0">Badges</h3>
            </NavLink>
          </NavItem>
        </Nav>
        {loader ? (
          <div className="text-center mt-2">
            {" "}
            <i className="spinner-border text-primary mb-3"></i>
          </div>
        ) : (
          <TabContent activeTab={activeTab} className="px-3 pt-3">
            <TabPane tabId="chat">
              {chatEarnings.length > 0 ? (
                chatEarnings.map((items, index) => {
                  const ratingMap = {
                    satisfied: "S",
                    RST: "U-RST",
                    NS: "U-NS",
                  };

                  const rating =
                    ratingMap[items?.rating?.chatResponse] || "no rating";

                  return (
                    <h5 key={index}>
                      {items?.name} arranged Chat with you on{" "}
                      {moment
                        .unix(items.athlete_payout.payout_date.seconds)
                        .format("DD-MM-YYYY")}{" "}
                      and rated you {rating} and you received{" "}
                      {items.athlete_payout.payout_amount}$
                    </h5>
                  );
                })
              ) : (
                <h5>You don't have any chat at the moment</h5>
              )}
              {!chatMore ? null : (
                <div className="text-center">
                  <Button
                    onClick={handelChatEarningLoadMore}
                    color="primary"
                    disabled={chatMore}
                  >
                    Load More
                  </Button>
                </div>
              )}
            </TabPane>
            <TabPane tabId="Badges">
              {badgesEarnings.length > 0 ? (
                badgesEarnings.map((items, index) => {
                  return (
                    <h5 key={index + 100}>
                      {items.name} purchased Badge license from you on{" "}
                      {moment
                        .unix(items.createdAt.seconds)
                        .format("DD-MM-YYYY")}{" "}
                      and you received {items.amount}${" "}
                      <span class="badge badge-primary">{items.type}</span>
                    </h5>
                  );
                })
              ) : (
                <h5>You don't have any badge at the moment</h5>
              )}
              {!badgesMore ? null : (
                <div className="text-center">
                  <Button
                    onClick={handelBadgesEarningLoadMore}
                    color="primary"
                    disabled={badgesMore}
                  >
                    Load More
                  </Button>
                </div>
              )}
            </TabPane>
          </TabContent>
        )}
      </Card>
      <div className="text-center mt-4">
        {activeTab == "chat" ? (
          <CSVLink
            ref={csvLink}
            headers={headers}
            filename={`Chat ${moment(new Date()).format("DD-MM-YYYY")}.csv`}
            data={
              chatEarnings == -1
                ? []
                : chatEarnings.map((item) => {
                    return {
                      ...item,
                      athleteName: userName,
                      athleteId: user.athleteID,
                      payout_amount:
                        item.athlete_payout.payout_amount + " " + "$",
                      rating: item.rating?.chatResponse,
                      date: moment
                        .unix(item.athlete_payout.payout_date.seconds)
                        .format("DD-MM-YYYY"),
                    };
                  })
            }
            className="ml-auto btn btn-primary"
          >
            Download chat records
          </CSVLink>
        ) : (
          <CSVLink
            ref={csvLink}
            headers={headers2}
            filename={`Badges ${moment(new Date()).format("DD-MM-YYYY")}.csv`}
            data={
              badgesEarnings == -1
                ? []
                : badgesEarnings.map((item) => {
                    return {
                      ...item,
                      athleteName: userName,
                      athleteId: user.athleteID,
                      payout_amount: item.amount + " " + "$",
                      date: moment
                        .unix(item.createdAt.seconds)
                        .format("DD-MM-YYYY"),
                    };
                  })
            }
            className="ml-auto btn btn-primary"
          >
            Download badges record
          </CSVLink>
        )}
      </div>
    </Container>
  );
};

export default AthleteAccountMonthly;
