import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import axios from "axios";
import { addPermanentManager } from "./managerAction";
import { RepositoryFactory } from "../../repositroy/RepositoryFactory";
import moment from "moment";
export const chatLoader = (val) => async (dispatch) => {
  dispatch({ type: "CHAT_LOADING", payload: val });
};
export const createChat = (chatObj, msgObj) => async (dispatch) => {
  dispatch(chatLoader(true));
  const Chat_id = uuidv4();
  let imgURL;
  if (msgObj.messageFile) {
    let customImgKey = uuidv4();
    var imgName = msgObj.messageFile.name;
    let ext2 = imgName.slice(imgName.lastIndexOf("."));
    var imgLets = await firebase
      .storage()
      .ref("user_files/" + customImgKey + ext2.toLowerCase())
      .put(msgObj.messageFile);
    imgURL = await imgLets.ref.getDownloadURL();
  }
  let newMsgObj = {
    message: msgObj.message,
    sender_id: msgObj.sender_id,
    messageFile_url: msgObj.messageFile ? imgURL : null,
    read: msgObj.read,
    created_at: firebase.firestore.Timestamp.now(),
  };

  const topic_id = chatObj?.chat_topic;

  await firebase
    .firestore()
    .collection("new_a4b_chat")
    .doc(Chat_id)
    .set(chatObj)
    .then(() => {
      dispatch(chatLoader(false));
      firebase
        .firestore()
        .collection("new_a4b_chat")
        .doc(Chat_id)
        .collection("messages")
        .add(newMsgObj)
        .then(() => {
          firebase
            .firestore()
            .collection("a4b_topics")
            .doc(topic_id)
            .update({ count: firebase.firestore.FieldValue.increment(1) })
            .then(() => {
              console.log("Count updated successfully.");
            })
            .catch((err) => {
              console.log("Error updating count:", err.message);
            });
        })

        .catch((err) => {
          console.log("Error fetching topic:", err.message);
        });
    })
    .catch((err) => {
      console.log("Error adding message:", err.message);
    });

  if (chatObj.custom_topic) {
    firebase
      .firestore()
      .collection("a4b_topics")
      .doc(chatObj.custom_topic)
      .update({ count: firebase.firestore.FieldValue.increment(1) })
      .then(() => {
        console.log("Custom topic count updated successfully.");
      })
      .catch((err) => {
        console.log("Error updating custom topic count:", err.message);
      });
  }
};

export const getAllChatsByIdFan =
  (id, lastDocument, onComplete = () => {}) =>
  async (dispatch) => {
    let querya = firebase
      .firestore()
      .collection("new_a4b_chat")
      .where("participants", "array-contains", id)
      .where("status", "in", [
        "accepted",
        "active",
        "complete",
        "payment pending",
      ])
      .where("type", "==", "appointment")
      .orderBy("chat_end", "desc");
    if (lastDocument != "") {
      querya = querya.startAfter(lastDocument);
    }
    querya.limit(5).onSnapshot(async (query) => {
      let tempChat = [];
      for await (let doc of query.docs) {
        let participant = doc.data().participants.find((ele) => ele != id);

        let userRef = await firebase
          .firestore()
          .collection("users")
          .doc(participant)
          .get();
        userRef.data().id = userRef.id;
        tempChat.push({
          chatUser: userRef.data(),
          partId: participant,
          id: doc.id,
          loader: false,
          ...doc.data(),
        });
      }

      let hasMoreDocument = tempChat.length >= 5;
      if (lastDocument != "") {
        dispatch({
          type: "GET_ALL_CHAT_BY_ID",
          payload: {
            tempChat,
            hasMoreDocument,
            lastDocument: query.docs[tempChat.length - 1],
          },
        });
      } else {
        dispatch({
          type: "GET_ALL_CHAT_BY_ID_EMPTY",
          payload: {
            tempChat,
            hasMoreDocument,
            lastDocument: query.docs[tempChat.length - 1],
          },
        });
      }
      onComplete();
    });
  };
export const getAllChatsByIdAthlete =
  (id, lastDocument, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      let querya = firebase
        .firestore()
        .collection("new_a4b_chat")
        .where("participants", "array-contains", id)
        .where("status", "==", "pending")
        .orderBy("chat_end", "desc");
      if (lastDocument != "") {
        querya = querya.startAfter(lastDocument);
      }
      querya.limit(5).onSnapshot(async (query) => {
        let tempChat = [];
        for (let doc of query.docs) {
          let participant = doc.data().participants.find((ele) => ele != id);
          let userRef = await firebase
            .firestore()
            .collection("users")
            .doc(participant)
            .get();
          userRef.data().id = userRef.id;
          tempChat.push({
            chatUser: userRef.data(),
            partId: participant,
            id: doc.id,
            rejectLoader: false,
            acceptLoader: false,
            ...doc.data(),
          });
        }
        let hasMoreDocument = tempChat.length >= 5;
        if (lastDocument != "") {
          dispatch({
            type: "GET_ALL_CHAT_BY_ID",
            payload: {
              tempChat,
              hasMoreDocument,
              lastDocument: query.docs[tempChat.length - 1],
            },
          });
        } else {
          dispatch({
            type: "GET_ALL_CHAT_BY_ID_EMPTY",
            payload: {
              tempChat,
              hasMoreDocument,
              lastDocument: query.docs[tempChat.length - 1],
            },
          });
        }
        onComplete();
      });
    } catch (error) {
      console.log("loading");
    }
  };
export const upDateChat = (index) => (dispatch) => {
  dispatch({
    type: "UPDATE_CHAT",
    payload: index,
  });
};
export const updateChatStatus = (chatId, status) => (dispatch) => {
  dispatch({
    type: "UPDATE_CHAT_STATUS",
    payload: { chatId, status },
  });
};
export const upDateChatLoader = (id, type) => (dispatch) => {
  dispatch({
    type: "UPDATE_CHAT_LOADER",
    payload: { id: id, type: type },
  });
};
export const fanRejectPaymentTimeSlot =
  (id, onComplete = () => {}) =>
  async (dispatch) => {
    await firebase
      .firestore()
      .collection("new_a4b_chat")
      .doc(id)
      .get()
      .then(async (chat) => {
        let a4b_athelete_timeslots = await firebase
          .firestore()
          .collection("a4b_athelete_timeslots")
          .doc(chat.data().time_slot_id);
        await a4b_athelete_timeslots.get().then(async (items) => {
          if (items.data().highPriorityQuestion !== null) {
            await firebase
              .firestore()
              .collection("a4b_questions")
              .doc(items.data().highPriorityQuestion)
              .update({
                status: "open",
              });
          }
        });
        a4b_athelete_timeslots.update({
          fan_id: firebase.firestore.FieldValue.delete(),
          highPriorityQuestion: firebase.firestore.FieldValue.delete(),
          status: "notBooked",
        });
        await firebase.firestore().collection("new_a4b_chat").doc(id).delete();
        dispatch({
          type: "REMOVE_CHAT",
          payload: id,
        });
        // onComplete();
      });
  };
export const getAllChatsBySlotId = (id, uid) => async (dispatch) => {
  firebase
    .firestore()
    .collection("new_a4b_chat")
    .where("time_slot_id", "==", id)
    .orderBy("created_at", "desc")
    .onSnapshot(async (query) => {
      var tempChat = [];
      for await (let doc of query.docs) {
        let participant = doc.data().participants[1];
        let userRef = await firebase
          .firestore()
          .collection("users")
          .doc(participant)
          .get();
        tempChat.push({
          chatUser: userRef.data(),
          partId: participant,
          id: doc.id,
          ...doc.data(),
        });
      }
      dispatch({
        type: "GET_ALL_CHAT_BY_SLOT_ID",
        payload: tempChat,
      });
    });
};

export const acceptChatById = (id, details) => async (dispatch) => {
  dispatch(chatLoader(true));
  firebase
    .firestore()
    .collection("new_a4b_chat")
    .doc(id)
    .update({ status: "payment pending" });
  dispatch(updateChatStatus(id, "payment pending"));
  toast.success("Chat request has been Accepted successfully");
  dispatch(chatLoader(false));
};
export const completeChatPayment =
  (id, details, athlete, oncomplete = () => {}) =>
  async (dispatch) => {
    dispatch(chatLoader(true));
    let chat_count = parseInt(athlete.chatUser.chat_count);
    firebase
      .firestore()
      .collection("new_a4b_chat")
      .doc(id)
      .update({
        chat_end: firebase.firestore.Timestamp.now(),
        status: "accepted",
        via: "paypal",
        paid_amount: details.purchase_units[0].amount,
        payment_id: details.id,
        chat_id: `${athlete.chatUser.athleteID}-C${
          athlete.chatUser.chat_count ? chat_count + 1 : 1
        }`,
      });
    firebase
      .firestore()
      .collection("users")
      .doc(athlete.athleteID)
      .update({
        chat_count: `${athlete.chatUser.chat_count ? chat_count + 1 : 1}`,
      });
    // dispatch(updateChatStatus(id, "complete"));
    toast.success("YOUR PAYMENT IS IN ESCROW AND YOU MAY PROCEED TO CHAT");
    oncomplete();
    dispatch(chatLoader(false));
  };

export const rejectChatById = (obj) => async (dispatch) => {
  await firebase
    .firestore()
    .collection("new_a4b_chat")
    .doc(obj.id)
    .update({ status: "rejected" });
  await firebase
    .firestore()
    .collection("a4b_athelete_timeslots")
    .doc(obj.timeSlotId)
    .update({ status: "notBooked" });
  dispatch(updateChatStatus(obj.id, "rejected"));
  toast.warning("Request for Chat has been Rejected");
};

export const chatReviewAction =
  (obj, obj1, onComplete = () => {}) =>
  async (dispatch) => {
    dispatch(chatLoader(true));
    let { slotId, chatType, ...newObj } = obj;
    if (obj.reviewComment == "") {
      delete newObj.reviewComment;
      await firebase.firestore().collection("a4b_chat_rating").add(newObj);
      await firebase
        .firestore()
        .collection("new_a4b_chat")
        .doc(obj.chatId)
        .update(obj1);

      toast.success("Chat review added successfully");
    } else {
      await firebase.firestore().collection("a4b_chat_rating").add(newObj);
      await firebase
        .firestore()
        .collection("new_a4b_chat")
        .doc(obj.chatId)
        .update(obj1);

      toast.success("Chat review added successfully");
    }
    if (chatType == "single") {
      dispatch({
        type: "FAN_REVIEW",
        payload: obj.chatId,
      });
    } else {
      let slot = await firebase
        .firestore()
        .collection("a4b_athelete_timeslots")
        .doc(slotId)
        .get();
      const updatedPayments = slot.data().payments.map((payment) => {
        if (payment.fanId === obj.from_id) {
          return { ...payment, is_reviewied_fan: true };
        } else {
          return payment;
        }
      });
      await firebase
        .firestore()
        .collection("a4b_athelete_timeslots")
        .doc(slotId)
        .update({ payments: updatedPayments });

      await dispatch({
        type: "UPDATE_REVIEW_STATUS",
        payload: { chatId: obj.chatId, fanId: obj.from_id },
      });
    }
    dispatch(chatLoader(false));
    onComplete();
  };
export const athleteChatReviewAction =
  (obj, obj1, onComplete = () => {}) =>
  async (dispatch) => {
    let { slotId, ...newObj } = obj;
    dispatch(chatLoader(true));
    dispatch({
      type: "ATHLETE-REVIEW",
      payload: obj.chatId,
    });
    if (obj.reviewComment == "") {
      delete newObj.reviewComment;
      await firebase.firestore().collection("a4b_chat_rating").add(newObj);
      await firebase
        .firestore()
        .collection("new_a4b_chat")
        .doc(obj.chatId)
        .update(obj1);
      toast.success("Chat review added successfully");
    } else {
      await firebase.firestore().collection("a4b_chat_rating").add(newObj);
      await firebase
        .firestore()
        .collection("new_a4b_chat")
        .doc(obj.chatId)
        .update(obj1);
      toast.success("Chat review added successfully");
    }
    onComplete();
  };
export const addAthleteTimeSlot =
  (object, onComplete = () => {}) =>
  async (dispatch) => {
    let { permanentManager, ...obj } = object;
    dispatch(chatLoader(true));
    let slots = await firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .where("athleteId", "==", obj.athleteId)
      .where("date", "==", obj.date)
      .get();
    obj.created_at = firebase.firestore.Timestamp.now();
    if (slots.docs.length == 0) {
      if (obj.managerEmail) {
        let fan = await firebase
          .firestore()
          .collection("users")
          .where("email", "==", obj.managerEmail)
          .get();
        if (fan.docs.length == 1 && fan.docs[0].data()?.role == "fan") {
          if (permanentManager) {
            dispatch(
              addPermanentManager({
                created_at: firebase.firestore.Timestamp.now(),
                athleteId: obj.athleteId,
                fanId: fan.docs[0].id,
              })
            );
          }
          await firebase
            .firestore()
            .collection("a4b_athelete_timeslots")
            .add(obj);
          console.log(obj, "objppbj");
          toast.success("Slot added successfully");
          onComplete();
        } else {
          toast.error("Manager Email Not Exist");
        }
      } else {
        await firebase
          .firestore()
          .collection("a4b_athelete_timeslots")
          .add(obj);
        console.log(obj, "objppbjelselese");
        toast.success("Slot added successfully");
        onComplete(obj);
      }
    } else {
      let canAdd = true;
      slots.forEach(async (slot) => {
        if (slot.data().start <= obj.start && slot.data().end > obj.start) {
          canAdd = false;
          toast.error("This Slot is already added");
        } else if (obj.slotType == "group") {
          if (slot.data().date == obj.date) {
            canAdd = false;
          }
        }
      });
      if (canAdd) {
        if (obj.managerEmail) {
          let fan = await firebase
            .firestore()
            .collection("users")
            .where("email", "==", obj.managerEmail)
            .get();
          if (fan.docs.length == 1 && fan.docs[0].data()?.role == "fan") {
            if (permanentManager) {
              await dispatch(
                addPermanentManager({
                  created_at: firebase.firestore.Timestamp.now(),
                  athleteId: obj.athleteId,
                  fanId: fan.docs[0].id,
                })
              );
            }
            await firebase
              .firestore()
              .collection("a4b_athelete_timeslots")
              .add(obj);
            toast.success("Slot added successfully");
            onComplete();
          } else {
            toast.error("Manager Email Not Exist");
          }
        } else {
          await firebase
            .firestore()
            .collection("a4b_athelete_timeslots")
            .add(obj);
          toast.success("Slot added successfully");
          onComplete();
        }
      } else {
        toast.error("This Slot is already added");
      }
    }
    dispatch(chatLoader(false));
  };

export const editAthleteTimeSlot =
  (obj, id, onComplete = () => {}) =>
  async (dispatch) => {
    dispatch(chatLoader(true));
    let Slot = firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .doc(id);
    let slot = await firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .where(firebase.firestore.FieldPath.documentId(), "!=", id)
      .where("athleteId", "==", obj.athleteId)
      .get();
    if (obj.slotType == "group") {
      let canEdit = true;
      slot.forEach(async (slot) => {
        console.log(slot.data());
        if (slot.data().start <= obj.start && slot.data().end > obj.start) {
          canEdit = false;
          toast.error("This Slot is already added");
        } else if (obj.slotType == "group") {
          console.log(slot.data().date, obj.date);
          if (slot.data().date == obj.date) {
            canEdit = false;
          }
        }
      });
      if (canEdit) {
        if (obj.managerEmail) {
          let fan = await firebase
            .firestore()
            .collection("users")
            .where("email", "==", obj.managerEmail)
            .get();
          if (fan.docs.length == 1 && fan.docs[0].data()?.role == "fan") {
            Slot.update({
              // chatAmount: obj.chatAmount,
              date: obj.date,
              end: obj.end,
              managerType: obj.managerType,
              // numberOfParticipants: obj.numberOfParticipants,
              perParticipantAmount: obj.perParticipantAmount,
              slotType: obj.slotType,
              start: obj.start,
              time_zone: obj.time_zone,
              zoom_link: obj.zoom_link,
              managerEmail: obj.managerEmail,
              endTime: obj.endTime,
              startTime: obj.startTime,
            });
            onComplete();
            toast.success("Slot Edit Successfully");
          } else {
            toast.error("Manager Email Not Exist");
          }
        } else {
          Slot.update({
            // chatAmount: obj.chatAmount,
            date: obj.date,
            end: obj.end,
            managerType: obj.managerType,
            // numberOfParticipants: obj.numberOfParticipants,
            perParticipantAmount: obj.perParticipantAmount,
            slotType: obj.slotType,
            start: obj.start,
            time_zone: obj.time_zone,
            zoom_link: obj.zoom_link,
            endTime: obj.endTime,
            startTime: obj.startTime,
          });
          onComplete();
          toast.success("Slot Edit Successfully");
        }
      } else {
        toast.error("This Slot is already added");
      }
    } else {
      Slot.update({
        time_zone: obj.time_zone,
        date: obj.date,
        end: obj.end,
        slotType: obj.slotType,
        start: obj.start,
        endTime: obj.endTime,
        startTime: obj.startTime,
      });
      onComplete();
      toast.success("Slot Edit Successfully");
    }
    dispatch(chatLoader(false));
  };

export const fanRequestedEmail = (obj) => () => {
  const athleteResponseEmailNew = `Athlete  ${obj.athleteName} has created a slot do checkout to communicate with them`;

  firebase
    .firestore()
    .collection("fan_requested_slots")
    .where("athleteId", "==", obj.id)
    .get()
    .then((query) => {
      query.forEach(async (doc) => {
        let user = await firebase
          .firestore()
          .collection("users")
          .doc(doc.data().fanId)
          .get();
        const options = {
          to: user.data().email,
          subject: "Fan Requested Slots",
          text: athleteResponseEmailNew,
        };
        axios
          .post(
            "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
            options
          )
          .then((response) => {
            firebase
              .firestore()
              .collection("fan_requested_slots")
              .doc(doc.id)
              .delete();
            toast.success("Email Sent Successfully");
          })
          .catch((error) => {
            toast.error("Error occured try Again !");
          });
      });
    });
};

export const getAllslotsById = (id) => async (dispatch) => {
  firebase
    .firestore()
    .collection("a4b_athelete_timeslots")
    .where("athleteId", "==", id)
    .where("type", "==", "appointment")
    .where("slotType", "==", "single")
    .orderBy("created_at", "desc")
    .onSnapshot(async (query) => {
      var tempSlots = [];
      for await (let doc of query.docs) {
        tempSlots.push({ id: doc.id, ...doc.data() });
      }
      dispatch({
        type: "GET_ALL_SLOTS_BY_ID",
        payload: tempSlots,
      });
    });
};
export const getAllGroupSlotsById =
  (obj, onComplete = () => {}) =>
  async (dispatch) => {
    const nextTwoDays = new Date();
    nextTwoDays.setDate(nextTwoDays.getDate() + 3);
    nextTwoDays.setHours(0, 0, 0, 0);
    let query = await firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .where("athleteId", "==", obj.athleteId)
      .where("slotType", "==", "group");
    if (obj.type == "fan") {
      query = query
        .where("status", "==", "notBooked")
        .where("startTime", "<", nextTwoDays);
    } else {
      query = query.orderBy("created_at", "desc");
    }
    query.onSnapshot(async (query) => {
      var tempSlots = [];
      for await (let doc of query.docs) {
        let athlete = await firebase
          .firestore()
          .collection("users")
          .doc(obj.athleteId)
          .get();
        if (doc.data().managerEmail) {
          let fan = await firebase
            .firestore()
            .collection("users")
            .where("email", "==", doc.data().managerEmail)
            .get();
          tempSlots.push({
            id: doc.id,
            ...doc.data(),
            athlete: athlete.data(),
            fan: fan.docs[0].data(),
          });
        } else {
          tempSlots.push({
            id: doc.id,
            ...doc.data(),
            athlete: athlete.data(),
          });
        }
      }
      dispatch({
        type: "GET_ALL_GROUP_SLOTS_BY_ID",
        payload: tempSlots,
      });
      onComplete();
    });
  };
export const getAvailableSlots = (id) => (dispatch) => {
  firebase
    .firestore()
    .collection("a4b_athelete_timeslots")
    .where("athleteId", "==", id)
    .where("status", "==", "notBooked")
    .where("slotType", "==", "single")
    .orderBy("created_at", "desc")
    .onSnapshot(async (query) => {
      var tempSlots = [];
      for await (let doc of query.docs) {
        tempSlots.push({ id: doc.id, ...doc.data() });
      }
      dispatch({
        type: "GET_AVAILABLE_SLOTS",
        payload: tempSlots,
      });
    });
};
export const getAvailableslotsByDate = (id, date) => async (dispatch) => {
  firebase
    .firestore()
    .collection("a4b_athelete_timeslots")
    .where("athleteId", "==", id)
    .where("date", "==", date)
    .where("status", "==", "notBooked")
    .orderBy("created_at", "desc")
    .onSnapshot(async (query) => {
      var tempSlots = [];
      for await (let doc of query.docs) {
        tempSlots.push({ id: doc.id, ...doc.data() });
      }
      dispatch({
        type: "GET_ALL_SLOTS_BY_DATE",
        payload: tempSlots,
      });
    });
  firebase
    .firestore()
    .collection("a4b_chat_rating")
    .where("to_id", "==", id)
    .onSnapshot(async (query) => {
      var tempStats = [];
      for await (let doc of query.docs) {
        tempStats.push({ id: doc.id, ...doc.data() });
      }
      const counts = tempStats.reduce((acc, { chatResponse }) => {
        acc[chatResponse] = (acc[chatResponse] || 0) + 1;
        return acc;
      }, {});
      counts.total = tempStats.length;
      const chatQuery = await firebase
        .firestore()
        .collection("new_a4b_chat")
        .where("athleteID", "==", id)
        .where("status", "==", "complete")
        .get();
      const tempTotalChat = chatQuery.docs.map((doc) => ({
        status: doc.status,
      }));
      let totalChat = tempTotalChat.length;
      dispatch({
        type: "GET_ALL_REVIEWS",
        payload: { counts: counts, totalChat: totalChat },
      });
      let topics = await firebase.firestore().collection("a4b_topics").get();
      let topictemp = [];
      topics.forEach((doc) => {
        topictemp.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: "GET_TOPICS",
        payload: topictemp,
      });
    });
};

export const chatBooking = ({ obj, onSuccess }) => {
  return async (dispatch) => {
    dispatch(chatLoader(true));
    firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .doc(obj.time_slot)
      .update({
        fan_id: obj.uid,
        status: "booked",
        highPriorityQuestion: obj.highPriorityQuestion,
        note: obj.note,
      })
      .then((res) => {
        if (obj.highPriorityQuestion !== null) {
          firebase
            .firestore()
            .collection("a4b_questions")
            .doc(obj.highPriorityQuestion)
            .update({
              status: "close",
            })
            .then((res) => {
              onSuccess();
            })
            .catch((error) => {
              toast.warning(error.message);
            });
        } else {
          onSuccess();
        }
      })
      .catch((error) => {
        toast.warning(error.message);
      });
  };
};

export const getChatsCountById = (id) => async (dispatch) => {
  firebase
    .firestore()
    .collection("new_a4b_chat")
    .where("participants", "array-contains", id)
    .onSnapshot(async (query) => {
      var tempChatCount = 0;
      tempChatCount = query.docs.length;
      dispatch({
        type: "GET_CHAT_COUNT_BY_ID",
        payload: tempChatCount,
      });
    });
};
export const getChatsEarningById = (id) => async (dispatch) => {
  firebase
    .firestore()
    .collection("new_a4b_chat")
    .where("participants", "array-contains", id)
    .onSnapshot(async (query) => {
      let tempChatEarning = 0;
      for await (let doc of query.docs) {
        if (doc.data()?.paid_amount?.value) {
          tempChatEarning += parseFloat(doc.data()?.paid_amount?.value);
        }
      }
      dispatch({
        type: "GET_CHAT_EARNING_BY_ID",
        payload: tempChatEarning,
      });
    });
};

export const getChatEarningByAthleteID = (id) => {
  return async (dispatch) => {
    let val = 0;
    let chatEarningData = await firebase
      .firestore()
      .collection("new_a4b_chat")
      .where("athleteID", "==", id)
      .where("athlete_payment", "==", true)
      .get();
    for (let doc of chatEarningData.docs) {
      val += (Number(doc.data()?.paid_amount.value) / 100) * 76;
    }
    dispatch({
      type: "GET_CHAT_EARNING_BY_ATHLETE_ID",
      payload: val,
    });
  };
};

export const getChatRatingById = (id) => async (dispatch) => {
  firebase
    .firestore()
    .collection("a4b_chat_rating")
    .where("to_id", "==", id)
    .onSnapshot(async (query) => {
      var tempRating = [];
      for await (let doc of query.docs) {
        let userRef = await firebase
          .firestore()
          .collection("users")
          .doc(doc.data().from_id)
          .get();
        let chatId = await firebase
          .firestore()
          .collection("new_a4b_chat")
          .doc(doc.data().chatId)
          .get();
        tempRating.push({
          fan: userRef.data(),
          chats: chatId.data(),
          id: doc.id,
          ...doc.data(),
        });
      }
      dispatch({
        type: "GET_ALL_RATING_BY_ID",
        payload: tempRating,
      });
    });
};
export const setIsShowAppointment = (boolean) => (dispatch) => {
  dispatch({
    type: "OPEN-APPOINTMENT-MODAL",
    payload: boolean,
  });
};

export const checkSlotPriority = (id) => async (dispatch) => {
  try {
    const query = await firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .doc(id)
      .get();
    const data = query.data() || {};

    const isHighPriority = data.highPriorityQuestion ? "yes" : "no";
    const questionId = data.highPriorityQuestion || null;

    dispatch({
      type: "CHECK_SLOT_PRIORITY",
      payload: { isHighPriority, questionId },
    });
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const sendEmailFanForGroupSlot = (obj) => async () => {
  let athleteName = obj.first_name + " " + obj.last_name;
  firebase
    .firestore()
    .collection("a4b_athlete_favorite")
    .where("athleteId", "==", obj.id)
    .get()
    .then(async (query) => {
      for await (let doc of query.docs) {
        if (doc.data().notification) {
          let fan = await firebase
            .firestore()
            .collection("users")
            .doc(doc.data().fanId)
            .get();
          const athleteResponseEmailNew = `An athlete ${athleteName} that you have added in your favorites has created a new slot login to book the slots and chat with your favorite athletes`;
          const options = {
            to: fan.data().email,
            subject: "Athlete Make Group Slot",
            text: athleteResponseEmailNew,
          };

          await axios.post(
            "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
            options
          );
        }
      }
    });
};

export const sendEmailFanForVolunteerGroupSlot = (obj) => async () => {
  let athleteName = obj.first_name + " " + obj.last_name;
  const currentTimestamp = firebase.firestore.Timestamp.now();
  const currentDate = currentTimestamp.toDate();
  const formattedDate = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
  firebase
    .firestore()
    .collection("a4b_athlete_favorite")
    .where("athleteId", "==", obj.id)
    .get()
    .then(async (query) => {
      for await (let doc of query.docs) {
        if (doc.data().volunteerNotification) {
          let fan = await firebase
            .firestore()
            .collection("users")
            .doc(doc.data().fanId)
            .get();
          const athleteResponseEmailNew = `An athlete ${athleteName} has requested a volunteer for group chat happening on ${formattedDate} on A4B`;
          const options = {
            to: fan.data().email,
            subject: "Make Volunteer Slot",
            text: athleteResponseEmailNew,
          };

          await axios.post(
            "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
            options
          );
        }
      }
    });
};

export const isQuestionId = (id) => (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("a4b_questions")
      .doc(id)
      .get()
      .then(async (query) => {
        if (id) {
          dispatch({
            type: "IS-QUESTION-EXISTS",
            payload: query.exists,
          });
        } else {
          dispatch({
            type: "IS-QUESTION-EXISTS",
            payload: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const confirmGroupChat =
  (slotId, onComplete = () => {}) =>
  () => {
    let data = {
      slotId: slotId,
    };
    const timeZoneRepository = RepositoryFactory.get("confirmChat");
    timeZoneRepository
      .confirmChat(data)
      .then((response) => {
        toast.success(response.data.message);
        console.log(response.data.message);
        onComplete();
      })
      .catch((error) => {
        console.error(error);
      });
  };
export const groupRefund =
  (slotId, onComplete = () => {}) =>
  () => {
    let data = {
      slotId: slotId,
    };
    const timeZoneRepository = RepositoryFactory.get("groupRefund");
    timeZoneRepository
      .groupRefund(data)
      .then((response) => {
        toast.success(response.data.message);
        console.log(response.data.message);
        onComplete();
      })
      .catch((error) => {
        console.error(error);
      });
  };
export const singleRefund = (data) => (dispatch) => {
  dispatch({
    type: "UPDATE-GROUP-SLOT-BY-ID",
    payload: data.fanId,
  });
  const timeZoneRepository = RepositoryFactory.get("singleRefund");
  timeZoneRepository
    .singleRefund(data)
    .then((response) => {
      toast.success(response.data.message);
      console.log(response.data.message);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getGroupChats =
  (id, onComplete = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("new_a4b_chat")
      .where("participants", "array-contains", id)
      .where("type", "==", "group")
      .onSnapshot(async (query) => {
        let temp_data = [];
        for await (let doc of query.docs) {
          let athlete = await firebase
            .firestore()
            .collection("users")
            .doc(doc.data().athleteID)
            .get();
          let slot = await firebase
            .firestore()
            .collection("a4b_athelete_timeslots")
            .doc(doc.data().time_slot_id)
            .get();
          if (slot.data()?.managerEmail) {
            let manager = await firebase
              .firestore()
              .collection("users")
              .where("email", "==", slot.data().managerEmail)
              .get();
            temp_data.push({
              id: doc.id,
              ...doc.data(),
              athleteData: athlete.data(),
              slot: slot.data(),
              manager: manager.docs[0].data(),
            });
          } else {
            temp_data.push({
              id: doc.id,
              ...doc.data(),
              athleteData: athlete.data(),
              slot: slot.data(),
            });
          }
        }
        dispatch({
          type: "GET-GROUP-CHAT",
          payload: temp_data,
        });
        onComplete();
      });
  };
export const getAthleteGroupChats =
  (id, onComplete = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("new_a4b_chat")
      .where("athleteID", "==", id)
      .where("type", "==", "group")
      .onSnapshot(async (query) => {
        let temp_data = [];
        for await (let doc of query.docs) {
          let slot = await firebase
            .firestore()
            .collection("a4b_athelete_timeslots")
            .doc(doc.data().time_slot_id)
            .get();
          if (slot.data()?.managerEmail) {
            let manager = await firebase
              .firestore()
              .collection("users")
              .where("email", "==", slot.data().managerEmail)
              .get();
            temp_data.push({
              id: doc.id,
              ...doc.data(),
              slot: slot.data(),
              manager: manager.docs[0].data(),
              managerId: manager.docs[0].id,
            });
          } else {
            temp_data.push({
              id: doc.id,
              ...doc.data(),
              slot: slot.data(),
            });
          }
        }
        dispatch({
          type: "GET-ATHLETE-GROUP-CHAT",
          payload: temp_data,
        });
        onComplete();
      });
  };
export const checkManagerReviews =
  (email, onComplete = () => {}) =>
  async (dispatch) => {
    let user = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .where("role", "==", "fan")
      .get();
    console.log(user);
    if (user.docs.length !== 0) {
      let rating = await firebase
        .firestore()
        .collection("a4b_chat_rating")
        .where("type", "==", "manager")
        .where("to_id", "==", user.docs[0].id)
        .get();
      let tempStats = rating.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const counts = tempStats.reduce((acc, { chatResponse }) => {
        acc[chatResponse] = (acc[chatResponse] || 0) + 1;
        return acc;
      }, {});
      counts.total = tempStats.length;
      const chatQuery = await firebase
        .firestore()
        .collection("a4b_athelete_timeslots")
        .where("managerEmail", "==", email)
        .where("status", "==", "booked")
        .get();
      const tempTotalChat = chatQuery.docs.map((doc) => ({
        status: doc.status,
      }));
      let totalChat = tempTotalChat.length;
      dispatch({
        type: "GET_MANAGER_REVIEWS",
        payload: {
          counts: counts,
          totalChat: totalChat,
          isUser: true,
          name: user.docs[0].data().name,
        },
      });
      onComplete();
    } else {
      toast.warning("Email not Exist");
      dispatch({
        type: "GET_MANAGER_REVIEWS",
        payload: { counts: null, isUser: false },
      });
      onComplete();
    }
  };

export const sendEmailPaidFanSlots =
  (obj, onComplete = () => {}) =>
  async (dispatch) => {
    const athleteResponseEmailNew = `${obj.text}`;
    firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .doc(obj.id)
      .get()
      .then(async (query) => {
        query.data()?.participants.forEach(async (items) => {
          let user = await firebase
            .firestore()
            .collection("users")
            .doc(items)
            .get();
          const options = {
            to: user.data().email,
            subject: "Edit Group Slot",
            text: athleteResponseEmailNew,
          };
          axios.post(
            "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
            options
          );
        });
        toast.success("Email Sent Successfully");
        onComplete();
      });
  };

export const questionRequestForGroupChat =
  (obj, onComplete = () => {}) =>
  (dispatch) => {
    firebase
      .firestore()
      .collection("a4b_question_requests")
      .add(obj)
      .then((res) => {
        toast.success("Question Requested Successfully");
        onComplete();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
export const isFanRequest =
  (obj, onComplete = () => {}) =>
  (dispatch) => {
    firebase
      .firestore()
      .collection("a4b_question_requests")
      .where("fanId", "==", obj.fanId)
      .where("athleteId", "==", obj.athleteId)
      .onSnapshot(async (query) => {
        let temp_data = [];
        for await (let doc of query.docs) {
          temp_data.push(doc.data().slotId);
        }
        console.log(temp_data);
        dispatch({
          type: "IS-FAN-REQUEST",
          payload: temp_data,
        });
      });
  };

export const getFansRequestsBySlotId = (slotId) => (dispatch) => {
  firebase
    .firestore()
    .collection("a4b_question_requests")
    .where("slotId", "==", slotId)
    .onSnapshot(async (query) => {
      let temp_data = [];
      for await (let doc of query.docs) {
        let fan = await firebase
          .firestore()
          .collection("users")
          .doc(doc.data().fanId)
          .get();
        temp_data.push({
          id: doc.id,
          ...doc.data(),
          fan: fan.data(),
        });
      }
      console.log(temp_data);
      dispatch({
        type: "GET-FAN-REQUESTS-BY-SLOT-ID",
        payload: temp_data,
      });
    });
};

export const getSlotByIdUrl =
  (id, onComplete = () => {}) =>
  (dispatch) => {
    firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .doc(id)
      .get()
      .then(async (query) => {
        console.log(query.exists);
        if (query.exists) {
          console.log(query.data().athleteId);
          let athlete = await firebase
            .firestore()
            .collection("users")
            .doc(query.data().athleteId)
            .get();
          onComplete({
            data: query.data(),
            id: id,
            athlete: athlete.data(),
            isData: true,
          });
        } else {
          toast.warning("No slot exist");
          onComplete({ data: "", isData: false });
        }
      });
  };
