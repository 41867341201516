import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const addFavoriteAthlete = (obj) => async () => {
  firebase
    .firestore()
    .collection("a4b_athlete_favorite")
    .add(obj)
    .then((query) => {
      toast.success("Add favorite successfully");
    });
};

export const isAthleteFavorite = (obj) => async (dispatch) => {
  firebase
    .firestore()
    .collection("a4b_athlete_favorite")
    .where("athleteId", "==", obj.athleteId)
    .where("fanId", "==", obj.fanId)
    .onSnapshot((query) => {
      let temp_data = false;
      if (query.docs.length == 1) {
        temp_data = true;
      }
      dispatch({
        type: "IS_ATHLETE_FAVORITE",
        payload: temp_data,
      });
    });
};

export const removeFromFavorite = (obj) => async (dispatch) => {
  dispatch({
    type: "REMOVE_LOADER",
    payload: obj.athleteId,
  });
  let favoriteAthlete = await firebase
    .firestore()
    .collection("a4b_athlete_favorite")
    .where("athleteId", "==", obj.athleteId)
    .where("fanId", "==", obj.fanId)
    .get();
  await firebase
    .firestore()
    .collection("a4b_athlete_favorite")
    .doc(favoriteAthlete.docs[0].id)
    .delete();
  toast.warning("Remove from favorite successfully");
};

export const getFavoriteAthletes =
  (id, lastDocument, onComplete = () => {}) =>
  async (dispatch) => {
    let favoriteAthlete = firebase
      .firestore()
      .collection("a4b_athlete_favorite")
      .where("fanId", "==", id);
    if (lastDocument != "") {
      favoriteAthlete = favoriteAthlete.startAfter(lastDocument);
    }
    favoriteAthlete.limit(5).onSnapshot(async (query) => {
      let temp_data = [];
      for await (let doc of query.docs) {
        let athlete = await firebase
          .firestore()
          .collection("users")
          .doc(doc.data().athleteId)
          .get();
        temp_data.push({
          ...doc.data(),
          ...athlete.data(),
          id: doc.id,
          btnLoader: false,
          notificationBtnLoader: false,
        });
      }
      let hasMoreDocument = temp_data.length >= 5;
      if (lastDocument != "") {
        dispatch({
          type: "GET_ATHLETE_FAVORITES",
          payload: {
            temp_data,
            hasMoreDocument,
            lastDocument: query.docs[temp_data.length - 1],
          },
        });
      } else {
        dispatch({
          type: "GET_ATHLETE_FAVORITES_EMPTY",
          payload: {
            temp_data,
            hasMoreDocument,
            lastDocument: query.docs[temp_data.length - 1],
          },
        });
      }
      onComplete();
    });
  };
export const changeNotification = (obj) => async (dispatch) => {
  firebase
    .firestore()
    .collection("a4b_athlete_favorite")
    .doc(obj.id)
    .update({ notification: obj.notification });
};

export const changeValunteerNotification = (obj) => async (dispatch) => {
  firebase
    .firestore()
    .collection("a4b_athlete_favorite")
    .doc(obj.id)
    .update({ volunteerNotification: obj.volunteerNotification });
};

export const notificationLoader = (id) => (dispatch) => {
  dispatch({
    type: "NOTIFICATION_LOADER",
    payload: id,
  });
};

export const notificationValunteerLoader = (id) => (dispatch) => {
  dispatch({
    type: "NOTIFICATION_VALUNTEER_LOADER",
    payload: id,
  });
};

export const addRequestForManager =
  (obj, onComplete = () => {}) =>
  async () => {
    await firebase
      .firestore()
      .collection("a4b_volunteer")
      .add(obj)
      .then((query) => {
        toast.success("Request added successfully");
        onComplete();
      })
      .catch((error) => {
        console.error("Error adding request:", error);
      });
  };
export const getVolunteerRequest =
  (id, onComplete = () => {}) =>
  async (dispatch) => {
    await firebase
      .firestore()
      .collection("a4b_volunteer")
      .where("slotId", "==", id)
      .onSnapshot(async (query) => {
        let temp_data = [];
        for await (let doc of query.docs) {
          let fan = await firebase
            .firestore()
            .collection("users")
            .doc(doc.data().fanId)
            .get();
          console.log(doc.data().fanId);
          let rating = await firebase
            .firestore()
            .collection("a4b_chat_rating")
            .where("type", "==", "manager")
            .where("to_id", "==", doc.data().fanId)
            .get();
          let tempStats = rating.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const counts = tempStats.reduce((acc, { chatResponse }) => {
            acc[chatResponse] = (acc[chatResponse] || 0) + 1;
            return acc;
          }, {});
          // counts.total = tempStats.length;
          const chatQuery = await firebase
            .firestore()
            .collection("a4b_athelete_timeslots")
            .where("managerEmail", "==", fan.data().email)
            .where("status", "==", "booked")
            .get();
          const tempTotalChat = chatQuery.docs.map((doc) => ({
            status: doc.status,
          }));
          let totalChat = tempTotalChat.length;
          temp_data.push({
            ...doc.data(),
            ...fan.data(),
            permanentManager: false,
            id: doc.id,
            counts: counts,
            totalChat: totalChat,
          });
        }
        dispatch({
          type: "GET_VOLUNTEER_REQUEST",
          payload: temp_data,
        });
      });
  };
export const setPermanentManager = (id) => async (dispatch) => {
  await dispatch({
    type: "SET-PERMANENT-MANAGER",
    payload: id,
  });
};
export const isVolunteerRequest = (obj) => (dispatch) => {
  firebase
    .firestore()
    .collection("a4b_volunteer")
    .where("athleteId", "==", obj.athleteId)
    .where("fanId", "==", obj.fanId)
    .onSnapshot(async (query) => {
      let temp_data = [];
      for await (let doc of query.docs) {
        temp_data.push(doc.data().slotId);
      }
      dispatch({
        type: "IS_REQUEST",
        payload: temp_data,
      });
    });
};
export const acceptVolunteerRequest =
  (obj, onComplete = () => {}) =>
  async () => {
    await firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .doc(obj.slotId)
      .update({
        managerEmail: obj.fanEmail,
        managerType: "Accept Volunteer",
        declinePayment: obj.declinePayment,
      })
      .then(async () => {
        firebase
          .firestore()
          .collection("a4b_volunteer")
          .where("slotId", "==", obj.slotId)
          .get()
          .then(async (query) => {
            for await (let doc of query.docs) {
              firebase
                .firestore()
                .collection("a4b_volunteer")
                .doc(doc.id)
                .delete();
            }
            onComplete();
          });
      });
  };

export const paymentGroupSlots = (payments, updateSlot) => async (dispatch) => {
  await firebase
    .firestore()
    .collection("a4b_athelete_timeslots")
    .doc(payments.slotId)
    .collection("payment")
    .doc(payments.paymentId)
    .set(payments);
  await firebase
    .firestore()
    .collection("a4b_athelete_timeslots")
    .doc(payments.slotId)
    .update({
      payments: firebase.firestore.FieldValue.arrayUnion(updateSlot),
      participants: firebase.firestore.FieldValue.arrayUnion(updateSlot.fanId),
    });
};

export const getGroupSlotById =
  (id, onComplete = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("a4b_athelete_timeslots")
      .doc(id)
      .onSnapshot(async (query) => {
        let temp_data = [];
        if (query.data()?.payments) {
          let payments = query.data().payments;
          for (let i = 0; i < payments.length; i++) {
            let fan = await firebase
              .firestore()
              .collection("users")
              .doc(payments[i].fanId)
              .get();
            temp_data.push({
              ...fan.data(),
              id: fan.id,
              payments: payments[i],
              paidAmount: query.data().perParticipantAmount,
              btnLoader: false,
            });
          }
        }
        dispatch({
          type: "GROUP-SLOT-BY-ID",
          payload: temp_data,
        });
        onComplete();
      });
  };
