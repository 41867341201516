import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Image from "../assets/img/Picture1.png";
import {
  getChatEarningByAthleteID,
  getChatsCountById,
  getChatsEarningById,
} from "../store/actions/chatAction";
function ActiveChats() {
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.authUser);
  let { chatsCount, chatsEarning, chatsEarningByID } = useSelector(
    (state) => state.chat
  );

  useEffect(() => {
    dispatch(getChatsCountById(uid));
    dispatch(getChatsEarningById(uid));
    dispatch(getChatEarningByAthleteID(uid));
  }, []);

  return (
    <>
      {user?.role == "fan" ? (
        <>
          <Col sm="12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Total Chats: {chatsCount}</h5>
                <h5 className="card-title">Amount Spent: $ {chatsEarning}</h5>
              </div>
            </div>
          </Col>
        </>
      ) : (
        <Container fluid className="m-0 Dashboard-container">
          <Row>
            <Col sm="6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-center">Presented by:</h5>
                  <img className="img-fluid" src={Image} />
                </div>
              </div>
            </Col>
            <Col sm="6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Total Chats: {chatsCount}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default ActiveChats;
