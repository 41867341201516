import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Input, Row, Table } from "reactstrap";
import {
  addOffPlatformSite,
  checkFanReviews,
  resetFanReviews,
} from "../store/actions/offPlatformSiteAction";
import { useEffect } from "react";
import { sendEmailToOffPlatformNotification } from "../store/actions/questionAnswerActions";

const AddOffPlatformData = () => {
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.authUser);
  const { loader } = useSelector((state) => state.offPlatform);
  const [questionId, setQuestionId] = useState("");
  const [minutes, setMinutes] = useState("");
  let minutesTypeNumber = parseInt(minutes);
  let athleteChatRate = parseInt(user.chatRate) * minutesTypeNumber;
  const [dollar, setDollar] = useState("");
  const [loader2, setLoader2] = useState(false);
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const moment = require("moment");
  const start = moment().format("HH:mm");
  const end = moment(start, "HH:mm").add(minutes, "minutes").format("HH:mm");
  useEffect(() => {
    dispatch(resetFanReviews());
  }, []);
  useEffect(() => {
    setDollar(athleteChatRate);
  }, [athleteChatRate]);
  const handelSubmit = (e) => {
    e.preventDefault();
    let payload = {
      fan_email: email,
      athlete_email: user.email,
      minutes: minutes,
      total_cost: athleteChatRate,
    };
    let data = {
      athleteId: uid,
      athlete_email: user.email,
      highPriorityQuestion: questionId,
      start: start,
      end: end,
      email: email,
      note: note,
      status: "booked",
      type: "Instant",
    };
    let data2 = {
      athleteID: uid,
      athlete_email: user.email,
      athlete_payment: false,
      athlete_chat_rate: user.chatRate,
      chat_minutes: minutesTypeNumber,
      is_reviewed_athlete: false,
      is_reviewed_fan: false,
      refund_request: false,
      fan_email: email,
      status: "payment pending",
      time_zone: "EST",
      type: "Instant",
    };
    dispatch(
      addOffPlatformSite(data, data2, () => {
        setQuestionId("");
        setEmail("");
        setMinutes("");
        setNote("");
        dispatch(sendEmailToOffPlatformNotification(payload));
      })
    );
  };
  const checkReviews = () => {
    setLoader2(true);
    dispatch(
      checkFanReviews(email, () => {
        setLoader2(false);
      })
    );
  };
  const { getFanReviews, isUserExist, totalChat } = useSelector(
    (state) => state.offPlatform
  );

  return (
    <>
      <Container>
        <Col md="8" className="pt-3">
          <div>
            <p>
              <b>Input:Off-Platform Agreement Data</b>
            </p>
            <p className="ml-3">
              {" "}
              By emailing each other, fan and athlete can reach agreement on
              chat terms. Athlete can enter those terms here, including number
              of minutes agreed for chat and the chat dollar rate per minute as
              agreed, In ‘Notes’ section, athlete can add details such as
              date/time chat is planned to start, etc
            </p>
          </div>
        </Col>
        <Col xs={12} lg={11}>
          <form onSubmit={handelSubmit}>
            <Table className="table table_Off-platform_site" responsive>
              <thead>
                <tr>
                  <th className="d-flex align-items-center">
                    QuestionId{" "}
                    <Input
                      required
                      value={questionId}
                      onChange={(e) => setQuestionId(e.target.value)}
                      className="ml-2"
                    />
                  </th>
                  <th></th>
                  <th>Athlete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      <li>Minutes</li>
                    </ul>
                  </td>
                  <td></td>
                  <td>
                    {" "}
                    <Input
                      required
                      className="w-50 mr-2 d-inline"
                      type="number"
                      value={minutes}
                      onChange={(e) => setMinutes(e.target.value)}
                      min="0"
                      max="60"
                    />
                    Minutes
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>Chat rate </li>
                    </ul>
                  </td>
                  <td></td>
                  <td>
                    <Input
                      className="w-50 mr-2 d-inline"
                      type="number"
                      value={dollar}
                    />
                    Dollars
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>Email</li>
                    </ul>
                  </td>
                  <td></td>
                  <td>
                    <Input
                      required
                      placeholder="Enter Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </td>
                  <td className="pt-3">Fan's Email</td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>Note</li>
                    </ul>
                  </td>
                  <td></td>
                  <td>
                    <Input
                      required
                      placeholder="Add Note"
                      type="textarea"
                      value={note}
                      onChange={(e) => setNote(e.currentTarget.value)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td className="text-right">
                    {loader ? (
                      <Button color="primary">
                        <div
                          class="spinner-border text-light size-sm"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </Button>
                    ) : (
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </form>
          <div className="text-center mb-3">
            <Button color="primary" onClick={checkReviews} disabled={loader2}>
              {loader2 ? (
                <div class="spinner-border text-light size-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Check Fan Reviews"
              )}
            </Button>
          </div>
          {isUserExist ? (
            <p className="text-center">
              Fan wishing to chat with you has given{" "}
              {getFanReviews?.satisfied ? getFanReviews?.satisfied : 0} S,{" "}
              {getFanReviews?.NS ? getFanReviews?.NS : 0} U-NS and{" "}
              {getFanReviews?.RST ? getFanReviews?.RST : 0} U-RST out of{" "}
              {totalChat ? totalChat : 0} total chats
            </p>
          ) : null}
        </Col>
      </Container>
    </>
  );
};

export default AddOffPlatformData;
