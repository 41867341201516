import { LOGIN, LOGOUT, UPDATE_PROFILE } from "../types";

const initialState = {
  user: null,
  uid: null,
};

export default function authUserReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.user.id,
        user: { ...action.user },
      };
    case "LOGIN_FAIL":
      localStorage.clear();
      return {
        ...state,
        uid: "",
      };
    case "LOGOUT_SUCCESS":
      localStorage.clear();
      return {
        ...state,
        uid: "",
        user: null,
      };
    default:
      return { ...state };
  }
}
