import React from "react";
import { useState } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";

const FiveWaysToStartChatModal = ({ isShow, toggleInstantChat }) => {
  const textToCopy =
    "Hi, I am an Athlete at FHP.I saw your question(QuestionID#_________).I think Ihave some good ideas about how to answer that question for you.Please send me a request to chat at FHP. My FHP Registration number is:____________.Chat with you soon.";
  const handleCopyText = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopy(false);
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };
  const [isCopy, setIsCopy] = useState(true);
  const [isShowTemplate, setIsShowTemplate] = useState(false);
  const toggleTemplate = () => {
    setIsShowTemplate(!isShowTemplate);
    setIsCopy(true);
  };
  return (
    <>
      <Modal isOpen={isShow} toggle={toggleInstantChat} size="md" centered>
        <ModalBody>
          <ol>
            <u>Five ways a Teacher-Athlete can start a chat:</u>
            <li>
              In the Question database, find question you want to answer, and
              the Fan's email so you can message using{" "}
              <h6 className="d-inline hover_pointer" onClick={toggleTemplate}>
                <u>TEMPLATE</u>
              </h6>
              ...OR
            </li>
            <li>
              In your calendar, set your time/date available to chat,so Fans can
              find you...OR
            </li>
            <li>
              In your social media, post the ad supplied in FHP at 'Athlete
              Posts this Ad'..OR
            </li>
            <li>
              Email your contacts and invite them to join FHP and chat...OR
            </li>
            <li>
              Answer your email when Fan at FHP messages a request to you to
              chat.
            </li>
          </ol>
          <div className="text-right">
            <Button color="primary" onClick={() => toggleInstantChat()}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isShowTemplate}
        toggle={toggleTemplate}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <ModalBody>
          <div className="text-right">
            <Button color="primary" onClick={handleCopyText}>
              {isCopy ? (
                <i class="far fa-copy"></i>
              ) : (
                <i class="fa fa-solid fa-check"></i>
              )}
            </Button>
          </div>
          <span>TEMPLATE FOR EMAIL TO FAN:</span>
          <p>{textToCopy}</p>
          <div className="text-right">
            <Button
              color="primary"
              className="p-1"
              onClick={() => toggleTemplate()}
            >
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FiveWaysToStartChatModal;
