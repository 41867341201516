import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  fetchTopics,
  getRandomAthletes,
} from "../store/actions/athleteActions";
import { Rating } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";

const LearnWithExpert = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  let { randomAthletes, topics } = useSelector((state) => state.athlete);
  useEffect(() => {
    dispatch(getRandomAthletes());
    dispatch(
      fetchTopics(() => {
        setLoader(true);
      })
    );
  }, []);

  return (
    <>
      <div className="p-4">
        <Link to="/home">
          <Button color="primary">Back</Button>
        </Link>{" "}
      </div>
      <Container className="term-and-condition-main">
        <p className="terms-title">
          <u>
            Hi Fans. Here are some choices you can make later when you post a
            question. The video links you see HERE on several lessons can also
            teach about that specific lesson, so give the link a try IF YOU WANT
            TO LEARN.
          </u>
        </p>
        {loader ? (
          <Row>
            <Col md={8}>
              <div>
                <ol type="1">
                  {topics &&
                    topics?.map((topic) => {
                      return (
                        <li className="termsPoint">
                          <p>
                            {topic?.name}
                            <a
                              href={topic?.link}
                              target="_blank"
                              className="mx-2"
                            >
                              {topic?.link ? topic?.link : ""}
                            </a>
                          </p>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </Col>
            <Col md={4}>
              <Row>
                {randomAthletes.map((ath, index) => {
                  return (
                    <Col md="8" className="mb-3" align="center" key={index}>
                      <Card className="filter-card mt-3">
                        <CardBody className="custom-card-body">
                          <img
                            className="random-avatar"
                            alt="Card image cap"
                            src={ath.player_image.url}
                          />
                          <h5>{ath.first_name + " " + ath.last_name}</h5>
                          <small class="email">{ath.email}</small>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        ) : (
          <div className="text-center">
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default LearnWithExpert;
