import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col, Button } from "reactstrap";
import Ambassador4Brand from "../assets/img/Ambassador4Brand.png";
import AmbassadorQr from "../assets/img/AmbassdorQr.png";
import { useHistory } from "react-router-dom";

function NewHome() {
  const history = useHistory();
  return (
    <Container fluid>
      <div className="d-block d-md-none bg-white  font-weight-bold justify-content-center">
        <img
          src={Ambassador4Brand}
          alt="Ambassador4Brand"
          className="img-fluid"
        />
      </div>
      <div className="new-home  h-100">
        <Row>
          <Col sm={12} className="d-none d-md-block">
            <div className="mt-5">
              <img
                src={Ambassador4Brand}
                alt="Ambassador4Brand"
                className="img-fluid"
              />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-left new-home_promotion-bar">
          <div className=" bg-white d-flex  ">
            <Col>
              <img
                src={AmbassadorQr}
                alt="AmbassadorQr"
                className="w-100 img-fluid "
              />
            </Col>
            <Col>
              <div className="my-auto  mt-md-5   new-home_Enter-Button_div">
                <Button
                  size="md"
                  className="font-weight-bold  new-home_Enter-Button "
                  color="primary"
                  onClick={() => history.push("/home")}
                >
                  Fan LOGIN- Chat with Athlete here
                </Button>
                <br />
                <Button
                  size="md"
                  className="font-weight-bold my-3 new-home_Enter-Button "
                  color="primary"
                  onClick={() => history.push("/home-for-athlete")}
                >
                   Teacher-Athlete LOGIN Here
                </Button>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      <div className="d-flex align-self-center  d-md-none bg-danger py-4">
        <h4 className="font-weight-bold text-white text-center mx-1">
          STUDENT ATHLETES SERVE AS BRAND AMBASSADOR
        </h4>
      </div>
    </Container>
  );
}

export default NewHome;
