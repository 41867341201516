import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row, Spinner } from "reactstrap";
import { addSuggestion } from "../store/actions/authAction";
const SuggestionBox = () => {
  const dispatch = useDispatch();
  let { user } = useSelector((state) => state.authUser);
  let { loading } = useSelector((state) => state.auth);

  const [suggestion, setSuggestion] = useState("");
  return (
    <Container fluid className="changePswrdMain mt-4 mt-md-0">
      <Row className="no-gutters justify-content-center">
        <Col lg="8">
          <div className="complaint-card mt-4">
            <div className="complaint-form">
              <h5 className="card-title">
                Send your valuable Suggestions to Admin
              </h5>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  let obj = {
                    suggestion,
                    user_id: user.id,
                    role: user?.role,
                    email: user.email,
                  };
                  if (user?.role == "fan") {
                    obj.user_name = user.name;
                  } else {
                    obj.user_name = `${user.first_name} ${user.last_name}`;
                  }
                  dispatch(
                    addSuggestion({
                      obj,
                      onSuccess: () => {
                        setSuggestion("");
                      },
                    })
                  );
                }}
              >
                <Row>
                  <Col>
                    <div className="d-flex">
                      <textarea
                        className="form-control mr-2"
                        value={suggestion}
                        rows={4}
                        onChange={(e) => {
                          setSuggestion(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="text-right mt-3">
                  <Button
                    className="mb-0 px-5"
                    color="primary"
                    type="submit"
                    disabled={suggestion ? false : true}
                  >
                    {loading ? <Spinner size="sm" /> : "Submit"}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SuggestionBox;
