import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Button,
} from "reactstrap";
import ClickHere from "../assets/img/clickhere.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchTopics, getAtheletes } from "../store/actions/athleteActions";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { getSports } from "../store/actions/athleteActions";
import { fetchCollegeStates } from "../store/actions/athleteActions";
import { fetchSchoolStates } from "../store/actions/athleteActions";
import { getCollegeCity } from "../store/actions/athleteActions";
import { getSchoolCounty } from "../store/actions/athleteActions";
import { styled } from "@mui/material/styles";
import { fetchQuestions } from "../store/actions/questionAnswerActions";

const CssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e7bf6",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#2e7bf6",
  },
});
const useStyles = makeStyles({
  auto_input: {
    width: "70%",
    paddingLeft: "10px",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  input: {
    color: "white",
  },
});

function SearchQuestions({ questions, setQuestions }) {
  const classes = useStyles();
  let auth = useSelector((state) => state.authUser);
  const { schoolStates, collegeStates } = useSelector((state) => state.athlete);
  let { sports } = useSelector((state) => state.athlete);
  let { allQuestions } = useSelector((state) => state.question);
  const lessons = useSelector((state) => state.athlete.topics);

  let dispatch = useDispatch();
  const [state, setState] = useState("");
  const [sport, setSport] = useState("");
  const [gender, setGender] = useState("");
  const [sportLevel, setSportLevel] = useState({ label: "", value: "" });
  const [priority, setPriority] = useState({ label: "", value: "" });
  const [topic, setTopic] = useState("");
  const [lesson, setLesson] = useState("");
  const [questionId, setQuestionId] = useState("");

  //Get Sports
  useEffect(() => {
    dispatch(getSports());
    dispatch(fetchQuestions());
    dispatch(fetchTopics());
  }, []);
  const defaultStateProps = {
    options: collegeStates?.map((insti) => {
      return insti.name;
    }),
    getOptionLabel: (option) => option,
  };
  const defaultSportLevelProps = {
    options: [
      { label: "College", value: "College-Athlete" },
      { label: "High School", value: "hs-athlete" },
    ],
    getOptionLabel: (option) => option.label,
  };
  const defaultGenderProps = {
    options: ["Male", "Female"],
    getOptionLabel: (option) => option,
  };
  const defaultPriorityProps = {
    options: [
      { label: "High", value: "paid" },
      { label: "Normal", value: "unpaid" },
    ],
    getOptionLabel: (option) => option.label,
  };
  const defaultTopicProps = {
    options: [
      "smart-goals",
      "technique",
      "favorite-moment",
      "dream-assignment",
      "hobbies-pets-music",
      "role-model",
      "diet-conditioning",
      "inspiration",
      "mental-tips",
      "staying-positive",
      "self-talk-mantras",
      "teamwork-skills",
    ],
    getOptionLabel: (option) => option,
  };
  const defaultLessonProps = {
    options: lessons.map((items) => {
      return items.name;
    }),
    getOptionLabel: (option) => option,
  };
  const defaultSportProps = {
    options:
      sports.length > 0 &&
      sports.map((item) => {
        return item.sport;
      }),
    getOptionLabel: (option) => option,
  };

  useEffect(() => {
    if (collegeStates.length == 0) {
      dispatch(fetchCollegeStates());
    }
    if (schoolStates.length == 0) {
      dispatch(fetchSchoolStates());
    }
    localStorage.removeItem("teamId");
    localStorage.removeItem("uniqData");
    localStorage.removeItem("ByteamID");
    dispatch(getAtheletes());
  }, []);
  useEffect(() => {
    if (state != "") {
      if (sportLevel == "College Athlete") {
        dispatch(getCollegeCity(state));
      } else {
        dispatch(getSchoolCounty(state));
      }
    }
  }, [state]);

  const handelClear = () => {
    setSportLevel({ label: "", value: "" });
    setState("");
    setSport("");
    setGender("");
    setPriority({ label: "", value: "" });
    setTopic("");
    setLesson("");
    setQuestionId("");
    setQuestions(allQuestions);
  };

  return (
    <>
      <Container fluid className="mb-5">
        <Row className=" justify-content-center ">
          <Col lg="12" sm="12">
            <div className="complaint-card mt-4">
              <div className="complaint-form">
                <p className="auth-desc-title mt-0 ml-0 text-center">
                  {auth?.user?.role === "athlete" ? (
                    <>
                      <h3 className="font-weight-bold">
                        Questions a teacher-athlete can search & answer for pay
                      </h3>
                      <p className="athlete-p">
                        If you want to ask a question you have to sign up on{" "}
                        <a
                          href="https://fhp-fans.web.app/auth/register"
                          target="_blank"
                        >
                          FANS HELP PLAYERS
                        </a>{" "}
                        as fan to ask a question
                      </p>
                    </>
                  ) : (
                    <>
                      <div
                        className="d-flex justify-content-center
                    align-items-center"
                      >
                        <h3 className="font-weight-bold">
                          User Search for FOCUS of Fan QUESTIONS
                        </h3>
                      </div>
                      <div className="fan_Question_hint">
                        <p className="text-left text-danger">HELPFUL HINTS:</p>
                        <p>
                          Before typing anything,scroll to see questions below.
                          Then,if desired you can filter further by typing in
                          these boxes.
                        </p>
                        <p className="p2 mt-4 text-center">
                          Note:Users can choose the platform they prefer for
                          their chat, such as Skype, Zoom, FaceTime, What’sApp,
                          Google Duo, Facebook Messenger, etc.
                        </p>
                      </div>
                      <div></div>
                    </>
                  )}
                </p>
                <Row className="mt-5">
                  <Col lg="4" align="center">
                    <InputGroup className="custom-input-group">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Autocomplete
                        className="input_feild"
                        {...defaultSportLevelProps}
                        id="auto-complete"
                        autoComplete
                        disableClearable={true}
                        disableListWrap
                        includeInputInList
                        value={sportLevel}
                        freeSolo
                        onChange={(e, val) => {
                          setSportLevel(val);
                        }}
                        renderInput={(params) => (
                          <CssTextField
                            {...params}
                            className={classes.text_input}
                            variant="standard"
                          />
                        )}
                      />
                    </InputGroup>
                    <Label className="auth-desc-subtitle ml-0 mt-2">
                      College focus or H.S focus?
                    </Label>
                  </Col>
                  <Col lg="4" align="center">
                    <InputGroup className="custom-input-group">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Autocomplete
                        className="input_feild"
                        {...defaultStateProps}
                        id="auto-complete"
                        autoComplete
                        includeInputInList
                        disableClearable={true}
                        disableListWrap
                        value={state}
                        freeSolo
                        onChange={(e, val) => {
                          setState(val);
                        }}
                        renderInput={(params) => (
                          <CssTextField {...params} variant="standard" />
                        )}
                      />
                    </InputGroup>
                    <Label className="auth-desc-subtitle ml-0 mt-2">
                      State focus?,SELECT
                    </Label>
                  </Col>

                  <Col lg="4" align="center">
                    <InputGroup className="custom-input-group">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Autocomplete
                        className="input_feild"
                        {...defaultSportProps}
                        id="auto-complete"
                        autoComplete
                        disableClearable={true}
                        disableListWrap
                        freeSolo
                        includeInputInList
                        value={sport}
                        onChange={(e, val) => {
                          setSport(val);
                        }}
                        renderInput={(params) => (
                          <CssTextField {...params} variant="standard" />
                        )}
                      />
                    </InputGroup>
                    <Label className="auth-desc-subtitle ml-0 mt-2">
                      Sport focus?,SELECT
                    </Label>
                  </Col>
                  <Col lg="4" align="center">
                    <InputGroup className="custom-input-group">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Autocomplete
                        className="input_feild"
                        {...defaultGenderProps}
                        id="auto-complete"
                        autoComplete
                        disableClearable={true}
                        disableListWrap
                        freeSolo
                        includeInputInList
                        value={gender}
                        onChange={(e, val) => {
                          setGender(val);
                        }}
                        renderInput={(params) => (
                          <CssTextField {...params} variant="standard" />
                        )}
                      />
                    </InputGroup>
                    <Label className="auth-desc-subtitle ml-0 mt-2">
                      Gender focus? SELECT
                    </Label>
                  </Col>
                  <Col lg="4" align="center">
                    <InputGroup className="custom-input-group">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Autocomplete
                        className="input_feild"
                        {...defaultTopicProps}
                        id="auto-complete"
                        autoComplete
                        disableClearable={true}
                        disableListWrap
                        freeSolo
                        includeInputInList
                        value={topic}
                        onChange={(e, val) => {
                          setTopic(val);
                        }}
                        renderInput={(params) => (
                          <CssTextField {...params} variant="standard" />
                        )}
                      />
                    </InputGroup>
                    <Label className="auth-desc-subtitle ml-0 mt-2">
                      Topic
                    </Label>
                  </Col>
                  <Col lg="4" align="center">
                    <InputGroup className="custom-input-group">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Autocomplete
                        className="input_feild"
                        {...defaultPriorityProps}
                        id="auto-complete"
                        autoComplete
                        disableClearable={true}
                        disableListWrap
                        freeSolo
                        includeInputInList
                        value={priority}
                        onChange={(e, val) => {
                          setPriority(val);
                        }}
                        renderInput={(params) => (
                          <CssTextField {...params} variant="standard" />
                        )}
                      />
                    </InputGroup>
                    <Label className="auth-desc-subtitle ml-0 mt-2">
                      Priority
                    </Label>
                  </Col>
                  <Col lg="8" align="center">
                    <InputGroup className="custom-input-group_lesson">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Autocomplete
                        className="input_feild"
                        {...defaultLessonProps}
                        id="auto-complete"
                        autoComplete
                        disableClearable={true}
                        disableListWrap
                        freeSolo
                        includeInputInList
                        value={lesson}
                        onChange={(e, val) => {
                          setLesson(val);
                        }}
                        renderInput={(params) => (
                          <CssTextField {...params} variant="standard" />
                        )}
                      />
                    </InputGroup>
                    <Label className="auth-desc-subtitle ml-0 mt-2">
                      lesson
                    </Label>
                  </Col>
                  <Col lg="4" align="center">
                    <InputGroup className="custom-input-group_QuestionId_Input">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Input
                        className="QuestionId_Input"
                        value={questionId}
                        onChange={(e) => {
                          setQuestionId(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <Label className="auth-desc-subtitle ml-0 mt-2">
                      QuestionId
                    </Label>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col className="d-flex justify-content-center m-auto ">
                    <Button
                      className="bg-transparent b-none "
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          state != "" ||
                          sportLevel != "" ||
                          sport != "" ||
                          gender != "" ||
                          topic != "" ||
                          lesson != "" ||
                          priority != "" ||
                          questionId != ""
                        ) {
                          let resArr =
                            allQuestions?.length > 0 &&
                            allQuestions?.filter((ath) => {
                              const collegeMatch =
                                !sportLevel.value ||
                                ath.college.toLowerCase() ==
                                  sportLevel.value.toLowerCase();
                              const stateMatch =
                                !state ||
                                ath.state
                                  .toLowerCase()
                                  .includes(state.toLowerCase());
                              const sportMatch =
                                !sport ||
                                ath.sport.toLowerCase() == sport.toLowerCase();
                              const genderMatch =
                                !gender ||
                                ath.gender.toLowerCase() ==
                                  gender.toLowerCase();
                              const priorityMatch =
                                !priority.value ||
                                ath.priority.toLowerCase() ===
                                  priority.value.toLowerCase();
                              const topicMatch =
                                !topic ||
                                ath.topic
                                  .toLowerCase()
                                  .includes(topic.toLowerCase());
                              const lessonMatch =
                                !lesson ||
                                ath.leson.name.toLowerCase() ==
                                  lesson.toLowerCase();
                              const questionIdMatch =
                                !questionId ||
                                ath.id
                                  .toLowerCase()
                                  .includes(questionId.toLowerCase());
                              if (
                                collegeMatch &&
                                stateMatch &&
                                sportMatch &&
                                genderMatch &&
                                priorityMatch &&
                                topicMatch &&
                                lessonMatch &&
                                questionIdMatch
                              ) {
                                return ath;
                              }
                            });
                          if (resArr.length > 0) {
                            setQuestions(resArr);
                          } else {
                            toast.warning("No Question match found.");
                          }
                        }
                      }}
                    >
                      <img src={ClickHere} alt="Click Here" height="60px" />
                      <br />
                      <Label className="auth-desc-subtitle ml-0 mt-2 ">
                        Click to Search
                      </Label>
                    </Button>
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        className="ml-3 p-1 text-white border_radius"
                        color="success"
                        onClick={handelClear}
                      >
                        CLEAR
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SearchQuestions;
