import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import mapImg from "../assets/img/time map img.jpeg";
import ReviewModal from "../components/ReviewModal";
import Paypal from "../components/Paypal";
import { getOffPlatformData } from "../store/actions/offPlatformSiteAction";
import {
  completeChatPayment,
  fanRejectPaymentTimeSlot,
} from "../store/actions/chatAction";
import {
  completeTopUpPayment,
  getMyChatInAction,
} from "../store/actions/myChatInActions";
import moment from "moment";

const AthleteChatInAction = () => {
  let dispatch = useDispatch();
  const currentDate = moment();
  const [receiverId, setReceiverId] = useState("");
  const [chatID, setChatID] = useState();
  const [chatId, setChatId] = useState();
  const [athlete, setAthlete] = useState();
  const [chatRate, setChatRate] = useState();
  const [minutes, setMinutes] = useState();
  const [amount, setAmount] = useState();
  const [wantPay, setWantPay] = useState(false);
  let minutesTypeNumber = parseInt(minutes);
  let athleteChatRate = parseInt(chatRate) * minutesTypeNumber;
  const [chat_id, setChat_ID] = useState();
  const currentTimestampSeconds = Math.floor(Date.now() / 1000);
  const [loader, setLoader] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const { uid, user } = useSelector((state) => state.authUser);
  const { completeChat, lastDocument } = useSelector(
    (state) => state.athleteCompleteChat
  );
  const lastData = useSelector(
    (state) => state.athleteCompleteChat.hasMoreDocuments
  );
  useEffect(() => {
    setAmount(athleteChatRate);
  }, [athleteChatRate]);
  useEffect(() => {
    setLoader(true);
    dispatch(
      getMyChatInAction(uid, "", () => {
        setLoader(false);
      })
    );
  }, []);
  const loadMore = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(
      getMyChatInAction(uid, lastDocument, () => {
        setLoader(false);
      })
    );
  };
  const handleShowReview = (participant, chatID, chat_id) => {
    setReceiverId(participant);
    setChatID(chatID);
    setChat_ID(chat_id);
    setShowReview(!showReview);
  };
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
    setWantPay(false);
    setMinutes("");
  };
  const completePaypalPayment = (id, details, athlete) => {
    dispatch(completeTopUpPayment(id, details, minutesTypeNumber));
    setShowModal(false);
  };
  return (
    <Container fluid>
      <Row noGutters>
        <Col align="center" className="mt-4 mb-2">
          <div className="ChatLeft">
            <Row noGutters>
              <span className="text-danger w-100 text-center p-3">
                S= Satisfied, U-NS= Unsatisfied No Show & U-RST= Unsatisfied
                Rude / Short Time
                <br></br>
                To ensure smooth communication, kindly keep your chat partner
                informed about any time zone differences. This will help both of
                you stay synchronized and maintain effective interaction.
              </span>
              <span className="w-100 text-center mt-3">
                <b>NOTE:</b>Bonus of $2.50 goes to Teacher-athlete who books
                chat regarding HIGH PRIORITY QUESTION.
              </span>
              <span className="w-100 text-center my-3">
                <b>NOTE:</b>
                {user?.role == "athlete" ? "Athlete" : "Fan"},if your schedule
                changes, please notify{" "}
                {user?.role == "athlete" ? "Fan" : "Athlete"}.
              </span>
              {user?.role == "athlete" ? (
                <span className="text-center mb-3 px-3 mx-2">
                  <b>
                    NOTE:<u> Hey Teacher-Athlete:</u>
                  </b>
                  If you and your Fan run long in your chat,you should remind
                  the fan about the Top-Up option.That's an input field the fan
                  can access when rating the chat.That input field lets the fan
                  click how many minutes your chat ran long,then pay you for the
                  extra time (we send you 88% of the payment).
                </span>
              ) : (
                <p className="text-center mb-3 px-3 mx-2 w-100">
                  <b>NOTE:</b>If your chat went over the minutes agreed, you can
                  add payment to cover the extra chat time.
                </p>
              )}
              <div className="text-center w-100">
                <img
                  src={mapImg}
                  style={{ width: "20%" }}
                  alt="Time Zone Img USA"
                />
              </div>
              <Col sm="12">
                <div className="chat">
                  <Table className="table" responsive>
                    <thead>
                      <tr>
                        <th> </th>
                        <th>Chat ID</th>
                        <th>
                          {user?.role !== "athlete" ? "ATHLETE" : "FAN"} email
                        </th>
                        <th>QuestionID</th>
                        <th>ChatType</th>
                        <th className="text-center">date/time agreed</th>
                        <th> NOTES</th>
                        {user?.role == "fan" ? (
                          <>
                            <th className="text-center">Review</th>
                            <th className="text-center">
                              Chat went over? pay extre?
                            </th>
                          </>
                        ) : null}
                      </tr>
                    </thead>
                    {completeChat.map((items, index) => {
                      const originalTimestamp = moment
                        .unix(items.chat_start.seconds)
                        .add(items.chat_start.nanoseconds, "nanoseconds");
                      const newTimestamp = originalTimestamp.add(7, "days");
                      const isAfter7Days = newTimestamp.isAfter(currentDate);
                      return (
                        <tbody key={index}>
                          <tr>
                            <td className="text-center">
                              <img
                                className="chat-profile"
                                src={items?.athleteImg}
                              />
                            </td>
                            <td className="table-chat-request">
                              {items.chat_id}
                            </td>
                            <td className="table-chat-request">
                              {user?.role !== "athlete"
                                ? items.athlete_email
                                : items.fan_email}
                            </td>
                            <td>
                              {items.questionId ? items.questionId : "NAN"}
                            </td>
                            <td>{items.type ? "Instant" : "Appointment"}</td>
                            <td className="table-chat-request text-center">
                              <p>time/date as PARTIES agree</p>
                            </td>
                            <td>{items.note ? items.note : "N/A"}</td>
                            {user?.role == "fan" ? (
                              <>
                                <td className="table-chat-request">
                                  <Button
                                    disabled={
                                      items.is_reviewed_fan ||
                                      items.status !== "complete" ||
                                      !isAfter7Days
                                    }
                                    className="btn-sm mx-auto"
                                    color="primary"
                                    onClick={() => {
                                      handleShowReview(
                                        items.participants.filter(
                                          (ele) => ele != uid
                                        ),
                                        items.id,
                                        items.chat_id
                                      );
                                    }}
                                  >
                                    {" "}
                                    Review Chat
                                  </Button>
                                </td>
                                <td className="table-chat-request">
                                  <Button
                                    color="primary"
                                    className="btn-sm mx-auto"
                                    onClick={() => {
                                      toggle();
                                      setChatId(items.id);
                                      setChatRate(items?.athlete_chat_rate);
                                      setAthlete(items);
                                    }}
                                    disabled={
                                      !isAfter7Days || items?.topUpPayment
                                    }
                                  >
                                    Pay additional amount
                                  </Button>
                                </td>{" "}
                              </>
                            ) : null}
                          </tr>
                        </tbody>
                      );
                    })}
                  </Table>
                </div>
              </Col>
            </Row>
            {loader ? (
              <i className="spinner-border text-primary mb-3"></i>
            ) : (
              <Button
                onClick={loadMore}
                color="primary"
                className="mb-3"
                disabled={!lastData}
              >
                Load more
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <ReviewModal
        showReviewModal={showReview}
        handleShowReview={() => {
          handleShowReview("", "");
        }}
        chatID={chatID}
        athleteId={receiverId[0]}
        chat_id={chat_id}
      ></ReviewModal>
      <Modal isOpen={showModal} toggle={toggle} centered>
        <ModalBody>
          <div>
            <label> Number of additional minutes</label>
            <Input
              placeholder="Enter minutes"
              type="number"
              min={1}
              value={minutes}
              onChange={(e) => setMinutes(e.target.value)}
            />
          </div>
          <div className="my-3">
            <label>Total amount </label>
            <Input
              placeholder="your amount"
              type="number"
              value={amount}
              disabled
            />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            {wantPay ? (
              <Paypal
                price={chatRate * minutes + 0.5}
                completePaypalPayment={completePaypalPayment}
                chatId={chatId}
                athelete={athlete}
              />
            ) : (
              <>
                <Button
                  color="primary"
                  onClick={() => setWantPay(true)}
                  disabled={!minutes}
                >
                  Click here for Payment
                </Button>{" "}
                <Button color="primary" className="ml-2" onClick={toggle}>
                  Close
                </Button>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default AthleteChatInAction;
