import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import { getGroupSlotById } from "../store/actions/favoriteAction";
import moment from "moment";
import { getFanQuestions } from "../store/actions/managerAction";
import {
  confirmGroupChat,
  groupRefund,
  sendEmailPaidFanSlots,
  singleRefund,
  getFansRequestsBySlotId,
} from "../store/actions/chatAction";

const ViewBookingGroupModal = (props) => {
  let dispatch = useDispatch();
  let [loader, setLoader] = useState(true);
  useEffect(() => {
    if (props.slotData.id !== "") {
      setLoader(true);
      dispatch(
        getGroupSlotById(props.slotData.id, () => {
          setLoader(false);
        })
      );
    }
  }, [props.slotData.id]);
  let { groupSlotById } = useSelector((state) => state.favorite);
  const filteredConfirm = groupSlotById.filter(
    (items) => !items.payments.is_refunded
  );
  const filteredRefund = groupSlotById.filter(
    (items) => items.payments.is_refunded
  );
  let { fanQuestions } = useSelector((state) => state.manager);
  let { slotsFansQuestionRequests } = useSelector((state) => state.chat);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  let [questionLoader, setQuestionLoader] = useState(false);
  const toggle = () => {
    setOpenModal(!openModal);
  };
  const toggle2 = () => {
    setOpenModal2(!openModal2);
    dispatch(getFansRequestsBySlotId(props.slotData.id));
  };
  const handelCheckQuestion = (items) => {
    toggle();
    setQuestionLoader(true);
    dispatch(
      getFanQuestions(items.email, () => {
        setQuestionLoader(false);
      })
    );
  };
  const handelSingleRefund = (items) => {
    let data = {
      slotId: props.slotData.id,
      fanId: items.id,
    };
    dispatch(singleRefund(data));
  };
  const [activeTab, setActiveTab] = useState("Confirm");
  const toggled = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [refundLoader, setRefundLoader] = useState(false);
  const handelConfirm = () => {
    setConfirmLoader(true);
    dispatch(
      confirmGroupChat(props.slotData.id, () => {
        props.handelViewBookingGroupModal();
        setConfirmLoader(false);
      })
    );
  };
  const handelRefund = () => {
    setRefundLoader(true);
    dispatch(
      groupRefund(props.slotData.id, () => {
        props.handelViewBookingGroupModal();
        setRefundLoader(false);
      })
    );
  };
  const handelClose = () => {
    props.handelViewBookingGroupModal();
    setRefundLoader(false);
    setConfirmLoader(false);
  };

  const [message, setMessage] = useState({
    openModal: false,
    text: "",
    btnLoader: false,
  });
  const messageModal = () => {
    setMessage((prev) => ({
      ...prev,
      openModal: !prev.openModal,
    }));
  };
  const handelInput = (e) => {
    setMessage((prev) => ({
      ...prev,
      text: e.target.value,
    }));
  };
  const sendMessage = (e) => {
    e.preventDefault();
    setMessage((prev) => ({
      ...prev,
      btnLoader: true,
    }));
    let obj = {
      id: props.slotData.id,
      text: message.text,
    };
    dispatch(
      sendEmailPaidFanSlots(obj, () => {
        setMessage({
          openModal: false,
          text: "",
          btnLoader: false,
        });
      })
    );
  };

  return (
    <>
      <Modal
        isOpen={props.ShowViewBookingGroupModal}
        toggle={props.handelViewBookingGroupModal}
        size="xl"
        top="true"
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
          <Card className="p-2">
            {loader ? (
              <div className="spinner-border text-primary loader mx-auto my-4"></div>
            ) : (
              <>
                <Nav className="mt-2" tabs>
                  <NavItem className="cursor-pointer">
                    <NavLink
                      className={
                        activeTab === "Confirm"
                          ? " bg-primary text-white py-1 px-3 "
                          : " py-1"
                      }
                      onClick={() => {
                        toggled("Confirm");
                      }}
                    >
                      <h5 className="m-0">Confirm</h5>
                    </NavLink>
                  </NavItem>
                  <NavItem className="cursor-pointer">
                    <NavLink
                      className={
                        activeTab === "Refund"
                          ? " bg-primary text-white py-1 px-3"
                          : " py-1"
                      }
                      onClick={() => {
                        toggled("Refund");
                      }}
                    >
                      <h5 className="m-0">Refund</h5>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="px-3 pt-3">
                  <TabPane tabId="Confirm">
                    <Table className="table" responsive>
                      <thead>
                        <tr>
                          <th>Fan Name</th>
                          <th>Fan Email</th>
                          <th>Payment Date</th>
                          <th>Paid Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredConfirm.length > 0 ? (
                          filteredConfirm.map((items, index) => {
                            return (
                              <tr key={index}>
                                <td>{items.name}</td>
                                <td>{items.email}</td>
                                <td>
                                  {moment
                                    .unix(items.payments.payment_date.seconds)
                                    .format("MMM-DD-YYYY")}
                                </td>
                                <td>{items.paidAmount}$</td>
                                <td>
                                  {props.type == "manager" && (
                                    <Button
                                      onClick={() => handelCheckQuestion(items)}
                                      color="primary"
                                      className="mr-2"
                                    >
                                      view Fan Questions
                                    </Button>
                                  )}
                                  <Button
                                    color="danger"
                                    disabled={
                                      props.slotData.status == "booked" ||
                                      props.slotData.is_refunded ||
                                      items.btnLoader
                                    }
                                    onClick={() => handelSingleRefund(items)}
                                  >
                                    {items.btnLoader ? (
                                      <i className="spinner-border spinner-border-sm text-white"></i>
                                    ) : (
                                      "Refund"
                                    )}
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>
                  <TabPane tabId="Refund">
                    <Table className="table" responsive>
                      <thead>
                        <tr>
                          <th>Fan Name</th>
                          <th>Fan Email</th>
                          <th>Payment Date</th>
                          <th>Paid Amount</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredRefund.length > 0 ? (
                          filteredRefund.map((items, index) => {
                            return (
                              <tr key={index}>
                                <td>{items.name}</td>
                                <td>{items.email}</td>
                                <td>
                                  {moment
                                    .unix(items.payments.payment_date.seconds)
                                    .format("MMM-DD-YYYY")}
                                </td>
                                <td>{items.paidAmount}$</td>
                                {/* <td>
                                  {props.type == "manager" && (
                                    <Button
                                      onClick={() => handelCheckQuestion(items)}
                                      color="primary"
                                      className="mr-2"
                                    >
                                      view Fan Questions
                                    </Button>
                                  )}
                                  <Button
                                    color="danger"
                                    disabled={
                                      props.slotData.status == "booked" ||
                                      props.slotData.is_refunded
                                    }
                                    onClick={() => handelSingleRefund(items)}
                                  >
                                    Refund
                                  </Button> 
                                </td>*/}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>
                </TabContent>
              </>
            )}
          </Card>
          <div className="mt-3 d-flex justify-content-end">
            {/* <Button
              color="primary"
              disabled={
                props.slotData.status == "booked" ||
                props.slotData.is_refunded ||
                confirmLoader ||
                refundLoader ||
                filteredConfirm.length == 0
              }
              onClick={handelConfirm}
            >
              {confirmLoader ? (
                <i className="spinner-border spinner-border-sm text-white"></i>
              ) : (
                "Confirm"
              )}
            </Button>*/}
            <Button
              color="danger"
              disabled={
                props.slotData.status == "booked" ||
                props.slotData.is_refunded ||
                confirmLoader ||
                refundLoader ||
                filteredConfirm.length == 0
              }
              className="mx-2"
              onClick={handelRefund}
            >
              {refundLoader ? (
                <i className="spinner-border spinner-border-sm text-white"></i>
              ) : (
                "Refund"
              )}
            </Button>
            {props.slotData.participants && (
              <>
                <Button color="primary" className="mr-2" onClick={messageModal}>
                  message
                </Button>
                <Button color="primary" className="mr-2" onClick={toggle2}>
                  Requests
                </Button>
              </>
            )}
            <Button color="primary" onClick={handelClose}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={message.openModal}
        toggle={messageModal}
        size="lg"
        top="true"
      >
        <ModalBody>
          <Form onSubmit={sendMessage}>
            <Input
              type="textarea"
              name="messageText"
              value={message.text}
              onChange={handelInput}
            />

            <div className="text-right mt-3">
              <Button
                color="primary"
                disabled={message.btnLoader}
                className="mr-2"
                type="Submit"
              >
                {message.btnLoader ? <Spinner size="sm" /> : "Send"}
              </Button>
              <Button color="primary" onClick={messageModal}>
                Close
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={openModal} toggle={toggle} size="lg" top="true">
        <ModalBody>
          <Card className="p-2">
            {questionLoader ? (
              <div className="spinner-border text-primary loader mx-auto my-4"></div>
            ) : (
              <Table className="table" responsive>
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {fanQuestions.length > 0 ? (
                    fanQuestions.map((items, index) => {
                      return (
                        <tr key={index}>
                          <td>{items.description}</td>
                          <td>{items.status}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </Card>
          <div className="text-right mt-3">
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={openModal2} toggle={toggle2} size="lg" top="true">
        <ModalBody>
          <div className="text-left mt-3">
            <Card className="p-2">
              {questionLoader ? (
                <div className="spinner-border text-primary loader mx-auto my-4"></div>
              ) : (
                <Table className="table" responsive>
                  <thead>
                    <tr>
                      <th>Fan Name</th>
                      <th>Fan Email</th>
                      <th>Question</th>
                    </tr>
                  </thead>
                  <tbody>
                    {slotsFansQuestionRequests.length > 0 ? (
                      slotsFansQuestionRequests.map((items, index) => {
                        return (
                          <tr key={index}>
                            <td>{items.fan.name}</td>
                            <td>{items.fan.email}</td>
                            <td>{items.question}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </Card>
            <div className="text-right mt-2">
              <Button color="primary" onClick={toggle2}>
                Close
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ViewBookingGroupModal;
