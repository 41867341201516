import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
function Home() {
  return (
    <div className="home-container">
      <h1 className="text-center mt-2 mt-lg-5 mobileViewSet">
       Info: Ambassador<span className="text-primary">4</span>
        <span style={{ color: "red" }}>Brand</span>
      </h1>
      <div className="text-center term-and-condition-main mt-4">
        <p className="termsPoint">
          <b>1. Student Athletes are Brand Ambassadors here to help Fans.</b>
        </p>
        <p className="termsPoint">
          <b>2. They can teach you how to use our websites & Programs</b>
        </p>
        <p className="termsPoint">
          <b>
            3. You can choose which Student Athlete Brand Ambassador you want
            for your teacher
          </b>
        </p>
        <p className="termsPoint">
          <b>
            4. They get paid from your PayPal by the minutes of chat you choose.
          </b>
        </p>
        <a
          href="https://youtu.be/uIWftF7cjug"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h5>https://youtu.be/uIWftF7cjug</h5>
        </a>
        <p className="termsPoint">
          <b>
          Hi Fans, You can learn about Question Topics, Lesson name & System Parts:
          </b>
        </p>
      </div>
      <div className="d-flex pt-3 justify-content-center align-items-center">
        <Link to="/terms-and-condition">
          <Button color="primary" className="login-btn text-white">Topics</Button>
        </Link>
        <Link to="/learn-with-expert">
          <Button color="primary"  className="login-btn text-white">
          Lesson names
          </Button>
        </Link>
        <Link to="/summary">
          <Button color="primary"  className="login-btn text-white">   
         System
          </Button>
        </Link>
      </div>
      <h3 className="mt-3 mx-5 px-5 d-flex text-center">
        IMPORTANT: During CHAT, please comply with rules of NCAA/amateur sports.
        Best not to discuss Gambling, Recruitment, Transfer Portal, alcohol,
        drugs, or other subject matter which could violate regulations.
      </h3>
      <h5 className="mx-5 px-5 pt-2 text-center">
        <b>Note:</b>The opinions offererd by teacher-athletes in their answers
        are not guaranteed to be accurate.For legal advice regarding amateur
        sport, please consult your own attorney.
      </h5>
      <p className="mt-3 text-center">
          <b className="d-flex justify-content-center align-items-center"> 
          Continue:
          <h2 className="mr-2">
            <Link to="/Login">
              <span className="text-primary"> Login </span>
            </Link> </h2> {" "}
            to select Your BRAND AMBASSADOR/STUDENT ATHLETE
          </b>
        </p>
    </div>
  );
}

export default Home;
