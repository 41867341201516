import React from "react";
import { useSelector } from "react-redux";
import FanGroupChat from "../components/groupChat/FanGroupChat";
import AthleteGroupChat from "../components/groupChat/AthleteGroupChat";

const GroupChat = () => {
  const { user } = useSelector((state) => state.authUser);

  return <>{user?.role == "fan" ? <FanGroupChat /> : <AthleteGroupChat />}</>;
};

export default GroupChat;
