import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import mapImg from "../assets/img/time map img.jpeg";
import ReviewModal from "../components/ReviewModal";
import Paypal from "../components/Paypal";
import {
  checkFanReviews,
  getOffPlatformData,
  resetFanReviews,
  sendEmailFanPaymentOffPlatFormChat,
  upDateOffPlatformData,
} from "../store/actions/offPlatformSiteAction";
import {
  completeChatPayment,
  fanRejectPaymentTimeSlot,
} from "../store/actions/chatAction";
import moment from "moment";
import { toast } from "react-toastify";

const GetOffPlatformData = () => {
  let dispatch = useDispatch();
  const currentDate = moment();
  const [receiverId, setReceiverId] = useState("");
  const [chatID, setChatID] = useState();
  const [chat_id, setChat_ID] = useState();
  const [loaderMan, setLoaderMan] = useState(true);
  const [loader, setLoader] = useState(true);
  const [showReview, setShowReview] = useState(false);
  const { uid, user } = useSelector((state) => state.authUser);
  const { lastDocument, offPlatformSiteData } = useSelector(
    (state) => state.offPlatform
  );
  const moreData = useSelector((state) => state.offPlatform.hasMoreDocument);
  useEffect(() => {
    dispatch(
      getOffPlatformData(uid, "", () => {
        setLoaderMan(false);
        setLoader(false);
      })
    );
  }, []);
  const loadMore = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(
      getOffPlatformData(uid, lastDocument, () => {
        setLoader(false);
      })
    );
  };
  const handelReject = (id) => {
    setLoaderMan(true);
    dispatch(fanRejectPaymentTimeSlot(id, () => {}));
  };
  const completePaypalPayment = (id, details, athlete) => {
    let payload = {
      athleteName:
        athlete?.chatUser.first_name + " " + athlete?.chatUser.last_name,
      athleteEmail: athlete.athleteEmail,
      fanName: user.name,
    };
    dispatch(
      completeChatPayment(id, details, athlete, () => {
        toast.warning(
          "Your chat escrow is funded. Find your chat now in My Instant Chats"
        );
        dispatch(sendEmailFanPaymentOffPlatFormChat(payload));
        setLoaderMan(true);
      })
    );
  };
  const handleShowReview = (participant, chatID, chat_id) => {
    setReceiverId(participant);
    setChatID(chatID);
    setChat_ID(chat_id);
    setShowReview(!showReview);
  };

  return (
    <Container fluid>
      <Row noGutters>
        <Col align="center" className="mt-4 mb-2">
          <div className="ChatLeft">
            <Row noGutters>
              <span className="text-danger w-100 text-center p-3">
                S= Satisfied, U-NS= Unsatisfied No Show & U-RST= Unsatisfied
                Rude / Short Time
                <br></br>
                To ensure smooth communication, kindly keep your chat partner
                informed about any time zone differences. This will help both of
                you stay synchronized and maintain effective interaction.
              </span>
              <span className="w-100 text-center mt-3">
                <b>NOTE:</b>Bonus of $2.50 goes to Teacher-athlete who books
                chat regarding HIGH PRIORITY QUESTION.
              </span>
              <span className="w-100 text-center my-3">
                <b>NOTE:</b>If your schedule changes, please inform the athlete
                by email.
              </span>
              <div className="text-center w-100">
                <img
                  src={mapImg}
                  style={{ width: "20%" }}
                  alt="Time Zone Img USA"
                />
              </div>
              <Col sm="12">
                <div className="chat">
                  {loaderMan ? (
                    <div className="text-center">
                      {" "}
                      <i className="spinner-border text-primary mb-3"></i>{" "}
                    </div>
                  ) : (
                    <Table className="table" responsive>
                      <thead>
                        <tr>
                          <th> </th>
                          <th>Chat ID</th>
                          <th>Pending athlete booking</th>
                          <th>Chat Amount</th>
                          <th>Chat Min/rate</th>
                          <th>Fan Email</th>
                          <th>Athlete Email</th>
                          <th className="text-center">Parties Arrange</th>
                          <th>Note</th>
                          <th>Rating</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      {offPlatformSiteData.map((items, index) => {
                        const originalTimestamp = moment
                          .unix(items.chat_end.seconds)
                          .add(items.chat_end.nanoseconds, "nanoseconds");
                        const newTimestamp = originalTimestamp.add(7, "days");
                        const isAfter7Days = newTimestamp.isAfter(currentDate);
                        return (
                          <tbody key={index + 500}>
                            <tr>
                              <td className="text-center">
                                <img
                                  className="chat-profile"
                                  src={items?.chatUser.player_image?.url}
                                />
                              </td>
                              <td className="table-chat-request">
                                {items.chatId ? items.chatId : "Not Assigned"}
                              </td>
                              <td className="table-chat-request">
                                {items?.chatUser.first_name}{" "}
                                {items?.chatUser.last_name}
                              </td>
                              <td className="table-chat-request">
                                {items.athleteChatRate * items.chatMinutes}$
                              </td>
                              <td className="table-chat-request">
                                {items.chatMinutes}min@${items.athleteChatRate}
                                ea
                              </td>
                              <td className="table-chat-request">
                                {items.fanEmail}
                              </td>
                              <td className="table-chat-request">
                                {items.athleteEmail}
                              </td>
                              <td className="table-chat-request text-center">
                                <p>time/date as PARTIES agree</p>
                              </td>
                              <td>
                                {items.timeSlot?.note
                                  ? items.timeSlot.note
                                  : "N/A"}
                              </td>
                              <td>
                                {items?.showRating == false ? (
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      dispatch(upDateOffPlatformData(index))
                                    }
                                  >
                                    Check Athlete Reviews{" "}
                                  </Button>
                                ) : (
                                  <>
                                    Athlete {items?.chatUser.first_name}{" "}
                                    {items?.chatUser.last_name} has received
                                    {items.count?.satisfied
                                      ? items.count?.satisfied
                                      : 0}{" "}
                                    S, {items.count?.NS ? items.count?.NS : 0}{" "}
                                    U-NS and{" "}
                                    {items.count?.RST ? items.count?.RST : 0}{" "}
                                    U-RST out of{" "}
                                    {items.totalChat ? items.totalChat : 0}{" "}
                                    total chats
                                  </>
                                )}
                              </td>
                              <td className="table-chat-request">
                                {items.status}
                              </td>
                              {items.status == "payment pending" ? (
                                <td className="table-chat-request text-center d-flex align-items-center">
                                  <Button
                                    className="p-1 mt-3 mr-2 btn-sm mx-auto"
                                    color="primary"
                                    onClick={() => handelReject(items.id)}
                                  >
                                    Reject
                                  </Button>
                                  <div className="mt-3 ml-1 d-flex paypal_button-chat">
                                    <Paypal
                                      price={
                                        items?.athlete_chat_rate *
                                          items?.chat_minutes +
                                        0.5
                                      }
                                      completePaypalPayment={
                                        completePaypalPayment
                                      }
                                      chatId={items.id}
                                      athelete={items}
                                    />
                                  </div>
                                </td>
                              ) : (
                                <td className="table-chat-request text-center">
                                  <Button
                                    disabled={
                                      items.is_reviewed_fan ||
                                      items.status !== "complete" ||
                                      !isAfter7Days
                                    }
                                    className="btn-sm mx-auto"
                                    color="primary"
                                    onClick={() => {
                                      handleShowReview(
                                        items.participants.filter(
                                          (ele) => ele != uid
                                        ),
                                        items.id,
                                        items.chat_id
                                      );
                                    }}
                                  >
                                    {" "}
                                    Review Chat
                                  </Button>
                                </td>
                              )}
                            </tr>
                          </tbody>
                        );
                      })}
                    </Table>
                  )}
                </div>
                {loaderMan ? null : loader ? (
                  <i className="spinner-border text-primary mb-3"></i>
                ) : (
                  <Button
                    color="primary"
                    onClick={loadMore}
                    disabled={!moreData}
                  >
                    Load More
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ReviewModal
        showReviewModal={showReview}
        handleShowReview={() => {
          handleShowReview("", "");
        }}
        chatID={chatID}
        athleteId={receiverId[0]}
        chat_id={chat_id}
      ></ReviewModal>
    </Container>
  );
};

export default GetOffPlatformData;
