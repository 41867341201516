import firebase from "../../config/firebase";
import { toast } from "react-toastify";
export const login = (creds) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN_REQUEST",
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then((data) => {
        var docRef = firebase
          .firestore()
          .collection("users")
          .doc(data.user.uid);
        docRef.get().then((doc) => {
          if (doc.exists) {
            if (doc.data().blocked) {
              toast.warning("You are not allowed to access this panel");
              dispatch({
                type: "LOGIN_REQUEST_END",
              });
            } else {
              dispatch(refreshAuth(data.user.uid));
              if (!doc.data()?.a4b_agree) {
                dispatch({
                  type: "AGREE_TO_TERMS",
                  payload: true,
                });
              }
              if (!doc.data()?.a4b_login) {
                docRef.update({
                  lastLoggedIn: firebase.firestore.Timestamp.now(),
                  a4b_login: true,
                });
              }
            }
          } else {
            toast.warning("You are not allowed to access this panel");
            dispatch({
              type: "LOGIN_REQUEST_END",
            });
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: "LOGIN_FAIL",
          uid: "",
          error: error,
        });
        dispatch({
          type: "LOGIN_REQUEST_END",
        });
        toast.warning(error.message);
      });
  };
};
export const refreshAuth = (uid) => async (dispatch) => {
  firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .onSnapshot((doc) => {
      dispatch({
        type: "LOGIN_REQUEST_END",
      });
      dispatch({
        type: "LOGIN_SUCCESS",
        user: { id: doc.id, ...doc.data() },
      });
    });
};

export const updateAgreeAndLogin =
  (uid, onSuccess = () => {}) =>
  async (dispatch) => {
    const userRef = firebase.firestore().collection("users").doc(uid);

    try {
      await userRef.update({
        a4b_agree: true,
        // a4b_login: true,
      });
      const updatedUserDoc = await userRef.get();
      const updatedUserData = {
        id: updatedUserDoc.id,
        ...updatedUserDoc.data(),
      };
      dispatch({
        type: "LOGIN_SUCCESS",
        user: updatedUserData,
      });
      onSuccess();
    } catch (error) {
      console.log("Error updating user document:", error);
    }
  };

export const logout = (uid) => {
  const userRef = firebase.firestore().collection("users").doc(uid);
  return (dispatch) => {
    firebase
      .auth()
      .signOut()
      .then(async (data) => {
        await userRef.update({
          // a4b_agree: false,
          a4b_login: false,
        });
        dispatch({
          type: "LOGOUT_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({
          type: "LOGOUT_FAIL",
          uid: "",
        });
      });
  };
};
export const updatePassword = ({ obj, onSuccess = () => {} }) => {
  return async (dispatch) => {
    dispatch({
      type: "PASSWORD_REQUEST",
    });
    let user = await firebase.auth().currentUser;
    if (user != null) {
      let creds = await firebase.auth.EmailAuthProvider.credential(
        user.email,
        obj.currentPassword
      );
      await user
        .reauthenticateWithCredential(creds)
        .then(() => {
          user.updatePassword(obj.newPassword);
          dispatch({
            type: "PASSWORD_REQUEST_END",
          });
          toast.success("Password Updated Successfully");
        })
        .catch((error) => {
          toast.warning("Current Password is Invalid");
          dispatch({
            type: "PASSWORD_REQUEST_END",
          });
        });
    } else {
      dispatch({
        type: "PASSWORD_REQUEST_END",
      });
    }
  };
};
export const updateChatRate = ({ obj, onSuccess = () => {} }) => {
  return async (dispatch) => {
    dispatch({
      type: "PASSWORD_REQUEST",
    });
    let user = await firebase.auth().currentUser;
    if (user != null) {
      await firebase.firestore().collection("users").doc(user.uid).update({
        chatRate: obj.newRate,
      });
      dispatch({
        type: "PASSWORD_REQUEST_END",
      });
      toast.success("Chat Rate Updated Successfully");
    } else {
      dispatch({
        type: "PASSWORD_REQUEST_END",
      });
    }
  };
};

export const updatePaypalEmail = (id, paypal_email) => {
  return (dispatch) => {
    firebase.firestore().collection("users").doc(id).update({ paypal_email });
  };
};

export const addSuggestion =
  ({ obj, onSuccess }) =>
  async (dispatch) => {
    await dispatch({
      type: "LOGIN_REQUEST",
    });
    obj.created_at = firebase.firestore.Timestamp.now();
    firebase
      .firestore()
      .collection("a4b_suggestion")
      .add(obj)
      .then(async () => {
        toast.success("Suggestion has been added to the box");
        onSuccess();
        await dispatch({
          type: "LOGIN_REQUEST_END",
        });
      })
      .catch(async (err) => {
        toast.warning(err.message);
        await dispatch({
          type: "LOGIN_REQUEST_END",
        });
      });
  };
