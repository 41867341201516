import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import axios from "axios";

export const addQuestion =
  (obj, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: "ADD_QUESTION_LOADER",
      payload: true,
    });
    const newObj = { ...obj, created_at: firebase.firestore.Timestamp.now() };
    try {
      const collectionRef = firebase.firestore().collection("a4b_questions");
      await collectionRef.add(newObj);
      dispatch({
        type: "ADD_QUESTION_LOADER",
        payload: false,
      });
      toast.success("Question added successfully");
      onSuccess();
    } catch (error) {
      console.error("Error adding question:", error);
      dispatch({
        type: "ADD_QUESTION_LOADER",
        payload: false,
      });
      toast.error("Failed to add question");
    }
  };
export const completeQuestionPayment =
  (obj, onSuccess = () => {}) =>
  async (dispatch) => {
    const newObj = { ...obj, created_at: firebase.firestore.Timestamp.now() };
    try {
      const collectionRef = firebase.firestore().collection("a4b_questions");
      await collectionRef.add(newObj);
      toast.success("Question added successfully");
      onSuccess();
    } catch (error) {
      console.error("Error adding question:", error);

      toast.error("Failed to add question");
    }
  };
export const fetchQuestions = () => async (dispatch) => {
  firebase
    .firestore()
    .collection("a4b_questions")
    .where("status", "==", "open")
    .onSnapshot(async (query) => {
      var temp = [];
      for await (let doc of query.docs) {
        let lessonId = doc.data()?.lesson;
        if (lessonId) {
          let lesson = await firebase
            .firestore()
            .collection("a4b_topics")
            .doc(lessonId)
            .get();

          temp.push({
            id: doc.id,
            leson: lesson?.data(),
            ...doc.data(),
          });
        } else {
          temp.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      }
      dispatch({
        type: "GET_QUESTIONS",
        payload: temp,
      });
    });
};

export const sendEmailToFanNotification =
  (payload, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch({
      type: "REPLY_LOADER",
      payload: true,
    });
    const athleteResponseEmail = `
    Hello,
    I saw the question you posted at FansHelpPlayers.I am a
   Teacher-Athlete, and my Registration Number is ${payload?.athleteID}
   I am available now to Chat, if you are ready.I have some great
   information to share on the topic of your question. 
   If you can answer back on my email ${payload?.athleteEmail}
   within 5 minutes,I will respond,and we can chat right away. just
   make your request on the INSTANT CHAT.I will accept your
   request.Then you escrow the money,and we chat.
  `;

    const options = {
      to: payload?.fanEmail,
      subject: "Response from Athlete",
      text: athleteResponseEmail,
    };

    axios
      .post(
        "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
        options
      )
      .then((response) => {
        toast.success("Email Sent Successfully");
        onSuccess();
        dispatch({
          type: "REPLY_LOADER",
          payload: false,
        });
      })
      .catch((error) => {
        toast.error("Error occured try Again !");
        dispatch({
          type: "REPLY_LOADER",
          payload: false,
        });
      });
  };

export const sendEmailToAthleteNotification =
  (payload, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch({
      type: "REPLY_LOADER",
      payload: true,
    });
    const athleteResponseEmail = `
  Hi,
  I'm a sports fan registered at FansHelpPlayers. i put a question in the chat question database.
  (Question ID#:${payload.questionId}).if you think you could answer this question for me,please visit the homepage for chat at FansHelpPlayers to signal me. i look forward to your answer on the website
  
  `;

    const options = {
      to: payload?.athleteEmail,
      subject: "Response from fan",
      text: athleteResponseEmail,
    };

    axios
      .post(
        "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
        options
      )
      .then((response) => {
        toast.success("Email Sent Successfully");
        onSuccess();
        dispatch({
          type: "REPLY_LOADER",
          payload: false,
        });
      })
      .catch((error) => {
        toast.error("Error occured try Again !");
        dispatch({
          type: "REPLY_LOADER",
          payload: false,
        });
      });
  };

export const sendEmailToOffPlatformNotification =
  (payload, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch({
      type: "REPLY_LOADER",
      payload: true,
    });
    const athleteResponseEmailNew = `Hello Sport Fan - An instant chat with you was just suggested by athlete ${payload.athlete_email}.If you are interested, please check out the section of your dashboard called 'Off platform to site'`;
    const options = {
      to: payload?.fan_email,
      subject: "Invite from Athletes",
      text: athleteResponseEmailNew,
    };

    axios
      .post(
        "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
        options
      )
      .then((response) => {
        toast.success("Email Sent Successfully");
        onSuccess();
        dispatch({
          type: "REPLY_LOADER",
          payload: false,
        });
      })
      .catch((error) => {
        toast.error("Error occured try Again !");
        dispatch({
          type: "REPLY_LOADER",
          payload: false,
        });
      });
  };

export const getHighpriorityQuestions = (id) => async (dispatch) => {
  try {
    const querySnapshot = await firebase
      .firestore()
      .collection("a4b_questions")
      .where("fan_id", "==", id)
      .where("priority", "==", "paid")
      .where("status", "==", "open")
      .get();
    const questions = [];
    querySnapshot.forEach(async (doc) => {
      questions.push({
        question: doc.data().question,
        id: doc.id,
      });
    });
    dispatch({
      type: "HIGH_PRIORITY_QUESTIONS",
      payload: questions,
    });
  } catch (error) {
    console.log(error);
  }
};

export const fanRequestedSlots =
  (obj, onSuccess = () => {}) =>
  async () => {
    let newObj = {
      fanId: obj.fanID,
      athleteId: obj.athleteID,
    };
    const athleteResponseEmailNew = `fan ${obj.fanName} has requested you to create Calendar availability so they can communicate with you`;
    const options = {
      to: obj?.athleteEmail,
      subject: "Fan Requested Slots",
      text: athleteResponseEmailNew,
    };
    firebase
      .firestore()
      .collection("fan_requested_slots")
      .add(newObj)
      .then(() => {
        axios
          .post(
            "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
            options
          )
          .then((response) => {
            toast.success("Email Sent Successfully");
            onSuccess();
          })
          .catch((error) => {
            toast.error("Error occured try Again !");
          });
      });
  };

export const checkRequested = (id) => (dispatch) => {
  firebase
    .firestore()
    .collection("fan_requested_slots")
    .where("fanId", "==", id)
    .get()
    .then((query) => {
      let temp_data = [];
      query.forEach((doc) => {
        temp_data.push({
          athleteId: doc.data().athleteId,
        });
        dispatch({
          type: "CHECK_REQUESTED",
          payload: temp_data,
        });
      });
    });
};
