import React from "react";
import { Col, Container, Row } from "reactstrap";
import imgLeft from "../assets/img/Picture1.jpg";
import imgRight from "../assets/img/Picture2.jpg";

const QuestionTopDetails = () => {
  return (
    <>
      <Container className="my-3">
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={3} className="text-center">
            <img
              src={imgLeft}
              className="ask_question_img"
              alt="img"
            />
          </Col>
          <Col md={4} className="text-center">
            <h1 className="font-weight-bold">
              Got a <br /> sports-related <br /> Question?
            </h1>
          </Col>
          <Col md={3} className="text-center">
            <img
              src={imgRight}
              className="ask_question_img"
              alt="img"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12} className="d-flex  justify-content-center m-auto">
            <div>
              <p className="mb-0">1. Post your question. All questions deleted after 2 weeks, or when chosen for chat.</p>
              <p>
                2. Search Teacher-Athletes + ask one to give you their best
                answer.
              </p>

              <h1 className="font-weight-bold">Everybody wins!</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default QuestionTopDetails;
