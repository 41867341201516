import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function Auth(props) {
  let auth = useSelector((state) => state.authUser);
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectPath = urlParams.get("redirect");
  console.log(redirectPath);
  if (auth.uid) {
    if (redirectPath) {
      window.location.href = redirectPath;
    } else {
      history.push("/dashboard");
    }
  }
  return <div>{props.children}</div>;
}

export default Auth;
