import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAthleteManagers,
  removeManager,
} from "../../store/actions/managerAction";
import { Button, Card, Container, Table } from "reactstrap";

const AthleteManager = () => {
  // const [loader, setLoader] = useState(true);
  const [loader, setLoader] = useState({
    dataLoader: true,
  });

  let dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.authUser);
  const { athleteManager } = useSelector((state) => state.manager);

  useEffect(() => {
    dispatch(
      getAthleteManagers(uid, () => {
        // setLoader(false);
        setLoader((prev) => ({
          ...prev,
          dataLoader: false,
        }));
      })
    );
  }, []);

  const handelRemove = (id) => {
    dispatch(removeManager(id));
  };
  return (
    <Container className="mt-3">
      <Card className="p-2">
        {loader.dataLoader ? (
          <div className="text-center">
            <i className="spinner-border text-primary mb-3"></i>{" "}
          </div>
        ) : (
          <Table className="table" responsive>
            <thead>
              <tr>
                <th>Manager Name</th>
                <th>Manager Email</th>
                <th>Action</th>
              </tr>
            </thead>
            {athleteManager.length > 0 ? (
              athleteManager.map((ele, index) => (
                <tbody key={index}>
                  <tr>
                    <td>{ele.name}</td>
                    <td>{ele.email}</td>
                    <td>
                      <Button
                        color="primary"
                        onClick={() => handelRemove(ele.id)}
                        disabled={ele.btnLoader}
                      >
                        {ele.btnLoader ? (
                          <i className="spinner-border spinner-border-sm text-white "></i>
                        ) : (
                          "Remove"
                        )}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              ))
            ) : (
              <tr>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
            )}
          </Table>
        )}
      </Card>
    </Container>
  );
};

export default AthleteManager;
