import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
  Container,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInstitutes,
  fetchHighSchoolInstitutes,
  getCollegeCity,
  getSchoolCounty,
  fetchSchoolStates,
  fetchCollegeStates,
} from "../store/actions/athleteActions";
import { getAtheletes } from "../store/actions/athleteActions";
import Rating from "@mui/material/Rating";

function FilterPlayers() {
  let history = useHistory();
  const dispatch = useDispatch();
  const {
    institutes,
    athletes,
    hsInstitutes,
    schoolStates,
    collegeStates,
    cities,
    counties,
  } = useSelector((state) => state.athlete);
  const [vidOpen, setVidOpen] = useState(false);
  const [vidURL, setVidURL] = useState("");
  const [newAthletes, setNewAthletes] = useState([]);
  const [filteredAthletes, setFilteredAthletes] = useState([]);
  const [state, setState] = useState("");
  const [sportLevel, setSportLevel] = useState("");
  const [sport, setSport] = useState("");
  const [college, setCollege] = useState("");
  const [gender, setGender] = useState("");
  const [letter, setLetter] = useState("");
  const [modalForLength, setModalForLength] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleModalForLength = () => {
    setModalForLength(!modalForLength);
    history.push("/fan/search-player");
  };

  const [id, setId] = useState("");
  const [searchByName, setSearchByName] = useState("");

  const defaultStateProps = {
    options:
      sportLevel == "College Athlete"
        ? collegeStates?.map((insti) => {
            return insti.name;
          })
        : schoolStates?.map((insti) => {
            return insti.name;
          }),
    getOptionLabel: (option) => option,
  };
  const defaultSportLevelProps = {
    options: ["College Athlete", "H.S. Athlete"],
    getOptionLabel: (option) => option,
  };
  const defaultCollegeProps = {
    options:
      sportLevel == "College Athlete"
        ? cities?.map((insti) => {
            return insti.city;
          })
        : counties?.map((insti) => {
            return insti.county;
          }),
    getOptionLabel: (option) => option,
  };
  const defaultGenderProps = {
    options: ["Male", "Female"],
    getOptionLabel: (option) => option,
  };
  const defaultLetterProps = {
    options: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
    getOptionLabel: (option) => option,
  };
  const defaultSportProps = {
    options: [
      "Badminton",
      "Baseball",
      "Basketball",
      "Cheerleading",
      "Cycling",
      "Cricket",
      "Cross Country",
      "Field Hockey",
      "Football",
      "Golf",
      "Gymnastics",
      "Ice Hockey",
      "Lacrosse",
      "OTHER",
      "Rowing",
      "Rugby",
      "Skateboarding",
      "Skating",
      "Skiing",
      "Soccer",
      "Softball",
      "Swimming",
      "Table Tennis",
      "Taekwondo",
      "Tennis",
      "Track and Field",
      "Volleyball",
      "Wrestling",
    ],
    getOptionLabel: (option) => option,
  };
  useEffect(() => {
    if (collegeStates.length == 0) {
      dispatch(fetchCollegeStates());
    }
    if (schoolStates.length == 0) {
      dispatch(fetchSchoolStates());
    }
    localStorage.removeItem("teamId");
    localStorage.removeItem("uniqData");
    localStorage.removeItem("ByteamID");
    dispatch(getAtheletes());
  }, []);
  useEffect(() => {
    if (state != "") {
      if (sportLevel == "College Athlete") {
        dispatch(getCollegeCity(state));
      } else {
        dispatch(getSchoolCounty(state));
      }
    }
  }, [state]);
  useEffect(() => {
    if (institutes?.length == 0) {
      dispatch(fetchInstitutes());
    }
    if (hsInstitutes?.length == 0) {
      dispatch(fetchHighSchoolInstitutes());
    }
    if (athletes?.length == 0) {
      dispatch(getAtheletes());
    }
    setId(history.location.state.id);
    setSearchByName(history.location.state.searchByName);
    setSportLevel(history.location.state.sportLevel);
    setState(history.location.state.state);
    setCollege(history.location.state.college);
    setSport(history.location.state.sport);
    setGender(history.location.state.gender);
    setLetter(history.location.state.letter);
    setNewAthletes(history.location.state.uniq);
  }, []);
  const vidToggle = () => {
    setVidOpen(!vidOpen);
  };
  const showSearchResult = () => {
    if (id == "" && searchByName == "") {
      return (
        <Row className="mt-1">
          <Col lg="2" md="4" sm="6" className="mt-3 mt-lg-0">
            <Autocomplete
              {...defaultSportLevelProps}
              id="auto-complete"
              autoComplete
              clearIcon={false}
              includeInputInList
              value={sportLevel}
              onChange={(e, val) => {
                setFilteredAthletes([]);
                setNewAthletes([]);
                setCollege("");
                setState("");
                setSport("");
                setGender("");
                setLetter("");
                setSportLevel(val);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Athlete"
                  variant="standard"
                />
              )}
            />
          </Col>
          <Col lg="2" md="4" sm="6" className={`mt-3 mt-lg-0 `}>
            <Autocomplete
              {...defaultStateProps}
              id="auto-complete"
              autoComplete
              includeInputInList
              clearIcon={false}
              disabled={!sportLevel}
              value={state}
              onChange={(e, val) => {
                setFilteredAthletes([]);
                setNewAthletes([]);
                setCollege("");
                setState(val);
                setSport("");
                setGender("");
                setLetter("");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="State of School"
                  variant="standard"
                />
              )}
            />
          </Col>
          <Col lg="2" md="4" sm="6" className={`mt-3 mt-lg-0 `}>
            <Autocomplete
              {...defaultCollegeProps}
              id="auto-complete"
              autoComplete
              includeInputInList
              disabled={!sportLevel || !state}
              clearIcon={false}
              value={college}
              onChange={(e, val) => {
                setFilteredAthletes([]);
                setNewAthletes([]);
                setCollege(val);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="City of School"
                  variant="standard"
                />
              )}
            />
          </Col>
          <Col lg="2" md="4" sm="6" className={`mt-3 mt-lg-0 `}>
            <Autocomplete
              {...defaultSportProps}
              id="auto-complete"
              autoComplete
              clearIcon={false}
              includeInputInList
              disabled={!sportLevel || !state || !college}
              value={sport}
              onChange={(e, val) => {
                setFilteredAthletes([]);
                setNewAthletes([]);
                setSport(val);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Sport" variant="standard" />
              )}
            />
          </Col>
          <Col lg="2" md="4" sm="6" className={`mt-3 mt-lg-0 `}>
            <Autocomplete
              {...defaultGenderProps}
              id="auto-complete"
              autoComplete
              clearIcon={false}
              includeInputInList
              value={gender}
              disabled={!sportLevel || !state || !college || !sport}
              onChange={(e, val) => {
                setFilteredAthletes([]);
                setNewAthletes([]);
                setGender(val);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Gender"
                  variant="standard"
                />
              )}
            />
          </Col>
          <Col lg="2" md="4" sm="6" className={`mt-3 mt-lg-0 `}>
            <Autocomplete
              {...defaultLetterProps}
              id="auto-complete"
              autoComplete
              clearIcon={false}
              includeInputInList
              disabled={!sportLevel || !state || !college || !sport || !gender}
              value={letter}
              onChange={(e, val) => {
                setFilteredAthletes([]);
                setNewAthletes([]);
                setLetter(val);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="First Letter of last name"
                  variant="standard"
                />
              )}
            />
          </Col>
        </Row>
      );
    } else if (!id == "") {
      return (
        <Row className="mt-1">
          <Col>
            <Input
              defaultValue={id}
              name="ID"
              readOnly
              type="text"
              className="mt-2 mt-sm-0 custom-filter-input"
            />
          </Col>
        </Row>
      );
    } else if (!searchByName == "") {
      return (
        <Row className="mt-1">
          <Col>
            <Input
              defaultValue={searchByName}
              name="ID"
              readOnly
              type="text"
              className="mt-2 mt-sm-0 custom-filter-input"
            />
          </Col>
        </Row>
      );
    }
  };

  const checkNewAthleteLength = () => {
    if (newAthletes.length > 0 && newAthletes.length <= 25) {
      setIsValid(true);
      return true;
    } else if (newAthletes.length > 25 && newAthletes.length <= 200) {
      setModalForLength(true);
      return true;
    } else if (newAthletes.length > 200) {
      return toast.warn(
        "Display of search results is over limit of 200 athletes. Please select other search method"
      );
    }
  };

  useEffect(() => {
    checkNewAthleteLength();
  }, [newAthletes]);

  const handleModalForLengthYesBtn = () => {
    setIsValid(true);
    setModalForLength(false);
  };

  return (
    <>
      <Container fluid className="mb-5 px-5">
        <Row>
          <Col>
            <h3 className="text-center">
              <strong>NOTE:</strong> Ambassador for brand does not verify or
              guarantee the identity of people registered on site.
            </h3>
            <Card className="filter-card">
              <CardBody className="custom-card-body">
                {showSearchResult()}{" "}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Col
            lg="3"
            md="4"
            className={`${newAthletes?.length > 0 && "d-none"}`}
          >
            <Button
              color="primary"
              block
              disabled={!state || !college || !sport || !gender || !letter}
              onClick={(e) => {
                e.preventDefault();
                if (
                  state != "" ||
                  college != "" ||
                  sport != "" ||
                  gender != "" ||
                  letter != ""
                ) {
                  let resArr =
                    athletes?.length > 0 &&
                    athletes.filter((ath) => {
                      if (
                        ath.college
                          .toLowerCase()
                          .includes(college.toLowerCase()) &&
                        ath.state.toLowerCase().includes(state.toLowerCase()) &&
                        ath.sport.toLowerCase().includes(sport.toLowerCase()) &&
                        ath.gender
                          .toLowerCase()
                          .includes(gender.toLowerCase()) &&
                        ath.last_name.substring(0, 1).toLowerCase() ==
                          letter.toLowerCase()
                      ) {
                        return ath;
                      }
                    });
                  if (resArr?.length > 0) {
                    setFilteredAthletes(resArr);
                  } else {
                    toast.warning("No Player Found!");
                  }
                }
              }}
            >
              Search Now
            </Button>
          </Col>
          <Col lg="3" md="4">
            <Button
              color="primary"
              block
              onClick={(e) => {
                e.preventDefault();
                setId("");
                setSearchByName("");
                setSportLevel("");
                setState("");
                setCollege("");
                setSport("");
                setGender("");
                setLetter("");
                setFilteredAthletes([]);
                setNewAthletes([]);
              }}
            >
              Clear Search
            </Button>
          </Col>
        </Row>
        <Row>
          {filteredAthletes?.length > 0
            ? filteredAthletes.map((ath, index) => {
                return (
                  <Col
                    lg="3"
                    md="6"
                    className="mt-3"
                    align="center"
                    key={index}
                  >
                    <Card className="filter-card mt-3">
                      <CardBody className="custom-card-body">
                        <img
                          className="avatar"
                          alt="Card image cap"
                          src={ath.player_image.url}
                        />
                        <h3 className=" py-2 mt-2 mx-3 px-3">
                          {ath.first_name + " " + ath.last_name}
                        </h3>
                        <br />
                        <i
                          className="fa fa-play fa-3x mt-3 text-danger"
                          onClick={() => {
                            setVidURL(ath.player_video.url);
                            vidToggle();
                          }}
                        ></i>
                        <br />
                        <Link to={`/search-player/${ath.id}/buy-rights`}>
                          <Button className="mt-4 custom-btn">
                            View Profile
                          </Button>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })
            : isValid
            ? newAthletes?.map((ath, index) => {
                return (
                  <Col
                    lg="3"
                    md="6"
                    className="mt-3"
                    align="center"
                    key={index}
                  >
                    <Card className="filter-card mt-3">
                      <CardBody className="custom-card-body">
                        <img
                          className="avatar"
                          alt="Card image cap"
                          src={ath.player_image.url}
                        />
                        <h3 className=" py-2 mt-2 mx-3 px-3">
                          {ath.first_name + " " + ath.last_name}
                        </h3>
                        <br />
                        <i
                          className="fa fa-play fa-3x mt-3 text-danger"
                          onClick={() => {
                            setVidURL(ath.player_video.url);
                            vidToggle();
                          }}
                        ></i>
                        <br />

                        <Link to={`/search-player/${ath.id}/buy-rights`}>
                          <Button className="mt-4 custom-btn">
                            View Profile
                          </Button>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })
            : ""}
        </Row>
      </Container>
      <Modal isOpen={vidOpen} centered>
        <video src={vidURL} controls></video>
        <Button color="danger" onClick={vidToggle}>
          {" "}
          Close
        </Button>
      </Modal>

      <Modal isOpen={modalForLength} toggle={handleModalForLength} centered>
        <ModalBody>
          <div className="d-flex justify-content-end">
            <div>
              <i onClick={handleModalForLength} className="fas fa-times"></i>
            </div>
          </div>
          <div>
            <h2>
              search results list is long, do you want to continue or do Regis
              #search or full name search instead?
            </h2>
          </div>

          <div className="d-flex mt-4 justify-content-end">
            <Button color="primary" onClick={handleModalForLengthYesBtn}>
              Continue?
            </Button>{" "}
            <Button color="secondary" onClick={handleModalForLength}>
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default FilterPlayers;
