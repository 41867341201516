const initialState = {
  isFavorite: false,
  favoriteAthletes: [],
  lastDocument: "",
  moreData: true,
  volunteerRequests: [],
  isRequest: [],
  groupSlotById: [],
};

const favoriteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET-PERMANENT-MANAGER":
      let slotId = action.payload;
      let volunteer = [...state.volunteerRequests];
      let updateVolunteer = volunteer.map((ele) => {
        if (slotId == ele.id) {
          return {
            ...ele,
            permanentManager: !ele.permanentManager,
          };
        }
        return ele;
      });
      return {
        ...state,
        volunteerRequests: updateVolunteer,
      };
    case "GROUP-SLOT-BY-ID":
      return {
        ...state,
        groupSlotById: action.payload,
      };
    case "UPDATE-GROUP-SLOT-BY-ID":
      let slot = [...state.groupSlotById];
      let updateSlot = slot.map((items) => {
        if (items.id == action.payload) {
          return {
            ...items,
            btnLoader: true,
          };
        } else {
          return items;
        }
      });
      return {
        ...state,
        groupSlotById: updateSlot,
      };
    case "NOTIFICATION_LOADER":
      let docId = action.payload;
      let athlete = [...state.favoriteAthletes];
      let updateChatLoader = athlete.map((data) => {
        if (docId == data.id) {
          return {
            ...data,
            notificationBtnLoader: !data.notificationBtnLoader,
          };
        }
        return data;
      });
      return {
        ...state,
        favoriteAthletes: updateChatLoader,
      };

      case "NOTIFICATION_VALUNTEER_LOADER":
        let documentId = action.payload;
        let athletes = [...state.favoriteAthletes];
        let updateChatLoaders = athletes.map((data) => {
          if (documentId == data.id) {
            return {
              ...data,
              notificationBtnLoaders: !data.notificationBtnLoader,
            };
          }
          return data;
        });
        return {
          ...state,
          favoriteAthletes: updateChatLoaders,
        };
   
        case "REMOVE_LOADER":
      let id = action.payload;
      let data = [...state.favoriteAthletes];
      let updateChatStatus = data.map((data) => {
        if (id == data.athleteId) {
          return {
            ...data,
            btnLoader: !data.btnLoader,
          };
        }
        return data;
      });
      return {
        ...state,
        favoriteAthletes: updateChatStatus,
      };
    case "IS_ATHLETE_FAVORITE":
      return {
        ...state,
        isFavorite: action.payload,
      };
    case "GET_ATHLETE_FAVORITES":
      return {
        ...state,
        favoriteAthletes: [
          ...state.favoriteAthletes,
          ...action.payload.temp_data,
        ],
        moreData: action.payload.hasMoreDocument,
        lastDocument: action.payload.lastDocument,
      };
    case "GET_ATHLETE_FAVORITES_EMPTY":
      return {
        ...state,
        favoriteAthletes: action.payload.temp_data,
        moreData: action.payload.hasMoreDocument,
        lastDocument: action.payload.lastDocument,
      };
    case "GET_VOLUNTEER_REQUEST":
      return {
        ...state,
        volunteerRequests: action.payload,
      };
    case "IS_REQUEST":
      return {
        ...state,
        isRequest: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default favoriteReducer;
