import React, { useEffect } from "react";
import { getAthleteGroupChats } from "../../store/actions/chatAction";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Button, Col, Container, Row, Table } from "reactstrap";
import mapImg from "../../assets/img/time map img.jpeg";
import moment from "moment";
import defaultImg from "../../assets/img/bgImg.jpg";
import ViewGroupChatParticipantModal from "../ViewGroupChatParticipantModal";
import ReviewModal from "../ReviewModal";

const AthleteGroupChat = () => {
  const currentDate = moment();

  const [loader, setLoader] = useState(true);
  const [seeParticipantModal, setSeeParticipantModal] = useState({
    openModal: false,
    slotId: "",
  });
  // const [slotId, setSlotId] = useState("");
  // const [receiverId, setReceiverId] = useState("");
  // const [chatID, setChatID] = useState();
  // const [chat_id, setChat_ID] = useState();
  // const [showReview, setShowReview] = useState(false);
  const [reviewData, setReviewData] = useState({
    showReview: false,
    chatID: "",
    chat_id: "",
    receiverId: "",
    slotId: "",
  });

  let dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.authUser);
  const { athleteGroupChats } = useSelector((state) => state.chat);

  useEffect(() => {
    dispatch(
      getAthleteGroupChats(uid, () => {
        setLoader(false);
      })
    );
  }, []);

  const toggled = () => {
    setSeeParticipantModal((prev) => ({
      ...prev,
      openModal: !prev.openModal,
    }));
  };
  const handleShowReview = (participant, chatID, chat_id, slotId) => {
    setReviewData((prev) => ({
      showReview: !prev.showReview,
      chatID: chatID,
      chat_id: chat_id,
      receiverId: participant,
      slotId: slotId,
    }));
    // setReceiverId(participant);
    // setChatID(chatID);
    // setChat_ID(chat_id);
    // setShowReview(!showReview);
  };

  function isButtonDisabled(chatStartTimestamp) {
    const chatStart = moment.unix(chatStartTimestamp);
    chatStart.add(24, "hours");
    return chatStart.isAfter(currentDate);
  }
  return (
    <Container fluid>
      <Row noGutters>
        <Col align="center" className="mt-4 mb-2">
          <div className="ChatLeft">
            <Row noGutters>
              <span className="text-danger w-100 text-center p-3">
                S= Satisfied, U-NS= Unsatisfied No Show & U-RST= Unsatisfied
                Rude / Short Time
                <br></br>
                To ensure smooth communication, kindly keep your chat partner
                informed about any time zone differences. This will help both of
                you stay synchronized and maintain effective interaction.
              </span>
              <div className="text-center w-100">
                <img
                  src={mapImg}
                  style={{ width: "20%" }}
                  alt="Time Zone Img USA"
                />
              </div>
              <Col sm="12">
                <div className="chat">
                  <Table className="table" responsive>
                    <thead>
                      <tr>
                        <th> </th>
                        <th>Chat id</th>
                        <th>Day for chat</th>
                        <th>Chat rate</th>
                        <th>Manager email</th>
                        <th>Manager type</th>
                        <th>Manager Of chat</th>
                        <th>See Participant</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    {!loader && (
                      <tbody>
                        {athleteGroupChats.map((items, index) => {
                          let disableButton = isButtonDisabled(
                            items.chat_end.seconds
                          );
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  className="chat-profile"
                                  src={
                                    items.athleteData?.player_image
                                      ? items.athleteData?.player_image.url
                                      : defaultImg
                                  }
                                />
                              </td>
                              <td>{items.chat_id}</td>
                              <td>
                                {" "}
                                {moment
                                  .unix(items.chat_start.seconds)
                                  .format("MMM Do YY")}
                              </td>
                              <td>{items.paid_amount.value.toFixed(2)}</td>
                              <td>
                                {items.slot?.managerEmail
                                  ? items.slot?.managerEmail
                                  : items.athlete_email}
                              </td>
                              <td>{items.slot?.managerType}</td>
                              <td>
                                {items.manager
                                  ? items.manager.name
                                  : user.first_name + " " + user.last_name}
                              </td>
                              <td>
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    toggled();
                                    // setSlotId(items.time_slot_id);
                                    setSeeParticipantModal((prev) => ({
                                      ...prev,
                                      slotId: items.time_slot_id,
                                    }));
                                  }}
                                >
                                  See participants
                                </Button>
                              </td>
                              <td>
                                {items.slot?.managerEmail && (
                                  <Button
                                    color="primary"
                                    disabled={
                                      items.is_reviewed_athlete ||
                                      !disableButton
                                    }
                                    onClick={() =>
                                      handleShowReview(
                                        items.managerId,
                                        items.id,
                                        items.chat_id,
                                        items.time_slot_id
                                      )
                                    }
                                  >
                                    Manager review
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </Table>
                  {loader && (
                    <div>
                      <i className="spinner-border text-primary mb-3"></i>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ViewGroupChatParticipantModal
        ShowViewBookingGroupModal={seeParticipantModal.openModal}
        handelViewBookingGroupModal={() => {
          toggled();
        }}
        slotId={seeParticipantModal.slotId}
      />
      <ReviewModal
        showReviewModal={reviewData.showReview}
        handleShowReview={() => {
          handleShowReview("", "");
        }}
        chatID={reviewData.chatID}
        slotId={reviewData.slotId}
        athleteId={reviewData.receiverId}
        chat_id={reviewData.chat_id}
        chatType="group"
      ></ReviewModal>
    </Container>
  );
};

export default AthleteGroupChat;
