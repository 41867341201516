import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "reactstrap";

const TermsAndCondition = () => {

  return (
    <>
      <div className="p-4"><Link to="/home"><Button color="primary">Back</Button></Link> </div>
      <Container className="term-and-condition-main ">
          <Card className="p-3">
          <h5 className="text-center mb-3">Topics</h5>
          <Row>
            <Col md="4" lg="3">
          <ul>
            <li>Smart Goals</li>
            <li>Technique</li>
            <li>Favorite Moment</li>
            <li>Dream Assignment</li>
            <li>Hobbies/pets/Music</li>
            <li>Role Model</li>
            <li>Diet/Conditioning</li>
            <li>Inspiration</li>
            <li>Mental Tips</li>
            <li>Staying Positive</li>
            <li>Self-Talk Mantras</li>
            <li>Teamwork Skills</li>
          </ul>
          </Col>
          <Col md="4" lg="9" className="d-flex align-items-center">
            <h4>Here are some optional 'topics' for questions to be posed by Fan in Question Database. Teacher-athletes answer such Fan questions during chats.</h4>
          </Col>

          </Row>

          </Card>
      </Container>
    </>
  );
};

export default TermsAndCondition;
