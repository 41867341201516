import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import firebase from "../config/firebase";
import {
  getAllGroupSlotsById,
  isFanRequest,
  questionRequestForGroupChat,
} from "../store/actions/chatAction";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../assets/img/bgImg.jpg";
import Paypal from "../components/Paypal";
import { useState } from "react";
import {
  addRequestForManager,
  isVolunteerRequest,
  paymentGroupSlots,
} from "../store/actions/favoriteAction";

const FilterGroupPlayers = () => {
  let dispatch = useDispatch();
  let url = useParams();
  let { groupSlots, FanQuestionRequests } = useSelector((state) => state.chat);
  const { uid, user } = useSelector((state) => state.authUser);
  const [loaderMan, setLoaderMan] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [question, setQuestion] = useState("");
  const [questionLoader, setQuestionLoader] = useState(false);
  const [openedModal, setOpenedModal] = useState(false);
  const [requestData, setRequestData] = useState("");
  // const [description, setDescription] = useState("");
  const [questionA, setQuestionA] = useState("");
  const [questionB, setQuestionB] = useState("");
  const [questionC, setQuestionC] = useState("");
  const [questionD, setQuestionD] = useState("");
  const [questionE, setQuestionE] = useState("");
  const [declinePayment, setDeclinePayment] = useState(false);
  useEffect(() => {
    let obj = {
      athleteId: url.id,
      fanId: uid,
      type: "fan",
    };
    dispatch(
      getAllGroupSlotsById(obj, () => {
        setLoaderMan(false);
      })
    );
    dispatch(isVolunteerRequest(obj));
    dispatch(isFanRequest(obj));
  }, []);
  let { isRequest } = useSelector((state) => state.favorite);
  const toggle = (items) => {
    setOpenModal(!openModal);
    setRequestData(items);
    setQuestionA("");
    setQuestionB("");
    setQuestionC("");
    setQuestionD("");
    setQuestionE("");
  };
  const toggle2 = (items) => {
    setOpenModal2(!openModal2);
    setRequestData(items);
  };
  const [seeMoreData, setSeeMoreData] = useState("");
  const toggled = (items) => {
    setOpenedModal(!openedModal);
    setSeeMoreData(items);
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    setOpenModal(false);
    let obj = {
      declinePayment: declinePayment,
      // description: description,
      questionA,
      questionB,
      questionC,
      questionD,
      questionE,
      slotId: requestData.id,
      athleteId: requestData.athleteId,
      athleteId: requestData.athleteId,
      fanId: uid,
      created_at: firebase.firestore.Timestamp.now(),
    };
    dispatch(
      addRequestForManager(obj, () => {
        // setDescription("");
        setQuestionA("");
        setQuestionB("");
        setQuestionC("");
        setQuestionD("");
        setQuestionE("");
      })
    );
  };
  const completePaypalPayment = (id, details, athlete) => {
    let payments = {
      slotId: athlete.id,
      paymentId: details.id,
      currency_code: details.purchase_units[0].amount.currency_code,
      paid_amount: parseFloat(details.purchase_units[0].amount.value),
      payment_date: firebase.firestore.Timestamp.now(),
    };
    let updateSlot = {
      fanId: uid,
      is_reviewied_fan: false,
      paid_amount: parseFloat(details.purchase_units[0].amount.value),
      payment_date: firebase.firestore.Timestamp.now(),
      paymentId: details.id,
      refund_request: false,
    };
    dispatch(paymentGroupSlots(payments, updateSlot));
  };
  const handelSubmitQuestion = (e) => {
    setQuestionLoader(true);
    e.preventDefault();
    let obj = {
      slotId: requestData.id,
      fanId: uid,
      athleteId: requestData.athleteId,
      question: question,
    };
    dispatch(
      questionRequestForGroupChat(obj, () => {
        setQuestion("");
        setOpenModal2(false);
        setQuestionLoader(false);
      })
    );
  };
  function isAddRequest(id) {
    let isRequest = FanQuestionRequests?.findIndex((slotId) => slotId === id);
    if (isRequest === -1) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Container fluid>
      <Row noGutters>
        <Col align="center" className="mt-4 mb-2">
          <div className="ChatLeft">
            <Row noGutters>
              <Col sm="12">
                <div className="chat">
                  {loaderMan ? (
                    <div className="text-center">
                      {" "}
                      <i className="spinner-border text-primary mb-3"></i>{" "}
                    </div>
                  ) : (
                    <Table className="table" responsive>
                      <thead>
                        <tr>
                          <th> </th>
                          <th>Athlete ID</th>
                          {/* <th>Tentative Start</th> */}
                          {/* <th>Tentative End</th> */}
                          <th>Athlete Email</th>
                          {/* <th>Manager Name</th> */}
                          {/* <th>Manager Email</th> */}
                          {/* <th>Required persons</th> */}
                          <th>Price Fan Pays to Join Group</th>
                          <th>See Chat Details</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupSlots.length > 0 ? (
                          groupSlots.map((items, index) => {
                            let getFan = items?.payments?.find(
                              (id) => id.fanId === uid
                            );
                            let isFanRequest = isAddRequest(items.id);

                            let participantAmount =
                              items?.perParticipantAmount + 0.5;
                            let fixParticipantAmount =
                              participantAmount.toFixed(2);
                            return (
                              <tr key={index}>
                                <td>
                                  {" "}
                                  <img
                                    className="chat-profile"
                                    src={
                                      items.athlete?.player_image
                                        ? items.athlete?.player_image.url
                                        : defaultImg
                                    }
                                  />
                                </td>
                                <td>{items.athlete.athleteID}</td>
                                {/* <td>
                                  {items.start.split(":")[0] > "12"
                                    ? `${items.start.split(":")[0] - 12}:${
                                        items.start.split(":")[1]
                                      } PM`
                                    : `${items.start.split(":")[0]}:${
                                        items.start.split(":")[1]
                                      } AM`}
                                </td>
                                <td>
                                  {items.end.split(":")[0] > "12"
                                    ? `${items.end.split(":")[0] - 12}:${
                                        items.end.split(":")[1]
                                      } PM`
                                    : `${items.end.split(":")[0]}:${
                                        items.end.split(":")[1]
                                      } AM`}
                                </td> */}
                                <td>{items.athlete.email}</td>
                                {/* <td>
                                  {items.fan
                                    ? items.fan.name
                                    : items.managerType}
                                </td>
                                <td>
                                  {items.fan
                                    ? items.fan.email
                                    : items.managerType}
                                </td>
                                <td>{items?.numberOfParticipants}</td> */}
                                <td>{fixParticipantAmount}$</td>
                                <td>
                                  <Button
                                    color="primary"
                                    onClick={() => toggled(items)}
                                  >
                                    See more
                                  </Button>
                                </td>
                                {getFan ? (
                                  <td className="d-flex justify-content-center align-items-center">
                                    <Button
                                      color="primary"
                                      className="mr-4"
                                      onClick={() => toggle2(items)}
                                      disabled={isFanRequest}
                                    >
                                      Request
                                    </Button>
                                    <span>
                                      {getFan?.is_refunded
                                        ? "Refunded"
                                        : "Pending"}
                                    </span>
                                  </td>
                                ) : (
                                  <td className="d-flex justify-content-center align-items-center">
                                    {items.managerType == "Volunteer" ? (
                                      <Button
                                        disabled={isRequest.includes(items.id)}
                                        color="primary"
                                        onClick={() => toggle(items)}
                                        className="text-nowrap mb-1 py-1 mr-2"
                                      >
                                        Send Request
                                      </Button>
                                    ) : null}
                                    {items?.managerEmail === user.email ||
                                    items?.numberOfParticipants ==
                                      100 ? null : (
                                      <Paypal
                                        price={fixParticipantAmount}
                                        completePaypalPayment={
                                          completePaypalPayment
                                        }
                                        chatId={items.id}
                                        athelete={items}
                                      />
                                    )}
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td></td>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                </div>
                {/* {loaderMan ? null : loader ? (
              <i className="spinner-border text-primary mb-3"></i>
            ) : (
              <Button
                color="primary"
                onClick={loadMore}
                disabled={!moreData}
              >
                Load More
              </Button>
            )} */}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Modal isOpen={openModal} toggle={toggle} size="md" centered>
        <ModalBody>
          <form onSubmit={handelSubmit}>
            <div className="my-2">
              {/* <label>
                Explain to Athlete why you are the best for volunteer
              </label>
              <Input
                required
                type="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <h6 className="my-3">
                I have experience as Manager at FHP, as indicated below:
              </h6> */}
              <div className="my-2">
                <label>
                  <b>a.</b> For 1 on 1 Chat, my experience includes:
                </label>
                <Input
                  required
                  type="textarea"
                  value={questionA}
                  onChange={(e) => setQuestionA(e.target.value)}
                />
              </div>
              <div className="my-2">
                <label>
                  <b>b.</b> For Group Chat, my experience includes:
                </label>
                <Input
                  required
                  type="textarea"
                  value={questionB}
                  onChange={(e) => setQuestionB(e.target.value)}
                />
              </div>
              <div className="my-2">
                <label>
                  <b>c.</b> For Mementos, my experience includes:
                </label>
                <Input
                  required
                  type="textarea"
                  value={questionC}
                  onChange={(e) => setQuestionC(e.target.value)}
                />
              </div>
              <div className="my-2">
                <label>
                  <b>d.</b> For Talking Badges, my experience includes:
                </label>
                <Input
                  required
                  type="textarea"
                  value={questionD}
                  onChange={(e) => setQuestionD(e.target.value)}
                />
              </div>
              <div className="my-2">
                <label>
                  <b>e.</b> For Seasonal Online Video Views and Seasonal
                  Badges/Cards, my experience includes:
                </label>
                <Input
                  required
                  type="textarea"
                  value={questionE}
                  onChange={(e) => setQuestionE(e.target.value)}
                />
              </div>

              <input
                className="pointer mt-2 mr-2"
                type="checkbox"
                id="check"
                value={declinePayment}
                onChange={() => setDeclinePayment((pay) => !pay)}
              />
              <label htmlFor="check" className="pointer mt-2">
                Does job for free
              </label>
            </div>
            <div className="mt-3 d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button color="primary" onClick={toggle} className="ml-2">
                Close
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal isOpen={openModal2} toggle={toggle2} size="md" centered>
        <ModalBody>
          <form onSubmit={handelSubmitQuestion}>
            <div>
              <label>Add question for discussion</label>
              <Input
                required
                type="textarea"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
            <div className="mt-3 d-flex justify-content-end">
              <Button color="primary" type="submit" disabled={questionLoader}>
                {questionLoader ? (
                  <Spinner color="light" size="sm" />
                ) : (
                  "Submit"
                )}
              </Button>
              <Button color="primary" onClick={toggle2} className="ml-2">
                Close
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal isOpen={openedModal} toggle={toggled} size="xl" centered>
        <ModalBody>
          <Table className="table" responsive>
            <thead>
              <th>Date</th>
              <th>Tentative Start</th>
              <th>Tentative End</th>
              <th>Manager Name</th>
              <th>Manager Email</th>
              <th>Required persons</th>
            </thead>
            <tbody>
              <td>{seeMoreData.date}</td>
              <td>
                {seeMoreData?.start
                  ? seeMoreData.start.split(":")[0] > "12"
                    ? `${seeMoreData.start.split(":")[0] - 12}:${
                        seeMoreData.start.split(":")[1]
                      } PM`
                    : `${seeMoreData.start.split(":")[0]}:${
                        seeMoreData.start.split(":")[1]
                      } AM`
                  : "N/A"}
              </td>
              <td>
                {seeMoreData?.end
                  ? seeMoreData.end.split(":")[0] > "12"
                    ? `${seeMoreData.end.split(":")[0] - 12}:${
                        seeMoreData.end.split(":")[1]
                      } PM`
                    : `${seeMoreData.end.split(":")[0]}:${
                        seeMoreData.end.split(":")[1]
                      } AM`
                  : "N/A"}
              </td>
              <td>
                {" "}
                {seeMoreData.fan
                  ? seeMoreData.fan.name
                  : seeMoreData.managerType}
              </td>
              <td>
                {seeMoreData.fan
                  ? seeMoreData.fan.email
                  : seeMoreData.managerType}
              </td>
              <td>{seeMoreData?.numberOfParticipants}</td>
            </tbody>
          </Table>

          <div className="mt-3 d-flex justify-content-end">
            <Button color="primary" onClick={toggled} className="ml-2">
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* <ReviewModal
        showReviewModal={showReview}
        handleShowReview={() => {
          handleShowReview("", "");
        }}
        chatID={chatID}
        athleteId={receiverId[0]}
        chat_id={chat_id}
      ></ReviewModal> */}
    </Container>
  );
};

export default FilterGroupPlayers;
