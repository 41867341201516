import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ChatRateChange from "../components/ChatRateChange";
import PasswordChange from "./PasswordChange";

const Setting = () => {
  const [collapseOpen, setCollapseOpen] = useState(true);

  let auth = useSelector((state) => state.authUser);
  const [active, setActive] = useState("1");

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  return (
    <div className="d-flex SettingMain">
      <button
        className={collapseOpen ? "collapseBtnTrue" : "collapseBtnFalse"}
        type="button"
        onClick={toggleCollapse}
      >
        <span>
          {collapseOpen ? (
            <i className="fas fa-angle-left"></i>
          ) : (
            <i className="fas fa-angle-right"></i>
          )}
        </span>
      </button>
      {collapseOpen ? (
        <div className="sideItems bg-gradient">
          <button
            className={collapseOpen ? "collapseBtnTrue" : "collapseBtnFalse"}
            type="button"
            onClick={toggleCollapse}
          >
            <span>
              {collapseOpen ? (
                <i className="fas fa-angle-left"></i>
              ) : (
                <i className="fas fa-angle-right"></i>
              )}
            </span>
          </button>
          <ul
            style={{
              flexDirection: "column",
              listStyle: "none",
              marginTop: "35px",
            }}
          >
            {auth.user?.role == "athlete" ? (
              <li className="sideLink" onClick={() => setActive("1")}>
                <Link className="linkItems text-dark" to="/setting">
                  <span className={active == "3" ? "active" : "itemText"}>
                    <p className="text-dark">Chat Rate</p>
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      ) : null}

      <div className="settingContents">
        {active == "1" ? <ChatRateChange /> : <PasswordChange />}
      </div>
    </div>
  );
};

export default Setting;
