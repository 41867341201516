const initState = {
  schoolLoading: false,
  athletes: [],
  randomAthletes: [],
  athlete: {},
  singleFan: {},
  institutes: [],
  schoolStates: [],
  collegeStates: [],
  hsInstitutes: [],
  cities: [],
  counties: [],
  sports: [],
  timeSlots: [],
  topics: [],
};
const athleteReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_SCHOOLS_LOADER":
      return {
        ...state,
        schoolLoading: action.payload,
      };
    case "GET_ATHLETES":
      return {
        ...state,
        athletes: action.payload,
      };
    case "GET_RANDOM_ATHLETES":
      return {
        ...state,
        randomAthletes: action.payload,
      };
    case "GET_SINGLE_ATHLETE":
      return {
        ...state,
        athlete: action.payload,
      };
    case "GET_SINGLE_FAN":
      return {
        ...state,
        singleFan: action.payload,
      };
    case "GET_INSTITUTES":
      return {
        ...state,
        institutes: action.payload,
      };
    case "GET_HIGH_SCHOOL_INSTITUTES":
      return {
        ...state,
        hsInstitutes: action.payload,
      };
    case "GET_COLLEGE_STATES":
      return {
        ...state,
        collegeStates: action.payload,
      };
    case "GET_SCHOOL_STATES":
      return {
        ...state,
        schoolStates: action.payload,
      };
    case "GET_CITY_BY_STATE":
      return {
        ...state,
        cities: action.payload,
      };
    case "GET_COUNTY_BY_STATE":
      return {
        ...state,
        counties: action.payload,
      };
    case "GET_SPORTS":
      return {
        ...state,
        sports: action.payload,
      };
    case "TIME_SLOTS":
      return {
        ...state,
        timeSlots: action.payload,
      };
    case "GET_TOPICS":
      return {
        ...state,
        topics: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default athleteReducer;
