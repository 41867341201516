import axios from "axios";
import firebase from "../../config/firebase";
import { toast } from "react-toastify";
export const fetchHighRespondedAthletes =
  (lastDocument, onComplete = () => {}) =>
  async (dispatch) => {
    let libraryData = firebase.firestore().collection("a4b_library_stats");
    if (lastDocument != "") {
      libraryData = libraryData.startAfter(lastDocument);
    }
    libraryData.limit(5).onSnapshot(async (query) => {
      let temp_data = [];
      for await (let doc of query.docs) {
        let user = await firebase
          .firestore()
          .collection("users")
          .doc(doc.data().athleteID)
          .get();
        temp_data.push({
          ...user.data(),
          athleteId: user.data()?.athleteID,
          ...doc.data(),
        });
      }
      let hasMoreDocument = temp_data.length >= 5;
      if (lastDocument != "") {
        dispatch({
          type: "HIGH_RESPONDED-ATHLETE",
          payload: {
            data: temp_data,
            hasMoreDocument: hasMoreDocument,
            lastDocument: query.docs[temp_data.length - 1],
          },
        });
      } else {
        dispatch({
          type: "HIGH_RESPONDED-ATHLETE-EMPTY",
          payload: {
            data: temp_data,
            hasMoreDocument: hasMoreDocument,
            lastDocument: query.docs[temp_data.length - 1],
          },
        });
      }
      onComplete();
    });
  };

export const getFanLibraryStates =
  (lastDocument, onComplete = () => {}) =>
  async (dispatch) => {
    let fanLibraryData = firebase
      .firestore()
      .collection("a4b_fan_library_stats")
      .orderBy("total_amount", "desc");
    if (lastDocument != "") {
      fanLibraryData = fanLibraryData.startAfter(lastDocument);
    }
    fanLibraryData.limit(5).onSnapshot(async (query) => {
      let temp_data = [];
      for await (let doc of query.docs) {
        let user = await firebase
          .firestore()
          .collection("users")
          .doc(doc.data().fanId)
          .get();
        temp_data.push({
          amount: doc.data().total_amount,
          lastBagBought: doc.data().update_date,
          fanId: user.id,
          user: user.data(),
        });
      }
      let hasMoreDocument = temp_data.length >= 5;
      if (lastDocument != "") {
        dispatch({
          type: "FAN_LIBRARY_STATES",
          payload: {
            data: temp_data,
            hasMoreDocument: hasMoreDocument,
            lastDocument: query.docs[temp_data.length - 1],
          },
        });
      } else {
        dispatch({
          type: "FAN_LIBRARY_STATES_EMPTY",
          payload: {
            data: temp_data,
            hasMoreDocument: hasMoreDocument,
            lastDocument: query.docs[temp_data.length - 1],
          },
        });
      }
      onComplete();
    });
  };
export const isAthleteRequest = (id) => (dispatch) => {
  firebase
    .firestore()
    .collection("athlete_connection_request")
    .where("athleteId", "==", id)
    .onSnapshot(async (query) => {
      let temp_data = [];
      for await (let doc of query.docs) {
        temp_data.push(doc.data().fanId);
      }
      dispatch({
        type: "IS_ATHLETE_REQUEST",
        payload: temp_data,
      });
    });
};
export const athleteRequestFanOnStates =
  (obj, onSuccess = () => {}) =>
  async () => {
    let newObj = {
      fanId: obj.fanID,
      athleteId: obj.athleteID,
      created_at: firebase.firestore.Timestamp.now(),
    };
    const athleteResponseEmailNew = `An athlete ${obj.athleteName} has reached out to talk to you. Please get back to them on ${obj.athleteEmail}`;
    const options = {
      to: obj?.fanEmail,
      subject: "Athlete Reached Out",
      text: athleteResponseEmailNew,
    };
    firebase
      .firestore()
      .collection("athlete_connection_request")
      .add(newObj)
      .then(() => {
        axios
          .post(
            "https://us-central1-fans-help-players.cloudfunctions.net/app/sendUserEmail",
            options
          )
          .then((response) => {
            toast.success("Email Sent Successfully");
            onSuccess();
          })
          .catch((error) => {
            toast.error("Error occured try Again !");
          });
      });
  };
