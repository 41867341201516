import AthletePaymentStates from "./AthletePaymentStates";
import ConfirmChat from "./ConfirmGroupChat";
import GroupRefund from "./GroupRefund";
import SingleRefund from "./SingleRefund";
const repositories = {
  athleteStats: AthletePaymentStates,
  confirmChat: ConfirmChat,
  groupRefund: GroupRefund,
  singleRefund: SingleRefund,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
