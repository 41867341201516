import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import firebase from "../config/firebase";
import {
  chatBooking,
  createChat,
  getAvailableslotsByDate,
  getSlotByIdUrl,
} from "../store/actions/chatAction";
import { toast } from "react-toastify";
import moment from "moment";
import { fetchTopics } from "../store/actions/athleteActions";
import { getHighpriorityQuestions } from "../store/actions/questionAnswerActions";
function AvailableSlots() {
  let history = useHistory();
  let dispatch = useDispatch();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectPath = urlParams.get("slotId");
  console.log(redirectPath);
  let auth = useSelector((state) => state.authUser);
  const highPriorityQuestions = useSelector(
    (state) => state.question?.highPriorityQuestions
  );
  const { timeSlots, reviewStats, topics, totalChat } = useSelector(
    (state) => state.chat
  );
  let { uid, user } = useSelector((state) => state.authUser);

  const [availableSlots, setAvailableSlots] = useState([]);
  const [modalForPayment, setModalForPayment] = useState(false);
  const [receiverId, setReceiverId] = useState("");
  const [chatSlot, setChatSlot] = useState("");
  const [chatMinutes, setChatMinutes] = useState("");
  const [otherTopic, setOtherTopic] = useState("");
  const [highPriorityQuestion, setHighPriorityQuestion] = useState(null);
  const [note, setNote] = useState("");
  const [check, setCheck] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleModalForPayment = async () => {
    if (modalForPayment) {
      setChatMinutes("");
      setSelectedOption("");
      setCheck(false);
    }
    setModalForPayment(!modalForPayment);
  };
  const getPaypalDetail = (athlete_chat_rate, chat_minutes) => {
    let obj = {
      time_slot: chatSlot.id,
      uid,
      highPriorityQuestion,
      note,
    };
    dispatch(
      chatBooking({
        obj,
        onSuccess: () => {
          redirectPath
            ? toast.success(
                ` ${athlete.first_name} 
            ${athlete.last_name} has agreed to chat with you. Now you just fund your escrow payment,and you will be all set.`
              )
            : toast.success(
                ` ${location?.state?.athlete.first_name} 
            ${location?.state?.athlete.last_name} has agreed to chat with you. Now you just fund your escrow payment,and you will be all set.`
              );
        },
      })
    );
    let chatObj;
    if (selectedOption == "rsd3g0JfMBV4ucP0WkXx") {
      chatObj = {
        created_at: firebase.firestore.Timestamp.now(),
        participants: [receiverId, uid],
        athleteID: receiverId,
        fan_email: user.email,
        chat_topic: selectedOption,
        custom_topic: otherTopic,
        chat_minutes,
        athlete_chat_rate,
        status: "pending",
        type: "appointment",
        time_slot_id: chatSlot.id,
        athlete_email: chatSlot.athlete_email,
        time_zone: chatSlot.time_zone,
        is_reviewed_fan: false,
        is_reviewed_athlete: false,
        refund_request: false,
        athlete_payment: false,
        chat_start: firebase.firestore.Timestamp.fromDate(
          new Date(chatSlot.begin)
        ),
        chat_end: firebase.firestore.Timestamp.fromDate(
          new Date(chatSlot.stop)
        ),
      };
    } else {
      chatObj = {
        created_at: firebase.firestore.Timestamp.now(),
        participants: [receiverId, uid],
        athleteID: receiverId,
        fan_email: user.email,
        chat_topic: selectedOption,
        status: "pending",
        type: "appointment",
        time_slot_id: chatSlot.id,
        athlete_email: chatSlot.athlete_email,
        chat_minutes,
        athlete_chat_rate,
        time_zone: chatSlot.time_zone,
        is_reviewed_fan: false,
        is_reviewed_athlete: false,
        refund_request: false,
        athlete_payment: false,
        chat_start: firebase.firestore.Timestamp.fromDate(
          new Date(chatSlot.begin)
        ),
        chat_end: firebase.firestore.Timestamp.fromDate(
          new Date(chatSlot.stop)
        ),
      };
    }
    let msgObj = {
      message: "Hi there!",
      sender_id: uid,
      read: false,
    };
    dispatch(createChat(chatObj, msgObj));
    history.push({
      pathname: `/chat`,
      search: "?query=abc",
      state: { tab: "pending" },
    });
  };
  const [athlete, setAthlete] = useState({});
  useEffect(() => {
    if (timeSlots.length > 0) {
      setAvailableSlots(timeSlots);
    }
  }, [timeSlots]);
  useEffect(() => {
    if (redirectPath) {
      dispatch(
        getSlotByIdUrl(redirectPath, (res) => {
          if (res.isData) {
            let isClosedChat = moment().isAfter(
              moment(
                moment(`${res.data.date} ${res.data.start}`, "YYYY-MM-DD HH:mm")
                  .subtract(48, "hours")
                  .format("YYYY-MM-DD HH:mm"),
                "YYYY-MM-DD HH:mm"
              )
            );
            if (isClosedChat) {
              toast.error("chat was closed");
              history.push("/dashboard");
            } else {
              setChatSlot({
                id: res.id,
                begin: `${res.data.date} ${res.data.start}:0`,
                stop: `${res.data.date} ${res.data.end}:0`,
                athlete_email: res.data.athlete_email,
                time_zone: res.data.time_zone,
              });
              setAthlete(res.athlete);
              handleModalForPayment();
            }
            //   ? toast.error("chat was closed")
            //   : setChatSlot({
            //       id: res.id,
            //       begin: `${res.data.date} ${res.data.start}:0`,
            //       stop: `${res.data.date} ${res.data.end}:0`,
            //       athlete_email: res.data.athlete_email,
            //       time_zone: res.data.time_zone,
            //     });
            // handleModalForPayment();
          }
        })
      );
    } else {
      setReceiverId(location?.state?.id);
      dispatch(
        getAvailableslotsByDate(location?.state?.id, location?.state?.date)
      );
    }
  }, []);
  useEffect(() => {
    dispatch(fetchTopics());
    dispatch(getHighpriorityQuestions(auth.uid));
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <div>
        <h3 className="text-center">
          Available time slots of {location?.state?.athlete?.first_name}{" "}
          {location?.state?.athlete?.last_name}
        </h3>
      </div>
      <div className="d-flex justify-content-between">
        <h5>Date: {location?.state?.date}</h5>
        <ul className="text-danger">
          <li>S= Satisfied</li>
          <li>U-NS= Unsatisfied No Show</li>
          <li>U-RST= Unsatisfied Rude / Short Time</li>
        </ul>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="text-center">Start time</th>
            <th className="text-center">End time</th>
            <th>Time zone</th>
            <th>Email</th>
            <th className="text-center">Reviews</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {availableSlots?.map((ele, index) => {
          return (
            <tbody key={index}>
              <tr>
                <td className="text-center">
                  {ele?.start?.split(":")[0] >= "12"
                    ? `${
                        ele?.start.split(":")[0] - 12 == 0
                          ? 12
                          : ele?.start.split(":")[0] - 12
                      }:${ele?.start.split(":")[1]} PM`
                    : `${ele?.start.split(":")[0]}:${
                        ele?.start.split(":")[1]
                      } AM`}
                </td>
                <td className="text-center">
                  {ele.end.split(":")[0] >= "12"
                    ? `${
                        ele?.end.split(":")[0] - 12 == 0
                          ? 12
                          : ele?.end.split(":")[0] - 12
                      }:${ele?.end.split(":")[1]} PM`
                    : `${ele?.end.split(":")[0]}:${ele?.end.split(":")[1]} AM`}
                </td>
                <td>{ele?.time_zone}</td>
                <td>{ele?.athlete_email}</td>
                <td className="text-center">
                  Athlete has{" "}
                  {reviewStats?.satisfied ? reviewStats?.satisfied : 0} S,{" "}
                  {reviewStats?.NS ? reviewStats?.NS : 0} U-NS and{" "}
                  {reviewStats?.RST ? reviewStats?.RST : 0} U-RST out of{" "}
                  {totalChat ? totalChat : 0} total chats
                </td>
                <td className="text-center">
                  <Button
                    disabled={
                      moment().isAfter(
                        moment(
                          moment(`${ele.date} ${ele.start}`, "YYYY-MM-DD HH:mm")
                            .subtract(48, "hours")
                            .format("YYYY-MM-DD HH:mm"),
                          "YYYY-MM-DD HH:mm"
                        )
                      )
                        ? true
                        : false
                    }
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setChatSlot({
                        id: ele.id,
                        begin: `${ele.date} ${ele.start}:0`,
                        stop: `${ele.date} ${ele.end}:0`,
                        athlete_email: ele.athlete_email,
                        time_zone: ele.time_zone,
                      });
                      handleModalForPayment();
                    }}
                  >
                    {moment().isAfter(
                      moment(
                        moment(`${ele.date} ${ele.start}`, "YYYY-MM-DD HH:mm")
                          .subtract(48, "hours")
                          .format("YYYY-MM-DD HH:mm"),
                        "YYYY-MM-DD HH:mm"
                      )
                    )
                      ? "Booking Closed"
                      : "Book"}
                  </Button>
                </td>
              </tr>
              <tr></tr>
            </tbody>
          );
        })}
      </table>
      <Modal
        size="xl"
        isOpen={modalForPayment}
        toggle={handleModalForPayment}
        centered
      >
        <ModalBody>
          <div className="d-flex justify-content-end">
            <div>
              <i onClick={handleModalForPayment} className="fas fa-times"></i>
            </div>
          </div>
          <Container>
            <Row className="justify-content-center">
              <Col md="10" className="ml-5 mb-5 ml-md-0">
                <>
                  <h2 className="mt-3 auth-label text-center">
                    Terms and Conditions of Use
                  </h2>
                  <br />
                  <p>
                    Users agree that Chat escrow will be settled according to
                    the following formula:<br></br>
                  </p>
                  <p>
                    If FAN selects Satisfactory as rating for Chat, or FAN does
                    not indicate any rating in 7 days after time set for Chat –
                    then Athlete receives 88% of escrow monies, Admin receives
                    12%.
                  </p>
                  <p>
                    If FAN selects Unsatisfactory as rating for Chat, and FAN
                    rates Athlete as NO SHOW, then Athlete receives 23% of
                    escrow monies, Admin receives 12%, and FAN receives refund
                    of 65% of escrow.
                  </p>
                  <p>
                    If FAN selects Unsatisfactory as rating for Chat, and FAN
                    rates Athlete as Rude/Short time, then Athlete receives 53%
                    of escrow monies, Admin receives 12%, and FAN receives
                    refund of 35% of escrow.
                  </p>
                  <p>
                    <b>
                      NOTE: Bonus of $2.50 goes to Teacher-athlete who books
                      chat regarding HIGH PRIORITY QUESTION.
                    </b>
                  </p>
                  <div className="d-flex mt-2 ml-4">
                    <Input
                      required
                      type="checkbox"
                      className="custom-checkbox"
                      checked={check}
                      onChange={(e) => setCheck(e.target.checked)}
                    />
                    <Label className="custom-checkbox-label text-dark">
                      I Agree.
                    </Label>
                  </div>
                  <Label>Select chat Topic</Label>
                  <div className="d-flex mb-3">
                    <select
                      className="form-control"
                      value={selectedOption}
                      onChange={handleDropdownChange}
                    >
                      <option value="">-- Select Topic --</option>
                      {topics &&
                        topics.map((topic) => {
                          return (
                            <option value={topic?.id}>{topic.name}</option>
                          );
                        })}
                    </select>
                    {selectedOption == "rsd3g0JfMBV4ucP0WkXx" ? (
                      <Input
                        className="form-control ml-2"
                        required
                        type="text"
                        value={otherTopic}
                        placeholder="Other Topic"
                        onChange={(e) => setOtherTopic(e.target.value)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <label htmlFor="highPriorityQuestion">
                    High priority question
                  </label>
                  <Input
                    id="highPriorityQuestion"
                    className="form-control"
                    type="select"
                    placeholder="Select high priority question"
                    value={highPriorityQuestion}
                    onChange={(e) => setHighPriorityQuestion(e.target.value)}
                  >
                    <option value="">Select high priority question</option>
                    {highPriorityQuestions?.map((item, id) => {
                      return (
                        <option key={id} value={item.id}>
                          {item.question}
                        </option>
                      );
                    })}
                  </Input>
                  <label htmlFor="add_note">Note</label>
                  <Input
                    id="add_note"
                    className="form-control"
                    type="textarea"
                    placeholder="Add note"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                  <Label className="text-danger">
                    *Chat minutes can not be more than available window*
                  </Label>
                  <div className="d-flex">
                    <Input
                      className="form-control"
                      min={0}
                      max={
                        (new Date(chatSlot.stop) - new Date(chatSlot.begin)) /
                        1000 /
                        60
                      }
                      type="number"
                      value={chatMinutes}
                      placeholder="Chat Minutes"
                      onChange={(e) => {
                        const value = e.target.value;
                        const intValue = parseInt(value);

                        if (!isNaN(intValue)) {
                          setChatMinutes(intValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        const isValidInput = !(
                          charCode > 31 &&
                          (charCode < 48 || charCode > 57)
                        );

                        if (!isValidInput) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <Button
                    disabled={
                      selectedOption == "" ||
                      (selectedOption == "rsd3g0JfMBV4ucP0WkXx" &&
                        otherTopic == "") ||
                      !check ||
                      (chatMinutes &&
                      chatMinutes <=
                        (new Date(chatSlot.stop) - new Date(chatSlot.begin)) /
                          1000 /
                          60
                        ? false
                        : true)
                    }
                    className="btn btn-lg btn-block mt-4"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      redirectPath
                        ? getPaypalDetail(athlete?.chatRate, chatMinutes)
                        : getPaypalDetail(
                            location?.state?.athlete?.chatRate,
                            chatMinutes
                          );
                    }}
                  >
                    Submit Request
                  </Button>
                </>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default AvailableSlots;
