import React, { useState } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  acceptVolunteerRequest,
  getVolunteerRequest,
  setPermanentManager,
} from "../store/actions/favoriteAction";
import firebase from "../config/firebase";
import { toast } from "react-toastify";
import { addPermanentManager } from "../store/actions/managerAction";
const VolunteerRequest = () => {

  let url = useParams();
  let history = useHistory();
  let dispatch = useDispatch();

  // States
  const [isShow, setIsShow] = useState(false);
  
  // UseSelectors
  let { volunteerRequests } = useSelector((state) => state.favorite);
  
  // Functions
  const toggle = () => setIsShow(!isShow);

  const handelAccept = (items) => {
    let obj = {
      slotId: url.id,
      fanEmail: items.email,
      declinePayment: items.declinePayment,
    };
    if (items.permanentManager) {
      let newObj = {
        fanId: items.fanId,
        athleteId: items.athleteId,
        create_at: firebase.firestore.Timestamp.now(),
      };
      dispatch(addPermanentManager(newObj));
    }
    dispatch(
      acceptVolunteerRequest(obj, () => {
        toast.success("Add Manager successfully");
        const tabToActivate = "Group";
        history.push({
          pathname: `/time-slot`,
          search: `?tab=${tabToActivate}`,
        });
      })
      );
    };
    const handelPermanentManager = (id) => {
      dispatch(setPermanentManager(id));
    };
    
    // UseEffects Functions
    useEffect(() => {
      dispatch(getVolunteerRequest(url.id));
    }, []);

    return (
      <>
      <div className="text-center pt-3">
        <h1>Fan Request for Slot Manager Accept Only One Request</h1>
        <Container className=" justify-content-center mt-2">
          <Row className="justify-content-center">
            <Col xs={12} lg={12} className="text-center">
              <Card className="p-3 mb-3">
                {/* {loader ? ( */}
                <>
                  <Table className="table table_library" responsive>
                    <thead>
                      <tr>
                        <th>Fan Name </th>
                        <th>Fan Email</th>
                        <th>Manager Questions</th>
                        <th>Decline Payment</th>
                        <th>Add Permanent Manager</th>
                        <th>Manager reviews</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {volunteerRequests.map((items, index) => {
                        return (
                          <tr key={index}>
                            <td>{items.name}</td>
                            <td>{items.email}</td>
                            <td>
                              <Button color="primary" size="sm" onClick={() => toggle()}>
                                view
                              </Button>
                            </td>
                            <td>{items.declinePayment ? "true" : "false"}</td>
                            <td>
                              {" "}
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={items.permanentManager}
                                  onChange={() =>
                                    handelPermanentManager(items.id)
                                  }
                                />
                                {items.permanentManager ? (
                                  <span className="slider round text-align-start">
                                    yes
                                  </span>
                                ) : (
                                  <span className="slider round text-align-end">
                                    no
                                  </span>
                                )}
                              </label>
                            </td>
                            <td>
                              {items.name}{" "}
                              {items.counts?.satisfied
                                ? items.counts?.satisfied
                                : 0}{" "}
                              S, {items.counts?.NS ? items.counts?.NS : 0} U-NS
                              and {items.count?.RST ? items.count?.RST : 0}{" "}
                              U-RST out of {items.totalChat} total chats
                              managers
                            </td>
                            <td>
                              <Button
                                color="primary"
                                onClick={() => handelAccept(items)}
                              >
                                Accept
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {/* <div className="mt-3">
                {BtnLoader ? (
                  <i className="spinner-border text-primary mb-3"></i>
                ) : (
                  <Button
                    color="primary"
                    onClick={loadMore}
                    disabled={!moreData}
                  >
                    Load more
                  </Button>
                )}
              </div> */}
                </>
                {/* ) : (
            <div className="spinner-border text-primary loader mx-auto"></div>
          )} */}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={isShow} toggle={toggle} size="lg" centered>
        <CardHeader className="border-bottom-0 bg-transparent">
          <h4>Manager Questions</h4>
        </CardHeader>
        {volunteerRequests.map((items, index) => {
          return (
            <ModalBody key={index} className="px-md-4">
              <h6 className="mb-0">a. For 1 on 1 Chat, my experience includes:</h6>
              <small><b>Ans: </b>{items?.questionA}</small>
              <h6 className="mb-0">b. For Group Chat, my experience includes:</h6>
              <small><b>Ans: </b>{items?.questionB}</small>
              <h6 className="mb-0">c. For Mementos, my experience includes:</h6>
              <small><b>Ans: </b>{items?.questionC}</small>
              <h6 className="mb-0">d. For Talking Badges, my experience includes:</h6>
              <small><b>Ans: </b>{items?.questionD}</small>
              <h6 className="mb-0">
                e. For Seasonal Online Video Views and Seasonal Badges/Cards, my
                experience includes:
              </h6>
              <small><b>Ans: </b>{items?.questionE}</small>
            </ModalBody>
          );
        })}
        <CardFooter className="text-right border-top-0 bg-transparent">
          <Button color="primary" onClick={() => toggle()}>
            Close
          </Button>
        </CardFooter>
      </Modal>
    </>
  );
};

export default VolunteerRequest;
