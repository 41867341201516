import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col, Button, Modal, ModalBody } from "reactstrap";
import Image from "../assets/img/Picture1.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useState } from "react";
function ProfileCard() {
  let { user } = useSelector((state) => state.authUser);
  const [isShow, setIsShow] = useState(false);
  const toggle = () => {
    setIsShow(!isShow);
  };
  return (
    <>
      {user?.role == "fan" ? (
        <Container fluid className="m-0 Dashboard-container">
          <Row>
            <Col sm="12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-center">Presented by:</h5>
                  <img className="img-fluid" src={Image} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}

      <Container fluid className="m-0 Dashboard-container">
        <Row>
          <Col sm="12">
            <div className="card">
              <div className="card-body d-flex flex-column justify-content-between">
                <div>
                  {user?.role == "athlete" ? (
                    <div className="text-right">
                      <i
                        className="fa fa-info cursor pointer text-primary mr-3"
                        onClick={toggle}
                      ></i>
                      <Link
                        to="/athlete-account-monthly"
                        className="text-white text-decoration-none btn btn-primary px-1 py-0 mr-3"
                      >
                        Transaction log
                      </Link>
                      <Link
                        to="/annual-earning"
                        className="text-white text-decoration-none btn btn-primary px-1 py-0 mr-3"
                      >
                        My Account
                      </Link>
                    </div>
                  ) : null}
                  <h5 className="card-title">
                    Name:{" "}
                    {user?.role == "athlete"
                      ? `${user?.first_name} ${user?.last_name}`
                      : user?.name}
                  </h5>
                  <h5 className="card-title">Email: {user?.email}</h5>
                </div>
                <div>
                  {user?.role == "athlete" ? (
                    <div className="text-right">
                      <Link
                        to="/home-for-athlete"
                        className="text-white text-decoration-none"
                      >
                        <Button color="primary" className="px-3 py-2 py-0 mr-3">
                          Back to INFO page
                        </Button>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={isShow} toggle={toggle} size="sm" left>
        <ModalBody>
          <p>
            Badge data fills in immediately. Chat data takes few hours (S, U-NS,
            U-RST) to a week (No rating data)
          </p>
          <div className="text-right">
            <Button color="primary" onClick={() => toggle()}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ProfileCard;
