const initialState = {
  highRespondedAthletes: [],
  hasMoreDocument: true,
  fanHasMoreDocument: true,
  lastDocument: "",
  fanLastDocument: "",
  fanStates: [],
  isAthleteRequestFan: [],
};

const libraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HIGH_RESPONDED-ATHLETE":
      return {
        ...state,
        highRespondedAthletes: [
          ...state.highRespondedAthletes,
          ...action.payload.data,
        ],
        hasMoreDocument: action.payload.hasMoreDocument,
        lastDocument: action.payload.lastDocument,
      };
    case "HIGH_RESPONDED-ATHLETE-EMPTY":
      return {
        ...state,
        highRespondedAthletes: action.payload.data,
        hasMoreDocument: action.payload.hasMoreDocument,
        lastDocument: action.payload.lastDocument,
      };
    case "FAN_LIBRARY_STATES":
      return {
        ...state,
        fanStates: [...state.fanStates, ...action.payload.data],
        fanHasMoreDocument: action.payload.hasMoreDocument,
        fanLastDocument: action.payload.lastDocument,
      };
    case "FAN_LIBRARY_STATES_EMPTY":
      return {
        ...state,
        fanStates: action.payload.data,
        fanHasMoreDocument: action.payload.hasMoreDocument,
        fanLastDocument: action.payload.lastDocument,
      };
    case "IS_ATHLETE_REQUEST":
      return {
        ...state,
        isAthleteRequestFan: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default libraryReducer;
