import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Container,
} from "reactstrap";
import firebase from "../config/firebase";
import timeZoneImg from "../assets/img/time map img.jpeg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addAthleteTimeSlot,
  checkManagerReviews,
  editAthleteTimeSlot,
  fanRequestedEmail,
  sendEmailFanForGroupSlot,
  sendEmailFanForVolunteerGroupSlot,
} from "../store/actions/chatAction";
import GirlPicture from "../assets/img/girlPicture.jpg";

const CreateSlotsModal = (props) => {
  let dispatch = useDispatch();
  // alert(`${props.edit ? "Edit" : "Add"}`);
  let { uid, user } = useSelector((state) => state.authUser);
  const [slotDate, setSlotDate] = useState("");
  const [slotType, setSlotType] = useState("");
  const [managerType, setManagerType] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [permanentManager, setPermanentManager] = useState(false);
  const [chatAmount, setChatAmount] = useState("");
  const [zoomLink, setZoomLink] = useState("");
  // const [numberOfParticipants, setNumberOfParticipants] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [fullDay, setFullDay] = useState(false);
  const [standardTimeZOne, setstandardTimeZone] = useState("");
  const [customEmail, setCustomEmail] = useState();
  const [GMTDiff, setGMTDiff] = useState(0);
  const [email, setEmail] = useState("primary");
  const [slotId, setSlotId] = useState("");
  const [athleteId, setAthleteId] = useState("");
  const [athleteEmail, setAthleteEmail] = useState("");
  const [seeReview, setSeeReview] = useState(false);
  const [modal, setModal] = useState(false);
  const Modaltoggle = () => setModal(!modal);

  useEffect(() => {
    setCustomEmail(props.athleteEmail);
    setAthleteEmail(props.athleteEmail);
    setAthleteId(props.athleteId);
    if (props.slotType == "fan") {
      setSlotType("group");
      setManagerType("External");
      setManagerEmail(props.fanEmail);
    }
  }, [props]);
  useEffect(() => {
    setSeeReview(false);
    // if (!props.edit) {
    //   setSlotType("");
    //   setManagerType("");
    //   setManagerEmail("");
    //   // setNumberOfParticipants("");
    //   setChatAmount("");
    //   setZoomLink("");
    //   setPermanentManager(false);
    // }
  }, []);
  useEffect(() => {
    if (props.edit) {
      setSlotId(props.editData.id);
      setSlotDate(props.editData.date);
      setEndTime(props.editData.end);
      setStartTime(props.editData.start);
      setSlotType(props.editData.slotType);
      setstandardTimeZone(props.editData.time_zone);
      if (props.editData.slotType === "group") {
        setManagerType(props.editData.managerType);
        // setNumberOfParticipants(props.editData.numberOfParticipants);
        setChatAmount(props.editData.perParticipantAmount);
        setZoomLink(props.editData.zoom_link);
        if (props.editData.managerEmail) {
          setManagerEmail(props.editData.managerEmail);
        }
      }
    }
  }, [props.editData]);
  const handleClick = () => {
    setFullDay(!fullDay);
  };
  const handleAddNewTimeSlot = () => {
    let payload = {
      athleteName: user.first_name + " " + user.last_name,
      id: athleteId,
    };
    if (fullDay) {
      const currentTime = moment(); // Get the current time

      const start = currentTime.format("HH:mm"); // Set the start time to the current time
      const end = currentTime.add(24, "hours").format("HH:mm"); // Add 24 hours to the current time for the end time

      let obj = {
        athleteId: athleteId,
        date: slotDate,
        start,
        end,
        status: "notBooked",
        type: "appointment",
        athlete_email: customEmail,
      };

      if (standardTimeZOne === "GMT") {
        obj.time_zone = `${standardTimeZOne}${
          GMTDiff > 0 ? `+${GMTDiff}` : GMTDiff
        }`;
      } else {
        obj.time_zone = standardTimeZOne;
      }
      if (props.edit) {
        dispatch(editAthleteTimeSlot(obj, slotId));
      } else {
        dispatch(
          addAthleteTimeSlot(obj, () => {
            dispatch(fanRequestedEmail(payload));
          })
        );
      }
      setSlotDate("");
      setEndTime("");
      setStartTime("");
      setstandardTimeZone("");
      setGMTDiff("");
      setEmail("primary");
      setCustomEmail("");
      props.handleModalTimeSlots();
      // setModalSetTimeSlot(!modalSetTimeSlot);
    } else {
      const start = moment(`${slotDate} ${startTime}`, "YYYY-MM-DD HH:mm");
      const end = moment(`${slotDate} ${endTime}`, "YYYY-MM-DD HH:mm");
      let objStart = start.toDate();
      let objEnd = end.toDate();

      if (start.isSameOrBefore(end) && !start.isSame(end)) {
        let obj = {
          athleteId: athleteId,
          startTime: firebase.firestore.Timestamp.fromDate(objStart),
          endTime: firebase.firestore.Timestamp.fromDate(objEnd),
          date: slotDate,
          start: startTime,
          end: endTime,
          status: "notBooked",
          type: "appointment",
          athlete_email: customEmail,
          slotType: slotType,
        };
        console.log(obj, "objfromgroup");
        if (slotType === "group") {
          // obj.chatAmount = Number(chatAmount);
          obj.numberOfParticipants = 0;
          obj.managerType = managerType;
          obj.perParticipantAmount = Number(chatAmount);
          obj.zoom_link = zoomLink;
          obj.createdBy = props.slotType;
          if (managerType === "External") {
            obj.permanentManager = permanentManager;
            obj.managerEmail = managerEmail;
          }
        }
        if (standardTimeZOne === "GMT") {
          obj.time_zone = `${standardTimeZOne}${
            GMTDiff > 0 ? `+${GMTDiff}` : GMTDiff
          }`;
        } else {
          obj.time_zone = standardTimeZOne;
        }

        if (props.edit) {
          dispatch(
            editAthleteTimeSlot(obj, slotId, () => {
              props.handleModalTimeSlots();
            })
          );
        } else {
          dispatch(
            addAthleteTimeSlot(obj, (obj) => {
              dispatch(fanRequestedEmail(payload));
              if (slotType === "group") {
                dispatch(sendEmailFanForGroupSlot(user));
              }
              if (obj.managerType == "Volunteer") {
                dispatch(sendEmailFanForVolunteerGroupSlot(user));
              }
              // setSlotType("");
              // setManagerEmail("");
              // setManagerType("");
              // setNumberOfParticipants("");
              // setChatAmount("");
              // setZoomLink("");
              // setSlotDate("");
              // setEndTime("");
              // setStartTime("");
              // setstandardTimeZone("");
              // setGMTDiff("");
              // setEmail("primary");
              // setCustomEmail("");
              props.handleModalTimeSlots();
            })
          );
        }
        // setModalSetTimeSlot(!modalSetTimeSlot);
      } else {
        toast.error(
          "Be aware! End time cannot be the same or before the start time"
        );
      }
    }
  };
  const { getManagerReviews, isUserExist, totalChat, name } = useSelector(
    (state) => state.chat
  );

  const getReviews = (e) => {
    e.preventDefault();
    dispatch(
      checkManagerReviews(managerEmail, () => {
        setSeeReview(isUserExist);
      })
    );
  };
  return (
    <>
      <Modal
        isOpen={props.modalSetTimeSlot}
        toggle={props.handleModalTimeSlots}
        centered
      >
        <ModalBody>
          <div className="d-flex justify-content-end">
            <div>
              <i
                onClick={props.handleModalTimeSlots}
                className="fas fa-times cursor-pointer"
              ></i>
            </div>
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddNewTimeSlot();
            }}
          >
            <div>
              <h3 className="text-center">New Availabity Window</h3>
            </div>
            <div className="text-center">
              <img
                src={timeZoneImg}
                style={{ width: "50%" }}
                alt="Time Zone USA img"
              />
            </div>

            <div className="">
              <Label className="labelText mt-3">Select a Date:</Label>
            </div>
            <Input
              required
              className="mt-2 ChatTextArea"
              type="date"
              min={moment().toISOString().substring(0, 10)}
              value={slotDate}
              onChange={(e) => setSlotDate(e.target.value)}
            />
            <Row>
              {props.slotType == "fan" ? null : (
                <Col md="6">
                  <div className="">
                    <Label className="labelText mt-3">Select Slot type</Label>
                  </div>
                  <select
                    className="form-control"
                    value={slotType}
                    onChange={(e) => setSlotType(e.target.value)}
                  >
                    <option value="">-- Select --</option>
                    <option value="single">Single</option>
                    <option value="group">Group</option>
                  </select>
                </Col>
              )}
              {slotType == "group" ? (
                <>
                  {props.slotType == "fan" ? null : (
                    <Col md="6">
                      <div className="">
                        <Label className="labelText mt-3">
                          Select Manager type
                        </Label>
                      </div>
                      <select
                        required
                        className="form-control"
                        value={managerType}
                        onChange={(e) => {
                          Modaltoggle();
                          setManagerType(e.target.value);
                        }}
                      >
                        <option value="">-- Select --</option>
                        <option value="Self">Self</option>
                        <option value="External">
                          Choose From Registered Fans
                        </option>
                        <option value="Volunteer">Ask For Volunteer</option>
                      </select>
                    </Col>
                  )}
                  {managerType == "External" && props.slotType == "athlete" ? (
                    <Col md="6">
                      <div className="">
                        <Label className="labelText mt-3">
                          Add Manager Email
                        </Label>
                      </div>
                      <Input
                        required
                        type="email"
                        value={managerEmail}
                        onChange={(e) => setManagerEmail(e.target.value)}
                      />
                      <div>
                        <Input
                          type="checkbox"
                          className="custom-checkbox m-0 position-relative cursor-pointer"
                          checked={permanentManager}
                          onChange={(e) =>
                            setPermanentManager(e.target.checked)
                          }
                        />
                        <label>
                          <small> Add Permanent Manager</small>
                        </label>
                      </div>
                      <div>
                        <Button color="primary" onClick={getReviews}>
                          Check manager rating
                        </Button>
                        {seeReview && (
                          <p>
                            <small>
                              {name} is{" "}
                              {getManagerReviews?.satisfied
                                ? getManagerReviews?.satisfied
                                : 0}{" "}
                              S,{" "}
                              {getManagerReviews?.NS
                                ? getManagerReviews?.NS
                                : 0}{" "}
                              U-NS and{" "}
                              {getManagerReviews?.RST
                                ? getManagerReviews?.RST
                                : 0}{" "}
                              U-RST out of {totalChat ? totalChat : 0} total
                              chats manage
                            </small>
                          </p>
                        )}
                      </div>
                    </Col>
                  ) : null}
                  <Col md="6">
                    <div className="">
                      <Label className="labelText mt-3">
                        Set per participant amount
                      </Label>
                    </div>
                    <Input
                      required
                      type="number"
                      min={0}
                      max={1000}
                      value={chatAmount}
                      onChange={(e) => setChatAmount(e.target.value)}
                    />
                  </Col>
                  <Col md="6">
                    <div className="">
                      <Label className="labelText mt-3">Add zoom link</Label>
                    </div>
                    <Input
                      required
                      type="text"
                      value={zoomLink}
                      onChange={(e) => setZoomLink(e.target.value)}
                    />
                  </Col>
                  {/* <Col md="6">
                  <div className="">
                    <Label className="labelText mt-3">Set Participants</Label>
                  </div>
                  <Input
                    required
                    type="number"
                    min={0}
                    max={100}
                    value={numberOfParticipants}
                    onChange={(e) => setNumberOfParticipants(e.target.value)}
                  />
                </Col> */}
                </>
              ) : null}
              <Col md="6">
                <div className="">
                  <Label className="labelText mt-3">Start Time:</Label>
                </div>
                <Input
                  className="form-control form-control-sm"
                  value={startTime}
                  onChange={(e) => {
                    setStartTime(e.target.value);
                  }}
                  type="time"
                  required
                  disabled={fullDay}
                />
              </Col>
              <Col md="6">
                <div className="">
                  <Label className="labelText mt-3">End Time:</Label>
                </div>
                <Input
                  className="form-control form-control-sm"
                  value={endTime}
                  onChange={(e) => {
                    const startT = startTime;
                    const endTime = e.target.value;
                    const format = "HH:mm";
                    const startMoment = moment(startT, format);
                    const endMoment = moment(endTime, format);
                    const timeDifference = endMoment.diff(
                      startMoment,
                      "minutes"
                    );
                    if (timeDifference > 0) {
                      if (timeDifference < 60) {
                        setEndTime(e.target.value);
                      } else {
                        toast.error("Please select time in between one hour");
                      }
                    } else {
                      toast.error(
                        "Be aware! End time can not be same or before start time"
                      );
                    }
                  }}
                  type="time"
                  required
                  disabled={fullDay}
                />
              </Col>
              <Col md="6">
                <div className="">
                  <Label className="labelText mt-3">Time Zone:</Label>
                </div>
                <select
                  className="form-control"
                  value={standardTimeZOne}
                  onChange={(e) => setstandardTimeZone(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  <option value="EST">Eastern Standard Time</option>
                  <option value="CST">Central Standard Time</option>
                  <option value="MT">Mountain Standard Time</option>
                  <option value="PT">Pacific Standard Time</option>
                  <option value="AKST">Alaska Standard Time</option>
                  <option value="HST">Hawaii-Aleutian</option>
                </select>
              </Col>

              <Col md="6">
                {standardTimeZOne == "GMT" ? (
                  <div className="">
                    <Label className="labelText mt-3">GMT Difference</Label>
                    <Input
                      className="form-control form-control-sm mt-1"
                      value={GMTDiff}
                      onChange={(e) => {
                        setGMTDiff(e.target.value);
                      }}
                      type="Number"
                      max={24}
                      min={-24}
                      required={standardTimeZOne == "GMT"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="">
                  <Label className="labelText mt-3">Email for Chat</Label>
                  <div className="radio mt-2">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="primary"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setCustomEmail(athleteEmail);
                        }}
                        checked={email == "primary" ? true : false}
                      />
                      <label className="form-check-label">Primary</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="custom"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setCustomEmail("");
                        }}
                        checked={email == "custom" ? true : false}
                      />
                      <label className="form-check-label">Custom Email</label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="">
                  <Label className="labelText mt-3">Email</Label>
                  <Input
                    disabled={email == "primary" ? true : false}
                    className="form-control form-control-sm mt-1"
                    value={customEmail}
                    onChange={(e) => {
                      setCustomEmail(e.target.value);
                    }}
                    type="email"
                    placeholder="abc@email.com"
                    required={email == "custom"}
                  />
                </div>
              </Col>
              <Col md={6} className="my-3">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    onClick={handleClick}
                    checked={fullDay}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Full Availability Day
                  </label>
                </div>
              </Col>
            </Row>
            <Button
              disabled={
                slotDate == ""
                  ? true
                  : false && startTime == ""
                  ? true
                  : false && endTime == ""
                  ? false
                  : true && standardTimeZOne == ""
                  ? true
                  : false && slotType == ""
                  ? true
                  : false
              }
              className="float-right mt-4"
              color="primary"
              type="submit"
            >
              {props.edit ? "Update" : "Add Window"}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal} toggle={Modaltoggle} size="xl">
        <Container className="px-2">
          <Row>
            <Col lg="8">
              <h3 className="text-center mt-4">The Role of Chat Manager</h3>
              <ul>
                <li>
                  {" "}
                  <strong className="text-underline"> Athletes get busy</strong>
                  , so they might ask you for help you can be paid a{" "}
                  <strong className="text-decoration-underline">
                    {" "}
                    10% share of the athletes share
                  </strong>
                </li>
                <li>
                  {" "}
                  You can indicate that you prefer to manage for no pay The
                  athlete can tell you what{" "}
                  <strong className="text-decoration-underline">
                    time slot to book{" "}
                  </strong>
                </li>
                <li>
                  {" "}
                  You book the slot and if you have a way to{" "}
                  <strong className="text-decoration-underline">
                    advertise to athletes fans about the chat schedule
                  </strong>
                  , please do so.{" "}
                </li>
                <li>
                  {" "}
                  you can handle any{" "}
                  <strong className="text-decoration-underline">
                    email communications
                  </strong>{" "}
                  such as letting the fans know if the schedule changes{" "}
                </li>
                <li>
                  {" "}
                  On the day of the chat, you can{" "}
                  <strong className="text-decoration-underline">
                    remind the athlete{" "}
                  </strong>
                  about the time, and you can attend the chat.{" "}
                </li>
                <li>
                  {" "}
                  You can
                  <strong className="text-decoration-underline">
                    {" "}
                    speak up during the chat
                  </strong>
                  , letting people know about the time, and you can attend the
                  chat.{" "}
                </li>
                <li>
                  {" "}
                  <strong className="text-decoration-underline">
                    {" "}
                    Answer any fans question{" "}
                  </strong>
                  about the process How fans can give a rating to the athlete.
                  Be proactive{" "}
                </li>
              </ul>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <img src={GirlPicture} alt="img" width="50%" />
            </Col>
          </Row>
        </Container>
        <div className="d-flex justify-content-end align-items-end mb-2 mr-4">
          <Button className="" color="primary" onClick={Modaltoggle}>
            Ok
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CreateSlotsModal;
