import React, { useState } from "react";
import defaultImg from "../assets/img/bgImg.jpg";
import {
  Button,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptChatById,
  checkSlotPriority,
  completeChatPayment,
  fanRejectPaymentTimeSlot,
  getAllChatsByIdAthlete,
  getAllChatsByIdFan,
  getChatRatingById,
  rejectChatById,
  upDateChat,
  upDateChatLoader,
} from "../store/actions/chatAction";
import mapImg from "../assets/img/time map img.jpeg";
import moment from "moment";
import ReviewModal from "../components/ReviewModal";
import { Rating } from "@mui/material";
import ChatDetailModal from "../components/ChatDetailModal";
import Paypal from "../components/Paypal";
import InstantChatModal from "../components/InstantChatModal";
import FiveWaysToStartChatModal from "../components/FiveWaysToStartChatModal";
import {
  checkAthleteReviews,
  checkFanReviews,
  sendEmailAthleteAccept,
  sendEmailFanPaymentOffPlatFormChat,
} from "../store/actions/offPlatformSiteAction";
import instructionRatingImg from "../assets/img/ratingImg.png";

const Chat = () => {
  const dispatch = useDispatch();
  const currentDate = moment();
  const [isChatReviewOpen, setIsChatReviewOpen] = useState(false);
  const [loaderMan, setLoaderMan] = useState(true);
  const [receiverId, setReceiverId] = useState("");
  const [chatDetail, setChatDetail] = useState("");
  const [showReview, setShowReview] = useState(false);
  const [loader, setLoader] = useState(true);
  const [showChatDetailModal, setShowChatDetailModal] = useState(false);
  const [chatID, setChatID] = useState();
  const [chat_id, setChat_ID] = useState();
  let { chats, loading, chatRatings, moreData, lastDocument } = useSelector(
    (state) => state.chat
  );
  const { uid, user } = useSelector((state) => state.authUser);
  const handleShowChatDetailModal = (ele) => {
    dispatch(checkSlotPriority(ele.time_slot_id));
    setChatDetail(ele);
    setShowChatDetailModal(!showChatDetailModal);
  };
  const handleShowReview = (participant, chatID, chat_id) => {
    setReceiverId(participant);
    setChatID(chatID);
    setChat_ID(chat_id);
    setShowReview(!showReview);
  };
  const [isChatRating, setIsChatRating] = useState(false);
  const [reviewLoader, setReviewLoader] = useState(false);
  const toggleRating = () => {
    setIsChatRating(!isChatRating);
  };
  const handelRating = (data) => {
    setReviewLoader(true);
    toggleRating();
    user?.role == "athlete"
      ? dispatch(
          checkFanReviews(data, () => {
            setReviewLoader(false);
          })
        )
      : dispatch(
          checkAthleteReviews(data, () => {
            setReviewLoader(false);
          })
        );
  };
  const {
    getFanReviews,
    getAthleteReviews,
    totalChat,
    totalAthleteChat,
    name,
  } = useSelector((state) => state.offPlatform);
  const toggle = () => {
    setIsChatReviewOpen(!isChatReviewOpen);
  };
  const [isShow, setIsShow] = useState(false);
  const toggleInstantChat = () => {
    setIsShow(!isShow);
  };

  const completePaypalPayment = (id, details, athlete) => {
    let payload = {
      athleteName:
        athlete?.chatUser.first_name + " " + athlete?.chatUser.last_name,
      athleteEmail: athlete.athlete_email,
      fanName: user.name,
    };
    dispatch(
      completeChatPayment(id, details, athlete, () => {
        dispatch(sendEmailFanPaymentOffPlatFormChat(payload));
      })
    );
  };
  useEffect(() => {
    if (user?.role == "athlete") {
      dispatch(
        getAllChatsByIdAthlete(uid, "", () => {
          setLoaderMan(false);
          setLoader(false);
        })
      );
    } else if (user?.role == "fan") {
      dispatch(
        getAllChatsByIdFan(uid, "", () => {
          setLoaderMan(false);
          setLoader(false);
        })
      );
    }
  }, []);
  const loadMore = (e) => {
    e.preventDefault();
    setLoader(true);

    if (user?.role == "athlete") {
      dispatch(
        getAllChatsByIdAthlete(uid, lastDocument, () => {
          setLoader(false);
        })
      );
    } else if (user?.role == "fan") {
      dispatch(
        getAllChatsByIdFan(uid, lastDocument, () => {
          setLoader(false);
        })
      );
    }
  };

  const sendEmail = (ele) => {
    let payload = {
      fanEmail: ele.fan_email,
      athleteName: user.first_name + " " + user.last_name,
      athleteID: user.athleteID,
    };
    dispatch(sendEmailAthleteAccept(payload));
  };
  return (
    <Container fluid>
      <Row noGutters>
        <Col align="center" className="mt-4 mb-2">
          <div className="ChatLeft">
            <Row noGutters>
              {user?.role == "athlete" ? null : (
                <div className="text-center w-100">
                  <img
                    src={instructionRatingImg}
                    // style={{ width: "30%" }}
                    alt="Time Zone Img USA"
                    className="instructionReviewImg"
                  />
                </div>
              )}
              <span className="text-danger w-100 text-center p-3">
                S= Satisfied, U-NS= Unsatisfied No Show & U-RST= Unsatisfied
                Rude / Short Time
                <br></br>
                To ensure smooth communication, kindly keep your chat partner
                informed about any time zone differences. This will help both of
                you stay synchronized and maintain effective interaction.
              </span>
              <span className="w-100 text-center">
                {user?.role == "athlete" ? (
                  <Button color="primary" onClick={() => toggleInstantChat()}>
                    FIVE WAYS TO START A CHAT
                  </Button>
                ) : null}
              </span>
              <span className="w-100 text-center my-3">
                <b>NOTE:</b>Bonus of $2.50 goes to Teacher-athlete who books
                chat regarding HIGH PRIORITY QUESTION.
              </span>
              <span className="w-100 text-center my-3">
                <b>NOTE:</b>
                {user?.role == "athlete" ? "Athlete" : "Fan"},if your schedule
                changes, please notify{" "}
                {user?.role == "athlete" ? "Fan" : "Athlete"}.
              </span>
              <div className="text-center w-100">
                <img
                  src={mapImg}
                  style={{ width: "20%" }}
                  alt="Time Zone Img USA"
                />
              </div>
              <Col sm="12">
                <div className="chat">
                  {loaderMan ? (
                    <div className="spinner-border text-primary loader"></div>
                  ) : (
                    <Table className="table" responsive>
                      <thead>
                        <tr>
                          <th> </th>
                          <th>Chat ID</th>
                          <th>
                            {" "}
                            {user?.role == "athlete"
                              ? "Request By"
                              : "Pending athlete booking"}
                          </th>
                          <th>
                            {user?.role == "athlete"
                              ? "Day Submitted"
                              : "Day for chat"}
                          </th>
                          <th>Chat Min/rate</th>
                          <th>Fan Email</th>
                          <th>Athlete Email</th>
                          <th className="text-center">Parties Arrange</th>
                          <th>Rating</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      {chats?.length > 0 &&
                        chats
                          .filter((chat) => {
                            if (
                              chat.status == "pending" &&
                              user?.role == "athlete"
                            ) {
                              return chat;
                            } else if (user?.role == "fan") {
                              return chat;
                            }
                          })
                          .map((ele, index) => {
                            const originalTimestamp = moment
                              .unix(ele.chat_start.seconds)
                              .add(ele.chat_start.nanoseconds, "nanoseconds");
                            // const newTimestamp = originalTimestamp.add(
                            //   7,
                            //   "days"
                            // );
                            const newTimestamp = originalTimestamp.add(
                              2,
                              "hours"
                            );
                            const isAfter7Days =
                              newTimestamp.isAfter(currentDate);
                            return (
                              <tbody key={index}>
                                <tr>
                                  <td className="text-center">
                                    <img
                                      className="chat-profile"
                                      src={
                                        ele.chatUser?.player_image
                                          ? ele.chatUser?.player_image.url
                                          : defaultImg
                                      }
                                    />
                                  </td>
                                  <td className="table-chat-request">
                                    {ele.chat_id ? ele.chat_id : "Not Assigned"}
                                  </td>
                                  <td className="table-chat-request">
                                    {ele.chatUser?.role == "athlete" ? (
                                      ele.chatUser?.first_name +
                                      " " +
                                      ele.chatUser?.last_name
                                    ) : (
                                      <div
                                        role="button"
                                        onClick={() => {
                                          dispatch(
                                            getChatRatingById(ele.partId)
                                          );
                                          toggle(
                                            ele.participants.filter(
                                              (ele) => ele != uid
                                            ),
                                            ele.id
                                          );
                                        }}
                                      >
                                        {ele.chatUser?.name}
                                      </div>
                                    )}
                                  </td>
                                  <td className="table-chat-request">
                                    {moment
                                      .unix(ele.chat_start.seconds)
                                      .format("MMM Do YY")}
                                  </td>
                                  <td className="table-chat-request">
                                    {ele.chat_minutes}min@$
                                    {ele.athlete_chat_rate}ea
                                  </td>
                                  <td className="table-chat-request">
                                    {ele.fan_email}
                                  </td>
                                  <td className="table-chat-request">
                                    {ele.athlete_email}
                                  </td>
                                  <td className="table-chat-request text-center">
                                    <p>time/date as PARTIES agree</p>
                                  </td>
                                  <td>
                                    {user?.role == "athlete" ? (
                                      <Button
                                        color="primary"
                                        onClick={() =>
                                          handelRating(ele.fan_email)
                                        }
                                      >
                                        Check Fan Reviews{" "}
                                      </Button>
                                    ) : (
                                      <Button
                                        color="primary"
                                        onClick={() =>
                                          handelRating(ele.athleteID)
                                        }
                                      >
                                        Check Athlete Reviews{" "}
                                      </Button>
                                    )}
                                  </td>
                                  <td className="table-chat-request">
                                    {ele.status == "complete"
                                      ? "escrow funded"
                                      : ele.status}
                                  </td>
                                  {user?.role == "athlete" ? (
                                    <td className="table-chat-request text-center">
                                      <Button
                                        className="w-100"
                                        color="primary"
                                        onClick={() => {
                                          handleShowChatDetailModal(ele);
                                        }}
                                      >
                                        <i className="fa fa-solid fa-eye"></i>
                                      </Button>
                                      <Button
                                        className="ml-0 mt-2"
                                        color="primary"
                                        disabled={ele.acceptLoader}
                                        onClick={() => {
                                          dispatch(
                                            upDateChatLoader(ele.id, "accept")
                                          );
                                          sendEmail(ele);
                                          dispatch(acceptChatById(ele.id));
                                        }}
                                      >
                                        {ele.acceptLoader ? (
                                          <Spinner size="sm" />
                                        ) : (
                                          <i className="fa fa-duotone fa-check"></i>
                                        )}
                                      </Button>
                                      <Button
                                        className="ml-1 mt-2"
                                        color="danger"
                                        disabled={ele.rejectLoader}
                                        onClick={() => {
                                          dispatch(
                                            upDateChatLoader(ele.id, "reject")
                                          );
                                          dispatch(
                                            rejectChatById({
                                              id: ele.id,
                                              timeSlotId: ele.time_slot_id,
                                            })
                                          );
                                        }}
                                      >
                                        {ele.rejectLoader ? (
                                          <Spinner size="sm" />
                                        ) : (
                                          <i className="fa fa-ban"></i>
                                        )}
                                      </Button>
                                    </td>
                                  ) : ele.status == "payment pending" ? (
                                    <td className="table-chat-request text-center d-flex align-items-center">
                                      <Button
                                        className="p-1 mt-3 mr-2 btn-sm mx-auto"
                                        color="primary"
                                        disabled={ele.rejectLoader}
                                        onClick={() => {
                                          dispatch(
                                            upDateChatLoader(ele.id, "reject")
                                          );
                                          dispatch(
                                            fanRejectPaymentTimeSlot(ele.id)
                                          );
                                        }}
                                      >
                                        {ele.rejectLoader ? (
                                          <Spinner size="sm" />
                                        ) : (
                                          "Reject"
                                        )}
                                      </Button>
                                      <div className="mt-3 ml-1 d-flex paypal_button-chat">
                                        <Paypal
                                          price={
                                            ele?.athlete_chat_rate *
                                              ele?.chat_minutes +
                                            0.5
                                          }
                                          completePaypalPayment={
                                            completePaypalPayment
                                          }
                                          chatId={ele.id}
                                          athelete={ele}
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    <td className="table-chat-request text-center">
                                      <Button
                                        disabled={
                                          ele.is_reviewed_fan ||
                                          ele.status !== "complete" ||
                                          !isAfter7Days
                                        }
                                        className="btn-sm mx-auto"
                                        color="primary"
                                        onClick={() => {
                                          handleShowReview(
                                            ele.participants.filter(
                                              (ele) => ele != uid
                                            ),
                                            ele.id,
                                            ele.chat_id
                                          );
                                        }}
                                      >
                                        {" "}
                                        Review Chat
                                      </Button>
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            );
                          })}
                    </Table>
                  )}
                </div>
                {loaderMan ? null : loader ? (
                  <i className="spinner-border text-primary mb-3"></i>
                ) : (
                  <Button
                    onClick={loadMore}
                    color="primary"
                    className="mb-3"
                    disabled={!moreData}
                  >
                    Load more
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Modal isOpen={isChatRating} toggle={toggleRating} size="md" centered>
        <ModalBody>
          {reviewLoader ? (
            <div className="text-center">
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : user?.role == "athlete" ? (
            <>
              <h2>Fan Name:{name}</h2>
              <b>Rating:</b> Fan wishing to chat with you has given{" "}
              {getFanReviews?.satisfied ? getFanReviews?.satisfied : 0} S,{" "}
              {getFanReviews?.NS ? getFanReviews?.NS : 0} U-NS and{" "}
              {getFanReviews?.RST ? getFanReviews?.RST : 0} U-RST out of{" "}
              {totalChat ? totalChat : 0} total chats
            </>
          ) : (
            <>
              <h2>Athlete Name:{name}</h2>
              <b>Rating:</b> Athlete {name} has received{" "}
              {getAthleteReviews?.satisfied ? getAthleteReviews?.satisfied : 0}{" "}
              S, {getAthleteReviews?.NS ? getAthleteReviews?.NS : 0} U-NS and{" "}
              {getAthleteReviews?.RST ? getAthleteReviews?.RST : 0} U-RST out of{" "}
              {totalAthleteChat ? totalAthleteChat : 0} total chats
            </>
          )}
          <div className="text-right">
            <Button color="primary" className="mt-3" onClick={toggleRating}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <ReviewModal
        showReviewModal={showReview}
        handleShowReview={() => {
          handleShowReview("", "");
        }}
        chatID={chatID}
        athleteId={receiverId[0]}
        chat_id={chat_id}
      ></ReviewModal>
      <Modal isOpen={isChatReviewOpen} toggle={toggle} size="lg" centered>
        <ModalBody>
          <div className="d-flex justify-content-end">
            <div>
              <i onClick={toggle} className="fas fa-times"></i>
            </div>
          </div>
          <Row className="justify-content-center">
            {chatRatings.length > 0 ? (
              chatRatings.map((ele, index) => {
                return (
                  <Col md="12" lg="12" key={index} className="mt-3">
                    <div className="complaint-card shadow d-flex ">
                      <div className="mt-4">
                        <Row className="request-text">
                          <Col
                            md="12"
                            className="d-flex justify-content-center"
                          >
                            <Label className="mt-2">Athlete Name:</Label>
                            <Label className=" ml-4 mt-2">
                              {ele.fan?.name}
                            </Label>
                          </Col>
                        </Row>
                        <Row className="request-text">
                          <Col md="12" className="d-flex jutify-content-center">
                            <Label className="mt-2 ml-3">Over All Rating</Label>
                            <Label className=" ml-2 mt-2">
                              <Rating
                                name="read-only"
                                value={parseFloat(ele.overAllRating)}
                                defaultValue={0}
                                precision={0.5}
                                readOnly
                              />
                            </Label>
                          </Col>
                          <Col md="12" className="d-flex text-center">
                            <Label className="mt-2 ml-3">knowledgeable</Label>
                            <Label className=" ml-2 mt-2">
                              <Rating
                                name="read-only"
                                value={parseFloat(ele.knowledgeable)}
                                defaultValue={0}
                                precision={0.5}
                                readOnly
                              />
                            </Label>
                          </Col>
                        </Row>
                        <Row className="request-text">
                          <Col md="6" className="d-flex text-center">
                            <Label className="mt-2 ml-3">personable</Label>
                            <Label className="mt-2">
                              <Rating
                                name="read-only"
                                value={parseFloat(ele.personable)}
                                defaultValue={0}
                                precision={0.5}
                                readOnly
                              />
                            </Label>
                          </Col>
                          <Col md="6" className="d-flex text-center">
                            <Label className="mt-2 ml-3">timely</Label>
                            <Label className=" mr-3 mt-2">
                              <Rating
                                name="read-only"
                                value={parseFloat(ele.timely)}
                                defaultValue={0}
                                precision={0.5}
                                readOnly
                              />
                            </Label>
                          </Col>
                        </Row>
                        <Row className="request-text">
                          <Col md="12" className="d-flex">
                            <Label className="mt-2 ml-3">Remarks:</Label>
                            <Label className=" ml-4 mt-2">
                              {ele?.reviewComment}
                            </Label>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <h1 className="text-center"> No Reviews Yet</h1>
            )}
          </Row>
        </ModalBody>
      </Modal>

      <FiveWaysToStartChatModal
        isShow={isShow}
        toggleInstantChat={toggleInstantChat}
      />
      <ChatDetailModal
        showInstruction={showChatDetailModal}
        handleShowInstruction={handleShowChatDetailModal}
        data={chatDetail}
      />
    </Container>
  );
};

export default Chat;
