import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Label,
  Modal,
  ModalBody,
  Form,
} from "reactstrap";
import firebase from "../config/firebase";
import { Link, useHistory } from "react-router-dom";
import timeZoneImg from "../assets/img/time map img.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { singleAthlete } from "../store/actions/athleteActions";
import { useParams } from "react-router-dom";
import {
  getAllslotsById,
  getAvailableSlots,
  getChatRatingById,
  setIsShowAppointment,
} from "../store/actions/chatAction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import {
  checkAthleteReviews,
  resetAthleteReviews,
} from "../store/actions/offPlatformSiteAction";
import ArrangeChatAppointmentModal from "../components/ArrangeChatAppointmentModal";
import { toast } from "react-toastify";
import {
  checkRequested,
  fanRequestedSlots,
} from "../store/actions/questionAnswerActions";
import {
  addFavoriteAthlete,
  isAthleteFavorite,
  removeFromFavorite,
} from "../store/actions/favoriteAction";

function BuyRights(props) {
  let history = useHistory();
  let ath = useSelector((state) => state.athlete);
  let auth = useSelector((state) => state.authUser);
  let { isFavorite } = useSelector((state) => state.favorite);
  const checkRequest = useSelector((state) => state.question.checkRequested);
  const { slots, availableSlots } = useSelector((state) => state.chat);
  const { getAthleteReviews, isAthleteUserExist, totalAthleteChat } =
    useSelector((state) => state.offPlatform);
  let dispatch = useDispatch();
  const params = useParams();
  let id = params?.id;
  let isRequestAthlete = checkRequest?.some((item) => item.athleteId === id);
  const [events, setEvents] = useState([]);
  const items = localStorage.getItem("teamId");
  const [modalForBookChat, setModalForBookChat] = useState(false);
  const [loader, setLoader] = useState(false);
  const [buttonShow, setShowButton] = useState(false);
  const handleModalForBookChat = () => {
    if (events.length == 0) {
      toast.warning(
        "Would you like an Instant Chat? (Chat by Appointment not Available)"
      );
      setShowButton(true);
    } else {
      setModalForBookChat(!modalForBookChat);
    }
  };
  const searchAvailableTime = (date) => {
    handleModalForBookChat();
    history.push({
      pathname: `/available-slots/${id}`,
      search: "?query=abc",
      state: { id, date, athlete: ath.athlete },
    });
  };
  useEffect(() => {
    let obj = {
      athleteId: id,
      fanId: auth.uid,
    };
    setShowButton(false);
    dispatch(isAthleteFavorite(obj));
    dispatch(checkRequested(auth.uid));
    dispatch(resetAthleteReviews());
    dispatch(singleAthlete(id));
    dispatch(getChatRatingById(id));
    dispatch(getAllslotsById(id));
    dispatch(getAvailableSlots(id));
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (availableSlots != null) {
      let temp = [];
      for (let index = 0; index < availableSlots.length; index++) {
        temp.push({
          allDay: true,
          start: `${availableSlots[index].date}`,
          end: `${availableSlots[index].date}`,
          display: "background",
          color: "green",
        });
      }
      setEvents(temp);
    }
  }, [availableSlots]);
  const checkAthleteReview = () => {
    setLoader(true);
    dispatch(
      checkAthleteReviews(id, () => {
        setLoader(false);
      })
    );
  };
  const moveOneDashBoard = () => {
    history.push("/dashboard");
    dispatch(setIsShowAppointment(true));
  };

  const sendEmail = () => {
    let obj = {
      athleteID: id,
      fanID: auth.uid,
      fanName: auth.user.name,
      athleteEmail: ath?.athlete?.email,
    };
    dispatch(
      fanRequestedSlots(obj, () => {
        dispatch(checkRequested(auth.uid));
      })
    );
  };
  const handelAdd = () => {
    let obj = {
      athleteId: id,
      fanId: auth.uid,
      created_at: firebase.firestore.Timestamp.now(),
      notification: true,
      volunteerNotification: false,
    };
    dispatch(addFavoriteAthlete(obj));
  };
  const handelRemove = () => {
    let obj = {
      athleteId: id,
      fanId: auth.uid,
    };
    dispatch(removeFromFavorite(obj));
  };
  return (
    <>
      <Container fluid className="my-5">
        <Row className="no-gutters justify-content-center">
          <Col lg="10">
            <div className="complaint-card shadow">
              <div className="complaint-form">
                <Row className="request-text">
                  <Col md="6">
                    <p className="auth-desc-subtitle mt-0 ml-0">
                      Hi Sports Fan, I am an amateur athlete
                    </p>
                  </Col>
                  <Col md="6" className="d-flex justify-content-end">
                    {isFavorite ? (
                      <>
                        <p>
                          Click heart to choose as Fav
                          <i
                            class="fa fa-solid fa-heart text-danger cursor-pointer ml-2"
                            onClick={handelRemove}
                          ></i>
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          Click heart to remove as Fav
                          <i
                            class="fa fa-light fa-heart cursor-pointer ml-2"
                            onClick={handelAdd}
                          ></i>
                        </p>
                      </>
                    )}
                  </Col>
                </Row>
                <div className="mt-4">
                  <Row className="request-text">
                    <Col md="6" className="d-flex">
                      <Label className="mt-2">My Name is:</Label>
                      <Label className="edit-password-info ml-4 mt-2">
                        {ath.athlete?.first_name} {ath.athlete?.last_name}
                      </Label>
                    </Col>
                    <Col md="6" className="d-flex">
                      <Label className="mt-2">My Player ID is:</Label>
                      <Label className="edit-password-info ml-4 mt-2">
                        {ath.athlete?.athleteID}
                      </Label>
                    </Col>
                  </Row>
                  <Row className="request-text">
                    <Col md="6" className="d-flex">
                      <Label className="mt-2">My School Location is:</Label>
                      <Label className="edit-password-info ml-4 mt-2">
                        {ath.athlete?.city}, {ath.athlete?.state}
                      </Label>
                    </Col>
                    <Col md="6" className="d-flex">
                      <Label className="mt-2">Gender:</Label>
                      <Label className="edit-password-info ml-4 mt-2">
                        {ath.athlete.gender}
                      </Label>
                    </Col>
                  </Row>
                  <Row className="request-text">
                    <Col md="6" className="d-flex">
                      <Label className="mt-2">The sport(s) I play:</Label>
                      <Label className="edit-password-info ml-4 mt-2">
                        {ath.athlete?.sport}
                      </Label>{" "}
                    </Col>
                    <Col md="6" className="d-flex">
                      <Label className="mt-2">I am a:</Label>
                      <Label className="edit-password-info ml-4 mt-2">
                        {ath.athlete?.sportLevel}
                      </Label>
                    </Col>
                    <Col md="6" className="d-flex">
                      <Label className="mt-2">Amount per minute to chat:</Label>
                      <Label className="edit-password-info ml-4 mt-2">
                        $ {ath?.athlete?.chatRate}
                      </Label>
                    </Col>
                    <Col md="6">
                      <div className="d-flex">
                        <Label className="mt-2">
                          My email to arrange instant chat is:
                        </Label>
                        <Label className="edit-password-info ml-4 mt-2">
                          {ath?.athlete?.a4bEmail
                            ? ath?.athlete?.a4bEmail
                            : ath?.athlete?.email}
                        </Label>
                      </div>
                      <p>
                        <span className="text-danger">Note:</span>Write this
                        email down.You'll need it shortly.
                      </p>
                    </Col>
                  </Row>

                  {items && items != "null" ? (
                    <div className="d-flex justify-content-end ml-5">
                      <Link to={`/fan/result-display-details/${items}`}>
                        <Button className="mt-3 custom-btn">
                          Back To Team Roster
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}

                  <Row
                    className={`request-text mt-3 ${
                      !ath.athlete?.youtube_url && "d-none"
                    }`}
                  >
                    <Col>
                      <Label className="mt-2">
                        HI FANS - PLEASE VISIT MY ONLINE POSTS BY CLICKING HERE:
                      </Label>
                      <br />
                      <a
                        className="edit-password-info  pointer"
                        href={ath.athlete.youtube_url}
                        target="_blank"
                      >
                        {ath.athlete.youtube_url}
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="text-center mt-4 mb-5">
              <Button
                className="custom-btn px-5 mr-2"
                onClick={moveOneDashBoard}
              >
                Book an Instant Chat
              </Button>
              {buttonShow ? (
                <Button
                  className="custom-btn px-5 ml-2"
                  onClick={sendEmail}
                  disabled={isRequestAthlete}
                >
                  Request Timeslots
                </Button>
              ) : (
                <Button
                  className="custom-btn px-5 ml-2"
                  onClick={handleModalForBookChat}
                >
                  By Appt
                </Button>
              )}
              <div className="mt-3">
                {isAthleteUserExist ? (
                  <p className="text-center mt-2">
                    Athlete {ath.athlete.first_name} {ath.athlete.last_name} has
                    received{" "}
                    {getAthleteReviews?.satisfied
                      ? getAthleteReviews?.satisfied
                      : 0}{" "}
                    S, {getAthleteReviews?.NS ? getAthleteReviews?.NS : 0} U-NS
                    and {getAthleteReviews?.RST ? getAthleteReviews?.RST : 0}{" "}
                    U-RST out of {totalAthleteChat ? totalAthleteChat : 0} total
                    chats
                  </p>
                ) : (
                  <Button
                    color="primary"
                    onClick={checkAthleteReview}
                    disabled={loader}
                  >
                    {loader ? (
                      <div
                        class="spinner-border text-light size-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Check Athlete Reviews"
                    )}
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={modalForBookChat}
        toggle={handleModalForBookChat}
        size="lg"
        centered
      >
        <ModalBody>
          <div className="d-flex justify-content-end">
            <div>
              <i onClick={handleModalForBookChat} className="fas fa-times"></i>
            </div>
          </div>
          <Form className="p-5">
            <div>
              <div className="my-3">
                <h3 className="text-center">
                  CLICK DATE TO SEE HOURS OF AVAILABILITY of{" "}
                  {ath.athlete.first_name} {ath.athlete.last_name}
                </h3>
                <div className="text-center">
                  <img
                    src={timeZoneImg}
                    style={{ width: "50%" }}
                    alt="Time Zone USA img"
                  />
                </div>
              </div>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin]}
                initialView="dayGridMonth"
                events={events}
                eventClick={(event) => {
                  searchAvailableTime(
                    moment(event.event?.start).format("YYYY-MM-DD")
                  );
                }}
              />
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default BuyRights;
