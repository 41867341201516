import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Button,
  Table,
  Modal,
  ModalBody,
  Form,
  Spinner,
} from "reactstrap";
import ClickHere from "../assets/img/clickhere.png";
import { useSelector, useDispatch } from "react-redux";
import { getAtheletes, getTimeSlots } from "../store/actions/athleteActions";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { getSports } from "../store/actions/athleteActions";
import { fetchCollegeStates } from "../store/actions/athleteActions";
import { fetchSchoolStates } from "../store/actions/athleteActions";
import { getCollegeCity } from "../store/actions/athleteActions";
import { getSchoolCounty } from "../store/actions/athleteActions";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { sendEmailToAthleteNotification } from "../store/actions/questionAnswerActions";
import { isQuestionId } from "../store/actions/chatAction";

const CssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e7bf6",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#2e7bf6",
  },
});
const useStyles = makeStyles({
  auto_input: {
    width: "70%",
    paddingLeft: "10px",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  input: {
    color: "white",
  },
});

const BulletinBoards = () => {
  const classes = useStyles();
  let athlete = useSelector((state) => state.athlete);
  const { schoolStates, collegeStates, timeSlots } = useSelector(
    (state) => state.athlete
  );
  let { sports } = useSelector((state) => state.athlete);
  const { user } = useSelector((state) => state.authUser);
  const { replyLoader } = useSelector((state) => state.question);
  const { isQuestionIdExists } = useSelector((state) => state.chat);
  let dispatch = useDispatch();
  const [athletes, setAthletes] = useState([]);
  const [id, setId] = useState("");

  const [state, setState] = useState("");
  const [sport, setSport] = useState("");
  const [college, setCollege] = useState("");
  const [gender, setGender] = useState("");
  const [sportLevel, setSportLevel] = useState("");
  const [fanDescription, setFanDescription] = useState("");
  const [contactModal, setContactModal] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const [athleteId, setAthleteId] = useState("");
  const handleContactModal = () => setContactModal(!contactModal);

  const defaultSportLevelProps = {
    options: ["College Athlete", "H.S. Athlete"],
    getOptionLabel: (option) => option,
  };

  const defaultGenderProps = {
    options: ["Male", "Female"],
    getOptionLabel: (option) => option,
  };

  const defaultSportProps = {
    options:
      sports.length > 0 &&
      sports.map((item) => {
        return item.sport;
      }),
    getOptionLabel: (option) => option,
  };
  const defaultStateProps = {
    options: collegeStates?.map((insti) => {
      return insti.name;
    }),
    getOptionLabel: (option) => option,
  };

  useEffect(() => {
    if (collegeStates.length == 0) {
      dispatch(fetchCollegeStates());
    }
    if (schoolStates.length == 0) {
      dispatch(fetchSchoolStates());
    }
    localStorage.removeItem("teamId");
    localStorage.removeItem("uniqData");
    localStorage.removeItem("ByteamID");
    dispatch(getAtheletes());
    dispatch(getSports());
    dispatch(getTimeSlots());
  }, []);
  useEffect(() => {
    if (state != "") {
      if (sportLevel == "College Athlete") {
        dispatch(getCollegeCity(state));
      } else {
        dispatch(getSchoolCounty(state));
      }
    }
  }, [state]);
  useEffect(() => {
    if (questionId) {
      dispatch(isQuestionId(questionId));
    }
  }, [questionId]);

  useEffect(() => {
    if (athlete?.athletes.length > 0) {
      setAthletes(athlete?.athletes);
    }
  }, [athlete]);

  const handelClear = () => {
    setSportLevel("");
    setSport("");
    setGender("");
    setState("");
    setAthleteId("");
    setAthletes(athlete?.athletes);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col align="center" className="mt-4 mb-2">
            <div className="ChatLeft">
              <h3 className="pt-4">
                <b> TEACHER - ATHLETES</b> Available to chat now or next 48
                hours
              </h3>
              <h5 className="px-5">
                (The opinions offered by teacher-athletes in their answers are
                not guaranteed to be accurate.For legal advice,such as regarding
                amateur sports matters,please consult your own attorney.)
              </h5>
              <Row className=" justify-content-center">
                <Col lg="12" sm="12">
                  <div className="complaint-card mt-4">
                    <div className="complaint-form">
                      <Row className="mt-3 justify-content-center">
                        <Col lg="4" align="center" className="mt-3">
                          <InputGroup className="custom-input-group">
                            <InputGroupText>
                              <i className="fa fa-search"></i>
                            </InputGroupText>
                            <Autocomplete
                              className="input_feild"
                              {...defaultSportLevelProps}
                              id="auto-complete"
                              autoComplete
                              disableClearable={true}
                              disableListWrap
                              includeInputInList
                              value={sportLevel}
                              freeSolo
                              onChange={(e, val) => {
                                setId("");
                                setSportLevel(val);
                              }}
                              renderInput={(params) => (
                                <CssTextField
                                  {...params}
                                  className={classes.text_input}
                                  variant="standard"
                                />
                              )}
                            />
                          </InputGroup>
                          <Label className="auth-desc-subtitle ml-0 mt-2">
                            College Athlete or H.S. Athlete
                          </Label>
                        </Col>
                        <Col lg="4" align="center" className="mt-3">
                          <InputGroup className="custom-input-group">
                            <InputGroupText>
                              <i className="fa fa-search"></i>
                            </InputGroupText>
                            <Autocomplete
                              className="input_feild"
                              {...defaultSportProps}
                              id="auto-complete"
                              autoComplete
                              disableClearable={true}
                              disableListWrap
                              freeSolo
                              includeInputInList
                              value={sport}
                              onChange={(e, val) => {
                                setId("");
                                setSport(val);
                              }}
                              renderInput={(params) => (
                                <CssTextField {...params} variant="standard" />
                              )}
                            />
                          </InputGroup>
                          <Label className="auth-desc-subtitle ml-0 mt-2">
                            Sport
                          </Label>
                        </Col>
                        <Col lg="4" align="center" className="mt-3">
                          <InputGroup className="custom-input-group">
                            <InputGroupText>
                              <i className="fa fa-search"></i>
                            </InputGroupText>
                            <Autocomplete
                              className="input_feild"
                              {...defaultGenderProps}
                              id="auto-complete"
                              autoComplete
                              disableClearable={true}
                              disableListWrap
                              freeSolo
                              includeInputInList
                              value={gender}
                              onChange={(e, val) => {
                                setId("");
                                setGender(val);
                              }}
                              renderInput={(params) => (
                                <CssTextField {...params} variant="standard" />
                              )}
                            />
                          </InputGroup>
                          <Label className="auth-desc-subtitle ml-0 mt-2">
                            Gender
                          </Label>
                        </Col>
                        <Col lg="4" align="center" className="mt-3">
                          <InputGroup className="custom-input-group_QuestionId_Input">
                            <InputGroupText>
                              <i className="fa fa-search"></i>
                            </InputGroupText>
                            <Input
                              className="QuestionId_Input"
                              value={athleteId}
                              onChange={(e) => {
                                setAthleteId(e.target.value);
                              }}
                            />
                          </InputGroup>
                          <Label className="auth-desc-subtitle ml-0 mt-2">
                            Athlete ID#
                          </Label>
                        </Col>
                        <Col lg="4" align="center">
                          <InputGroup className="custom-input-group">
                            <InputGroupText>
                              <i className="fa fa-search"></i>
                            </InputGroupText>
                            <Autocomplete
                              className="input_feild"
                              {...defaultStateProps}
                              id="auto-complete"
                              autoComplete
                              includeInputInList
                              disableClearable={true}
                              disableListWrap
                              value={state}
                              freeSolo
                              onChange={(e, val) => {
                                setState(val);
                              }}
                              renderInput={(params) => (
                                <CssTextField {...params} variant="standard" />
                              )}
                            />
                          </InputGroup>
                          <Label className="auth-desc-subtitle ml-0 mt-2">
                            State
                          </Label>
                        </Col>
                      </Row>{" "}
                      <Row className=" d-flex justify-content-center     align-items-center my-2">
                        <Button
                          className="bg-transparent b-none pt-3 d-flex 
                          justify-content-center"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              college !== "" ||
                              sport !== "" ||
                              gender !== "" ||
                              state !== ""
                            ) {
                              let resArr =
                                athlete?.athletes.length > 0 &&
                                athlete?.athletes?.filter((ath) => {
                                  if (
                                    ath.college
                                      .toLowerCase()
                                      .includes(college.toLowerCase()) &&
                                    ath.sport
                                      .toLowerCase()
                                      .includes(sport.toLowerCase()) &&
                                    ath.gender
                                      .toLowerCase()
                                      .includes(gender.toLowerCase()) &&
                                    ath.state
                                      .toLowerCase()
                                      .includes(state.toLowerCase())
                                  ) {
                                    return ath;
                                  }
                                });
                              if (resArr.length > 0 && !resArr[0].blocked) {
                                setAthletes(resArr);
                              } else {
                                toast.warning("No Player Found!");
                              }
                            } else if (athleteId !== "") {
                              let resArr =
                                athlete?.athletes.length > 0 &&
                                athlete?.athletes?.filter(
                                  (ath) => ath.athleteID == athleteId
                                );
                              if (resArr.length > 0 && !resArr[0].blocked) {
                                setAthletes(resArr);
                              } else {
                                toast.warning("No Player Found!");
                              }
                            } else {
                            }
                          }}
                        >
                          <img src={ClickHere} alt="Click Here" height="60px" />
                          <br />
                          <Label className="auth-desc-subtitle ml-0 mt-2 ">
                            Click to Search
                          </Label>
                        </Button>
                        <Button
                          color="primary"
                          className="ml-3"
                          onClick={handelClear}
                        >
                          Clear
                        </Button>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <div className="chat">
                    <Table className="table" responsive>
                      <thead>
                        <tr>
                          <th>Athlete name </th>
                          <th>AthleteID</th>
                          <th>School Level</th>
                          <th>State</th>
                          <th>Gender</th>
                          <th>Sport</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {athletes &&
                          athletes?.map((data) => {
                            const isAthleteInTimeslot = timeSlots.some(
                              (slot) => slot.athleteId === data?.id
                            );
                            timeSlots.map((items) => {});
                            if (isAthleteInTimeslot) {
                              return (
                                <tr key={data?.athleteID}>
                                  <td>
                                    {data?.first_name} {data?.last_name}
                                  </td>
                                  <td>{data?.athleteID}</td>
                                  <td>{data?.sportLevel}</td>
                                  <td>{data?.state}</td>
                                  <td>{data?.gender}</td>
                                  <td>{data?.sport}</td>
                                  <td className="p-0 text-center btn-custom">
                                    <Link
                                      to={`/search-player/${data?.id}/buy-rights`}
                                    >
                                      <Button className="mt-2 custom-btn">
                                        View Profile
                                      </Button>
                                    </Link>
                                    <Button
                                      onClick={() => {
                                        handleContactModal();
                                      }}
                                      className="mt-2 custom-btn ml-3"
                                    >
                                      Contact
                                    </Button>
                                    <Modal
                                      isOpen={contactModal}
                                      toggle={handleContactModal}
                                      centered
                                    >
                                      <ModalBody>
                                        <Form
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            let payload = {
                                              email: user?.email,
                                              fanDescription: fanDescription,
                                              athleteEmail: data?.email,
                                              questionId: questionId,
                                            };
                                            dispatch(
                                              sendEmailToAthleteNotification(
                                                payload,
                                                () => {
                                                  handleContactModal();
                                                  setFanDescription("");
                                                  setQuestionId("");
                                                }
                                              )
                                            );
                                          }}
                                        >
                                          <p>
                                            Hi,I'm a sports fan registered at
                                            FansHelpPlayers. i put a question in
                                            the chat question database.
                                            <br /> (Question ID#:
                                            <Input
                                              required
                                              className=" d-inline w-auto"
                                              value={questionId}
                                              onChange={(e) =>
                                                setQuestionId(e.target.value)
                                              }
                                            />
                                            ).if you think you could answer this
                                            question for me,please visit the
                                            homepage for chat at FansHelpPlayers
                                            to signal me. i look forward to your
                                            answer on the website
                                          </p>

                                          <Button
                                            className="float-right mt-4"
                                            color="primary"
                                            type="submit"
                                            disabled={!isQuestionIdExists}
                                          >
                                            {replyLoader ? (
                                              <Spinner size="sm" />
                                            ) : (
                                              "Send"
                                            )}
                                          </Button>
                                        </Form>
                                      </ModalBody>
                                    </Modal>
                                  </td>
                                </tr>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BulletinBoards;
