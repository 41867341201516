import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button, Container } from "reactstrap";
import { Link } from "react-router-dom";
function HomeAthlete() {
  return (
    <div className="home-container">
      <Container className="term-and-condition-main mt-5">
         <p className="terms-title">
          INFO for: Amateur athletes Becoming BRAND AMBASSADOR for FANSHELPPLAYERS
          brand group of websites and programs:
        </p>
        <div>
        <ul>
        <li className="termsPoint">
              <p>
                By agreeing to do{" "}
                <b>
                  <a target="_blank" href="https://athlete-for-brand.web.app">
                    AMBASSADOR4BRAND.com
                  </a>
                </b>{" "}
                work, you agree to be an <b>AMBASSADOR</b> for the{" "}
                <b>FANSHELPPLAYERS</b> brand.
              </p>
            </li>
            <li className="termsPoint">
              <p>
                The <b>FANSHELPPLAYERS</b> brand includes{" "}
                <b>
                  <a
                    target="_blank"
                    href="https://fans-help-players-athlete.web.app"
                  >
                    FANSHELPPLAYERS.com
                  </a>
                </b>
                ,{" "}
                <b>
                  <a
                    target="_blank"
                    href="https://match4future-platform.web.app"
                  >
                    MATCH4FUTURE.com
                  </a>
                </b>
                ,{" "}
                <b>
                  <a
                    target="_blank"
                    href="https://collective-alums-main.web.app"
                  >
                    COLLECTIVEALUMS.com
                  </a>
                </b>
                ,{" "}
                <b>
                  <a href="https://fhp-nft.web.app" target="_blank">
                    FHPNFT.com
                  </a>
                </b>{" "}
                ,
                <b>
                  <a href="https://athlete-for-brand.web.app" target="_blank">
                    AMBASSADOR4BRAND.com
                  </a>
                </b>{" "}
                , and promotional support to a non-profit 501c3 called{" "}
                <b>
                  <a target="_blank" href="https://unkle501.web.app">
                    UNKLE501.com
                  </a>
                </b>
                .
              </p>
            </li>
            <li className="termsPoint">
              <p>
                You will be expected to{" "}
                <b>
                  familiarize yourself with the sites (see summary of each
                  below).
                </b>
              </p>
            </li>
        </ul>
        </div>
        </Container>
      <div className="text-center term-and-condition-main mt-4">
        <a
          href="https://youtu.be/uIWftF7cjug"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h5>https://youtu.be/uIWftF7cjug</h5>
        </a>
      </div>
      <div className="d-flex pt-3 justify-content-center align-items-center">
        <Link to="/terms-and-condition-for-athlete">
          <Button color="primary" className="login-btn text-white">INSTANT CHAT</Button>
        </Link>
        <Link to="/learn-with-expert-athlete">
          <Button color="primary"  className="login-btn text-white">
          Learn to teach by video links & site experience
          </Button>
        </Link>
        <Link to="/summary-athlete">
          <Button color="primary"  className="login-btn text-white">
            Summ
            <span>a</span>
            ry
          </Button>
        </Link>
      </div>
      <h3 className="mt-3 mx-5 px-5 d-flex text-center">
        IMPORTANT: During CHAT, please comply with rules of NCAA/amateur sports.
        Best not to discuss Gambling, Recruitment, Transfer Portal, alcohol,
        drugs, or other subject matter which could violate regulations.
      </h3>
      <h5 className="mx-5 px-5 pt-2 text-center">
        <b>Note:</b>The opinions offererd by teacher-athletes in their answers
        are not guaranteed to be accurate.For legal advice regarding amateur
        sport, please consult your own attorney.
      </h5>
      <p className="mt-3 text-center">
          <b className="d-flex justify-content-center align-items-center"> 
          <h2 className="mr-2">
          Continue:
            <Link to="/Login">
              <span className="text-primary"> Login </span>
            </Link> </h2> {" "}
          </b>
        </p>
    </div>
  );
}

export default HomeAthlete;
