import AuthView from "../views/auth/AuthView";
import Home from "../views/Home";
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import ProfileCard from "../components/ProfileCard";
import FilterPlayers from "../views/FilterPlayers";
import BuyRights from "../views/BuyRights";
import Chat from "../views/Chat";
import Setting from "../views/Setting";
import PasswordChange from "../views/PasswordChange";
import TermsAndCondition from "../views/TermsAndCondition";
import Summary from "../views/Summary"; 
import NewHome from "../views/NewHome";
import TimeSlots from "../views/TimeSlots";
import AvailableSlots from "../views/AvailableSlots";
import SuggestionBox from "../components/SuggestionBox";
import LearnWithExpert from "../views/LearnWithExpert";
import BulletinBoards from "../views/BulletinBoards";
import QA from "../views/QA";
import AskQuestion from "../views/AskQuestion";
import Library from "../views/Library";
import OffPlatformToSite from "../views/OffPlatformToSite";
import HomeAthlete from "../views/HomeAthlete";
import TermsAndConditionForAthlete from "../views/TermsAndConditionForAthlete";
import LearnWithExpertAthlete from "../views/LearnWithExpertAthlete";
import SummaryAthlete from "../views/SummaryAthlete";
import ChatInAction from "../views/ChatInAction";
import AthleteAnnualEarning from "../views/AthleteAnnualEarning";
import AthleteAccountMonthly from "../views/AthleteAccountMonthly";
import Favorites from "../views/Favorites";
import FilterGroupPlayers from "../views/FilterGroupPlayers";
import VolunteerRequest from "../views/VolunteerRequest";
import GroupChat from "../views/GroupChat";
import Managers from "../views/Managers";
let routes = [
  {
    path: "/manager",
    component: Managers,
    layout: "main",
  },
  {
    path: "/volunteer-requests/:id/accept",
    component: VolunteerRequest,
    layout: "main",
  },
  {
    path: "/favorite",
    component: Favorites,
    layout: "main",
  },
  {
    path: "/athlete-account-monthly",
    component: AthleteAccountMonthly,
    layout: "main",
  },
  {
    path: "/annual-earning",
    component: AthleteAnnualEarning,
    layout: "main",
  },
  {
    path: "/auth",
    component: AuthView,
    layout: "auth",
  },
  {
    path: "/home",
    component: Home,
    layout: "public",
  },
  {
    path: "/home-for-athlete",
    component: HomeAthlete,
    layout: "public",
  },
  {
    path: "/login",
    component: Login,
    layout: "auth",
  },
  {
    path: "/terms-and-condition",
    component: TermsAndCondition,
    layout: "public",
  },
  {
    path: "/terms-and-condition-for-athlete",
    component: TermsAndConditionForAthlete,
    layout: "public",
  },
  {
    path: "/learn-with-expert",
    component: LearnWithExpert,
    layout: "public",
  },
  {
    path: "/learn-with-expert-athlete",
    component: LearnWithExpertAthlete,
    layout: "public",
  },
  {
    path: "/summary",
    component: Summary,
    layout: "public",
  },
  {
    path: "/summary-athlete",
    component: SummaryAthlete,
    layout: "public",
  },
  {
    path: "/",
    component: NewHome,
    layout: "auth",
  },
  {
    path: "/dashboard",
    component: Dashboard,
    layout: "main",
  },
  {
    path: "/teacher-athletes",
    component: BulletinBoards,
    layout: "main",
  },
  {
    path: "/q-a",
    component: QA,
    layout: "main",
  },
  {
    path: "/ask-questions",
    component: AskQuestion,
    layout: "main",
  },
  {
    path: "/library",
    component: Library,
    layout: "main",
  },
  {
    path: "/off-platform-to-site",
    component: OffPlatformToSite,
    layout: "main",
  },
  {
    path: "/time-slot",
    component: TimeSlots,
    layout: "main",
  },
  {
    path: `/available-slots/:id`,
    component: AvailableSlots,
    layout: "main",
  },
  {
    path: "/profile",
    component: ProfileCard,
    layout: "main",
  },
  {
    path: "/search-player/filter-player",
    component: FilterPlayers,
    layout: "main",
  },
  {
    path: "/search-player/:id/buy-rights",
    component: BuyRights,
    layout: "main",
  },
  {
    path: "/search-group-player/:id/buy-rights",
    component: FilterGroupPlayers,
    layout: "main",
  },
  {
    path: "/Chat",
    component: Chat,
    layout: "main",
  },
  {
    path: "/group-chat",
    component: GroupChat,
    layout: "main",
  },
  {
    path: "/complete-chat",
    component: ChatInAction,
    layout: "main",
  },
  {
    path: "/setting",
    component: Setting,
    layout: "main",
  },
  {
    path: "/PasswordChange",
    component: PasswordChange,
    layout: "main",
  },
  {
    path: "/suggestion-box",
    component: SuggestionBox,
    layout: "main",
  },
];
export default routes;
