import React from 'react'
import { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap'

const ArrangeChatAppointmentModal = ({isShow,toggleInstantChat}) => {
  return (
    <>
       <Modal isOpen={isShow} toggle={toggleInstantChat} size="lg" centered>
        <ModalBody>
          <ol>
           <li>Fan enters QUESTION using 'Ask Question'</li>
           <li>Fan click 'Athletes Ready to Chat'</li>
           <li>Fan views Teacher\Athlete selection in database</li>
           <li>Fan Selects a Teacher Athlete and views their calendar</li>
           <li>Fan picks date/time from available calendar options</li>
           <li>Fan sends Request to chosen Teacher-Athlete (i.e Fan clicks "Book" button).</li>
           <li>Teacher-Athlete, if accepting chat, send offer to Fan's 'My Appt chat'</li>
           <li>Fan, if agreeing escrows required funds and they proceed to chat per appt.</li>
           <li>Fan and Teacher-Athlete can email each other to adjust time/date of chat</li>
          </ol>
          <div className="text-right">
            <Button color="primary" onClick={() => toggleInstantChat()}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ArrangeChatAppointmentModal
