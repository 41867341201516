const initialState = {
  completeChat: [],
  hasMoreDocuments: true,
  lastDocument: "",
};

const myChatInActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CHAT_ACTION_COMPLETE":
      return {
        completeChat: [...state.completeChat, ...action.payload.athleteData],
        hasMoreDocuments: action.payload.hasMoreDocuments,
        lastDocument: action.payload.lastDocument,
      };
    case "GET_CHAT_ACTION_COMPLETE_EMPTY":
      return {
        completeChat: action.payload.athleteData,
        hasMoreDocuments: action.payload.hasMoreDocuments,
        lastDocument: action.payload.lastDocument,
      };
    default:
      return {
        ...state,
      };
  }
};

export default myChatInActionReducer;
