import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import { logout, updateAgreeAndLogin } from "../store/actions/authAction";
import { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Box } from "@mui/material";
import chatImg from "../assets/img/PER_CENTCHAT_.jpg";

const Main = (props) => {
  let dispatch = useDispatch();
  const history = useHistory();

  let auth = useSelector((state) => state.authUser);
  let { agreeToTerms } = useSelector((state) => state.auth);
  const [agree, setAgree] = useState(false);
  const handleCheckboxChange = (event) => {
    setAgree(event.target.checked);
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (!auth?.user?.a4b_agree) {
      toggle();
    }
  }, [agreeToTerms]);
  if (!auth.uid) {
    history.push("/login");
  }

  //                   one hour no activity logout Logic
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const handleActivity = () => {
    localStorage.setItem("lastActivityTime", new Date().getTime());
  };
  useEffect(() => {
    const checkInactivity = () => {
      const lastActivityTime = localStorage.getItem("lastActivityTime");
      const currentTime = new Date().getTime();
      const oneHourInMillis = 60 * 60 * 1000;
      if (currentTime - lastActivityTime >= oneHourInMillis) {
        setIsLoggedIn(false);
        localStorage.removeItem("lastActivityTime");
      }
    };
    const interval = setInterval(checkInactivity, 60 * 1000);
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (!isLoggedIn) {
      const logoutCallback = () => {
        dispatch(logout(auth.uid));
      };
      logoutCallback();
    }
  }, [isLoggedIn, dispatch, logout]);
  return (
    <>
      <Header />
      {props.children}
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader>Terms and Conditions</ModalHeader>
        <ModalBody>
          <Box
            component="img"
            src={chatImg}
            className="d-flex m-auto"
            width={200}
          />
          <p>
            Users agree that Chat escrow can be held until chat rating is
            applied by fan, or for up to 1 week if fan gives no rating. Users
            agree escrow will be settled according to the following formula: If
            FAN selects Satisfactory as rating for Chat, or FAN does not
            indicate any rating in 7 days after escrow is funded, then Athlete
            receives 88% of escrow monies, Admin receives 12%.
          </p>

          <p>
            If FAN selects Unsatisfactory as rating for Chat, and FAN rates
            Athlete as NO SHOW, then Athlete receives 23% of escrow monies,
            Admin receives 12%, and FAN receives refund of 65% of escrow.
          </p>

          <p>
            If FAN selects Unsatisfactory as rating for Chat, and FAN rates
            Athlete as Rude/Short time, then Athlete receives 53% of escrow
            monies, Admin receives 12%, and FAN receives refund of 35% of
            escrow.
          </p>

          <div className="mx-3">
            <Input
              type="checkbox"
              checked={agree}
              onChange={handleCheckboxChange}
            />
            <span>
              I agree not to discuss any topic in my chats which could reflect
              negatively on my team, my coaches, my school or amateur athletics.
              Specifically, I agree not to discuss gambling, drugs, alcohol, or
              any other subjects detrimental to amateur status. I understand
              that it is possible for my words to be recorded during my chats or
              otherwise reported. I pledge to conduct myself in a respectful and
              mature manner.
            </span>{" "}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              dispatch(
                updateAgreeAndLogin(auth?.uid, () => {
                  toggle();
                  setAgree(false);
                })
              );
            }}
            disabled={!agree}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Main;
