import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Collapse,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { updateChatRate } from "../store/actions/authAction";

const ChatRateChange = () => {
  const dispatch = useDispatch();
  let { passwordLoading } = useSelector((state) => state.auth);
  let auth = useSelector((state) => state.authUser);

  const [newRate, setNewRate] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  
  const editCollapse = () => {
    setEditOpen(!editOpen);
  };
  return (
    <Container fluid className="changePswrdMain mt-4 mt-md-0">
      <Row className="no-gutters justify-content-center">
        <Col lg="7">
          <div className="complaint-card mt-4">
            <div className="complaint-form">
              <h5 className="card-title">Change Chat Rate </h5>
              <Row>
                <Col sm="6">
                  <p className="edit-password-info">Current chat rate:</p>
                </Col>
                <Col align="end">
                  <div className="d-flex">
                    <Input
                      className="form-control mr-2"
                      type="number"
                      value={auth.user.chatRate}
                      readOnly
                    />

                    <p
                      className="edit-password-info text-white ml-auto px-4 bg-gradient"
                      style={{ cursor: "pointer" }}
                      onClick={editCollapse}
                    >
                      Edit
                    </p>
                  </div>
                </Col>
              </Row>
              <Collapse isOpen={editOpen}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    let obj = {
                      newRate: newRate,
                    };
                    dispatch(
                      updateChatRate({
                        obj,
                        onSuccess: () => {
                          setNewRate("");
                        },
                      })
                    );
                  }}
                >
                  <Label className="mt-2">New Chat Rate</Label>

                  <div className="paswordChangeOuter">
                    <Input
                      required
                      className="edit-password-info"
                      type="number"
                      placeholder="New chat Rate(Click here)"
                      value={newRate}
                      onChange={(e) => {
                        if (/^\d+$/.test(e.target.value)) {
                          setNewRate(e.target.value);
                        }}}
                    />
                  </div>
                  <div className="text-right mt-3">
                    <Button className="mb-0 px-5" color="primary" type="submit">
                      {passwordLoading ? <Spinner size="sm" /> : "Update"}
                    </Button>
                  </div>
                </Form>
              </Collapse>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatRateChange;
