import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuestions,
  sendEmailToFanNotification,
} from "../store/actions/questionAnswerActions";
import SearchQuestions from "./SearchQuestions";

const QA = (args) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { user } = useSelector((state) => state.authUser);
  const { allQuestions, replyLoader } = useSelector((state) => state.question);
  const [athleteEmail, setAthleteEmail] = useState(user?.email);

  const dispatch = useDispatch();

  const [replyModal, setReplyModal] = useState(false);
  const handleReplyModal = () => setReplyModal(!replyModal);
  const [athleteDescription, setAthleteDescription] = useState("");
  const [quesDetails, setQuesDetails] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [questionDetails, setQuestionDetails] = useState();
  const [description, setDescription] = useState("");

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggletooltip = () => setTooltipOpen(!tooltipOpen);
  useEffect(() => {
    dispatch(fetchQuestions());
  }, []);

  const getFilteredData = () => {
    if (questions?.length > 0) {
      return questions;
    } else {
      return allQuestions;
    }
  };

  return (
    <>
      {allQuestions?.length > 0 && (
        <Tooltip
          {...args}
          isOpen={tooltipOpen}
          target="TooltipExample"
          toggle={toggletooltip}
        >
          {description}
        </Tooltip>
      )}
      <Container className="justify-content-center mbl">
        <SearchQuestions questions={questions} setQuestions={setQuestions} />
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Sport Focus</th>
              <th>State Focus</th>
              <th>Gender Focus </th>
              <th className="width-table-th">HS and/or College Focus</th>
              <th>Fan Focus, Hover over dots</th>
              <th>QuestionId</th>
              <th>For Details, Hover over dots</th>
              <th>topic</th>
              <th className="text-center">lesson</th>
              <th className="text-center">Question PRIORITY</th>
              <th>Fan Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <>
            <tbody>
              {allQuestions?.length > 0 ? (
                getFilteredData()?.map((ques) => {
                  return (
                    <tr>
                      <td>{ques?.sport}</td>
                      <td>{ques?.state}</td>
                      <td>{ques?.gender}</td>
                      <td>
                        {ques?.college == "hs-athlete"
                          ? "High School"
                          : "College"}
                      </td>
                      <td>{ques?.question}</td>
                      <td>{ques?.id}</td>
                      <td>
                        {ques?.description.slice(0, 10)}
                        <a
                          id="TooltipExample"
                          onMouseOver={() => setDescription(ques?.description)}
                        >
                          ....
                        </a>
                      </td>
                      <td>{ques?.topic}</td>
                      <td className="font_size">{ques?.leson?.name}</td>
                      <td className="text-center">
                        {ques?.priority == "paid" ? "High" : "Normal"}
                      </td>
                      <td>{ques?.email}</td>

                      <td className="d-flex">
                        <Button
                          className="mx-2 p-1"
                          color="primary"
                          onClick={() => {
                            toggle();
                            setQuestionDetails(ques);
                          }}
                        >
                          View Details
                        </Button>
                        {user?.role === "athlete" && (
                          <Button
                            color="primary"
                            onClick={() => {
                              handleReplyModal();
                              setQuesDetails(ques);
                            }}
                          >
                            Reply
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  Questions not found
                </div>
              )}
            </tbody>
          </>
        </Table>
      </Container>

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader>
          <h3>Question Focus</h3>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6} className="my-2">
              <Label>Priority</Label>
              <Input readOnly value={questionDetails?.priority} />
            </Col>
            <Col md={6} className="my-2">
              <Label>Sport Focus</Label>
              <Input readOnly value={questionDetails?.sport} />
            </Col>
            <Col md={6} className="my-2">
              <Label>Gender Focus</Label>
              <Input readOnly value={questionDetails?.gender} />
            </Col>
            <Col md={6} className="my-2">
              <Label>State Focus</Label>
              <Input readOnly value={questionDetails?.state} />
            </Col>
            <Col md={6} className="my-2">
              <Label>Topic</Label>
              <Input readOnly value={questionDetails?.topic} />
            </Col>
            <Col md={6} className="my-2">
              <Label>Level FOCUS</Label>
              <Input readOnly value={questionDetails?.college} />
            </Col>
            <Col md={12} className="my-2">
              <Label>Lesson</Label>
              <Input
                readOnly
                type="textarea"
                value={questionDetails?.leson?.name}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="btn" color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={replyModal} toggle={handleReplyModal} centered>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              let payload = {
                email: user?.email,
                athleteID: user?.athleteID,
                athleteEmail: athleteEmail,
                athleteDescription: athleteDescription,
                lesson: quesDetails?.lesson,
                fanEmail: quesDetails?.email,
              };
              dispatch(
                sendEmailToFanNotification(payload, () => {
                  handleReplyModal();
                  setAthleteDescription("");
                })
              );
            }}
          >
            <h4 className="text-center">Instant Chat Opportunity</h4>
            <p className="text-center">
              I saw the question you posted at FansHelpPlayers.I am a
              Teacher-Athlete, and my Registration Number is
              <Input
                className="w-25 bg-white d-inline"
                required
                value={user?.athleteID}
                type="text"
                disabled
              />
              . I am available now to Chat, if you are ready.I have some great
              information to share on the topic of your question.
            </p>{" "}
            <p className="text-center">
              If you can answer back on my email
              <Input
                className="w-auto d-inline"
                required
                value={athleteEmail}
                onChange={(e) => setAthleteEmail(e.target.value)}
                placeholder="Enter you registration Number"
                type="text"
              />
              within 5 minutes,I will respond,and we can chat right away. just
              make your request on the INSTANT CHAT.I will accept your
              request.Then you escrow the money,and we chat.
            </p>
            <Button className="float-right mt-4" color="primary" type="submit">
              {replyLoader ? <Spinner size="sm" /> : "Send"}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default QA;
