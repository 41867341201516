import { toast } from 'react-toastify'
import firebase from '../../config/firebase'

export const athleteRequestResetRating = (id) => async () => {
    let data = {
        athleteID: id,
        created_at: firebase.firestore.Timestamp.now(),
    }
    let requestPending = await firebase.firestore().collection("reset_athlete_summary").where("athleteID", "==", id).get()
    if (requestPending.docs.length == 0) {
        firebase.firestore().collection("reset_athlete_summary").add(data).then((ref) => {
            toast.success("Successfully add request")
        })
    } else {
        toast.warning("A reset request is already pending");
    }
}