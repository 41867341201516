import React from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";

const ChatDetailModal = (props) => {
  let { checkQuestionPriority } = useSelector((state) => state.chat);
  return (
    <Modal
      isOpen={props.showInstruction}
      toggle={props.handleShowInstruction}
      size="xl"
      centered
    >
      <ModalBody>
        <div className="d-flex justify-content-end">
          <div>
            <i
              onClick={props.handleShowInstruction}
              className="fas fa-times"
            ></i>
          </div>
        </div>
        <div>
          <p className="text-dark auth-desc-title mt-0">
            Chat Slot Booking details:
          </p>
          <table className="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Chat Rate x Minutes= $</th>
                {checkQuestionPriority.questionId ? (
                  <th>Subject of Fans Question(ID#________)</th>
                ) : null}
                <th className="text-center">High priority question</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table-chat-request">{props.data?.fan_email}</td>
                <td className="table-chat-request">
                  {props.data?.athlete_chat_rate * props.data?.chat_minutes}
                </td>
                {checkQuestionPriority.questionId ? (
                  <td className="table-chat-request">
                    {checkQuestionPriority.questionId}
                  </td>
                ) : null}
                <td className="text-center">
                  {checkQuestionPriority.isHighPriority}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChatDetailModal;
