import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Container, Row, Table } from "reactstrap";
import {
  athleteRequestFanOnStates,
  getFanLibraryStates,
  isAthleteRequest,
} from "../../store/actions/libraryAction";
import moment from "moment";

const FanLibrary = () => {
  const [loader, setLoader] = useState({
    dataLoader: false,
    loadMoreBtnLoader: false,
  });

  // const [BtnLoader, setBtnLoader] = useState(false);

  let dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  let { fanStates, isAthleteRequestFan, fanHasMoreDocument, fanLastDocument } =
    useSelector((state) => state.library);

  useEffect(() => {
    dispatch(
      getFanLibraryStates("", () => {
        setLoader((prev) => ({
          ...prev,
          dataLoader: true,
        }));
      })
    );
    dispatch(isAthleteRequest(user.id));
  }, []);

  const sendEmail = (items) => {
    let obj = {
      fanEmail: items.user.email,
      athleteName: user.first_name + " " + user.last_name,
      athleteEmail: user.email,
      fanID: items.fanId,
      athleteID: user.id,
    };
    dispatch(athleteRequestFanOnStates(obj));
  };

  const loadMore = () => {
    setLoader((prev) => ({
      ...prev,
      loadMoreBtnLoader: true,
    }));
    dispatch(
      getFanLibraryStates(fanLastDocument, () => {
        // setBtnLoader(false);
        setLoader((prev) => ({
          ...prev,
          loadMoreBtnLoader: false,
        }));
      })
    );
  };
  return (
    <div>
      <Container className=" justify-content-center mt-2">
        <Row className="justify-content-center">
          <Col xs={12} lg={12} className="text-center">
            <p>Note: The library data may take a few seconds to load.</p>
            <Card className="p-3 mb-3">
              {loader.dataLoader ? (
                <>
                  <Table className="table table_library" responsive>
                    <thead>
                      <tr>
                        <th>Fan</th>
                        <th>Fan Last Login</th>
                        <th>Last Chat Bought</th>
                        <th>Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fanStates?.map((items, index) => {
                        let condition = isAthleteRequestFan.includes(
                          items.fanId
                        );
                        return (
                          <tr key={index}>
                            <td>{items.user.name}</td>
                            <td>
                              {moment
                                .unix(items.user.lastLoggedIn.seconds)
                                .format("MM-DD-YYYY")}
                            </td>
                            <td>
                              {moment
                                .unix(items.lastBagBought.seconds)
                                .format("MM-DD-YYYY")}
                            </td>
                            <td>${items.amount}</td>
                            <td>
                              <Button
                                disabled={condition}
                                className="custom-btn"
                                onClick={() => sendEmail(items)}
                              >
                                Send Request
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <div className="mt-3">
                    {loader.loadMoreBtnLoader ? (
                      <i className="spinner-border text-primary mb-3"></i>
                    ) : (
                      <Button
                        color="primary"
                        onClick={loadMore}
                        disabled={!fanHasMoreDocument}
                      >
                        Load more
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                <div className="spinner-border text-primary loader mx-auto"></div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FanLibrary;
