import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Form,
  Label,
  Input,
  Table,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
  addAthleteTimeSlot,
  editAthleteTimeSlot,
  fanRequestedEmail,
  getAllChatsBySlotId,
  getAllGroupSlotsById,
  getAllslotsById,
  sendEmailFanForGroupSlot,
} from "../store/actions/chatAction";
import ChatBookingModal from "../components/ChatBookingModal";
import ViewBookingGroupModal from "../components/ViewBookingGroupModal";
import CreateSlotsModal from "../components/CreateSlotsModal";
function TimeSlots() {
  const dispatch = useDispatch();
  let { uid, user } = useSelector((state) => state.authUser);
  let { slots, groupSlots } = useSelector((state) => state.chat);
  const [modalSetTimeSlot, setModalSetTimeSlot] = useState(false);
  const [chatBookingModal, setChatBookingModal] = useState(false);
  const [slotDate, setSlotDate] = useState("");
  const [slotID, setSlotID] = useState("");
  const [slotData, setSlotData] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [standardTimeZOne, setstandardTimeZone] = useState("");
  const [slotType, setSlotType] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerType, setManagerType] = useState("");
  const [chatAmount, setChatAmount] = useState("");
  const [numberOfParticipants, setNumberOfParticipants] = useState("");
  const [GMTDiff, setGMTDiff] = useState(0);
  const [email, setEmail] = useState("primary");
  const [statusFilter, setStatusFilter] = useState("");
  const [managerTypeFilter, setManagerTypeFilter] = useState("");
  const [customEmail, setCustomEmail] = useState(user.email);
  const [dateFilter, setDateFilter] = useState("");
  const [fullDay, setFullDay] = useState(false);
  const [loaderMan, setLoaderMan] = useState(true);
  const [edit, setEdit] = useState(false);
  const [permanentManager, setPermanentManager] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTabFromQuery = searchParams.get("tab");
  const [activeTab, setActiveTab] = useState(activeTabFromQuery || "single");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleModalTimeSlots = () => {
    setEdit(false);
    setEditData("");
    setModalSetTimeSlot(!modalSetTimeSlot);
    setSlotDate("");
    setEndTime("");
    setStartTime("");
    setSlotType("");
    setManagerEmail("");
    setManagerType("");
    setNumberOfParticipants("");
    setChatAmount("");
  };
  const [editData, setEditData] = useState("");
  const editTimeSlots = (ele) => {
    setEdit(true);
    setModalSetTimeSlot(!modalSetTimeSlot);
    setEditData(ele);
  };
  const handleChatBookingModal = (id) => {
    if (chatBookingModal) {
      setSlotID("");
    } else {
      dispatch(getAllChatsBySlotId(id));
      setSlotID(id);
    }
    setChatBookingModal(!chatBookingModal);
  };
  const handleAddNewTimeSlot = () => {
    let payload = {
      athleteName: user.first_name + " " + user.last_name,
      id: uid,
    };
    if (fullDay) {
      const currentTime = moment(); // Get the current time

      const start = currentTime.format("HH:mm"); // Set the start time to the current time
      const end = currentTime.add(24, "hours").format("HH:mm"); // Add 24 hours to the current time for the end time

      let obj = {
        athleteId: uid,
        date: slotDate,
        start,
        end,
        status: "notBooked",
        type: "appointment",
        athlete_email: customEmail,
      };

      if (standardTimeZOne === "GMT") {
        obj.time_zone = `${standardTimeZOne}${
          GMTDiff > 0 ? `+${GMTDiff}` : GMTDiff
        }`;
      } else {
        obj.time_zone = standardTimeZOne;
      }
      if (edit) {
        dispatch(editAthleteTimeSlot(obj, slotData.id));
      } else {
        dispatch(
          addAthleteTimeSlot(obj, () => {
            dispatch(fanRequestedEmail(payload));
          })
        );
      }
      setSlotDate("");
      setEndTime("");
      setStartTime("");
      setstandardTimeZone("");
      setGMTDiff("");
      setEmail("primary");
      setCustomEmail(user.email);
      setModalSetTimeSlot(!modalSetTimeSlot);
    } else {
      const start = moment(`${slotDate} ${startTime}`, "YYYY-MM-DD HH:mm");
      const end = moment(`${slotDate} ${endTime}`, "YYYY-MM-DD HH:mm");

      if (start.isSameOrBefore(end) && !start.isSame(end)) {
        let obj = {
          athleteId: uid,
          date: slotDate,
          start: startTime,
          end: endTime,
          status: "notBooked",
          type: "appointment",
          athlete_email: customEmail,
          slotType: slotType,
        };
        if (slotType === "group") {
          if (managerType == "External") {
            obj.permanentManager = permanentManager;
            obj.managerEmail = managerEmail;
            obj.chatAmount = Number(chatAmount);
            obj.numberOfParticipants = Number(numberOfParticipants);
            obj.managerType = managerType;
            obj.perParticipantAmount = chatAmount / numberOfParticipants;
          } else {
            obj.chatAmount = Number(chatAmount);
            obj.numberOfParticipants = Number(numberOfParticipants);
            obj.managerType = managerType;
            obj.perParticipantAmount = chatAmount / numberOfParticipants;
          }
        }
        if (standardTimeZOne === "GMT") {
          obj.time_zone = `${standardTimeZOne}${
            GMTDiff > 0 ? `+${GMTDiff}` : GMTDiff
          }`;
        } else {
          obj.time_zone = standardTimeZOne;
        }

        if (edit) {
          dispatch(editAthleteTimeSlot(obj, slotData.id));
        } else {
          dispatch(
            addAthleteTimeSlot(obj, () => {
              dispatch(fanRequestedEmail(payload));
              if (slotType === "group") {
                dispatch(sendEmailFanForGroupSlot(user));
              }
            })
          );
        }
        setSlotType("");
        setManagerEmail("");
        setManagerType("");
        setNumberOfParticipants("");
        setChatAmount("");
        setSlotDate("");
        setEndTime("");
        setStartTime("");
        setstandardTimeZone("");
        setGMTDiff("");
        setEmail("primary");
        setCustomEmail(user.email);
        setModalSetTimeSlot(!modalSetTimeSlot);
      } else {
        toast.error(
          "Be aware! End time cannot be the same or before the start time"
        );
      }
    }
  };
  useEffect(() => {
    let obj = {
      athleteId: uid,
      type: "athlete",
    };
    dispatch(getAllslotsById(uid));
    dispatch(
      getAllGroupSlotsById(obj, () => {
        setLoaderMan(false);
      })
    );
  }, []);
  const handleClick = () => {
    setFullDay(!fullDay);
  };
  const filteredGroupSlots = groupSlots.filter((ele) => {
    const statusMatch =
      statusFilter === "" ||
      statusFilter === "all" ||
      ele.status === statusFilter;
    const managerTypeMatch =
      managerTypeFilter === "" ||
      managerTypeFilter === "all" ||
      ele.managerType === managerTypeFilter;
    const dateMatch = dateFilter === "" || ele.date === dateFilter;

    return statusMatch && managerTypeMatch && dateMatch;
  });
  const [openModal, setOpenModal] = useState(false);
  const toggled = () => {
    setOpenModal(!openModal);
  };
  return (
    <>
      <Container className="justify-content-center mbl">
        <Row>
          <Col sm="12">
            <p className="mt-5 text-center">
              <b>
                NOTE: Fan and Athlete can communicate with each other by email
                about issues such as time zones or if adjustment to the chat
                schedule Is needed.
              </b>
            </p>
          </Col>
          <Col md="4">
            <h2 className="mt-2">My Scheduled Chats:</h2>
          </Col>
          <Col md="2">
            <select
              className="form-control form-control-md mt-2"
              value={managerTypeFilter}
              onChange={(e) => {
                setManagerTypeFilter(e.target.value);
              }}
            >
              <option value="">-- Select --</option>
              <option value="Self">Self</option>
              <option value="External">Choose From Registered Fans</option>
              <option value="Volunteer">Ask For Volunteer</option>{" "}
            </select>
          </Col>
          <Col md="2">
            <select
              className="form-control form-control-md mt-2"
              value={statusFilter}
              onChange={(e) => {
                setStatusFilter(e.target.value);
              }}
            >
              <option disabled value="">
                Select Status
              </option>
              <option value="all">All</option>
              <option value="complete">Completed</option>
              <option value="booked">Booked</option>
              <option value="notBooked">Not booked</option>
              <option value="refunded">Refunded</option>
            </select>
          </Col>
          <Col md="2">
            <Input
              className="mt-2"
              type="date"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            />
          </Col>
          <Col md="2">
            <Button
              className="mt-2"
              color="primary"
              onClick={handleModalTimeSlots}
            >
              + New window
            </Button>
          </Col>
        </Row>
        <Nav className="mt-2" tabs>
          <NavItem className="cursor-pointer">
            <NavLink
              className={
                activeTab === "single"
                  ? " bg-primary text-white py-1 px-3 "
                  : " py-1"
              }
              onClick={() => {
                toggle("single");
              }}
            >
              <h5 className="m-0">Single Slots</h5>
            </NavLink>
          </NavItem>
          <NavItem className="cursor-pointer">
            <NavLink
              className={
                activeTab === "Group"
                  ? " bg-primary text-white py-1 px-3"
                  : " py-1"
              }
              onClick={() => {
                toggle("Group");
              }}
            >
              <h5 className="m-0">Group Slots</h5>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="px-3 pt-3">
          <TabPane tabId="single">
            <Table className="table" responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Tentative start</th>
                  <th>Tentative end</th>
                  <th>Time zone</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              {slots.length > 0 ? (
                slots.map((ele, index) => {
                  return (
                    <>
                      {(statusFilter == "" || statusFilter == "all") &&
                      dateFilter == "" ? (
                        <tbody key={index}>
                          <tr>
                            <td>{ele.date}</td>
                            <td>
                              {ele.start.split(":")[0] >= "12"
                                ? `${
                                    ele.start.split(":")[0] - 12 == 0
                                      ? 12
                                      : ele.start.split(":")[0] - 12
                                  }:${ele.start.split(":")[1]} PM`
                                : `${ele.start.split(":")[0]}:${
                                    ele.start.split(":")[1]
                                  } AM`}
                            </td>
                            <td>
                              {ele.end.split(":")[0] >= "12"
                                ? `${
                                    ele.end.split(":")[0] - 12 == 0
                                      ? 12
                                      : ele.end.split(":")[0] - 12
                                  }:${ele.end.split(":")[1]} PM`
                                : `${ele.end.split(":")[0]}:${
                                    ele.end.split(":")[1]
                                  } AM`}
                            </td>
                            <td>Fan and Athlete discuss</td>
                            <td>{ele?.athlete_email}</td>
                            <td className="button-width">
                              <Button
                                className="custom-btn text-nowrap  mr-md-2"
                                onClick={() => {
                                  handleChatBookingModal(ele.id, uid);
                                }}
                              >
                                View Bookings
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => editTimeSlots(ele)}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      ) : ele?.status == statusFilter &&
                        ele.date == dateFilter ? (
                        <tbody>
                          <tr>
                            <td>{ele.date}</td>
                            <td>
                              {ele.start.split(":")[0] > "12"
                                ? `${ele.start.split(":")[0] - 12}:${
                                    ele.start.split(":")[1]
                                  } PM`
                                : `${ele.start.split(":")[0]}:${
                                    ele.start.split(":")[1]
                                  } AM`}
                            </td>
                            <td>
                              {ele.end.split(":")[0] > "12"
                                ? `${ele.end.split(":")[0] - 12}:${
                                    ele.end.split(":")[1]
                                  } PM`
                                : `${ele.end.split(":")[0]}:${
                                    ele.end.split(":")[1]
                                  } AM`}
                            </td>
                            <td>{ele?.time_zone}</td>
                            <td>{ele?.athlete_email}</td>
                            <td className="button-width">
                              <Button
                                className="custom-btn text-nowrap  mr-md-2"
                                onClick={() => {
                                  handleChatBookingModal(ele.id, uid);
                                }}
                              >
                                View Bookings
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => editTimeSlots(ele)}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      ) : ele?.status == statusFilter && dateFilter == "" ? (
                        <tbody>
                          <tr>
                            <td>{ele.date}</td>
                            <td>
                              {ele.start.split(":")[0] > "12"
                                ? `${ele.start.split(":")[0] - 12}:${
                                    ele.start.split(":")[1]
                                  } PM`
                                : `${ele.start.split(":")[0]}:${
                                    ele.start.split(":")[1]
                                  } AM`}
                            </td>
                            <td>
                              {ele.end.split(":")[0] > "12"
                                ? `${ele.end.split(":")[0] - 12}:${
                                    ele.end.split(":")[1]
                                  } PM`
                                : `${ele.end.split(":")[0]}:${
                                    ele.end.split(":")[1]
                                  } AM`}
                            </td>
                            <td>{ele?.time_zone}</td>
                            <td>{ele?.athlete_email}</td>
                            <td className="button-width">
                              <Button
                                className="custom-btn text-nowrap  mr-md-2"
                                onClick={() => {
                                  handleChatBookingModal(ele.id, uid);
                                }}
                              >
                                View Bookings
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => editTimeSlots(ele)}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      ) : ele.date == dateFilter && statusFilter == "" ? (
                        <tbody>
                          <tr>
                            <td>{ele.date}</td>
                            <td>
                              {ele.start.split(":")[0] > "12"
                                ? `${ele.start.split(":")[0] - 12}:${
                                    ele.start.split(":")[1]
                                  } PM`
                                : `${ele.start.split(":")[0]}:${
                                    ele.start.split(":")[1]
                                  } AM`}
                            </td>
                            <td>
                              {ele.end.split(":")[0] > "12"
                                ? `${ele.end.split(":")[0] - 12}:${
                                    ele.end.split(":")[1]
                                  } PM`
                                : `${ele.end.split(":")[0]}:${
                                    ele.end.split(":")[1]
                                  } AM`}
                            </td>
                            <td>{ele?.time_zone}</td>
                            <td>{ele?.athlete_email}</td>
                            <td className="button-width">
                              <Button
                                className="custom-btn text-nowrap  mr-md-2"
                                onClick={() => {
                                  handleChatBookingModal(ele.id, uid);
                                }}
                              >
                                View Bookings
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => editTimeSlots(ele)}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })
              ) : (
                <tr>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                </tr>
              )}
            </Table>
          </TabPane>
          <TabPane tabId="Group">
            {loaderMan ? (
              <div className="text-center">
                {" "}
                <i className="spinner-border text-primary mb-3"></i>{" "}
              </div>
            ) : (
              <Table className="table" responsive>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Tentative start</th>
                    <th>Tentative end</th>
                    <th>Time zone</th>
                    <th>My Price</th>
                    <th>Email</th>
                    <th>Manager name</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                {groupSlots.length > 0 ? (
                  filteredGroupSlots.map((ele, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>{ele.date}</td>
                        <td>
                          {ele.start.split(":")[0] > "12"
                            ? `${ele.start.split(":")[0] - 12}:${
                                ele.start.split(":")[1]
                              } PM`
                            : `${ele.start.split(":")[0]}:${
                                ele.start.split(":")[1]
                              } AM`}
                        </td>
                        <td>
                          {ele.end.split(":")[0] > "12"
                            ? `${ele.end.split(":")[0] - 12}:${
                                ele.end.split(":")[1]
                              } PM`
                            : `${ele.end.split(":")[0]}:${
                                ele.end.split(":")[1]
                              } AM`}
                        </td>
                        <td>{ele?.time_zone || "Fan and Athlete discuss"}</td>
                        <td>{ele?.perParticipantAmount}</td>
                        <td>{ele?.athlete_email}</td>
                        <td>{ele.fan ? ele.fan.name : ele.managerType}</td>
                        <td className="d-flex flex-column justify-content-end align-items-center button-width">
                          <div className="d-flex flex-column flex-md-row">
                            <Button
                              className="custom-btn text-nowrap mb-2 mb-md-0 mr-md-2"
                              onClick={() => {
                                toggled();
                                setSlotData(ele);
                              }}
                            >
                              View Bookings
                            </Button>
                            {ele.status == "notBooked" && (
                              <Button
                                color="primary"
                                onClick={() => editTimeSlots(ele)}
                              >
                                Edit Slot
                              </Button>
                            )}
                          </div>
                          <div className="mt-2">
                            {ele?.managerType == "Volunteer" ? (
                              <Link to={`/volunteer-requests/${ele.id}/accept`}>
                                <Button color="primary" className="text-nowrap">
                                  See Volunteer
                                </Button>
                              </Link>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                )}
              </Table>
            )}
          </TabPane>
        </TabContent>
      </Container>
      {modalSetTimeSlot && (
        <CreateSlotsModal
          modalSetTimeSlot={modalSetTimeSlot}
          handleModalTimeSlots={handleModalTimeSlots}
          edit={edit}
          editData={editData}
          athleteId={uid}
          athleteEmail={user.email}
          slotType="athlete"
        />
      )}

      <ViewBookingGroupModal
        ShowViewBookingGroupModal={openModal}
        handelViewBookingGroupModal={() => {
          toggled();
        }}
        slotData={slotData}
        type="athlete"
      />
      <ChatBookingModal
        showChatBookingModal={chatBookingModal}
        handleChatBookingModal={() => {
          handleChatBookingModal("");
        }}
        slotID={slotID}
      ></ChatBookingModal>
    </>
  );
}

export default TimeSlots;
